import { Modal } from '@rivallapp/volosports-components';
import type { ComponentProps, FC } from 'react';
import PlayerDetails from './PlayerDetails';

type Props = ComponentProps<typeof Modal> & {
  userId: string;
};

/** Native-base modal version of the `PlayerDetailsPopup` */
const PlayerModal: FC<Props> = ({ userId, ...props }) => {
  return (
    <Modal size="full" useRNModal {...props}>
      <Modal.Content maxW="95%" minH={700}>
        <Modal.CloseButton />
        <Modal.Body pt={10}>
          <PlayerDetails userId={userId} />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default PlayerModal;
