import { useState } from 'react';
import { useMutation } from '@apollo/client';

// eslint-disable-next-line import/named
import { useAlertMessage, useLogout } from '../../hooks';
import { ConfirmButton } from '../../components';
import { FancyField } from '../../components/fancyFieldsV2';
import { DEACTIVATE_CURRENT_USER } from './ACCOUNT_FORMS_MUTATIONS_AND_QUERIES';

const DeactivateProfile = () => {
  const { showError, showSuccess } = useAlertMessage();
  const { logoutAndRedirect } = useLogout({ redirectPath: '/' });

  const [password, setPassword] = useState('');
  const [reason, setReason] = useState('');
  const [deactivateMutation, { loading }] = useMutation(DEACTIVATE_CURRENT_USER);

  const onClick = async () => {
    if (password.length < 4 || loading) return;
    try {
      await deactivateMutation({
        variables: {
          password,
          reason,
        },
      });
      showSuccess('Delete success, logging out.');
      logoutAndRedirect();
    } catch (e) {
      showError(e);
    }
  };

  return (
    <div>
      <div className="my-4">
        We&apos;re sorry to see you go!
        <FancyField
          className="mt-2"
          value={reason}
          onChange={v => setReason(v)}
          noLabel
          placeholder="Reason for deleting (optional)"
          name="explanationText"
          blockStyle
          heavyBorder
          textarea
          inputStyle={{ height: '125px' }}
        />
      </div>
      <div className="my-4">
        Please confirm your password to continue
        <FancyField
          className="mt-2"
          type="password"
          value={password}
          onChange={v => setPassword(v)}
          noLabel
          placeholder="Password"
          name="password"
          blockStyle
          heavyBorder
        />
      </div>
      <ConfirmButton danger disabled={password.length < 4} onClick={onClick}>
        Delete
      </ConfirmButton>
    </div>
  );
};

export default DeactivateProfile;
