/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMutation, useSuspenseQuery, skipToken } from '@apollo/client';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import { Suspense, useEffect, useState, type FC } from 'react';
import type { SingleValue } from 'react-select';
import type { ResultOf, VariablesOf } from 'graphql-schema';

import styles from './edit-game.module.scss';
import AddWeekDay from '../../../../apps/RFO/Manage/Schedule/EditSchedule/EditWeekItem/AddWeekDay';
import {
  Button,
  Error as ErrorComponent,
  FilterSelect,
  GenericErrorBoundary,
  Loading,
  Popup,
} from '../../../../components';
import { FancyField } from '../../../../components/fancyFieldsV2';
import minus from '../../../../images/icons/blue/less.svg';
import { useActionStates } from '../../../../hooks';
import {
  CREATE_GAME,
  GET_GAME_DETAILS,
  GET_LEAGUE_DETAILS,
  UPDATE_GAME,
  VENUE_SCHEDULE_FRAGMENT,
} from './graphql';
import hasuraClient from '../../../../apollo/hasuraClient';
import { isValidUUID } from '../../../../utils';

type GameState = {
  team1: string;
  team2: string;
  startTime: string;
  endTime: string;
  field_name: string;
  location?: ResultOf<typeof VENUE_SCHEDULE_FRAGMENT> | null;
  dayId: string;
  weekId: string;
};

const cx = classNames.bind(styles);

/**
 * Only allows gameId OR dayId, not both
 */
type EditGame = {
  leagueId: string;
  afterSave: () => Promise<void>;
  weekId?: string;
} & ({ gameId: string; dayId?: null } | { dayId: string; gameId?: null });

const EditGame: FC<EditGame> = ({
  gameId,
  leagueId,
  afterSave,
  dayId: initialDayId,
  weekId: initialWeekId,
}) => {
  const { setError } = useActionStates({ withAlerts: true });

  const [updateGameMutation] = useMutation(UPDATE_GAME, { client: hasuraClient });
  const [createGameMutation] = useMutation(CREATE_GAME, { client: hasuraClient });

  type LeagueWhereClauseType = VariablesOf<typeof GET_LEAGUE_DETAILS>['leagueWhereClause'];
  const leagueWhereClause: LeagueWhereClauseType = {
    // subschedule filter ported from v1
    // 1. allow approved/unapproved subschedules
    // 2. only show league schedules, not tournaments
    subschedules: {
      subschedule_approved: { _in: [true, false] },
      schedule_type: { _eq: 'league' },
    },
  };

  if (leagueId) {
    if (isValidUUID(leagueId)) {
      leagueWhereClause._id = { _eq: leagueId };
    } else {
      leagueWhereClause.external_id = { _eq: leagueId };
    }
  }

  const leagueRef = useSuspenseQuery(
    GET_LEAGUE_DETAILS,
    leagueId
      ? {
          fetchPolicy: 'network-only',
          variables: { leagueWhereClause },
          client: hasuraClient,
        }
      : skipToken
  );

  type GameWhereClauseType = VariablesOf<typeof GET_GAME_DETAILS>['gameWhereClause'];
  const gameWhereClause: GameWhereClauseType = {};

  if (gameId) {
    if (isValidUUID(gameId)) {
      gameWhereClause._id = { _eq: gameId };
    } else {
      gameWhereClause.external_id = { _eq: gameId };
    }
  }

  const gameRef = useSuspenseQuery(
    GET_GAME_DETAILS,
    gameId
      ? {
          fetchPolicy: 'network-only',
          variables: { gameWhereClause },
          client: hasuraClient,
        }
      : skipToken
  );

  const [gameState, setGameState] = useState<GameState>({
    team1: 'TBD',
    team2: 'TBD',
    startTime: '',
    endTime: '',
    field_name: '',
    location: undefined,
    dayId: '',
    weekId: '',
  });
  const [showSubLocation, setShowSubLocation] = useState(false);
  const [showNewDay, setShowNewDay] = useState(false);

  const updateGameState = (update: Partial<GameState>) => setGameState({ ...gameState, ...update });

  const { leagues = [] } = leagueRef?.data ?? {};
  const league = leagues?.[0];

  const { games = [] } = gameRef?.data ?? {};
  const game = games?.[0];

  const { teams = [], venue, days = [], weeks = [], organization, schedules = [] } = league ?? {};
  const { venues = [] } = organization ?? {};

  const defaultScheduleId = schedules[0]?._id; // until multi-schedules are used

  useEffect(() => {
    // only update if initial set
    const initialTeams = gameId ? game?.teams?.map(({ team }) => team) : teams.slice(0, 2);
    setGameState({
      team1: initialTeams?.[0]?._id ?? 'TBD',
      team2: initialTeams?.[1]?._id ?? 'TBD',
      startTime: (gameId ? game?.start_time_str : league?.start_time_estimate) ?? '18:00',
      endTime: (gameId ? game?.end_time_str : league?.end_time_estimate) ?? '21:00',
      field_name: game?.field_name ?? '',
      location: gameId ? game?.venue : venue,
      dayId: (gameId ? game?.day : initialDayId) ?? '',
      weekId: (gameId ? game?.week : initialWeekId) ?? '',
    });
    setShowSubLocation(!!game?.field_name);
    // We only want this useEffect to run on initial mount to prepopulate state correctly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (leagueRef.error || gameRef.error || !leagueId) {
    return (
      <ErrorComponent error={leagueRef.error || gameRef.error || 'No league found for game!'} />
    );
  }

  const { team1, team2, startTime, endTime, field_name, location, dayId, weekId } = gameState;

  const onSave = async () => {
    try {
      const sharedInput = {
        dayId,
        endTime,
        subvenue: field_name,
        startTime,
        teams: [team1, team2],
        venueId: location?._id ?? '',
      };
      const updateOnlyInput = {
        gameId: gameId!,
        weekId,
      };
      const createOnlyInput = {
        subscheduleId: defaultScheduleId ?? '',
      };
      // filter out TBD games if they don't know which yet
      if (gameId && game?.is_tournament) {
        sharedInput.teams = sharedInput.teams.filter(t => t !== 'TBD');
      }
      if (gameId) {
        await updateGameMutation({ variables: { input: { ...sharedInput, ...updateOnlyInput } } });
      } else {
        await createGameMutation({ variables: { input: { ...sharedInput, ...createOnlyInput } } });
      }
      afterSave();
    } catch (err) {
      setError(err);
    }
  };

  const changeWeek = (update: SingleValue<{ value: string; label: string }>) => {
    const weekDay = days.find(d => d.week === update?.value);
    updateGameState({ weekId: update?.value, dayId: weekDay?._id });
  };

  const addDay = async (newDayId: string) => {
    try {
      await leagueRef.refetch();
      updateGameState({ dayId: newDayId });
      setShowNewDay(false);
    } catch (err) {
      setError(err);
    }
  };

  const teamOptions = teams.map(t => ({ label: t.name, value: t._id }));
  const venueOptions = venues.map(v => ({ label: v.shorthand_name, value: v._id }));
  const weekOptions = weeks
    .toSorted((a, b) => a.week_num - b.week_num)
    .map(w => ({ label: `Week #${w.week_num}`, value: w._id }));
  // only show days within the selected week
  const dayOptions = days
    .filter(d => d.week === weekId)
    .map(d => ({ label: moment(d.date).format('MMMM Do, YYYY'), value: d._id }));

  return (
    <div className={cx('edit-game-popup')}>
      <Popup hidden={!showNewDay} close={() => setShowNewDay(false)}>
        <AddWeekDay
          weekId={weekId}
          leagueId={leagueId}
          afterSave={addDay}
          is_tournament={!!game?.is_tournament}
        />
      </Popup>
      <h2>{game ? 'Edit Game' : 'Add New Game'}</h2>
      <div className="row mt-3">
        <div className="col-12 col-md-6">
          <label>Team 1</label>
          <FilterSelect
            short
            shadow
            value={teamOptions.find(({ value }) => value === team1) ?? null}
            options={teamOptions}
            onChange={update => updateGameState({ team1: update?.value })}
            placeholder="Select Team"
            disabled={!!game?.is_tournament}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Team 2</label>
          <FilterSelect
            short
            shadow
            value={teamOptions.find(({ value }) => value === team2) ?? null}
            options={teamOptions}
            onChange={update => updateGameState({ team2: update?.value })}
            placeholder="Select Team"
            disabled={!!game?.is_tournament}
          />
        </div>
        {game?.is_tournament && (
          <div className="mt-3">
            Editing tournament team matchups is disabled. To change the teams, please remake the
            tournament with a different seed order.
          </div>
        )}
      </div>
      <div className="row my-4">
        <div className="col-12">
          <label>Venue</label>
          <FilterSelect
            skinny
            shadow
            value={venueOptions.find(({ value }) => value === location?._id) || null}
            options={venueOptions}
            onChange={update => {
              const foundVenue = venues.find(({ _id }) => _id === update?.value);
              if (foundVenue) {
                updateGameState({ location: foundVenue });
              }
            }}
            placeholder="Select Venue"
          />
          <p style={{ padding: '5px 0 0 2px', fontSize: '0.65em', textAlign: 'left' }}>
            Timezone: <b>{location?.timezone || venue?.timezone}</b>
          </p>
        </div>
      </div>
      {!!gameId &&
        !game?.is_tournament && ( // only allow already created games to change date
          <div className="row my-4">
            <div className="col-12 col-md-6">
              <label>Week</label>
              <FilterSelect
                skinny
                shadow
                value={weekOptions.find(({ value }) => value === weekId)}
                options={weekOptions}
                onChange={changeWeek}
                placeholder="Select Week"
              />
            </div>
            <div className="col-12 col-md-6">
              <label>Day</label>
              <FilterSelect
                skinny
                shadow
                value={dayOptions.find(({ value }) => value === dayId)}
                options={dayOptions}
                onChange={update => updateGameState({ dayId: update?.value })}
                placeholder="Select Day"
              />
              <div className={cx('add-location-link', 'my-2')}>
                <a onClick={() => setShowNewDay(true)} role="button" tabIndex={0}>
                  + Add a day
                </a>
              </div>
            </div>
          </div>
        )}
      <div className="row">
        <div className="col-12 col-md-6">
          <label>Start</label>
          <FancyField
            blockStyle
            noLabel
            value={startTime}
            onChange={(value: string) => updateGameState({ startTime: value })}
            type="time"
          />
        </div>
        <div className="col-12 col-md-6">
          <label>End</label>
          <FancyField
            blockStyle
            noLabel
            value={endTime}
            onChange={(value: string) => updateGameState({ endTime: value })}
            type="time"
          />
        </div>
      </div>
      {showSubLocation ? (
        <div className="row my-4">
          <div className="col-10">
            <label>Location</label>
            <FancyField
              grey
              noLabel
              value={field_name}
              onChange={(value: string) => updateGameState({ field_name: value })}
            />
          </div>
          <div className="col-2 my-auto p-0">
            <a
              onClick={() => {
                updateGameState({ field_name: '' });
                setShowSubLocation(false);
              }}
              role="button"
              tabIndex={0}
            >
              <img className={cx('icon-img')} src={minus} alt="minus" />
            </a>
          </div>
        </div>
      ) : (
        <div className={cx('add-location-link', 'my-2')}>
          <a onClick={() => setShowSubLocation(true)} role="button" tabIndex={0}>
            + Add a location
          </a>
        </div>
      )}
      <Button
        primary
        wide
        onClick={onSave}
        disabled={
          !(
            (game?.is_tournament || (team1 && team2 && team1 !== team2)) &&
            moment(endTime, 'HH:mm').isAfter(moment(startTime, 'HH:mm'))
          )
        }
      >
        Save
      </Button>
      <div className="row mt-3">
        <div className="col-12 col-md-auto">
          {(game?.created_by_name || game?.updated_at) && (
            <small className={cx('creator')}>
              <b>Last updated: </b> {game.updated_at ? moment(game.updated_at).format('lll') : ''}{' '}
              {game.created_by_name ? `by ${game.created_by_name}` : ''}
            </small>
          )}
        </div>
        <div className="col-12 col-md">
          {gameId && (
            <div className="text-right">
              <small>
                <b>GameId:</b> {gameId}
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EditGameWrapped: FC<EditGame> = props => (
  <GenericErrorBoundary>
    <Suspense fallback={<Loading />}>
      <EditGame {...props} />
    </Suspense>
  </GenericErrorBoundary>
);

export default EditGameWrapped;
