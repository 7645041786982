import { graphql } from 'graphql-schema';

const UPDATE_TEAM_ROLE = graphql(`
  mutation updateTeamRole($input: ActionsRegistrantsUpdateTeamRoleInput!) {
    updateTeamRole(input: $input) {
      teamId
    }
  }
`);

export default UPDATE_TEAM_ROLE;
