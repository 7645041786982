import { useCallback, useState, type FC } from 'react';
import { ApolloProvider, useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames/bind';

import styles from './player-details.module.scss';
import { UPDATE_USER_PROFILE } from './PLAYER_DETAILS_MUTATIONS';
import { Loading, TabNav } from '../../components';
import useActionStates from '../../hooks/useActionStates';
import PlayerInfo from './PlayerInfo/PlayerInfo';
import { GenderNameEnum, type ExtractedGenderEnum } from '../../shared/gender-enum';
import UserShiftLogsTab from './UserShiftLogsTab/UserShiftLogsTab';
import hasuraClient from '../../apollo/hasuraClient';
import { GET_USER } from './PLAYER_DETAILS_QUERIES';
import PlayerWaiversTab from './PlayerWaiversTab/PlayerWaiversTab';
import PlayerRecords from './PlayerRecords/PlayerRecords';
import PlayerParticipation from './PlayerParticipation/PlayerParticipation';
import { PlayerPaymentTab } from '.';

const cx = classNames.bind(styles);

type PlayerDetailsProps = {
  userId?: string;
};

const PlayerDetails: FC<PlayerDetailsProps> = props => {
  const { userId = '' } = props;

  const { data, loading, error } = useQuery(GET_USER, {
    skip: !userId,
    client: hasuraClient,
    fetchPolicy: 'network-only',
    variables: { userId },
  });

  const userDetails = data?.users_by_pk;

  const MemoPayment = useCallback(() => <PlayerPaymentTab userId={userId} />, [userId]);
  const MemoRecords = useCallback(() => <PlayerRecords userId={userId} />, [userId]);
  const MemoParticipation = useCallback(() => <PlayerParticipation userId={userId} />, [userId]);
  const MemoWaivers = useCallback(
    () => <PlayerWaiversTab userId={userId} userFullName={userDetails?.full_name} />,
    [userId, userDetails]
  );

  const MemoShiftLogs = useCallback(() => <UserShiftLogsTab userId={userId} />, [userId]);

  if (loading) return <Loading />;
  if (!userDetails || error || !userId)
    return <div>Error! {JSON.stringify(error || 'Missing userId')}</div>;

  // Users always have at least PLAYER. Any more and they have access to shift logs.
  const showShiftLogs = userDetails.roles.length > 1;

  return (
    <div className={cx('playerDetails')}>
      <div className="row">
        <PlayerInfo userDetails={userDetails} />
        <div className="col-12 col-md-10">
          <TabNav
            initialTab={1}
            allTabs={[
              { label: 'Payment', component: MemoPayment },
              { label: 'Participation', component: MemoParticipation },
              { label: 'Records', component: MemoRecords },
              { label: 'Waivers', component: MemoWaivers },
              ...(showShiftLogs ? [{ label: 'Shift Logs', component: MemoShiftLogs }] : []),
            ]}
          />
        </div>
      </div>
      <small className="float-right">userId: {userId}</small>
    </div>
  );
};

export default PlayerDetails;
