import { graphql, type ResultOf } from 'graphql-schema';

const ORGANIZATION_FRAGMENT = graphql(`
  fragment OrganizationDetails on organizations @_unmask {
    _id
    is_volo_pass_active
    volo_pass_monthly_plan_id
    volo_pass_monthly_price_cents
    volo_pass_monthly_fee_cents
  }
`);

export const LEAGUE_FRAGMENT = graphql(
  `
    fragment LeagueDetails on leagues {
      _id
      program_type
      is_volo_pass_exclusive
      organization: organizationByOrganization {
        ...OrganizationDetails
      }
    }
  `,
  [ORGANIZATION_FRAGMENT]
);

export const PRICE_BREAKDOWN_FRAGMENT = graphql(`
  fragment PriceBreakdownDetails on PricingBreakdown {
    afterDiscountCents
    creditAmount
    creditCents
    currentPhase
    donationAmount
    donationCents
    feePercentage
    flatFeeCents
    memberPrice
    membershipDiscountApplied
    nonMemberPrice
    originalPrice
    priceCents
    processingFeeCents
    processingFeePercentage
    programPriceCents
    promoCode
    promoDiscountCents
    supportFeeCents
    techFeeCents
    totalCents
    totalChargeCents
    totalFeeCents
    totalTransferCents
  }
`);

export const GET_CURRENT_USER_HOME_ORG = graphql(
  `
    query getCurrentUserHomeOrg($currentUserHomeOrgId: uuid!) {
      organizations_by_pk(_id: $currentUserHomeOrgId) {
        ...OrganizationDetails
      }
    }
  `,
  [ORGANIZATION_FRAGMENT]
);

export const PROGRAM_PRICING = graphql(
  `
    query programPricing(
      $programId: uuid!
      $regInput: ProgramPricingForRegistrationInput!
      $vpInput: ProgramPricingForRegistrationInput!
    ) {
      program: leagues_by_pk(_id: $programId) {
        ...LeagueDetails
      }
      regular: programPricingForRegistration(input: $regInput) {
        pricingBreakdown {
          ...PriceBreakdownDetails
        }
        promoCodeMessage
      }
      vp: programPricingForRegistration(input: $vpInput) {
        pricingBreakdown {
          ...PriceBreakdownDetails
        }
        promoCodeMessage
      }
    }
  `,
  [LEAGUE_FRAGMENT, PRICE_BREAKDOWN_FRAGMENT]
);

export type LeaguePricingData = ResultOf<typeof PROGRAM_PRICING>;

export const DROP_IN_PRICING_FRAGMENT = graphql(`
  fragment DropInPricingDetails on DropinPricingBreakdown {
    programPriceCents
    afterDiscountCents
    creditAmount
    creditCents
    donationAmount
    donationCents
    feePercentage
    flatFeeCents
    membershipDiscountApplied
    priceCents
    processingFeeCents
    techFeeCents
    supportFeeCents
    totalCents
    promoDiscountCents
    promoCode
    processingFeePercentage
    totalChargeCents
    totalFeeCents
    totalTransferCents
  }
`);

export const DROP_IN_PRICING = graphql(
  `
    query dropInPricing($programId: uuid!, $input: DropinPricingForRegistrationInput!) {
      program: leagues_by_pk(_id: $programId) {
        ...LeagueDetails
      }
      dropinPricingForRegistration(input: $input) {
        nonmemberBreakdown {
          ...DropInPricingDetails
        }
        memberBreakdown {
          ...DropInPricingDetails
        }
        currentUserPaymentCriteria
        promoCodeMessage
      }
    }
  `,
  [DROP_IN_PRICING_FRAGMENT, LEAGUE_FRAGMENT]
);

export type DropInPricingData = ResultOf<typeof DROP_IN_PRICING>;
