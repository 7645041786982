import { graphql } from 'graphql-schema';

const SEND_RENTAL_EMAILS = graphql(`
  mutation InsertRentalEmail($email: emails_insert_input!) {
    insert_emails_one(object: $email) {
      _id
    }
  }
`);

export default SEND_RENTAL_EMAILS;
