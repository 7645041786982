import { skipToken, useSuspenseQuery } from '@apollo/client';

import {
  DISCOVER_RENTAL_NEIGHBORHOODS,
  HASURA_DISCOVER_NEIGHBORHOODS,
} from '../graphql/DISCOVER_NEIGHBORHOODS';
import { View } from '../../../base-components';
import { CustomCheckbox } from '../../../custom-components';

import styles from '../styles';
import DiscoverFilterBoundary from '../DiscoverFilterBoundary/DiscoverFilterBoundary';

export type NeighborhoodFilterProps = {
  onPress?: (id: string) => void;
  cityName?: string;
  selectedNeighborhoodIds?: string[];
  isRental?: boolean;
};

const NeighborhoodFilter = ({
  onPress,
  cityName,
  isRental,
  selectedNeighborhoodIds = [],
}: NeighborhoodFilterProps) => {
  const { data: rentalNeighborhoodsData } = useSuspenseQuery(
    DISCOVER_RENTAL_NEIGHBORHOODS,
    cityName && isRental
      ? {
          fetchPolicy: 'cache-and-network',
          variables: { input: { cityName } },
        }
      : skipToken
  );
  const { data: programNeighborhoodsData } = useSuspenseQuery(
    HASURA_DISCOVER_NEIGHBORHOODS,
    cityName && !isRental
      ? {
          fetchPolicy: 'cache-and-network',
          variables: { cityName },
        }
      : skipToken
  );

  const neighborhoodNames =
    (isRental
      ? rentalNeighborhoodsData?.discoverRentalNeighborhoods
      : programNeighborhoodsData?.neighborhoods) ?? [];

  return (
    <View style={styles.container}>
      {neighborhoodNames.map(({ _id: neighborhoodId, name }) => (
        <CustomCheckbox
          key={neighborhoodId}
          value={neighborhoodId}
          label={name}
          onPress={val => onPress?.(val)}
          checked={selectedNeighborhoodIds.includes(neighborhoodId)}
        />
      ))}
    </View>
  );
};

export default (props: NeighborhoodFilterProps) => (
  <DiscoverFilterBoundary>
    <NeighborhoodFilter {...props} />
  </DiscoverFilterBoundary>
);
