import { graphql } from 'graphql-schema';

export const ORGANIZATION_LIST_QUERY = graphql(`
  query organizationList {
    organizations(order_by: { name: asc }) {
      _id
      name
    }
  }
`);

export const GET_USER_PROFILE_DETAILS = graphql(`
  query userProfileDetails {
    currentUser {
      _id
      email
      gender
      zip
      shirt_size
      birthday
      first_name
      last_name
      username
      address
      home_organization
    }
  }
`);

export const GET_USER_NOTIFICATION_PREFERENCES = graphql(`
  query userNotificationPreferences {
    currentUser {
      _id
      notification_preferences {
        _id
        marketing_email
        marketing_push
        marketing_sms
        team_email
        team_push
      }
    }
  }
`);

export const GET_USER_PRIVACY_PREFERENCES = graphql(`
  query userPrivacyPreferences {
    currentUser {
      _id
      first_name
      last_name
      privacy_preferences {
        _id
        share_email
        share_phone
        display_name_pickups
        display_name_team_roster
      }
    }
  }
`);

export const UPDATE_PRIVACY_PREFERENCES = graphql(`
  mutation updatePrivacyPreferences(
    $userId: uuid!
    $share_email: Boolean!
    $share_phone: Boolean!
    $display_name_pickups: display_name_preference_enum!
    $display_name_team_roster: display_name_preference_enum!
  ) {
    insert_user_privacy_preferences_one(
      object: {
        user: $userId
        share_email: $share_email
        share_phone: $share_phone
        display_name_pickups: $display_name_pickups
        display_name_team_roster: $display_name_team_roster
      }
      on_conflict: {
        constraint: user_privacy_preferences_user_unique
        update_columns: [share_email, share_phone, display_name_pickups, display_name_team_roster]
      }
    ) {
      _id
      share_email
      share_phone
      display_name_pickups
      display_name_team_roster
    }
  }
`);

export const UPDATE_PASSWORD_MUTATION = graphql(`
  mutation ($currentPassword: String!, $newPassword: String!) {
    updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      _id
    }
  }
`);

export const UPDATE_CURRENT_USER = graphql(`
  mutation updateCurrentUser($input: ActionsUserUpdateCurrentUserInput!) {
    updateCurrentUser(input: $input) {
      _id
      username
      email
      first_name
      last_name
      gender
      zip
      shirt_size
      birthday
      address
      home_organization
    }
  }
`);

export const UPDATE_NOTIFICATIONS_PREFERENCES = graphql(`
  mutation updateNotificationPreferences(
    $input: ActionsMarketingUpdateNotificationPreferencesInput!
  ) {
    updateNotificationPreferences(input: $input) {
      userId
      user {
        _id
        notification_preferences {
          _id
          marketing_email
          marketing_push
          marketing_sms
          team_email
          team_push
        }
      }
    }
  }
`);

export const UPDATE_TEAM_EMAIL_PREFERENCES = graphql(`
  mutation updateTeamEmailPreferences($userId: uuid!, $team_email: Boolean!) {
    update_user_notification_preferences(
      where: { user: { _eq: $userId } }
      _set: { team_email: $team_email }
    ) {
      returning {
        _id
      }
    }
  }
`);
