import { graphql } from 'graphql-schema';

const PLAYER_INCIDENT_REPORT_QUERY = graphql(`
  query IncidentReports($userId: uuid!, $limit: Int = 10) {
    incident_reports(where: { parties_involved: { user: { _eq: $userId } } }, limit: $limit) {
      _id
      incident_date
      incident_flag
      incident_type
      resolution_status
    }
  }
`);

export default PLAYER_INCIDENT_REPORT_QUERY;
