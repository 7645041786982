import React, { useCallback } from 'react';
import { Text, View } from '@rivallapp/volosports-components';
import { useQuery } from '@apollo/client';
import { Error } from '../../../components';
import GET_USER_SHIFT_LOGS from './gql/GET_USER_SHIFT_LOGS';
import useParseShiftLogData from '../../../apps/RFO/HostForms/ShiftLogs/hooks/useParseShiftLogData';
import ShiftLogsList from '../../../apps/RFO/HostForms/ShiftLogs/Dashboard/ShiftLogsList';
import ShiftLogsListSkeleton from '../../../apps/RFO/HostForms/ShiftLogs/Dashboard/ShiftLogsList/components/ShiftLogsListSkeleton';
import hasuraClient from '../../../apollo/hasuraClient';

type UserShiftLogsTabProps = {
  userId: string;
};

const UserShiftLogsTab: React.FC<UserShiftLogsTabProps> = ({ userId }) => {
  /**
   * Baffling issue where using `useSuspenseQuery` causes an infinite render.
   * Commenting out `StarRatingComponent` in `ShiftLogsListRow` fixes the issue.
   */
  const { data, loading, error, fetchMore } = useQuery(GET_USER_SHIFT_LOGS, {
    errorPolicy: 'none',
    client: hasuraClient,
    variables: {
      userId,
    },
  });

  const user = data?.users_by_pk;

  const totalCount = user?.shiftLogsByCreatedBy_aggregate.aggregate?.count ?? 0;
  const shiftLogs = useParseShiftLogData(user?.shiftLogsByCreatedBy ?? []);
  const hasMore = shiftLogs.length < totalCount;

  const handleFetchMore = useCallback(async () => {
    if (!hasMore) return;
    await fetchMore({
      variables: {
        offset: shiftLogs.length,
      },
    });
  }, [hasMore, fetchMore, shiftLogs.length]);

  if (loading) return <ShiftLogsListSkeleton />;
  if (error) return <Error error={error} />;

  return (
    <View>
      <Text textAlign="center" type="h2">
        Shift Logs
      </Text>
      <Text textAlign="center" type="caption" marginTop="0.5" marginBottom="1">
        Total: {totalCount}
      </Text>
      <ShiftLogsList
        shiftLogs={shiftLogs}
        hasMore={hasMore}
        onListEndReached={handleFetchMore}
        rowLinkProps={{ target: '_blank' }}
      />
    </View>
  );
};

export default UserShiftLogsTab;
