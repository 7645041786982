/* eslint-disable react/destructuring-assignment */
import React, { useState, type ComponentProps } from 'react';
import { useMutation } from '@apollo/client';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { InputGroup, InputLeftAddon, InputRightAddon } from '@rivallapp/native-base';

import EDIT_STRIKE_COUNT from '../gql/EDIT_STRIKE_COUNT';
import { Button, Row, TextInput, IconButton, FontAwesomeIcon } from '../../../base-components';
import { ADMIN_PLAYER_STRIKE_COUNT } from '../gql/PLAYER_STRIKE_COUNT';
import PLAYER_STRIKE_HISTORY from '../gql/PLAYER_STRIKE_HISTORY';

const EditStrikesIconButton = (props: ComponentProps<typeof IconButton>) => (
  <IconButton
    size="xs"
    _icon={{ size: 'xs' }}
    variant="outline"
    colorScheme="primary"
    borderRadius="full"
    {...props}
  />
);

type EditStrikesProps = {
  userId: string;
  currentStrikeCount: number;
  onSave?: () => any;
};

const EditStrikes: React.FC<EditStrikesProps> = ({ userId, currentStrikeCount, onSave }) => {
  const [desiredStrikeCount, setDesiredStrikeCount] = useState(currentStrikeCount.toString());

  const [editStrikes, { loading }] = useMutation(EDIT_STRIKE_COUNT, {
    // Clear the old cache if the strike count was modified
    refetchQueries: [
      ADMIN_PLAYER_STRIKE_COUNT,
      { query: PLAYER_STRIKE_HISTORY, variables: { id: userId } },
    ],
  });

  const formInvalid =
    Number.isNaN(parseInt(desiredStrikeCount, 10)) ||
    parseInt(desiredStrikeCount, 10) === currentStrikeCount ||
    parseInt(desiredStrikeCount, 10) < 0;

  const handleFormChange = (newValue: string) => {
    // Special case: Let the user change the form to an empty value.
    if (newValue === '') {
      setDesiredStrikeCount(newValue);
      return;
    }

    const count = parseInt(newValue, 10);

    if (Number.isNaN(count) || count < 0) return;

    setDesiredStrikeCount(count.toString());
  };

  const increment = () => {
    let count = parseInt(desiredStrikeCount, 10);
    if (Number.isNaN(count)) {
      count = 0;
    }

    handleFormChange((count + 1).toString());
  };

  const decrement = () => {
    let count = parseInt(desiredStrikeCount, 10);
    if (Number.isNaN(count)) {
      count = 0;
    }

    handleFormChange((count - 1).toString());
  };

  const handleSave = async () => {
    if (formInvalid) {
      return;
    }
    await editStrikes({
      variables: {
        userId,
        desiredStrikeCount: +desiredStrikeCount,
      },
    });

    onSave?.();
  };

  return (
    <>
      <Row justifyContent="center">
        <InputGroup my="2">
          <InputLeftAddon>
            <EditStrikesIconButton icon={<FontAwesomeIcon icon={faMinus} />} onPress={decrement} />
          </InputLeftAddon>
          <TextInput
            keyboardType="number-pad"
            value={desiredStrikeCount}
            onChangeText={handleFormChange}
            variant="filled"
            placeholder="0"
            type="text"
            maxW="24"
          />
          <InputRightAddon>
            <EditStrikesIconButton icon={<FontAwesomeIcon icon={faPlus} />} onPress={increment} />
          </InputRightAddon>
        </InputGroup>
      </Row>
      <Row my="2" justifyContent="center">
        <Button
          flex={1}
          maxW="56"
          colorScheme="lightBlue"
          onPress={handleSave}
          isDisabled={formInvalid}
          isLoading={loading}
        >
          Save
        </Button>
      </Row>
    </>
  );
};

export default EditStrikes;
