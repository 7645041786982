import { graphql } from 'graphql-schema';

const GET_ANNUAL_SAVINGS = graphql(`
  query GetAnnualSavings($userId: uuid!) {
    subscriptions(
      where: {
        user: { _eq: $userId }
        type: { _eq: VOLO_PASS }
        status: { _eq: active }
        interval: { _eq: month }
      }
    ) {
      _id
      organizationByOrganization {
        _id
        volo_pass_annual_savings_cents
      }
    }
  }
`);

export default GET_ANNUAL_SAVINGS;
