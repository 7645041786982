import classNames from 'classnames/bind';
import type { CSSProperties, FC } from 'react';
import beer from '../../../images/icons/beer.svg';
import styles from './styles.module.scss';
import type { StaffLeagueData } from '../../../apps/Staff/graphql';

const cx = classNames.bind(styles);

type LeagueSponsorBar = {
  league: StaffLeagueData;
  style?: CSSProperties;
};

const LeagueSponsorBar: FC<LeagueSponsorBar> = ({ league, style = {} }) => {
  const { sponsor_bar } = league;

  if (!sponsor_bar || !sponsor_bar?.name) return null;

  return (
    <div className={cx('info-container')} style={style}>
      <img className={cx('icon')} src={beer} alt="beer" />
      <p className={cx('title')}>Sponsor Bar</p>
      <p>{sponsor_bar.name}</p>
      <p>{sponsor_bar.formatted_address}</p>
    </div>
  );
};

export default LeagueSponsorBar;
