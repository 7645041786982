import { useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { graphql, type VariablesOf } from 'graphql-schema';
import { StripeSubscriptionStatusEnum } from '../../constants';
import { CURRENT_USER_QUERY } from '../useCurrentUserV2';

/** only volo pass promo codes are supported by `GET_STRIPE_PROMO` query */
export const GET_STRIPE_PROMO = graphql(`
  query getStripePromo($orgId: String!, $promoId: String!) {
    getStripePromo(orgId: $orgId, promoId: $promoId) {
      valid
      promo {
        active
        amount_off
        code
        created
        currency
        customer
        duration
        duration_in_months
        expires_at
        id
        max_redemptions
        name
        object
        percent_off
        redeem_by
        restrictions {
          first_time_transaction
        }
        times_redeemed
        valid
      }
    }
  }
`);
type GetStripePromoVariables = VariablesOf<typeof GET_STRIPE_PROMO>;

export const START_VOLO_PASS_MEMBERSHIP = graphql(`
  mutation startVoloPassMembership($planId: String!, $orgId: String!, $promoCode: String) {
    startVoloPass(planId: $planId, orgId: $orgId, promoCode: $promoCode) {
      _id
      status
      end_date
    }
  }
`);
type StartVoloPassMembershipVariables = VariablesOf<typeof START_VOLO_PASS_MEMBERSHIP>;

export const CANCEL_VOLO_PASS_MEMBERSHIP = graphql(`
  mutation cancelVoloPassMembership {
    cancelVoloPass {
      success
    }
  }
`);

export const UPGRADE_VOLO_PASS_MEMBERSHIP = graphql(`
  mutation upgradeVoloPassMembership($planId: String!, $promoCode: String) {
    upgradeVoloPass(planId: $planId, promoCode: $promoCode) {
      _id
      status
      end_date
    }
  }
`);
type UpgradeVoloPassMembershipVariables = VariablesOf<typeof UPGRADE_VOLO_PASS_MEMBERSHIP>;

export const REINSTATE_VOLO_PASS_MEMBERSHIP = graphql(`
  mutation reinstateVoloPass {
    reinstateVoloPass {
      _id
      status
      end_date
    }
  }
`);

const ErrorResponse = (message: string): { success: false; error: Error; data: undefined } => {
  return { success: false, error: new Error(message), data: undefined };
};

const SuccessResponse = <T>(data: T): { success: true; error: undefined; data: T } => {
  return { success: true, error: undefined, data };
};

/**
 * Hook for actions related to Volo Pass memberships
 *
 * ---
 *
 * @important Error handling is returned through each method and should be handled by the caller to easily integrate between platforms
 *
 * Each method for `starting`, `canceling`, `upgrading`, and `validating` Volo Pass memberships return the same signature
 *
 * ```ts
 * const { success, error, data } = await startVoloPassMembership({ ... });
 * ```
 */
const useVoloPassActions = () => {
  // ================================
  // Start membership
  // ================================
  const [startMembershipMutation] = useMutation(START_VOLO_PASS_MEMBERSHIP);
  const startVoloPassMembership = useCallback(
    async (variables: StartVoloPassMembershipVariables) => {
      try {
        const { data } = await startMembershipMutation({
          variables,
          update: (cache, { data }) => {
            if (!data?.startVoloPass) {
              return;
            }

            const { status } = data.startVoloPass;

            if (status === StripeSubscriptionStatusEnum.ACTIVE) {
              const data = cache.readQuery({ query: CURRENT_USER_QUERY });
              if (!data?.currentUser) {
                return;
              }

              cache.writeQuery({
                query: CURRENT_USER_QUERY,
                data: {
                  currentUser: {
                    ...data.currentUser,
                    volo_pass_subscription_status: status,
                    has_volo_pass: true,
                  },
                },
              });
            }
          },
        });

        if (!data?.startVoloPass) {
          return ErrorResponse('Unexpected error, failed to start Volo Pass membership');
        }

        return SuccessResponse(data.startVoloPass);
      } catch (error) {
        return ErrorResponse(
          error instanceof Error
            ? error.message
            : 'Unknown error occurred while starting Volo Pass membership'
        );
      }
    },
    [startMembershipMutation]
  );

  // ================================
  // Cancel membership
  // ================================
  const [cancelMembershipMutation] = useMutation(CANCEL_VOLO_PASS_MEMBERSHIP);
  const cancelVoloPassMembership = useCallback(async () => {
    try {
      const { data } = await cancelMembershipMutation();

      if (!data?.cancelVoloPass) {
        return ErrorResponse('Unexpected error, failed to cancel Volo Pass membership');
      }

      return SuccessResponse(data.cancelVoloPass);
    } catch (error) {
      return ErrorResponse(
        error instanceof Error
          ? error.message
          : 'Unknown error occurred while cancelling Volo Pass membership'
      );
    }
  }, [cancelMembershipMutation]);

  // ================================
  // Reinstate membership
  // ================================
  const [reinstateMembershipMutation] = useMutation(REINSTATE_VOLO_PASS_MEMBERSHIP);
  const reinstateVoloPassMembership = useCallback(async () => {
    try {
      const { data } = await reinstateMembershipMutation();

      if (!data?.reinstateVoloPass) {
        return ErrorResponse('Unexpected error, failed to reinstate Volo Pass membership');
      }

      return SuccessResponse(data.reinstateVoloPass);
    } catch (error) {
      return ErrorResponse(
        error instanceof Error
          ? error.message
          : 'Unknown error occurred while reinstating Volo Pass membership'
      );
    }
  }, [reinstateMembershipMutation]);

  // ================================
  // Upgrade membership
  // ================================
  const [upgradeMembershipMutation] = useMutation(UPGRADE_VOLO_PASS_MEMBERSHIP);
  const upgradeVoloPassMembership = useCallback(
    async (variables: UpgradeVoloPassMembershipVariables) => {
      try {
        const { data } = await upgradeMembershipMutation({
          variables,
        });

        if (!data?.upgradeVoloPass) {
          return ErrorResponse('Unexpected error, failed to upgrade Volo Pass membership');
        }

        return SuccessResponse(data.upgradeVoloPass);
      } catch (error) {
        return ErrorResponse(
          error instanceof Error
            ? error.message
            : 'Unknown error occurred while upgrading Volo Pass membership'
        );
      }
    },
    [upgradeMembershipMutation]
  );

  // ================================
  // Validate Volo Pass promo code
  // ================================
  const [getStripePromo] = useLazyQuery(GET_STRIPE_PROMO);
  const validatePromoCode = useCallback(
    async (variables: GetStripePromoVariables) => {
      try {
        const { data } = await getStripePromo({
          variables,
        });

        if (!data?.getStripePromo) {
          return ErrorResponse('Unexpected error, failed to validate promo code with Stripe');
        }

        if (!data.getStripePromo.valid) {
          return ErrorResponse('Promo code is not valid for Volo Pass!');
        }

        if (!data.getStripePromo.promo) {
          return ErrorResponse('Unexpected error, no promo code data found!');
        }

        return SuccessResponse({ valid: true, promo: data.getStripePromo.promo } as const);
      } catch (error) {
        return ErrorResponse(
          error instanceof Error
            ? error.message
            : 'Unknown error occurred while validating promo code'
        );
      }
    },
    [getStripePromo]
  );

  return {
    /** starts a volo pass membership with the provided input */
    startVoloPassMembership,
    /** cancels a volo pass membership */
    cancelVoloPassMembership,
    /** reinstates a volo pass membership */
    reinstateVoloPassMembership,
    /** upgrades a volo pass membership */
    upgradeVoloPassMembership,
    /** validates a promo code for a volo pass membership */
    validatePromoCode,
  };
};

export default useVoloPassActions;
