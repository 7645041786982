import { ApolloProvider, useMutation } from '@apollo/client';
import classNames from 'classnames/bind';
import { Suspense, useState } from 'react';
import { usePlayerPayments } from '@rivallapp/volosports-components';
import styles from '../player-details.module.scss';
import MembershipPayments from './MembershipPayments';
import PaymentAction from './PaymentAction';
import { DELETE_CREDITS } from './PLAYER_PAYMENT_MUTATION_AND_QUERIES';
import ProgramPayments from './ProgramPayments';
import { AreYouSure, GenericErrorBoundary, Loading } from '../../../components';
import { formatCents } from '../../../utils';
import { useAlertMessage } from '../../../hooks';
import hasuraClient from '../../../apollo/hasuraClient';

const cx = classNames.bind(styles);

export type CreditState = {
  showCreditPopup?: boolean;
  creditId?: string;
  organizationId?: string;
};

type Props = {
  userId: string;
};

const PlayerPaymentContainer = (props: Props) => {
  const { userId } = props;

  const [updating, setUpdating] = useState(false);

  const { showSuccess, showError } = useAlertMessage();

  const [deleteCreditState, setDeleteCreditState] = useState<CreditState>({});
  const [registrantDeleteCredits] = useMutation(DELETE_CREDITS);

  const {
    data: { user, userPayments },
    refetch,
  } = usePlayerPayments({
    fetchPolicy: 'cache-and-network',
    variables: {
      userId,
    },
  });

  const deleteCredit = async () => {
    const { creditId, organizationId } = deleteCreditState;
    const amount = userPayments?.payments.find(payment => payment.paymentId === creditId)?.credits;

    if (!amount || !creditId || !organizationId) {
      showError('Credit not found.');
      return;
    }

    setUpdating(true);
    try {
      await registrantDeleteCredits({
        variables: {
          userId,
          creditId,
          organizationId,
          amount: -amount,
        },
      });
      await refetch();
      setDeleteCreditState({ ...deleteCreditState, showCreditPopup: false });
      showSuccess('Player credit deleted.');
    } catch (e) {
      showError(e);
    } finally {
      setUpdating(false);
    }
  };

  const { showCreditPopup } = deleteCreditState;
  const {
    payments = [],
    totalPayments,
    totalDonations,
    totalNet,
    totalRefunds,
    totalCredits = 0,
  } = userPayments ?? {};

  const { totalReferralRewards } = user;

  return (
    <>
      <div className="container">
        <div className="row my-4">
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Total Payments</h2>
              <h2 className={cx('regColor', 'mt-3')}>{formatCents(totalPayments)}</h2>
            </div>
          </div>
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Net Revenue</h2>
              <h2 className={cx('regColor', 'mt-3')}>{formatCents(totalNet)}</h2>
            </div>
          </div>
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Total Refunds</h2>
              <h2 className={cx('redColor', 'mt-3')}>-{formatCents(totalRefunds)}</h2>
            </div>
          </div>
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Available Credits</h2>
              <h2 className={cx('greenColor', 'mt-3')}>+{formatCents(totalCredits)}</h2>
            </div>
          </div>
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Donations Made</h2>
              <h2 className={cx('regColor', 'mt-3')}>{formatCents(totalDonations)}</h2>
            </div>
          </div>
          <div className="col mx-auto p-1">
            <div className={cx('paymentBox')}>
              <h2>Total Rewards</h2>
              <h2 className={cx('greenColor', 'mt-3')}>+{formatCents(totalReferralRewards)}</h2>
            </div>
          </div>
        </div>
        <ProgramPayments
          payments={payments}
          availableCredits={totalCredits}
          setDeleteCreditState={setDeleteCreditState}
        />
        <MembershipPayments userId={userId} />
      </div>
      <PaymentAction
        userId={userId}
        payments={payments}
        refetch={refetch}
        updating={updating}
        setUpdating={setUpdating}
      />
      <AreYouSure
        body="Are you sure you want to delete this credit?"
        hidden={!showCreditPopup}
        yesAction={deleteCredit}
        close={() => setDeleteCreditState({ ...deleteCreditState, showCreditPopup: false })}
      />
    </>
  );
};

export default (props: Props) => (
  <Suspense fallback={<Loading />}>
    <GenericErrorBoundary>
      <ApolloProvider client={hasuraClient}>
        <PlayerPaymentContainer {...props} />
      </ApolloProvider>
    </GenericErrorBoundary>
  </Suspense>
);
