import type { TPaymentListItem } from '../types';
import type { RentalRegistrantData } from '../usePlayerPayments';

type RentalPaymentItem = {
  rentalPaginatedPayments: TPaymentListItem[];
  rentalTotals: {
    rentalCount: number;
    rentalTotalPayments: number;
    rentalTotalNet: number;
    rentalTotalRefunds: number;
    rentalTotalDonations: number;
  };
};
/**
 * Helper function to format data from `RentalRegistrant` aggregation for `userPayments`.
 */
const getPaymentsFromRentalRegistrants = (
  rentalRegistrants: RentalRegistrantData
): RentalPaymentItem => {
  const rentalTotals = {
    rentalCount: rentalRegistrants.length,
    rentalTotalPayments: 0,
    rentalTotalNet: 0,
    rentalTotalRefunds: 0,
    rentalTotalDonations: 0,
  };

  const rentalPaginatedPayments = rentalRegistrants.map(registrant => {
    const { _id: registrantId, rentalByRental: rental } = registrant;

    const charge =
      registrant.rental_reservation.rental_charge_reservations[0]?.rentalChargeByRentalCharge;

    const donation_cents = charge?.donation_cents ?? 0;
    const credits_refunded_cents = charge?.credits_refunded_cents ?? 0;
    const total_charge_cents = charge?.total_charge_cents ?? 0;
    const fee_cents = charge?.fee_cents ?? 0;
    const promo_code_str = charge?.promo_code_str;
    const promoDiscountCents = charge?.promo_discount_cents ?? 0;
    const created_at = charge?.created_at ?? '';
    const updated_at = charge?.updated_at;

    const total_refunded_cents =
      charge?.rental_charge_refunds_aggregate.aggregate?.sum?.refund_cents ?? 0;

    /**
     * EXPECTED BEHAVIOR: Full credit/refund amount includes fees but not donations
     * https://volo-company.slack.com/archives/C04HBA50V34/p1725032522187989?thread_ts=1725025933.615749&cid=C04HBA50V34
     *
     * Refunding rentals through player card isn't actually supported yet though
     */
    const maxRefundableCents = total_charge_cents - donation_cents;

    const revenue =
      total_charge_cents -
      fee_cents -
      donation_cents -
      credits_refunded_cents -
      total_refunded_cents;

    const net = total_charge_cents - total_refunded_cents;

    rentalTotals.rentalTotalPayments += total_charge_cents;
    rentalTotals.rentalTotalNet += revenue;
    rentalTotals.rentalTotalRefunds += total_refunded_cents;
    rentalTotals.rentalTotalDonations += donation_cents;

    return {
      paymentId: registrantId,
      rentalId: rental?._id,
      date: created_at,
      refund: total_refunded_cents,
      refundIssuedAt: updated_at, // not exactly a logical choice but only refunds update the document right now
      donation: donation_cents,
      credits: credits_refunded_cents,
      revenue,
      maxRefundableCents,
      reason: rental?.name,
      status: (total_refunded_cents > 0 ? 'Refund' : 'Paid') as TPaymentListItem['status'],
      type: (total_refunded_cents > 0 ? 'R' : 'P') as TPaymentListItem['type'],
      promoCode: promo_code_str,
      promoDiscountCents,
      net,
    };
  });

  return { rentalPaginatedPayments, rentalTotals };
};

export default getPaymentsFromRentalRegistrants;
