/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import classNames from 'classnames/bind';
import PlayerDeactivate from './PlayerDeactivate';
import { Button, ConfirmButton, FilterSelect } from '../../../../components';
import { FancyField } from '../../../../components/fancyFieldsV2';
import { formatBday } from '../../../../utils';
import styles from '../../player-details.module.scss';
import type { UserDetails } from '../../PLAYER_DETAILS_QUERIES';
import { GenderNameEnum, type ExtractedGenderEnum } from '../../../../shared/gender-enum';
import useEditPlayerInfo from '../../hooks/useEditPlayerInfo';

const cx = classNames.bind(styles);

type Props = {
  userDetails: UserDetails;
  setUpdatingInfo: (updating: boolean) => void;
};

const PlayerInfoEdit = (props: Props) => {
  const { userDetails, setUpdatingInfo } = props;

  const [showDeactivate, setShowDeactivate] = useState(false);

  // Editable user fields
  const [firstName, setFirstName] = useState(userDetails.first_name);
  const [lastName, setLastName] = useState(userDetails.last_name);
  const [email, setEmail] = useState(userDetails.email);
  const [phoneNumber, setPhoneNumber] = useState(userDetails.phone_number);
  const [birthday, setBirthday] = useState(userDetails.birthday);
  const [countryCode, setCountryCode] = useState(userDetails.phone_country_code);
  const [gender, setGender] = useState(userDetails.gender);

  const genderOptions = Object.entries(GenderNameEnum).map(([key, value]) => ({
    label: value,
    value: key as ExtractedGenderEnum,
  }));

  const { updating, updateUserInfo } = useEditPlayerInfo(
    {
      userId: userDetails._id,
      ...(firstName !== userDetails.first_name && { firstName }),
      ...(lastName !== userDetails.last_name && { lastName }),
      ...(email !== userDetails.email && { email }),
      ...(phoneNumber !== userDetails.phone_number && { phoneNumber }),
      ...(birthday !== userDetails.birthday && { birthday }),
      ...(countryCode !== userDetails.phone_country_code && { countryCode }),
      ...(gender !== userDetails.gender && { gender }),
    },
    () => setUpdatingInfo(false)
  );

  return showDeactivate ? (
    <PlayerDeactivate
      userId={userDetails._id}
      deactivated={userDetails.deactivated}
      goBackAction={() => setShowDeactivate(false)}
      clearSelectedUser={() => setUpdatingInfo(false)}
    />
  ) : (
    <>
      <FancyField
        grey
        noLabel
        persistPlaceholder
        value={firstName}
        type="text"
        onChange={(e: string) => setFirstName(e)}
        placeholder="First Name"
        className="mb-3"
      />
      <FancyField
        grey
        noLabel
        persistPlaceholder
        value={lastName}
        type="text"
        onChange={(e: string) => setLastName(e)}
        placeholder="Last Name"
        className="mb-3"
      />
      <FancyField
        grey
        noLabel
        persistPlaceholder
        value={email}
        type="text"
        onChange={(e: string) => setEmail(e)}
        placeholder="Email"
        className="mb-3"
      />
      <PhoneInput
        placeholder="Phone"
        country={countryCode}
        value={phoneNumber ?? ''}
        onChange={p => setPhoneNumber(p || '')}
        onCountryChange={c => setCountryCode(c || '')}
        className={cx('phoneInput')}
      />
      <FancyField
        grey
        noLabel
        persistPlaceholder
        value={birthday}
        onChange={(e: string) => setBirthday(e)}
        transform={formatBday}
        className="mb-3"
      />
      <FilterSelect
        border
        rounded
        maxMenuHeight={90}
        placeholder="Select"
        options={genderOptions}
        value={genderOptions.find(({ value }) => value === gender) || null}
        onChange={opt => setGender(opt?.value ?? null)}
        grey
        className="mb-3"
      />
      <div className={cx('d-flex justify-content-center mb-2 col')}>
        <a
          className="text-volo-danger font-italic"
          role="button"
          tabIndex={0}
          onClick={() => setShowDeactivate(true)}
        >
          {userDetails.deactivated ? 'Reactivate User' : 'Deactivate User'}
        </a>
      </div>
      <div className={cx('d-flex justify-content-between')}>
        <Button link square className="col" onClick={() => setUpdatingInfo(false)}>
          Cancel
        </Button>
        {/* @ts-expect-error - JSX types */}
        <ConfirmButton
          onClick={updateUserInfo}
          disabled={updating}
          className="wide col"
          primary
          square
          large
        >
          {updating ? 'Saving...' : 'Save'}
        </ConfirmButton>
      </div>
      <br />
    </>
  );
};

export default PlayerInfoEdit;
