import { graphql } from 'graphql-schema';

const GET_HASURA_PROGRAM_PRICING = graphql(`
  query ProgramPricing($input: ProgramPricingForRegistrationInput!) {
    programPricingForRegistration(input: $input) {
      pricingBreakdown {
        memberPrice
        originalPrice
        totalFeeCents
      }
    }
  }
`);

export default GET_HASURA_PROGRAM_PRICING;
