import { graphql } from 'graphql-schema';

const GET_ORGANIZATIONS_QUERY = graphql(`
  query Organizations {
    organizations(order_by: { name: asc }) {
      _id
      name
    }
  }
`);

export default GET_ORGANIZATIONS_QUERY;
