import { graphql } from 'graphql-schema';

export const ADD_PAYMENT_SOURCE = graphql(`
  mutation addUserPaymentSource($tokenId: String!) {
    addUserPaymentSource(tokenId: $tokenId) {
      success
    }
  }
`);

export const REGISTER_FOR_PROGRAM = graphql(`
  mutation registerforProgram($input: RegisterForProgramInput!) {
    registerForProgram(input: $input) {
      registrantId
    }
  }
`);
