import { StripeMembershipIntervalEnum, StripeSubscriptionStatusEnum } from './stripe-enum';

export enum ReferralTypeEnum {
  USER = 'user',
  CAMPAIGN = 'campaign',
}

// Constants for Volo Pass/membership trial length
export enum MembershipTrialEnum {
  TWO_WEEKS = 'TWO_WEEKS',
  FOUR_WEEKS = 'FOUR_WEEKS',
}

export const MembershipTrialDaysMap = {
  [MembershipTrialEnum.TWO_WEEKS]: 14,
  [MembershipTrialEnum.FOUR_WEEKS]: 28,
} as const;

// For a future sprint, we should consider moving this to the Organization table
// in order to accommodate per-organization commitment periods.
export const MEMBERSHIP_MINIMUM_MONTHS = 3;

export enum MembershipEnum {
  VOLO_PASS = 'VOLO_PASS',
  VOLO_KIDS_RECURRING = 'VOLO_KIDS_RECURRING',
  /** @deprecated */
  VOLO_FITNESS = 'VOLO_FITNESS',
}

export const Memberships = {
  [MembershipEnum.VOLO_PASS]: {
    name: 'Volo Pass',
    value: MembershipEnum.VOLO_PASS,
  },
  [MembershipEnum.VOLO_KIDS_RECURRING]: {
    name: 'Volo Kids Recurring Donation',
    value: MembershipEnum.VOLO_KIDS_RECURRING,
  },
  /** @deprecated */
  [MembershipEnum.VOLO_FITNESS]: {
    name: 'Volo Fitness',
    value: MembershipEnum.VOLO_FITNESS,
  },
} as const;

// #region Volo membership naming convention
// Stripe values converted to Volo names
export const MembershipInterval = {
  [StripeMembershipIntervalEnum.MONTH]: {
    label: 'Monthly',
    value: StripeMembershipIntervalEnum.MONTH,
  },
  [StripeMembershipIntervalEnum.YEAR]: {
    label: 'Annual',
    value: StripeMembershipIntervalEnum.YEAR,
  },
} as const;

export const MembershipStatus = {
  [StripeSubscriptionStatusEnum.ACTIVE]: {
    label: 'Active',
    value: StripeSubscriptionStatusEnum.ACTIVE,
  },
  [StripeSubscriptionStatusEnum.TRIALING]: {
    label: 'Trialing',
    value: StripeSubscriptionStatusEnum.TRIALING,
  },
  [StripeSubscriptionStatusEnum.INCOMPLETE]: {
    label: 'Incomplete',
    value: StripeSubscriptionStatusEnum.INCOMPLETE,
  },
  [StripeSubscriptionStatusEnum.INCOMPLETE_EXPIRED]: {
    label: 'Incomplete - Payment Failed',
    value: StripeSubscriptionStatusEnum.INCOMPLETE_EXPIRED,
  },
  [StripeSubscriptionStatusEnum.PAST_DUE]: {
    label: 'Past Due',
    value: StripeSubscriptionStatusEnum.PAST_DUE,
  },
  [StripeSubscriptionStatusEnum.CANCELED]: {
    label: 'Expired',
    value: StripeSubscriptionStatusEnum.CANCELED,
  },
  [StripeSubscriptionStatusEnum.UNPAID]: {
    label: 'Unpaid',
    value: StripeSubscriptionStatusEnum.UNPAID,
  },
  [StripeSubscriptionStatusEnum.PAUSED]: {
    label: 'Paused',
    value: StripeSubscriptionStatusEnum.PAUSED,
  },
} as const;
// #endregion

export type MembershipStatusType =
  (typeof MembershipStatus)[keyof typeof MembershipStatus]['label'];
export type MembershipIntervalType =
  (typeof MembershipInterval)[keyof typeof MembershipInterval]['label'];

export type MembershipName = (typeof Memberships)[keyof typeof Memberships]['name'];
