import { graphql, type ResultOf } from 'graphql-schema';

export type GetUserParticipationData = NonNullable<
  ResultOf<typeof GET_USER_PARTICIPATION>['users_by_pk']
>;

export type RegistrantData = GetUserParticipationData['registrants'][number];
export type RentalRegistrantData = GetUserParticipationData['rental_registrants'][number];

// Post-migration cleanup idea: fragments!

const GET_USER_PARTICIPATION = graphql(`
  query getUserParticipation($userId: uuid!) {
    users_by_pk(_id: $userId) {
      _id
      full_name
      registrants {
        __typename
        _id
        dropInSlot: dropInSlotByDropInSlot {
          team: teamByTeam {
            name
          }
        }
        league: leagueByLeague {
          _id
          name
          organization
          sportBySport {
            _id
            name
          }
          seasonBySeason {
            _id
            name
          }
          # Is there a better way to find the team name? (stagedTeam in old api)
          teams(
            where: {
              _or: [
                {
                  free_agents: { registrantByRegistrant: { userByUser: { _id: { _eq: $userId } } } }
                }
                { players: { userByUser: { _id: { _eq: $userId } } } }
              ]
            }
          ) {
            _id
            name
          }
        }
        is_drop_in
        group: groupByGroup {
          _id
          name
        }
        kind
        waiver_id
        waiver_date_signed
        waiver_signed
        waiver_reminders {
          _id
          date_sent
          userByCreatedBy {
            _id
            username
          }
        }
        created_at
      }
      rental_registrants(where: { rentalByRental: { deleted_at: { _is_null: true } } }) {
        __typename
        _id
        rental: rentalByRental {
          _id
          sport: sportBySport {
            _id
            name
          }
          name
        }
        kind
        waiver_id
        waiver_date_signed
        waiver_signed
        waiver_reminders {
          _id
          date_sent
          userByCreatedBy {
            _id
            username
          }
        }
        created_at
      }
    }
  }
`);

export default GET_USER_PARTICIPATION;
