import { useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { type ExtractEnum } from 'graphql-schema';
import { useActionStates } from '../../../hooks';
import {
  GET_USER_PRIVACY_PREFERENCES,
  UPDATE_PRIVACY_PREFERENCES,
} from '../graphql/ACCOUNT_FORMS_MUTATIONS_AND_QUERIES';
import { Alert, Select, Skeleton, Switch, Text, View } from '../../../base-components';
import { ErrorMessageEnum } from '../../../constants/enums';
import styles from '../styles';

type DisplayNameEnum = ExtractEnum<'display_name_preference_enum'>;

// ** Note to future devs: we're using state to prevent deprecated fields from overwriting new preferences
// eg: if emailHidden is set to true, emailPreference can never be false
// const emailPreference =currentUser.privacyPreferences.shareEmail || currentUser.emailHidden || false

const InformationSharing = () => {
  const [shareEmail, setShareEmail] = useState(false);
  const [sharePhone, setSharePhone] = useState(false);
  const [displayNameTeamRoster, setDisplayNameTeamRoster] =
    useState<DisplayNameEnum>('lastInitial');
  const [displayNamePickups, setDisplayNamePickups] = useState<DisplayNameEnum>('lastInitial');

  const { setSuccess, setError, showAlert, setShowAlert, alertMessage, alertType } =
    useActionStates({ withAlerts: true });

  const [updatePrivacyPreferences, { loading: privacyUpdating }] = useMutation(
    UPDATE_PRIVACY_PREFERENCES
  );

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER_PRIVACY_PREFERENCES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ currentUser }) => {
      const { privacy_preferences } = currentUser || {};
      if (privacy_preferences) {
        setShareEmail(privacy_preferences.share_email || false);
        setSharePhone(privacy_preferences.share_phone || false);
        setDisplayNameTeamRoster(privacy_preferences.display_name_team_roster || 'lastInitial');
        setDisplayNamePickups(privacy_preferences.display_name_pickups || 'lastInitial');
      }
    },
  });

  const { currentUser } = userData || {};

  const playerFullName = `${currentUser?.first_name} ${currentUser?.last_name}`;
  const playerLastInitial = `${currentUser?.first_name} ${currentUser?.last_name[0]}.`;

  const displayNameTeamOptions = useMemo(
    () => [
      <Select.Item value="fullName" label={playerFullName} key="fullName" />,
      <Select.Item value="lastInitial" label={playerLastInitial} key="lastInitial" />,
    ],
    [playerFullName, playerLastInitial]
  );

  const displayNamePickupOptions = useMemo(
    () => [
      <Select.Item value="fullName" label={playerFullName} key="fullName" />,
      <Select.Item value="lastInitial" label={playerLastInitial} key="lastInitial" />,
      <Select.Item value="anonymous" label="Anonymous" key="anonymous" />,
    ],
    [playerFullName, playerLastInitial]
  );

  const updateUserPrivacyPreferences = async (update: {
    share_email?: boolean;
    share_phone?: boolean;
    display_name_team_roster?: DisplayNameEnum;
    display_name_pickups?: DisplayNameEnum;
  }) => {
    try {
      await updatePrivacyPreferences({
        variables: {
          userId: currentUser?._id ?? '',
          share_email: shareEmail,
          share_phone: sharePhone,
          display_name_team_roster: displayNameTeamRoster,
          display_name_pickups: displayNamePickups,
          ...update,
        },
      });
      setSuccess('Privacy preferences updated!');
    } catch (error) {
      setError(ErrorMessageEnum.UPDATE_FAILED);
      console.error(error);
    }
  };

  const handleShareEmail = () => {
    setShareEmail(!shareEmail);
    updateUserPrivacyPreferences({ share_email: !shareEmail });
  };
  const handleSharePhone = () => {
    setSharePhone(!sharePhone);
    updateUserPrivacyPreferences({ share_phone: !sharePhone });
  };

  const handleTeamDisplayName = (val: DisplayNameEnum) => {
    setDisplayNameTeamRoster(val);
    updateUserPrivacyPreferences({ display_name_team_roster: val });
  };

  const handlePickupDisplayName = (val: DisplayNameEnum) => {
    setDisplayNamePickups(val);
    updateUserPrivacyPreferences({ display_name_pickups: val });
  };

  if (userError) {
    setError(ErrorMessageEnum.USER_INFO_LOAD_FAILED);
    console.error(userError);
  }

  if (userLoading)
    return (
      <View style={styles.skeletonContainer}>
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
      </View>
    );

  return (
    <View>
      {showAlert ? (
        <Alert status={alertType} message={alertMessage} showAlert setShowAlert={setShowAlert} />
      ) : null}
      <View style={styles.containerRow}>
        <Text style={styles.label}>Share email with teammates</Text>
        <Switch isDisabled={privacyUpdating} value={shareEmail} onToggle={handleShareEmail} />
      </View>

      <View style={styles.containerRow}>
        <Text style={styles.label}>Share phone number with teammates</Text>
        <Switch isDisabled={privacyUpdating} value={sharePhone} onToggle={handleSharePhone} />
      </View>

      <View style={styles.containerColumn}>
        <Text style={styles.label}>Display Name for Team Rosters</Text>
        <Select
          flex={1}
          selectedValue={displayNameTeamRoster}
          onValueChange={v => handleTeamDisplayName(v as DisplayNameEnum)}
          isDisabled={privacyUpdating}
        >
          {displayNameTeamOptions}
        </Select>
      </View>

      <View style={styles.containerColumn}>
        <Text style={styles.label}>
          Display Name for Pickup Programs (Ex: &quot;Who&apos;s Playing&quot; in pickups)
        </Text>
        <Select
          flex={1}
          selectedValue={displayNamePickups}
          onValueChange={v => handlePickupDisplayName(v as DisplayNameEnum)}
          isDisabled={privacyUpdating}
        >
          {displayNamePickupOptions}
        </Select>
      </View>
    </View>
  );
};

export default InformationSharing;
