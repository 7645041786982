import type { ExtractEnum } from 'graphql-schema';

export enum TeamRoleEnum {
  TEAM_CAPTAIN = 'team_captain',
  CO_CAPTAIN = 'co_captain',
  TEAM_MEMBER = 'team_member',
}

type TeamRolesType = Readonly<{
  [Role in ExtractEnum<'team_role_enum'>]: {
    value: Role;
    label: string;
    isCaptain?: boolean;
    isCoCaptain?: boolean;
    toCamel: string;
  };
}>;

export const TeamRoles = Object.freeze<TeamRolesType>({
  [TeamRoleEnum.TEAM_CAPTAIN]: {
    value: TeamRoleEnum.TEAM_CAPTAIN,
    label: 'Team Captain',
    isCaptain: true,
    toCamel: 'teamCaptain',
    /** @deprecated redundant */
    isCoCaptain: false,
  },
  [TeamRoleEnum.CO_CAPTAIN]: {
    value: TeamRoleEnum.CO_CAPTAIN,
    label: 'Co Captain',
    toCamel: 'coCaptain',
    /** @deprecated redundant */
    isCoCaptain: true,
  },
  [TeamRoleEnum.TEAM_MEMBER]: {
    value: TeamRoleEnum.TEAM_MEMBER,
    label: 'Team Member',
    toCamel: 'teamMember',
    /** @deprecated redundant */
    isCoCaptain: false,
  },
});

export default TeamRoleEnum;
