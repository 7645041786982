/* eslint-disable react/require-default-props */
// ok to disable default props for now as the defaults are inline
// will eventually be converted to TS
// see http://react-component.github.io/slider
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Slider, { Range } from 'rc-slider';
import { useState } from 'react';
import styles from './styles.module.scss';
import { primaryHex } from '../../utils/constants';
import { isClient } from '../../environment';

if (isClient) {
  // eslint-disable-next-line global-require
  require('rc-slider/assets/index.css');
}

const cx = classNames.bind(styles);

export const defaultSliderStyles = (value, disabled, color) => {
  const trackStyle = { backgroundColor: disabled ? '#ccc' : color };
  const handleStyle = { borderColor: disabled ? '#ccc' : color };

  if (Array.isArray(value)) {
    const numValues = value.length;
    return {
      trackStyle: Array(numValues - 1).fill(trackStyle),
      handleStyle: Array(numValues).fill(handleStyle),
    };
  }
  return { trackStyle, handleStyle };
};

const CustomSlider = props => {
  const {
    value,
    onChange,
    max,
    min,
    title = null,
    subtitle = null,
    disabled = false,
    step = undefined,
    color = primaryHex,
  } = props;

  const [dragging, setDragging] = useState(false);

  if ((!value && typeof value !== 'number') || (!min && typeof min !== 'number') || !max) {
    return <span>Missing data for slider</span>;
  }

  const sliderProps = {
    value,
    min,
    max,
    step,
    disabled,
    onChange: v => {
      setDragging(true);
      onChange(v);
    },
    onAfterChange: v => {
      setDragging(false);
      onChange(v);
    },
    ...defaultSliderStyles(value, disabled, color),
    ...styles,
  };

  return (
    <div className={cx('slider-wrap', { dragging }, 'py-2 my-1')}>
      {(title || subtitle) && (
        <div className="d-flex justify-content-between">
          <span>{title}</span>
          <span>{subtitle}</span>
        </div>
      )}
      {Array.isArray(value) ? <Range {...sliderProps} /> : <Slider {...sliderProps} />}
    </div>
  );
};

CustomSlider.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  disabled: PropTypes.bool,
  step: PropTypes.number,
  color: PropTypes.string,
};

export default CustomSlider;
