import classNames from 'classnames/bind';
import orderBy from 'lodash-es/orderBy';
import times from 'lodash-es/times';
import type { FC } from 'react';
import styles from './styles.module.scss';
import date from '../../../images/icons/date.svg';
import type { StaffLeagueData } from '../../../apps/Staff/graphql';

const cx = classNames.bind(styles);

type LeagueWeekDescriptions = {
  league: StaffLeagueData;
  minify?: boolean;
};

const LeagueWeekDescriptions: FC<LeagueWeekDescriptions> = ({ league, minify = false }) => {
  const { week_descriptions, week_tags, weeks } = league;

  const { num_weeks_estimate } = league;
  const weekTags = orderBy(weeks, 'week_num')?.map(({ week_tag }) => week_tag);
  const weekTagOrder = weekTags.filter(val => Boolean(val))?.length ? weekTags : week_tags;

  if (!weekTagOrder?.length && !week_descriptions?.length) return <span />;

  return (
    <div className={cx('info-container')}>
      <img className={cx('icon')} src={date} alt="date" />
      {week_descriptions?.length && !week_tags?.length ? (
        <>
          <p className={cx('title')}>By Week</p>
          {times(num_weeks_estimate, num => {
            const description = week_descriptions?.[num]?.description;
            return (
              <p key={num}>
                Week {num + 1}
                {description && `: ${description}`}
              </p>
            );
          })}
        </>
      ) : (
        <>
          <p className={cx('title')}>Notable Weeks</p>
          {times(num_weeks_estimate, weekNum => {
            const weekTag = weekTagOrder[weekNum];
            if (!weekTag) {
              return <span key={weekNum} />;
            }
            const weekTagIdx = week_tags.findIndex(
              t => t._id === (typeof weekTag === 'object' ? weekTag._id : '')
            );
            const weekDescription = week_descriptions[weekTagIdx];

            return (
              <div key={weekNum} className="py-1">
                Week {weekNum + 1}:
                <span className={cx('week-tag')}>
                  {typeof weekTag === 'object' ? weekTag.week_tag : weekTag}{' '}
                  <i>{!minify && `- ${weekDescription}`}</i>
                </span>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default LeagueWeekDescriptions;
