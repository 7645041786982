import * as _ from 'lodash-es';

// enum values are what get saved to league.seasonName
export enum SeasonEnum {
  WINTER_2016 = 'winter 2016',
  SUMMER_2016 = 'summer 2016',
  SPRING_2016 = 'spring 2016',
  FALL_2016 = 'fall 2016',
  LATE_FALL_2016 = 'late fall 2016',
  WINTER_2017 = 'winter 2017',
  SUMMER_2017 = 'summer 2017',
  SPRING_2017 = 'spring 2017',
  FALL_2017 = 'fall 2017',
  LATE_FALL_2017 = 'late fall 2017',
  WINTER_2018 = 'winter 2018',
  SUMMER_2018 = 'summer 2018',
  SPRING_2018 = 'spring 2018',
  FALL_2018 = 'fall 2018',
  LATE_FALL_2018 = 'late fall 2018',
  WINTER_2019 = 'winter 2019',
  SUMMER_2019 = 'summer 2019',
  SPRING_2019 = 'spring 2019',
  FALL_2019 = 'fall 2019',
  LATE_FALL_2019 = 'late fall 2019',
  WINTER_2020 = 'winter 2020',
  SUMMER_2020 = 'summer 2020',
  SPRING_2020 = 'spring 2020',
  FALL_2020 = 'fall 2020',
  LATE_FALL_2020 = 'late fall 2020',
  WINTER_2021 = 'winter 2021',
  SUMMER_2021 = 'summer 2021',
  SPRING_2021 = 'spring 2021',
  FALL_2021 = 'fall 2021',
  LATE_FALL_2021 = 'late fall 2021',
  WINTER_2022 = 'winter 2022',
  SUMMER_2022 = 'summer 2022',
  SPRING_2022 = 'spring 2022',
  FALL_2022 = 'fall 2022',
  LATE_FALL_2022 = 'late fall 2022',
  WINTER_2023 = 'winter 2023',
  SPRING_2023 = 'spring 2023',
  SUMMER_2023 = 'summer 2023',
  FALL_2023 = 'fall 2023',
  LATE_FALL_2023 = 'late fall 2023',
  WINTER_2024 = 'winter 2024',
  SPRING_2024 = 'spring 2024',
  SUMMER_2024 = 'summer 2024',
  FALL_2024 = 'fall 2024',
  LATE_FALL_2024 = 'late fall 2024',
  WINTER_2025 = 'winter 2025',
  SPRING_2025 = 'spring 2025',
  SUMMER_2025 = 'summer 2025',
  FALL_2025 = 'fall 2025',
  LATE_FALL_2025 = 'late fall 2025',
}

const seasonOrder = ['WINTER', 'SPRING', 'SUMMER', 'FALL', 'LATE FALL'];

export enum SeasonNames {
  WINTER = 'WINTER',
  SPRING = 'SPRING',
  SUMMER = 'SUMMER',
  FALL = 'FALL',
  LATE_FALL = 'LATE FALL',
}

export type Season = {
  value: SeasonEnum;
  year: number;
  season: SeasonNames;
  name: string;
  inPast?: boolean;
  inFuture?: boolean;
  bestGuessPrevious?: boolean;
  bestGuessCurrent?: boolean;
  bestGuessNext?: boolean;
};

export const Seasons: Readonly<Record<string, Season>> = Object.freeze({
  [SeasonEnum.WINTER_2016]: {
    value: SeasonEnum.WINTER_2016,
    year: 2016,
    season: SeasonNames.WINTER,
    name: 'Winter 2016',
    inPast: true,
  },
  [SeasonEnum.SPRING_2016]: {
    value: SeasonEnum.SUMMER_2016,
    year: 2016,
    season: SeasonNames.SUMMER,
    name: 'Summer 2016',
    inPast: true,
  },
  [SeasonEnum.SUMMER_2016]: {
    value: SeasonEnum.SPRING_2016,
    year: 2016,
    season: SeasonNames.SPRING,
    name: 'Spring 2016',
    inPast: true,
  },
  [SeasonEnum.FALL_2016]: {
    value: SeasonEnum.FALL_2016,
    year: 2016,
    season: SeasonNames.FALL,
    name: 'Fall 2016',
    inPast: true,
  },
  [SeasonEnum.LATE_FALL_2016]: {
    value: SeasonEnum.LATE_FALL_2016,
    year: 2016,
    season: SeasonNames.LATE_FALL,
    name: 'Late Fall 2016',
    inPast: true,
  },
  [SeasonEnum.WINTER_2017]: {
    value: SeasonEnum.WINTER_2017,
    year: 2017,
    season: SeasonNames.WINTER,
    name: 'Winter 2017',
    inPast: true,
  },
  [SeasonEnum.SUMMER_2017]: {
    value: SeasonEnum.SUMMER_2017,
    year: 2017,
    season: SeasonNames.SUMMER,
    name: 'Summer 2017',
    inPast: true,
  },
  [SeasonEnum.SPRING_2017]: {
    value: SeasonEnum.SPRING_2017,
    year: 2017,
    season: SeasonNames.SPRING,
    name: 'Spring 2017',
    inPast: true,
  },
  [SeasonEnum.FALL_2017]: {
    value: SeasonEnum.FALL_2017,
    year: 2017,
    season: SeasonNames.FALL,
    name: 'Fall 2017',
    inPast: true,
  },
  [SeasonEnum.LATE_FALL_2017]: {
    value: SeasonEnum.LATE_FALL_2017,
    year: 2017,
    season: SeasonNames.LATE_FALL,
    name: 'Late Fall 2017',
    inPast: true,
  },
  [SeasonEnum.WINTER_2018]: {
    value: SeasonEnum.WINTER_2018,
    year: 2018,
    season: SeasonNames.WINTER,
    name: 'Winter 2018',
    inPast: true,
  },
  [SeasonEnum.SUMMER_2018]: {
    value: SeasonEnum.SUMMER_2018,
    year: 2018,
    season: SeasonNames.SUMMER,
    name: 'Summer 2018',
    inPast: true,
  },
  [SeasonEnum.SPRING_2018]: {
    value: SeasonEnum.SPRING_2018,
    year: 2018,
    season: SeasonNames.SPRING,
    name: 'Spring 2018',
    inPast: true,
  },
  [SeasonEnum.FALL_2018]: {
    value: SeasonEnum.FALL_2018,
    year: 2018,
    season: SeasonNames.FALL,
    name: 'Fall 2018',
    inPast: true,
  },
  [SeasonEnum.LATE_FALL_2018]: {
    value: SeasonEnum.LATE_FALL_2018,
    year: 2018,
    season: SeasonNames.LATE_FALL,
    name: 'Late Fall 2018',
    inPast: true,
  },
  [SeasonEnum.WINTER_2019]: {
    value: SeasonEnum.WINTER_2019,
    year: 2019,
    season: SeasonNames.WINTER,
    name: 'Winter 2019',
    inPast: true,
  },
  [SeasonEnum.SUMMER_2019]: {
    value: SeasonEnum.SUMMER_2019,
    year: 2019,
    season: SeasonNames.SUMMER,
    name: 'Summer 2019',
    inPast: true,
  },
  [SeasonEnum.SPRING_2019]: {
    value: SeasonEnum.SPRING_2019,
    year: 2019,
    season: SeasonNames.SPRING,
    name: 'Spring 2019',
    inPast: true,
  },
  [SeasonEnum.FALL_2019]: {
    value: SeasonEnum.FALL_2019,
    year: 2019,
    season: SeasonNames.FALL,
    name: 'Fall 2019',
    inPast: true,
  },
  [SeasonEnum.LATE_FALL_2019]: {
    value: SeasonEnum.LATE_FALL_2019,
    year: 2019,
    season: SeasonNames.LATE_FALL,
    name: 'Late Fall 2019',
    inPast: true,
  },
  [SeasonEnum.WINTER_2020]: {
    value: SeasonEnum.WINTER_2020,
    year: 2020,
    season: SeasonNames.WINTER,
    name: 'Winter 2020',
    inPast: true,
  },
  [SeasonEnum.SUMMER_2020]: {
    value: SeasonEnum.SUMMER_2020,
    year: 2020,
    season: SeasonNames.SUMMER,
    name: 'Summer 2020',
    inPast: true,
  },
  [SeasonEnum.SPRING_2020]: {
    value: SeasonEnum.SPRING_2020,
    year: 2020,
    season: SeasonNames.SPRING,
    name: 'Spring 2020',
    inPast: true,
  },
  [SeasonEnum.FALL_2020]: {
    value: SeasonEnum.FALL_2020,
    year: 2020,
    season: SeasonNames.FALL,
    name: 'Fall 2020',
    inPast: true,
  },
  [SeasonEnum.LATE_FALL_2020]: {
    value: SeasonEnum.LATE_FALL_2020,
    year: 2020,
    season: SeasonNames.LATE_FALL,
    name: 'Late Fall 2020',
    inPast: true,
  },
  [SeasonEnum.WINTER_2021]: {
    value: SeasonEnum.WINTER_2021,
    year: 2021,
    season: SeasonNames.WINTER,
    name: 'Winter 2021',
    inPast: true,
  },
  [SeasonEnum.SUMMER_2021]: {
    value: SeasonEnum.SUMMER_2021,
    year: 2021,
    season: SeasonNames.SUMMER,
    name: 'Summer 2021',
    inPast: true,
  },
  [SeasonEnum.SPRING_2021]: {
    value: SeasonEnum.SPRING_2021,
    year: 2021,
    season: SeasonNames.SPRING,
    name: 'Spring 2021',
    inPast: true,
  },
  [SeasonEnum.FALL_2021]: {
    value: SeasonEnum.FALL_2021,
    year: 2021,
    season: SeasonNames.FALL,
    name: 'Fall 2021',
    inPast: true,
  },
  [SeasonEnum.LATE_FALL_2021]: {
    value: SeasonEnum.LATE_FALL_2021,
    year: 2021,
    season: SeasonNames.LATE_FALL,
    name: 'Late Fall 2021',
    inPast: true,
  },
  [SeasonEnum.WINTER_2022]: {
    value: SeasonEnum.WINTER_2022,
    year: 2022,
    season: SeasonNames.WINTER,
    name: 'Winter 2022',
    inPast: true,
  },
  [SeasonEnum.SPRING_2022]: {
    value: SeasonEnum.SPRING_2022,
    year: 2022,
    season: SeasonNames.SPRING,
    name: 'Spring 2022',
    inPast: true,
  },
  [SeasonEnum.SUMMER_2022]: {
    value: SeasonEnum.SUMMER_2022,
    year: 2022,
    season: SeasonNames.SUMMER,
    name: 'Summer 2022',
    inPast: true,
  },
  [SeasonEnum.FALL_2022]: {
    value: SeasonEnum.FALL_2022,
    year: 2022,
    season: SeasonNames.FALL,
    name: 'Fall 2022',
    inPast: true,
  },
  [SeasonEnum.LATE_FALL_2022]: {
    value: SeasonEnum.LATE_FALL_2022,
    year: 2022,
    season: SeasonNames.LATE_FALL,
    name: 'Late Fall 2022',
    inPast: true,
  },
  [SeasonEnum.WINTER_2023]: {
    value: SeasonEnum.WINTER_2023,
    year: 2023,
    season: SeasonNames.WINTER,
    name: 'Winter 2023',
    inPast: true,
  },
  [SeasonEnum.SPRING_2023]: {
    value: SeasonEnum.SPRING_2023,
    year: 2023,
    season: SeasonNames.SPRING,
    name: 'Spring 2023',
    inPast: true,
  },
  [SeasonEnum.SUMMER_2023]: {
    value: SeasonEnum.SUMMER_2023,
    year: 2023,
    season: SeasonNames.SUMMER,
    name: 'Summer 2023',
    inPast: true,
  },
  [SeasonEnum.FALL_2023]: {
    value: SeasonEnum.FALL_2023,
    year: 2023,
    season: SeasonNames.FALL,
    name: 'Fall 2023',
    inPast: true,
  },
  [SeasonEnum.LATE_FALL_2023]: {
    value: SeasonEnum.LATE_FALL_2023,
    year: 2023,
    season: SeasonNames.LATE_FALL,
    name: 'Late Fall 2023',
    inPast: true,
  },
  [SeasonEnum.WINTER_2024]: {
    value: SeasonEnum.WINTER_2024,
    year: 2024,
    season: SeasonNames.WINTER,
    name: 'Winter 2024',
    inPast: true,
  },
  [SeasonEnum.SPRING_2024]: {
    value: SeasonEnum.SPRING_2024,
    year: 2024,
    season: SeasonNames.SPRING,
    name: 'Spring 2024',
    inPast: true,
  },
  [SeasonEnum.SUMMER_2024]: {
    value: SeasonEnum.SUMMER_2024,
    year: 2024,
    season: SeasonNames.SUMMER,
    name: 'Summer 2024',
    inPast: true,
    bestGuessPrevious: true,
  },
  [SeasonEnum.FALL_2024]: {
    value: SeasonEnum.FALL_2024,
    year: 2024,
    season: SeasonNames.FALL,
    name: 'Fall 2024',
    bestGuessCurrent: true,
  },
  [SeasonEnum.LATE_FALL_2024]: {
    value: SeasonEnum.LATE_FALL_2024,
    year: 2024,
    season: SeasonNames.LATE_FALL,
    name: 'Late Fall 2024',
    inFuture: true,
    bestGuessNext: true,
  },
  [SeasonEnum.WINTER_2025]: {
    value: SeasonEnum.WINTER_2025,
    year: 2025,
    season: SeasonNames.WINTER,
    name: 'Winter 2025',
    inFuture: true,
  },
  [SeasonEnum.SPRING_2025]: {
    value: SeasonEnum.SPRING_2025,
    year: 2025,
    season: SeasonNames.SPRING,
    name: 'Spring 2025',
    inFuture: true,
  },
  [SeasonEnum.SUMMER_2025]: {
    value: SeasonEnum.SUMMER_2025,
    year: 2025,
    season: SeasonNames.SUMMER,
    name: 'Summer 2025',
    inFuture: true,
  },
  [SeasonEnum.FALL_2025]: {
    value: SeasonEnum.FALL_2025,
    year: 2025,
    season: SeasonNames.FALL,
    name: 'Fall 2025',
    inFuture: true,
  },
  [SeasonEnum.LATE_FALL_2025]: {
    value: SeasonEnum.LATE_FALL_2025,
    year: 2025,
    season: SeasonNames.LATE_FALL,
    name: 'Late Fall 2025',
    inFuture: true,
  },
});

export const SeasonsDesc = _.orderBy(
  Seasons,
  season => season.year + seasonOrder.indexOf(season.season) / 4,
  'desc'
);

export const orderSeasons = (seasons: Season[]) =>
  _.orderBy(
    seasons,
    ({ value }) =>
      value.split(' ')[1]! + _.indexOf(seasonOrder, _.upperCase(value.split(' ')[0])) / 4,
    'desc'
  );

export const CurrentSeason = Object.values(Seasons).find(
  ({ bestGuessCurrent }) => bestGuessCurrent
);
export const PreviousSeason = Object.values(Seasons).find(
  ({ bestGuessPrevious }) => bestGuessPrevious
);
