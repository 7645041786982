/* eslint-disable import/prefer-default-export */
import { graphql, type ResultOf } from 'graphql-schema';

export const LEAGUE_STANDINGS_QUERY = graphql(`
  query LeagueStandings($leagueId: uuid!) {
    leagues_by_pk(_id: $leagueId) {
      _id
      teams_approved
      schedule_approved
      divisions_activated
      divisions {
        _id
        divisionByDivision {
          _id
          name
        }
      }
      standings(
        order_by: [{ wins: desc }, { ties: desc }, { forfeits: asc }, { points_differential: desc }]
      ) {
        _id
        wins
        losses
        ties
        forfeits
        points_for
        points_against
        points_differential
        teamByTeam {
          _id
          name
          division
          captain_user
          color_hex
          color_name
          is_volo_pass_team
          co_captains {
            _id
            user
          }
          registrants(order_by: [{ userByUser: { last_name: asc } }]) {
            _id
            userByUser {
              _id
              email
              first_name
              last_name
              full_name
              gender
              shirt_size
              zip
              birthday
              phone_number_formatted
            }
            waiver_id
            waiver_date_signed
            waiver_reminders {
              _id
              date_sent
              userByCreatedBy {
                _id
                username
              }
            }
          }
        }
      }
    }
  }
`);

export type TeamStanding = NonNullable<
  ResultOf<typeof LEAGUE_STANDINGS_QUERY>['leagues_by_pk']
>['standings'][number];
