/**
 * NOTE: taken from apps/volosports-api/src/shared/registration-fees-enum.ts
 *
 * Daily Programs (League Drop-in, Pickup, Class, Clinic, Practice): flat $1 Tech fee
 * Events: Tech Fee equal to 10% of the event registration cost.
 * Tournaments: Tech Fee equal to 10% of the tournament registration cost.
 */
export const TechFees = {
    flat: 100,
    percentage: 0.1,
};
