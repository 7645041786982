import { graphql, type ResultOf } from 'graphql-schema';

export type PlayerStrike = NonNullable<
  ResultOf<typeof PLAYER_STRIKE_HISTORY>
>['player_strikes'][number];

const PLAYER_STRIKE_HISTORY = graphql(`
  query PlayerStrikeHistory($id: uuid!) {
    player_strikes(where: { user: { _eq: $id } }, order_by: { created_at: desc }) {
      _id
      created_at
      name
      fee_applied
      invalidated
      invalidation_type
      game {
        _id
        name
        league
      }
    }
  }
`);

export default PLAYER_STRIKE_HISTORY;
