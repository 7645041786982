/* eslint-disable react/jsx-props-no-spreading */
import { Suspense, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';
import { capitalize } from '@rivallapp/volosports-components';
import { FilterSelect, Loading, ListContainer, GenericErrorBoundary } from '../../../components';
import { Checkbox } from '../../../components/fancyFieldsV2';
import { WaiverAction, WaiverStatus } from '../components';
import hasuraClient from '../../../apollo/hasuraClient';
import PLAYER_WAIVERS_QUERY, { formatRegistrants } from './PLAYER_WAIVERS_GQL';

// Got lazy here
const filterStyles = Object.freeze({
  container: (base: any) => ({
    ...base,
    width: '100%',
  }),
  valueContainer: (base: any) => ({
    ...base,
    width: '100%',
    fontSize: '.75rem',
    textAlign: 'left',
    margin: '0 1rem',
  }),
  control: (base: any) => ({
    ...base,
    width: '100%',
    border: '1px solid black',
    minHeight: '1.75rem',
    height: '1.75rem',
  }),
});

type WaiverFilters = {
  signed: boolean;
  unsigned: boolean;
  seasons: string[];
  types: string[];
};

type Props = {
  userId?: string;
  userFullName?: string | null;
};

const PlayerWaiversTab = ({ userId, userFullName }: Props) => {
  const [filters, setFilters] = useState<WaiverFilters>({
    signed: false,
    unsigned: false,
    seasons: [],
    types: [],
  });

  const updateFilters = (update: Partial<WaiverFilters>) =>
    setFilters(previous => ({ ...previous, ...update }));

  const { data, refetch } = useSuspenseQuery(PLAYER_WAIVERS_QUERY, {
    client: hasuraClient,
    skip: !userId,
    fetchPolicy: 'network-only',
    variables: {
      userId: userId!,
      where: {
        user: { _eq: userId },
        ...(filters.seasons.length
          ? {
              leagueByLeague: { seasonBySeason: { name: { _in: filters.seasons } } },
            }
          : {}),
        ...(filters.types.length ? { waiver: { type: { _in: filters.types } } } : {}),
        ...(filters.signed ? { waiver_signed: { _eq: true } } : {}),
        ...(filters.unsigned ? { waiver_signed: { _eq: false } } : {}),
      },
    },
  });

  const registrants = formatRegistrants(data?.registrants || []);

  const { availableSeasons, availableTypes } = registrants.reduce<{
    availableSeasons: string[];
    availableTypes: string[];
  }>(
    (acc, { season, waiverType }) => {
      const { availableSeasons: seasons, availableTypes: types } = acc;
      return {
        availableSeasons: [...new Set([...seasons, season])],
        availableTypes: [...new Set([...types, waiverType])],
      };
    },
    {
      availableSeasons: [],
      availableTypes: [],
    }
  );

  const seasonsOptions = availableSeasons.map(season => ({
    value: season,
    label: capitalize(season),
  }));
  const typesOptions = availableTypes.map(type => ({ value: type, label: capitalize(type) }));

  return (
    <>
      <div className="d-flex my-3">
        <div className="col-12 col-md-3">
          <h3>Filter by:</h3>
        </div>
        <div className="col-12 col-md-3">
          <div className="text-left mb-2">
            <b>Status</b>
          </div>
          <div className="text-left mt-3">
            <Checkbox
              className="mr-2"
              label="signed"
              value={filters.signed}
              onChange={(val: boolean) => updateFilters({ signed: val })}
            />
            <Checkbox
              className="mr-2"
              label="unsigned"
              value={filters.unsigned}
              onChange={(val: boolean) => updateFilters({ unsigned: val })}
            />
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="text-left mb-2">
            <b>Season</b>
          </div>
          <FilterSelect
            styles={filterStyles}
            multi
            clearable
            labelSingular="Season"
            labelPlural="Seasons"
            options={seasonsOptions}
            value={seasonsOptions.filter(v => filters.seasons?.includes(v?.value))}
            onChange={v => {
              const values = v.map(item => item.value);
              updateFilters({ seasons: values });
            }}
          />
        </div>
        <div className="col-12 col-md-3">
          <div className="text-left mb-2">
            <b>Type of Waiver</b>
          </div>
          <FilterSelect
            styles={filterStyles}
            multi
            clearable
            labelSingular="Type"
            labelPlural="Types"
            options={typesOptions}
            value={typesOptions.filter(v => filters.types.includes(v.value))}
            onChange={v => {
              const values = v.map(item => item.value);
              updateFilters({ types: values });
            }}
          />
        </div>
      </div>
      <ListContainer
        columnTitles={['Waiver', 'Program', 'Season', 'Signed', 'Date Signed', 'Actions']}
        columnData={registrants.map(reg => [
          reg.waiverType ? capitalize(reg.waiverType) : 'General',
          reg.programId ? (
            <Link
              to={{
                pathname: `/rfo/league/${reg.programId}/view`,
                search: `?organization=${reg.organizationId}`,
              }}
            >
              {reg.programName}
            </Link>
          ) : (
            reg.programName
          ),
          reg.season ? capitalize(reg.season) : '',
          <WaiverStatus signed={!!reg.waiverDateSigned} />,
          reg.waiverDateSigned ? new Date(reg.waiverDateSigned).toLocaleString() : '',
          <WaiverAction
            dateSigned={reg.waiverDateSigned}
            waiverReminders={reg.waiverReminders}
            waiverId={reg.waiverId}
            refetch={refetch}
            registrantId={reg.registrantId}
            userFullName={userFullName}
          />,
        ])}
        numOfCol={6}
        emptyText="No waivers"
      />
    </>
  );
};

export default (props: Props) => (
  <Suspense fallback={<Loading type="inline" />}>
    <GenericErrorBoundary>
      <PlayerWaiversTab {...props} />
    </GenericErrorBoundary>
  </Suspense>
);
