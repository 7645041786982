import { useSuspenseQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import { useState, useRef, type FC, Suspense } from 'react';
import { useReactToPrint } from 'react-to-print';
import LeagueTeamInfoDetail from './LeagueTeamInfoDetail';
import { LEAGUE_STANDINGS_QUERY } from './LEAGUE_STANDINGS_QUERY';
import styles from './styles.module.scss';
import LeagueTeamPrintView from './LeagueTeamPrintView';
import { Button, PageLoader, FilterSelect, GenericErrorBoundary } from '../../../components';
import hasuraClient from '../../../apollo/hasuraClient';

const cx = classNames.bind(styles);

type Props = {
  leagueId: string;
};

const LeagueTeamInfo: FC<Props> = ({ leagueId }) => {
  // Used for print view
  const ref = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const [showAllPlayers, setShowAllPlayers] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState<string | undefined>(undefined);

  const {
    data: { leagues_by_pk: league },
  } = useSuspenseQuery(LEAGUE_STANDINGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    client: hasuraClient,
    variables: { leagueId },
  });

  if (!league) throw new Error('League not found..');

  const { standings, teams_approved, divisions_activated, schedule_approved, divisions } = league;

  if (!teams_approved)
    return <p className="my-2">This league does not have finalized teams yet.</p>;

  const divisionsOptions = [
    {
      _id: undefined,
      name: 'No Division',
    },
    ...divisions.map(d => d.divisionByDivision),
  ]?.map(division => ({
    value: division?._id,
    label: division?.name,
  }));

  const filteredStandings = selectedDivision
    ? standings?.filter(({ teamByTeam }) => selectedDivision === teamByTeam?.division)
    : standings;

  return (
    <div className={cx('league-roster-detail', 'mt-3')}>
      <div className="d-flex align-items-center mb-3">
        <Button small link onClick={() => setShowAllPlayers(!showAllPlayers)}>
          {showAllPlayers ? 'Hide Player Lists' : 'Show Player Lists'}
        </Button>
        <div>
          <Button small onClick={handlePrint} className="m-2">
            Print View
          </Button>
          <div style={{ display: 'none' }}>
            <LeagueTeamPrintView ref={ref} {...league} />
          </div>
        </div>
        {divisions_activated && (
          <div>
            <FilterSelect
              options={divisionsOptions}
              onChange={opt => {
                setSelectedDivision(opt?.value);
              }}
              value={divisionsOptions.filter(({ value }) => value === selectedDivision)}
              placeholder="Select Division"
              styles={{
                control: base => ({
                  ...base,
                  width: '200px',
                  paddingLeft: '.5rem',
                }),
              }}
            />
          </div>
        )}
      </div>
      <div className={cx('roster-item-wrap', 'header-row')}>
        <div className={cx('main-row')}>
          <div className={cx('left')}>
            <strong>Team Name</strong>
          </div>
          {schedule_approved && (
            <div className={cx('right')}>
              <span className={cx('record')}>Wins</span>
              <span className={cx('record')}>Losses</span>
              <span className={cx('record')}>Ties</span>
              <span className={cx('record')}>Forfeits</span>
              <span className={cx('record')}>Points For</span>
              <span className={cx('record')}>Points Against</span>
              <span className={cx('record')}>Points Differential</span>
            </div>
          )}
        </div>
      </div>
      {filteredStandings.map(standing => (
        <LeagueTeamInfoDetail
          key={standing._id}
          standing={standing}
          showAllPlayers={showAllPlayers}
          scheduleApproved={schedule_approved}
        />
      ))}
      {schedule_approved && (
        <div className={cx('points-formula')}>
          <p className={cx('points-formula-header')}>
            <strong>How Teams are ranked:</strong> Win = 3pts, Loss = 0pts, Tie = 1pt, Forfeit =
            -1pt
          </p>
          <p>
            Ties are broken by comparing the <i>Points differential</i>. If the result is a tie the
            next tiebreaker is
            <i> Points For</i> and then, if necessary, by
            <i> Points Against</i> until there is a clear winner.
          </p>
        </div>
      )}
      <div className={cx('key')}>
        <p>* = team captain</p>
      </div>
    </div>
  );
};

export default (props: Props) => (
  <GenericErrorBoundary>
    <Suspense fallback={<PageLoader />}>
      <LeagueTeamInfo {...props} />
    </Suspense>
  </GenericErrorBoundary>
);
