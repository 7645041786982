import { type ReactNode, isValidElement, type FC } from 'react';
import {
  Pressable,
  View,
  Text,
  Chevron,
  type ITextProps,
  type PressableProps,
} from '../../base-components';

export type CollapsibleBaseProps = {
  children?: React.ReactNode;

  /**
   * is drawer in collapsed state
   * @default true
   * */
  isCollapsed: boolean;
  /** callback executed when drawer is pressed/clicked */
  onPress: () => void;
  /** drawer label */
  title?: ReactNode;
  titleProps?: ITextProps;
  /** drawer subtitle */
  subtitle?: string;
  subtitleStyle?: ITextProps;
  CustomChevron?: React.ComponentType<{ clicked: boolean }>;
  _pressableProps?: PressableProps;
};

const CollapsibleBase: FC<CollapsibleBaseProps> = ({
  title,
  subtitle,
  subtitleStyle,
  onPress,
  children,
  isCollapsed = true,
  CustomChevron,
  titleProps = {},
  _pressableProps = {},
}) => {
  return (
    <>
      <Pressable
        onPress={onPress}
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        marginX={2.5}
        {..._pressableProps}
      >
        <View flexDir="row" alignItems="center">
          {isValidElement(title) ? (
            title
          ) : (
            <Text fontSize={18} bold {...titleProps}>
              {title || 'Item'}
            </Text>
          )}
          {!!subtitle && (
            <Text type="caption" ml={1} {...subtitleStyle}>
              {subtitle}
            </Text>
          )}
        </View>
        {CustomChevron ? (
          <CustomChevron clicked={!isCollapsed} />
        ) : (
          <Chevron clicked={!isCollapsed} size="sm" />
        )}
      </Pressable>
      {!isCollapsed && children}
    </>
  );
};

export default CollapsibleBase;
