import { skipToken, useSuspenseQuery } from '@apollo/client';
import { Card, Text, View } from '@rivallapp/volosports-components';
import type { FC } from 'react';

import { calculatePricingValues, PriceText, styles } from './paymentSummaryUtils';
import type { MembershipPaymentSummaryProps } from './types';
import GET_UPGRADE_PRICING from '../gql/GET_UPGRADE_PRICING';
import hasuraClient from '../../../../../../apollo/hasuraClient';

/**
 * @param {string} selectedId current planId selected
 * @param {string} planId planId to compare to
 * @param {number} planCents cents value associated with planId
 * @param {number} altPlanCents alternative cents value if ID check fails */
const getValueFromPlanId = (
  selectedId: string | null,
  planId: string | null,
  planCents: number,
  altPlanCents: number
) => {
  if (!selectedId) return null;
  if (selectedId === planId) return planCents;
  return altPlanCents;
};

/**
 * Copied nearly 1:1 from player-app, with some tweaks to make it web compatible
 */
const MembershipPaymentSummary: FC<MembershipPaymentSummaryProps> = ({
  planDetails,
  annualPlanId,
  annualFeeCents,
  monthlyFeeCents,
  annualPriceCents,
  monthlyPriceCents,
  upgradeAnnual = false,
}) => {
  const { planId, promoData } = planDetails;
  const promoCodeString = promoData?.code || promoData?.name || '';

  const { feesCents, totalCents, membershipCents, promoDiscountCents, originalMembershipCents } =
    calculatePricingValues({
      promoCodeData: promoData,
      feesCents: getValueFromPlanId(planId, annualPlanId, annualFeeCents, monthlyFeeCents),
      membershipCents: getValueFromPlanId(
        planId,
        annualPlanId,
        annualPriceCents,
        monthlyPriceCents
      ),
    });

  const { data } = useSuspenseQuery(
    GET_UPGRADE_PRICING,
    upgradeAnnual && planId
      ? {
          fetchPolicy: 'network-only',
          variables: {
            planId,
            promoCode: promoCodeString,
          },
          client: hasuraClient,
        }
      : skipToken
  );

  const subtotalCents = data?.getVoloPassUpgradePricing.subtotalCents ?? 0;
  const taxCents = data?.getVoloPassUpgradePricing.taxCents ?? 0;
  const proratedAmountCents = data?.getVoloPassUpgradePricing.proratedAmountCents ?? 0;
  const annualTotalCents = data?.getVoloPassUpgradePricing?.totalCents ?? 0;

  const showUpgradePricing =
    upgradeAnnual &&
    !!planId &&
    !!subtotalCents &&
    !!taxCents &&
    !!annualTotalCents &&
    !!originalMembershipCents;

  const showNonUpgradePricing =
    !upgradeAnnual && !!feesCents && !!totalCents && !!membershipCents && !!originalMembershipCents;

  let priceItems: {
    label: string;
    cents: number;
    strikethroughCents?: number;
    bold?: boolean;
    subtract?: boolean;
  }[] = [];

  if (showUpgradePricing) {
    priceItems = [
      {
        label: 'Prorated Membership Price',
        cents: originalMembershipCents + proratedAmountCents,
        strikethroughCents: originalMembershipCents,
      },
      {
        label: 'Fees',
        cents: taxCents,
      },
      ...(promoDiscountCents
        ? [
            {
              label: promoCodeString,
              subtract: true,
              cents: promoDiscountCents,
            },
          ]
        : []),
      {
        label: 'Total Due',
        cents: annualTotalCents,
        bold: true,
      },
    ];
  }

  if (showNonUpgradePricing) {
    priceItems = [
      {
        label: 'Membership Price',
        cents: originalMembershipCents,
      },
      {
        label: 'Fees',
        cents: feesCents,
      },
      ...(promoDiscountCents
        ? [
            {
              label: promoCodeString!,
              subtract: true,
              cents: promoDiscountCents,
            },
          ]
        : []),
      {
        label: 'Total Due',
        cents: totalCents,
        bold: true,
      },
    ];
  }

  return (
    <Card padding={3} borderRadius={15}>
      <View>
        {priceItems.map(({ label, cents, bold = false, subtract = false, strikethroughCents }) => {
          return (
            <View style={[styles.row]} key={label}>
              <View style={styles.label}>
                <Text bold={bold} fontSize="md">
                  {label}
                </Text>
              </View>
              <View style={styles.value}>
                {!!(strikethroughCents && strikethroughCents !== cents) && (
                  <PriceText bold={bold} strikethrough fontSize="md" value={strikethroughCents} />
                )}
                <PriceText bold={bold} value={cents} subtract={subtract} fontSize="md" />
              </View>
            </View>
          );
        })}
      </View>
    </Card>
  );
};

export default MembershipPaymentSummary;
