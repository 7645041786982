import { graphql } from 'graphql-schema';

export const PLAYER_STRIKE_COUNT = graphql(`
  query PlayerStrikeCount {
    currentUser {
      _id
      has_volo_pass
      next_strike_will_trigger_fee
      player_strikes_aggregate(where: { invalidated: { _eq: false } }) {
        aggregate {
          count
        }
      }
    }
  }
`);

export const ADMIN_PLAYER_STRIKE_COUNT = graphql(`
  query AdminPlayerStrikeCount($id: uuid!) {
    user: users_by_pk(_id: $id) {
      _id
      has_volo_pass
      next_strike_will_trigger_fee
      player_strikes_aggregate(where: { invalidated: { _eq: false } }) {
        aggregate {
          count
        }
      }
    }
  }
`);
