export const REGISTER_PATH = '/app/register';

export const RegisterSections = Object.freeze({
  START: {
    path: 'start',
  },
  REGISTRATION_TYPE: {
    path: 'rtype',
  },
  SELECT_GROUP: {
    path: 'join-group',
  },
  JOIN_PREPAID_TEAM: {
    path: 'join-team',
  },
  NEW_GROUP: {
    path: 'new-group',
  },
  NEW_TEAM: {
    path: 'new-team',
  },
  FORMS: {
    path: 'deets',
  },
  SKILL_LEVEL: {
    path: 'skill-level',
  },
  DONATION: {
    path: 'donation',
  },
  PAYMENT: {
    path: 'finish-line',
  },
  CONFIRMATION: {
    path: 'confirmation',
  },
});

export const RegisterTypes = Object.freeze({
  PREPAID_TEAM_CAPTAIN: 'ptc',
  PREPAID_TEAM_MEMBER: 'ptm',
  GROUP_CAPTAIN: 'gc',
  GROUP_MEMBER: 'gm',
  FREE_AGENT: 'fa',
  WAITLIST: 'w',
});

export const ConfirmationScreenLinks: Record<
  string,
  { label: string; to: string } | { label: string; url: string }
> = Object.freeze({
  findMore: {
    label: 'Find More Programs',
    to: '/discover',
  },
  dashboard: {
    label: 'Go to your Dashboard',
    to: '/app/dashboard',
  },
  volunteer: {
    label: 'Volunteer with Volo Kids',
    url: 'https://volokids.org/',
  },
  // becomeHost: {
  //   label: 'Become a Host',
  //   url: '',
  // },
  playVirtually: {
    label: 'Play Virtually',
    to: '/Virtual',
  },
});
