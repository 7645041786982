import { graphql, type ResultOf } from 'graphql-schema';

const HASURA_DISCOVER_PROGRAM_TAGS = graphql(`
  query GetActiveTags($cityName: String!) {
    program_tags(
      where: {
        leagues: {
          organizationByOrganization: { name: { _eq: $cityName } }
          status: { _eq: registration_open }
          archived: { _eq: false }
          private: { _eq: false }
          program_tag: { _is_null: false }
        }
      }
    ) {
      _id
      name
    }
  }
`);

export type HasuraDiscoverProgramTagsData = ResultOf<typeof HASURA_DISCOVER_PROGRAM_TAGS>;

export default HASURA_DISCOVER_PROGRAM_TAGS;
