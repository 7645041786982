import discoverHelpers from '../helpers';
import { MainTabEnum, SubTabEnum } from '../../../constants/enums/discover-tabs-enum';
import { View, Text } from '../../../base-components';
import { CustomButton } from '../../../custom-components';

import styles from '../styles';
import { ProgramType } from '../../../constants';

export type ProgramTypeFilterType = {
  subTab?: (typeof SubTabEnum)[keyof typeof SubTabEnum];
  mainTab?: (typeof MainTabEnum)[keyof typeof MainTabEnum];
  onPress: (param?: string) => void;
  selectedProgramTypes: string[];
};

const { getProgramTypesFromTabs } = discoverHelpers;

const ProgramTypeFilter = ({
  subTab = SubTabEnum.LEAGUE,
  mainTab = MainTabEnum.SPORTS,
  onPress,
  // used if program types are present in url params
  selectedProgramTypes = [],
}: ProgramTypeFilterType) => {
  const programTypes = getProgramTypesFromTabs(mainTab, subTab);

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        {programTypes.length > 0 ? (
          programTypes.map(type => (
            <CustomButton
              key={type}
              value={ProgramType[type].name}
              variant="outline"
              onPress={() => onPress(ProgramType[type].value)}
              pressed={selectedProgramTypes.includes(type)}
              style={styles.button}
            />
          ))
        ) : (
          <Text>No program types found</Text>
        )}
      </View>
    </View>
  );
};

export default ProgramTypeFilter;
