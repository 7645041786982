/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { TOGGLE_USER_ACCOUNT_STATUS } from '../../PLAYER_DETAILS_MUTATIONS';
import { AreYouSure, Button } from '../../../../components';
import useActionStates from '../../../../hooks/useActionStates';
import hasuraClient from '../../../../apollo/hasuraClient';
import useCurrentUserV2 from '../../../../hooks/useCurrentUserV2';

type Props = {
  userId: string;
  deactivated: boolean;
  goBackAction: () => void;
  clearSelectedUser: () => void;
};

const PlayerDeactivate = ({ userId, deactivated, goBackAction, clearSelectedUser }: Props) => {
  const [showCheck, setShowCheck] = useState(false);

  const [toggleAccountStatusMutation, { loading }] = useMutation(TOGGLE_USER_ACCOUNT_STATUS, {
    client: hasuraClient,
  });

  const { setError, setSuccess } = useActionStates({ withAlerts: true });

  const { currentUser } = useCurrentUserV2();
  const { username } = currentUser || {};

  return (
    <>
      <AreYouSure
        body={`Are you sure you want to ${
          deactivated
            ? 'reactivate this user? They will immediately be able to login.'
            : 'deactivate this user? They will no longer be able to login.'
        }`}
        hidden={!showCheck}
        yesAction={async () => {
          try {
            await toggleAccountStatusMutation({
              variables: {
                userId,
                deactivated: !deactivated,
                note: `User ${!deactivated ? 'deactivated' : 'reactivated'} by ${username}`,
              },
            });
            setSuccess(`Player ${deactivated ? 're' : 'de'}activated!`);
          } catch (e) {
            setError(e);
            throw new Error('Unable to deactivate account for some reason');
          } finally {
            clearSelectedUser();
          }
        }}
        close={() => setShowCheck(false)}
      />
      <div className="text-center">
        <h3 className="m-2">Deactivate User</h3>
        <div className="">
          <Button
            {...{
              ...(deactivated ? { success: true } : { danger: true }),
            }}
            disabled={loading}
            square
            onClick={() => setShowCheck(true)}
            className="col my-2"
          >
            {deactivated ? 'Reactivate' : 'Deactivate'}
          </Button>
          <a
            className="text-volo-danger font-italic"
            role="button"
            tabIndex={0}
            onClick={() => goBackAction()}
          >
            Go Back
          </a>
        </div>
      </div>
    </>
  );
};

export default PlayerDeactivate;
