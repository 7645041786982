import type { ExtractEnum } from 'graphql-schema';

export const GenderEnum = Object.freeze({
  OTHER: 'o',
  FEMALE: 'f',
  MALE: 'm',
});

export enum GenderEnum_TS {
  OTHER = 'o',
  FEMALE = 'f',
  MALE = 'm',
}

export const DropInSlotGenderEnum = Object.freeze({
  MALE: 'm',
  FEMALE: 'f',
  ANY: 'a',
});

export enum DropInSlotGenderEnum_TS {
  FEMALE = 'f',
  ANY = 'a',
  /**
   * @deprecated
   * use 'ANY' instead
   */
  MALE = 'm',
}

export const GenderNameEnum = Object.freeze({
  [GenderEnum.MALE]: 'Man',
  [GenderEnum.FEMALE]: 'Woman',
  [GenderEnum.OTHER]: 'Non-Binary',
});

export const Genders = Object.freeze({
  [GenderEnum.MALE]: {
    value: GenderEnum.MALE,
    name: 'Men',
  },
  [GenderEnum.FEMALE]: {
    value: GenderEnum.FEMALE,
    name: 'Women',
  },
  [GenderEnum.OTHER]: {
    value: GenderEnum.OTHER,
    name: 'Any',
  },
});

// this is how we save gender in a league
export const ProgramGenderEnum = Object.freeze({
  COED: 'coed',
  MEN: 'men',
  WOMEN: 'women',
  OPEN: 'open',
});

export const ProgramGenders = Object.freeze({
  [ProgramGenderEnum.COED]: {
    value: ProgramGenderEnum.COED,
    name: 'Coed',
    validGenders: [GenderEnum.OTHER, GenderEnum.FEMALE, GenderEnum.MALE],
  },
  [ProgramGenderEnum.MEN]: {
    value: ProgramGenderEnum.MEN,
    name: 'Men',
    validGenders: [GenderEnum.OTHER, GenderEnum.MALE, GenderEnum.FEMALE],
  },
  [ProgramGenderEnum.WOMEN]: {
    value: ProgramGenderEnum.WOMEN,
    name: 'Women',
    validGenders: [GenderEnum.OTHER, GenderEnum.FEMALE],
  },
  [ProgramGenderEnum.OPEN]: {
    value: ProgramGenderEnum.OPEN,
    name: 'Open',
    validGenders: [GenderEnum.OTHER, GenderEnum.FEMALE, GenderEnum.MALE],
  },
});

export const GenderCaseChange = Object.freeze({
  MALE: 'm',
  FEMALE: 'f',
  OTHER: 'o',
});

export const GenderOptions = Object.freeze<
  { value: ExtractEnum<'gender_enum'>; label: string; display: string }[]
>([
  {
    value: 'm',
    label: 'Man',
    display: 'm',
  },
  {
    value: 'f',
    label: 'Woman',
    display: 'w',
  },
  {
    value: 'o',
    label: 'Non-Binary',
    display: 'nb',
  },
]);

export const GenderLabels = Object.freeze({
  [GenderEnum.MALE]: 'm',
  [GenderEnum.FEMALE]: 'w',
  [GenderEnum.OTHER]: 'nb',
});

/**
 * Map of user gender to an array of valid program genders
 */
export const UserGenderToValidProgramGenders = Object.freeze<
  Record<(typeof GenderEnum)[keyof typeof GenderEnum], ExtractEnum<'program_gender_enum'>[]>
>({
  [GenderEnum.MALE]: ['men', 'coed', 'open'],
  [GenderEnum.FEMALE]: ['men', 'women', 'coed', 'open'],
  [GenderEnum.OTHER]: ['men', 'women', 'coed', 'open'],
});
