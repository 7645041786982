const capitalizeFirstLetter = (v) => v.length ? `${v[0].toUpperCase()}${v.slice(1)}` : '';
export const capitalize = (v) => {
    if (!v)
        return '';
    return v
        .split(' ')
        .map((word, i) => {
        if (['the', 'of', 'is', 'and'].some(exception => exception.toLowerCase() === word.toLowerCase() && i !== 0 // Capitalize first word with no exceptions
        )) {
            return word.toLowerCase();
        }
        return capitalizeFirstLetter(word);
    })
        .join(' ');
};
export const formatUsername = (u) => {
    if (!u)
        return '';
    return u.toLowerCase().replace(/[^0-9a-z_]/g, '');
};
export const formatEmail = (e) => e ? e.toLowerCase().replace(/ /g, '') : '';
