/**
 * This is a port of the league.registration.phases resolver:
 * apps/volosports-api/src/graphql/types/league/resolvers/index.js
 *
 * Reshapes our postgres data to what the `Pricing` component expects from the old API.
 *
 * (Hopefully) a temporary measure that will be removed in new create flow!
 */
import { calcPriceWithFees } from './utils.js';
const formatToYYMMDD = (date, timezone) => {
    return new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    })
        .format(date)
        .replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2');
};
const getDateStr = (dbDate, timezone) => {
    if (!dbDate)
        return '';
    return formatToYYMMDD(new Date(dbDate), timezone);
};
const getDayBeforeStr = (dbDate, timezone) => {
    if (!dbDate)
        return '';
    const date = new Date(dbDate);
    date.setDate(date.getDate() - 1);
    return formatToYYMMDD(date, timezone);
};
const getRegCloseStr = ({ registration_close_date, timezone, }) => {
    return formatToYYMMDD(new Date(registration_close_date), timezone);
};
const getEndOfDayBefore = (dateStr, timezone) => {
    // Create date in target timezone
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    // Get the date one day before
    const date = new Date(dateStr);
    date.setDate(date.getDate() - 1);
    // Format the date parts in the correct timezone
    const parts = formatter.formatToParts(date);
    const year = parts.find(p => p.type === 'year')?.value;
    const month = parts.find(p => p.type === 'month')?.value;
    const day = parts.find(p => p.type === 'day')?.value;
    // Create end of day time (23:59:59.999) in the target timezone
    return new Date(`${year}-${month}-${day}T23:59:59.999`);
};
function safeToCents(num) {
    if (num === null)
        return null;
    return Math.round(num * 100);
}
const resolveRegistrationPricePhases = ({ registration, timezone, programType, prepaidMinTeamSize, organizationFees, }) => {
    const { early_bird_allowed, early_bird_price, early_bird_start_date, early_bird_volo_pass_price, corporate_early_bird_price, corporate_early_bird_allowed, regular_price, regular_start_date, regular_volo_pass_price, corporate_price, last_chance_allowed, last_chance_price, last_chance_start_date, last_chance_volo_pass_price, corporate_last_chance_allowed, corporate_last_chance_price, registration_close_date, } = registration;
    return {
        earlyBird: {
            allow: Boolean(early_bird_allowed),
            allowIndividual: Boolean(early_bird_allowed),
            allowPrepaidTeam: Boolean(corporate_early_bird_allowed),
            individualPrice: safeToCents(early_bird_price),
            individualPriceWithFees: calcPriceWithFees({
                originalPriceCents: safeToCents(early_bird_price),
                programType,
                fees: organizationFees,
            }),
            individualVoloPassMemberPrice: safeToCents(early_bird_volo_pass_price),
            individualVoloPassMemberPriceWithFees: calcPriceWithFees({
                originalPriceCents: safeToCents(early_bird_volo_pass_price),
                programType,
                fees: organizationFees,
            }),
            prepaidTeamPrice: corporate_early_bird_price,
            prepaidTeamPriceWithFees: calcPriceWithFees({
                originalPriceCents: safeToCents(corporate_early_bird_price),
                programType,
                fees: organizationFees,
                prepaidMinTeamSize,
            }),
            startDate: early_bird_start_date,
            startDateStr: getDateStr(early_bird_start_date, timezone),
            endDateStr: getDayBeforeStr(regular_start_date, timezone),
            endDate: getEndOfDayBefore(regular_start_date, timezone),
        },
        regular: {
            allow: true,
            allowIndividual: true,
            allowPrepaidTeam: true,
            individualPrice: safeToCents(regular_price),
            individualPriceWithFees: calcPriceWithFees({
                originalPriceCents: safeToCents(regular_price),
                programType,
                fees: organizationFees,
            }),
            individualVoloPassMemberPrice: safeToCents(regular_volo_pass_price),
            individualVoloPassMemberPriceWithFees: calcPriceWithFees({
                originalPriceCents: safeToCents(regular_volo_pass_price),
                programType,
                fees: organizationFees,
            }),
            prepaidTeamPrice: corporate_price,
            prepaidTeamPriceWithFees: calcPriceWithFees({
                originalPriceCents: safeToCents(corporate_price),
                programType,
                fees: organizationFees,
                prepaidMinTeamSize,
            }),
            startDate: regular_start_date,
            startDateStr: getDateStr(regular_start_date, timezone),
            endDateStr: last_chance_allowed
                ? getDayBeforeStr(last_chance_start_date, timezone)
                : getRegCloseStr({ registration_close_date, timezone }),
            endDate: last_chance_allowed && last_chance_start_date
                ? getEndOfDayBefore(last_chance_start_date, timezone)
                : getEndOfDayBefore(registration_close_date, timezone),
        },
        lastChance: {
            allow: Boolean(last_chance_allowed),
            allowIndividual: Boolean(last_chance_allowed),
            allowPrepaidTeam: Boolean(corporate_last_chance_allowed),
            individualPrice: safeToCents(last_chance_price),
            individualPriceWithFees: calcPriceWithFees({
                originalPriceCents: safeToCents(last_chance_price),
                programType,
                fees: organizationFees,
            }),
            individualVoloPassMemberPrice: safeToCents(last_chance_volo_pass_price),
            individualVoloPassMemberPriceWithFees: calcPriceWithFees({
                originalPriceCents: safeToCents(last_chance_volo_pass_price),
                programType,
                fees: organizationFees,
            }),
            prepaidTeamPrice: safeToCents(corporate_last_chance_price),
            prepaidTeamPriceWithFees: calcPriceWithFees({
                originalPriceCents: safeToCents(corporate_last_chance_price),
                programType,
                fees: organizationFees,
            }),
            startDate: last_chance_start_date,
            startDateStr: getDateStr(last_chance_start_date, timezone),
            endDateStr: getRegCloseStr({ registration_close_date, timezone }),
            endDate: registration_close_date,
        },
    };
};
export default resolveRegistrationPricePhases;
