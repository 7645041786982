import {
  ProgramTypeEnum,
  ProgramType,
  MembershipEnum,
  Memberships,
} from '../../../constants/enums';
import { PriceFormatter } from '../../../utilities';

type GetMembershipDescriptionArgs = {
  membership: MembershipEnum.VOLO_PASS;
  monthlyPriceOfVoloPassInCents: number;
  savingsInCents: number;
  programType?: ProgramTypeEnum;
  voloPassTrial?: boolean;
};

/**
 * Determines the advertising copy to display underneath the radio button for a particular membership.
 */
const getMembershipDescription = (args: GetMembershipDescriptionArgs) => {
  const membershipName = Memberships[args.membership]?.name;
  switch (args.membership) {
    case MembershipEnum.VOLO_PASS: {
      const { monthlyPriceOfVoloPassInCents, savingsInCents, programType, voloPassTrial } = args;

      const formattedVoloPassPricing = PriceFormatter.format(monthlyPriceOfVoloPassInCents / 100);
      const formattedSavings = PriceFormatter.format(savingsInCents / 100);
      const formattedProgramTypeName = programType
        ? ProgramType[programType].name.toLowerCase()
        : 'program';

      return voloPassTrial
        ? `Sign up for a 2-week free trial for ${membershipName} for ${formattedVoloPassPricing} and get ${formattedSavings} off this ${formattedProgramTypeName}! Initial 3-month commitment after the trial ends.`
        : `Sign up for ${membershipName} for ${formattedVoloPassPricing} per month and get ${formattedSavings} off this ${formattedProgramTypeName}! Initial 3-month commitment.`;
    }
    default:
      throw new Error('useMembershipDescription: Unexpected input');
  }
};

export default getMembershipDescription;
