import { ProgramTypeEnum } from '../constants/enums';
import generatePalette from '../utilities/color';
import type {
  BlueColors,
  Color,
  GlobalColors,
  GreyColors,
  ISelectColors,
  NavTheme,
  TBrandColors,
  AccentBaseColors,
} from './types';
import colorManipulation from '../utilities/tinyColor';
import * as tokens from './tokens';

// ======================= OLD VARIABLES - MANY SHOULD BE DEPRECATED =======================
//        These have been mapped to their closest match in our design token system
//       All future/refactored work should directly reference the token variables and/or
//               token aliases that align with a defined design system
// =========================================================================================

// DEPRECATE -- use token variables directly
export const voloBlues: BlueColors = {
  veryDark: tokens.colorPrimary,
  dark: tokens.colorBlue800,
  medium: tokens.colorBlue700,
  light: tokens.colorBlue400,
  veryLight: tokens.colorBlue200,
} as const;

// DEPRECATE -- use token variables directly
export const voloGreys: GreyColors = {
  extraLight: tokens.colorGrey50,
  veryLight: tokens.colorGrey50,
  light: tokens.colorGrey200,
  medium: tokens.colorGrey500,
  dark: tokens.colorGrey900,
  veryDark: tokens.colorGrey1000,
} as const;

// hex for digital screens <--- ??
// DEPRECATE -- use token variables directly
export const primaryShades = {
  100: tokens.colorPrimary,
  75: tokens.colorBlue800,
  50: tokens.colorBlue700,
  25: tokens.colorBlue400,
  0: tokens.colorBlue200,
} as const;

// accent colors, updated from Megan's design (7/19/23)
// DEPRECATE -- use token variables directly
export const accents = {
  teal: tokens.colorTeal300,
  lightTeal: tokens.colorTeal100,
  accentBlue: tokens.colorBlue400,
  complimentaryYellow: tokens.colorHighlighter300,
  yellow: tokens.colorHighlighter300,
  lightYellow: tokens.colorHighlighter50,
  purple: tokens.colorPurple700,
  rsvpGreen: tokens.colorGreen900,
  yes: tokens.colorSuccess,
  maybe: tokens.colorWarning,
  no: tokens.colorDanger,
} as const satisfies AccentBaseColors;

// DEPRECATE
export const rentalBlueHex = tokens.colorBlue400;
export const lightRentalBlueRgb = {
  // where do we need rgb values?
  r: 0,
  g: 149,
  b: 238,
};
export const darkRentalBlueRgb = {
  // where do we need rgb values?
  r: 0,
  g: 187,
  b: 246,
};

// For NativeBase theme customization
// All items should reference token variables
export const VoloNativeBaseShades: TBrandColors = {
  primary: {
    50: tokens.colorBlue100,
    100: tokens.colorBlue200,
    200: tokens.colorBlue300,
    300: tokens.colorBlue400,
    400: tokens.colorBlue500,
    500: tokens.colorBlue700,
    600: tokens.colorPrimary, // Deprecate - nearly equal to primary.900
    700: tokens.colorPrimary, // Deprecate - nearly equal to primary.900
    800: tokens.colorPrimary, // Deprecate - nearly equal to primary.900
    900: tokens.colorPrimary,
  },
  secondary: {
    // Deprecate - use primary blues
    50: tokens.colorWhite, // deprecate, use primary blue
    100: tokens.colorWhite, // deprecate, use primary blue
    200: tokens.colorBlue50, // deprecate, use primary blue
    300: tokens.colorBlue200, // deprecate, use primary blue
    400: tokens.colorBlue300, // deprecate, use primary blue
    500: tokens.colorBlue300, // deprecate, use primary blue
    600: tokens.colorBlue400, // deprecate, use primary blue
    700: tokens.colorBlue500, // deprecate, use primary blue
    800: tokens.colorBlue600, // deprecate, use primary blue
    900: tokens.colorBlue800, // deprecate, use primary blue
  },
  tertiary: {
    50: tokens.colorTeal100,
    100: tokens.colorTeal200,
    200: tokens.colorTeal300,
    300: tokens.colorTeal400,
    400: tokens.colorTeal400,
    500: tokens.colorTeal500,
    600: tokens.colorTeal400,
    700: tokens.colorTeal700,
    800: tokens.colorTeal800,
    900: tokens.colorTeal950,
  },
  white: {
    // note whites are not in order of value, color matched
    50: tokens.colorGrey50,
    100: tokens.colorGrey50, // deprecate, use white50
    200: tokens.colorGrey100,
    300: tokens.colorGrey100, // deprecate, use white200
    400: tokens.colorGrey100, // deprecate, use white200
    500: tokens.colorGrey100, // deprecate, use white200
    600: tokens.colorWhite, // deprecate, use white
    700: tokens.colorGrey50, // deprecate, use white50
    800: tokens.colorGrey50, // deprecate, use white50
    900: tokens.colorGrey50, // deprecate, use white50
  },
  gray: {
    50: tokens.colorGrey50,
    100: tokens.colorGrey200,
    200: tokens.colorGrey300,
    300: tokens.colorGrey400,
    400: tokens.colorGrey500,
    500: tokens.colorGrey800,
    600: tokens.colorGrey1000, // deprecate, use 900
    700: tokens.colorGrey1000, // deprecate, use 900
    800: tokens.colorGrey1000, // deprecate, use 900
    900: tokens.colorGrey1000,
  },
  // prev comment: "was using 'lightBlue' but it was just different enough they wanted their own color palette for it"
  // emmy update: absolutely not.
  rentalBlue: {
    50: tokens.colorBlue100, // deprecate, use primary blue
    100: tokens.colorBlue200, // deprecate, use primary blue
    200: tokens.colorBlue300, // deprecate, use primary blue
    300: tokens.colorBlue400, // deprecate, use primary blue
    400: tokens.colorBlue500, // deprecate, use primary blue
    500: tokens.colorBlue500, // deprecate, use primary blue
    600: tokens.colorBlue500, // deprecate, use primary blue
    700: tokens.colorBlue600, // deprecate, use primary blue
    800: tokens.colorBlue700, // deprecate, use primary blue
    900: tokens.colorBlue800, // deprecate, use primary blue
  },
  success: {
    // ui colors - IMO not necessary to have 10 shades. using duplicate colors to satisfy NativeBase format.
    50: tokens.colorGreen100, // do not use
    100: tokens.colorGreen100,
    200: tokens.colorGreen100, // do not use
    300: tokens.colorSuccess, // do not use
    400: tokens.colorSuccess,
    500: tokens.colorSuccess, // do not use
    600: tokens.colorSuccess, // do not use
    700: tokens.colorGreen900, // do not use
    800: tokens.colorGreen900, // do not use
    900: tokens.colorGreen900,
  },
  warning: {
    50: tokens.colorOrange100, // do not use
    100: tokens.colorOrange100,
    200: tokens.colorOrange100, // do not use
    300: tokens.colorWarning, // do not use
    400: tokens.colorWarning,
    500: tokens.colorWarning, // do not use
    600: tokens.colorWarning, // do not use
    700: tokens.colorOrange900, // do not use
    800: tokens.colorOrange900, // do not use
    900: tokens.colorOrange900,
  },
  error: {
    50: tokens.colorRed100, // do not use
    100: tokens.colorRed100,
    200: tokens.colorRed100, // do not use
    300: tokens.colorDanger, // do not use
    400: tokens.colorDanger,
    500: tokens.colorDanger, // do not use
    600: tokens.colorDanger, // do not use
    700: tokens.colorRed900, // do not use
    800: tokens.colorRed900, // do not use
    900: tokens.colorRed900,
  },
  /**
   * Custom palettes used for Radio
   * Radio light mode icon uses colorScheme.600, whereas dark mode uses colorScheme.500
   */
  lightSelect: generatePalette(tokens.colorTeal300), // deprecate or replace with tokens
  darkSelect: generatePalette(accents.lightTeal, 500), // deprecate or replace with tokens
};

/**
 * select colors for any select components, updated from Megan's design (7/19/23)
 * has a light and dark mode for easy use and update in the future
 */
export const selectColors: Record<string, ISelectColors> = {
  light: {
    bg: tokens.colorTeal300,
    text: tokens.colorBlack,
  },
  dark: {
    bg: tokens.colorTeal100,
    text: tokens.colorBlack,
  },
} as const;

// Color accents for each of the Program Types
export const programColors: Record<ProgramTypeEnum, Color> = {
  [ProgramTypeEnum.DROPIN]: tokens.colorProgramDropin,
  [ProgramTypeEnum.PICKUP]: tokens.colorProgramPickup,
  [ProgramTypeEnum.LEAGUE]: tokens.colorProgramLeague,
  [ProgramTypeEnum.CLINIC]: tokens.colorProgramClinic,
  [ProgramTypeEnum.PRACTICE]: tokens.colorProgramPractice,
  [ProgramTypeEnum.TOURNAMENT]: tokens.colorProgramTournament,
  [ProgramTypeEnum.EVENT]: tokens.colorProgramEvent,
  [ProgramTypeEnum.CLASS]: tokens.colorProgramClass,
  [ProgramTypeEnum.SERIES]: tokens.colorProgramSeries,
  [ProgramTypeEnum.VOLUNTEER_EVENT]: tokens.colorProgramVolunteerEvent,
  [ProgramTypeEnum.VOLUNTEER_LEAGUE]: tokens.colorProgramVolunteerLeague,
  // Not used for color border
  [ProgramTypeEnum.RENTAL]: tokens.colorProgramRental,
  // The following programs should not be discoverable
  [ProgramTypeEnum.PLAYOFFS]: tokens.colorProgramPlayoffs,
  [ProgramTypeEnum.VIRTUAL]: tokens.colorProgramVirtual,
} as const;

// DEPRECATE. Prior comment: "try not to use this, i left this for reference of the way we used to do it in mobile4" :(
export const globalColors: Record<string, Color> = {
  brandPrimary: tokens.colorPrimary,
  brandSecondary: tokens.colorHighlighter300,
  brandTertiary: tokens.colorBlue500,
  lightYellow: tokens.colorHighlighter200,
  accentYellow: tokens.colorHighlighter100,
  accentLightYellow: tokens.colorHighlighter50,
  lightBlue: tokens.colorBlue700,
  accentBlue: tokens.colorTeal100,
  accentLightBlue: tokens.colorTeal50,
  accentLightBlueBorder: tokens.colorTeal300,
  veryDarkBlue: tokens.colorPrimary,
  accentGreen: tokens.colorSuccess,
  black: tokens.colorBlack,
  white: tokens.colorWhite,
  error: tokens.colorDanger,
  opaqueWhite: 'rgba(255, 255, 255, 0.5)', // opaque means no transparency
  lightGrey: tokens.colorGrey200,
  mediumGrey: tokens.colorGrey500,
  darkGrey: tokens.colorGrey900,
  yes: tokens.colorSuccess,
  maybe: tokens.colorWarning,
  no: tokens.colorDanger,
} as const;

export const Colors: GlobalColors = {
  primary: tokens.colorPrimary,
  secondary: tokens.colorBlue400,
  accent: tokens.colorTeal300,
  shades: VoloNativeBaseShades,
  error: tokens.colorDanger,
  warning: tokens.colorWarning,
  success: tokens.colorSuccess,
  link: tokens.colorAction,
  greys: voloGreys, // deprecate
  blues: voloBlues, // deprecate
  white: tokens.colorWhite,
  black: tokens.colorBlack,
  select: selectColors,
  hover: tokens.colorGrey50,
  banner: tokens.colorTeal100,
  programColors,
  accents, // deprecate
  logoColors: {
    primary: tokens.colorPrimary,
    white: tokens.colorWhite,
  },
} as const;

export const LightTheme: NavTheme = {
  dark: false,
  colors: {
    primary: tokens.colorPrimary,
    secondary: tokens.colorBlue400,
    tertiary: tokens.colorTeal300,
    background: tokens.colorWhite,
    card: colorManipulation.darken(tokens.colorWhite, 3), // :(
    text: tokens.colorBlack,
    border: tokens.colorGrey200,
    notification: tokens.colorDanger,
    accents: {
      ...accents,
      select: selectColors.light!, // TODO: This shouldn't need an assertion but the type for `selectColors` needs a rework
      hover: tokens.colorGrey50,
    },
    programColors,
    logoColors: {
      primary: tokens.colorPrimary,
      white: tokens.colorWhite,
    },
  },
} as const;

export const DarkTheme: NavTheme = {
  dark: true,
  colors: {
    primary: tokens.colorBlue500,
    secondary: tokens.colorBlue400,
    tertiary: tokens.colorTeal300,
    background: colorManipulation.darken(tokens.colorGrey1000, 4), // :( this is probably close to black..?
    card: tokens.colorGrey1000,
    text: tokens.colorWhite,
    border: tokens.colorGrey900,
    notification: tokens.colorDanger,
    accents: {
      ...accents,
      select: selectColors.dark!, // TODO: This shouldn't need an assertion but the type for `selectColors` needs a rework
      hover: tokens.colorGrey50,
    },
    programColors,
    logoColors: {
      primary: tokens.colorPrimary,
      white: tokens.colorWhite,
    },
  },
} as const;
