import { useSuspenseQuery } from '@apollo/client';
import { useState, type CSSProperties, type FC } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from '@rivallapp/volosports-components';
import { graphql } from 'graphql-schema';
import { Image, Popup, Tooltip } from '../../../components';
import {
  alarm,
  cup,
  date,
  marker,
  refShirt,
  users,
  users_team,
  user_single,
} from '../../../images/icons';
import styles from '../../../styles/main.module.scss';
import { pluralize } from '../../../utils/index';
import { ProgramGenders } from '../../../shared/gender-enum';
import hasuraClient from '../../../apollo/hasuraClient';

const cx = classNames.bind(styles);

const GET_LEAGUE_DATA = graphql(`
  query getLeagueData($programId: uuid!) {
    league: leagues_by_pk(_id: $programId) {
      _id
      deep_link
      end_time_estimate
      format_type
      gender
      league_rules
      num_playoff_weeks_estimate
      num_weeks_estimate
      start_date
      start_time_estimate
      timezone
      days_of_weeks {
        _id
        day_of_week
      }
      registration: registrationByRegistration {
        _id
        allow_prepaid_teams
        max_team_size
        min_team_size
      }
      sport: sportBySport {
        _id
        name
      }
      venue: venueByVenue {
        _id
        formatted_address
        shorthand_name
      }
    }
  }
`);

type LeagueBasicsCard = {
  leagueId: string;
  style?: CSSProperties;
  hideWaysToRegister?: boolean;
  hideDeepLink?: boolean;
};

const LeagueBasicsCard: FC<LeagueBasicsCard> = ({
  leagueId,
  style = {},
  hideWaysToRegister = false,
  hideDeepLink = false,
}) => {
  const [didCopy, onCopy] = useState(false);
  const [showRules, setShowRules] = useState(false);

  const { data } = useSuspenseQuery(GET_LEAGUE_DATA, {
    variables: { programId: leagueId },
    client: hasuraClient,
  });

  // hide copied text after 3 sec
  const showCopied = () => {
    onCopy(true);
    setTimeout(() => onCopy(false), 1000);
  };

  if (!data?.league) {
    throw new Error('uh oh!');
  }
  const { league } = data;

  const {
    registration,
    sport,
    format_type,
    gender,
    deep_link,
    venue: { formatted_address, shorthand_name } = {},
    league_rules,
    timezone,
    start_date,
    start_time_estimate,
    end_time_estimate,
    days_of_weeks,
    num_weeks_estimate,
    num_playoff_weeks_estimate,
  } = league;
  const { allow_prepaid_teams, max_team_size, min_team_size } = registration;
  const { name: activityName } = sport;

  // If the league doesn't have prepaid teams but does permit groups,
  //  we show groups as being 2-(max) players. Otherwise, 2-(min-1).
  const maxGroupPlayersStr = !allow_prepaid_teams
    ? `${max_team_size <= 2 ? '' : ` - ${max_team_size}`}`
    : `${min_team_size <= 3 ? '' : ` - ${min_team_size - 1}`}`;

  const genderString =
    gender?.toLowerCase() === 'n/a' ? 'Open' : `${ProgramGenders[gender!]?.name}'s`;
  const startMoment = moment.tz(start_date, timezone);
  const beginStr =
    days_of_weeks?.length > 1
      ? startMoment.format('dddd M/D')
      : `${moment().isAfter(startMoment) ? 'Began' : 'Begins'} ${startMoment.format('M/D')}`;

  const numWeekString = `${num_weeks_estimate - (num_playoff_weeks_estimate || 0)} ${
    num_playoff_weeks_estimate ? `+ ${num_playoff_weeks_estimate} playoff ` : ' '
  }`;

  return (
    <div className={cx('basic-container')}>
      <section className={cx('league-basics')}>
        <div className={cx('row')} style={style}>
          <div className={cx('col-12', 'col-sm-4', styles.item)}>
            <div>
              <div style={{ minWidth: '32px' }}>
                <Image src={cup} />
              </div>
              <p>
                {genderString} {activityName}{' '}
                {format_type && format_type !== 'N/A' && `(${format_type})`}
              </p>
            </div>
            <div>
              <div style={{ minWidth: '32px' }}>
                <Image src={marker} />
              </div>
              <p>
                <a
                  href={
                    `https://www.google.com/maps/dir//${
                      formatted_address
                        ? formatted_address.replace(/,/g, '').replace(/ /g, '+')
                        : (shorthand_name ?? '').replace(/ /g, '+')
                    }` || '#'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {shorthand_name || formatted_address}
                </a>
              </p>
            </div>
            {!!league_rules && (
              <div>
                <div style={{ minWidth: '32px' }}>
                  <Image src={refShirt} />
                </div>
                <Button
                  onPress={() => setShowRules(true)}
                  variant="link"
                  padding={0}
                  margin={0}
                  height={0}
                  _text={{
                    fontSize: 14,
                    color: '#1e62b4', // matches <a> color above
                    padding: 0,
                    margin: 0,
                  }}
                  _hover={{
                    _text: { textDecoration: 'none' },
                  }}
                >
                  League Rules
                </Button>
              </div>
            )}
          </div>
          <div className={cx('col-12', 'col-sm-4', styles.item)}>
            <div>
              <div style={{ minWidth: '32px' }}>
                <Image src={date} />
              </div>
              <p>{beginStr}</p>
            </div>
            {num_weeks_estimate && (
              <div>
                <div style={{ minWidth: '32px' }}>
                  <Image src={date} />
                </div>
                <p>
                  {numWeekString}
                  {pluralize('week', num_weeks_estimate + (num_playoff_weeks_estimate ?? 0))}
                </p>
              </div>
            )}
            <div>
              <div style={{ minWidth: '32px' }}>
                <Image src={alarm} />
              </div>
              <p>
                {moment.tz(start_time_estimate ?? '', 'HH:mm', timezone).format('h:mma')} -{' '}
                {moment.tz(end_time_estimate ?? '', 'HH:mm', timezone).format('h:mma z')}
              </p>
            </div>
          </div>
          {max_team_size > 1 && !hideWaysToRegister && (
            <div className={cx('col-12', 'col-sm-4', styles.item)}>
              <div>
                <div style={{ minWidth: '32px' }}>
                  <Image src={user_single} />
                </div>
                <p>Individual: 1 player</p>
              </div>
              <div>
                <div style={{ minWidth: '32px' }}>
                  <Image src={users} />
                </div>
                <p>Group: 2{maxGroupPlayersStr} players</p>
              </div>
              {allow_prepaid_teams && (
                <div>
                  <div style={{ minWidth: '32px' }}>
                    <Image src={users_team} />
                  </div>
                  <p>Team: {min_team_size}+ players</p>
                </div>
              )}
            </div>
          )}
        </div>
        <Popup className="wider" hidden={!showRules} close={() => setShowRules(false)}>
          <div className={cx('mb-4', styles.rules)}>
            <Image style={{ maxWidth: '36px' }} className="mb-3" src={refShirt} />
            <h4 className="mb-3">League Rules</h4>
            <p
              className="d-inline-block text-left"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: league_rules ?? '' }}
            />
          </div>
          <button onClick={() => setShowRules(false)} type="button" tabIndex={0}>
            Close
          </button>
        </Popup>
      </section>
      {!hideDeepLink && (
        <CopyToClipboard text={deep_link ?? ''} onCopy={showCopied}>
          <span
            className="d-inline-flex align-items-center"
            data-for="copy-link"
            data-tip={didCopy ? 'Deep link copied!' : 'Click to copy'}
          >
            <p>{didCopy ? 'Link copied!' : 'Deep Link'}</p>
            <Tooltip id="copy-link" />
          </span>
        </CopyToClipboard>
      )}
    </div>
  );
};

export default LeagueBasicsCard;
