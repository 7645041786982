import classNames from 'classnames/bind';
import { useState, type FC } from 'react';
import styles from './styles.module.scss';
import { ColorIndicator } from '../../../components';
import { GenderNameEnum } from '../../../shared/gender-enum';
import WaiverStatus from '../../PlayerDetails/components/WaiverStatus';
import WaiverAction from '../../PlayerDetails/components/WaiverAction';
import PlayerDetailsPopup from '../../PlayerDetails/PlayerDetailsPopup';
import Picture from '../../../components/Picture/Picture';
import type { TeamStanding } from './LEAGUE_STANDINGS_QUERY';

const cx = classNames.bind(styles);

type Props = {
  standing: TeamStanding;
  showAllPlayers?: boolean;
  scheduleApproved?: boolean | null;
};

const LeagueTeamInfoDetail: FC<Props> = ({ standing, showAllPlayers, scheduleApproved }) => {
  const [showPlayers, setShowPlayers] = useState(false);

  const { teamByTeam: team } = standing;
  if (!team) return <span />;

  const { is_volo_pass_team, name, captain_user, co_captains, color_hex, color_name } = team;

  const wins = standing.wins ?? 0;
  const losses = standing.losses ?? 0;
  const ties = standing.ties ?? 0;
  const forfeits = standing.forfeits ?? 0;
  const points_for = standing.points_for ?? 0;
  const points_against = standing.points_against ?? 0;
  const points_differential = standing.points_differential ?? 0;

  const color = color_name || color_hex || 'N/A';
  const registrants = team.registrants ?? [];

  const getPlayerLabel = (_id: string) => {
    if (_id === captain_user) return <b>(c)</b>;
    if (co_captains.some(c => c.user === _id)) return '(co)';
    return '';
  };

  return (
    <div className={cx('roster-item-wrap')}>
      <div className={cx('main-row')}>
        <div
          className={cx('left')}
          onClick={() => setShowPlayers(!showPlayers)}
          onKeyDown={() => setShowPlayers(!showPlayers)}
          role="button"
          tabIndex={0}
        >
          <ColorIndicator
            color={color_hex}
            style={{ height: '10px', width: '10px', marginRight: '4px' }}
          />
          <span className={cx('team-color')}>({color})</span>
          <h2>
            {name}
            {is_volo_pass_team && (
              <Picture
                className={cx('vp-img')}
                src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/VoloPass.png"
                alt="volo pass"
              />
            )}
          </h2>
        </div>
        {scheduleApproved && (
          <div className={cx('right')}>
            <span className={cx('record')}>{wins}</span>
            <span className={cx('record')}>{losses}</span>
            <span className={cx('record')}>{ties}</span>
            <span className={cx('record')}>{forfeits}</span>
            <span className={cx('record')}>{points_for}</span>
            <span className={cx('record')}>{points_against}</span>
            <span className={cx('record')}>{points_differential}</span>
          </div>
        )}
      </div>
      <div className={cx('detail', { shown: showPlayers || showAllPlayers })}>
        <p className={cx('color', { shown: showPlayers || showAllPlayers })}>
          Color: {color}
          {forfeits ? (
            <span className={cx('forfeits')}>
              <br />
              Forfeits: {forfeits}
            </span>
          ) : null}
        </p>
        <table className={cx('player-list')}>
          <tbody>
            {registrants.map(registrant => {
              const {
                _id: registrantId,
                waiver_id,
                waiver_date_signed,
                waiver_reminders,
                userByUser: {
                  _id,
                  email,
                  gender,
                  first_name,
                  last_name,
                  full_name,
                  shirt_size,
                  phone_number_formatted,
                },
              } = registrant;
              return (
                <tr key={_id}>
                  <td className={cx('table-col')} aria-label="Player Details">
                    <PlayerDetailsPopup userId={_id} />
                  </td>
                  <td className={cx('table-col', 'center')}>{getPlayerLabel(_id)}</td>
                  <td className={cx('table-col', 'col-link')}>{first_name}</td>
                  <td className={cx('table-col')}>{last_name}</td>
                  <td className={cx('table-col', 'center')}>
                    <span>{shirt_size?.toUpperCase()}</span>
                  </td>
                  <td className={cx('table-col')}>
                    <span>{gender && GenderNameEnum[gender]}</span>
                  </td>
                  <td className={cx('table-col')}>
                    <span>
                      <a href={`mailto:${email}`}>{email}</a>
                    </span>
                  </td>
                  <td className={cx('table-col')}>
                    <span>
                      <a href={`tel:${phone_number_formatted}`}>{phone_number_formatted}</a>
                    </span>
                  </td>
                  <td className={cx('table-col', 'd-flex', 'align-items-center')}>
                    <WaiverStatus signed={!!waiver_date_signed} />
                    <WaiverAction
                      waiverId={waiver_id}
                      userFullName={full_name}
                      registrantId={registrantId}
                      dateSigned={waiver_date_signed}
                      waiverReminders={waiver_reminders}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeagueTeamInfoDetail;
