import type { ExtractEnum } from 'graphql-schema';
import { SignupsCustomParams } from './host-dashboard-custom-params-enum';

export type ExtractedRegistrantTypeEnum = ExtractEnum<'registrant_type_enum'>;

/** @deprecated - Replace with extracted type */
export enum RegistrantTypeEnum {
  GROUP_CAPTAIN = 'group_captain',
  PRIVATE_CAPTAIN = 'private_captain',
  GROUP_MEMBER = 'group_member',
  PRIVATE_MEMBER = 'private_member',
  INDIVIDUAL = 'individual',
  WAITLISTED_INDIVIDUAL = 'waitlist_individual',
  STAFF = 'staff',
  CANCELLED = 'cancelled',
}

type RegistrantTypes = {
  [Key in ExtractedRegistrantTypeEnum]: {
    value: Key;
    label: string;
    abbrev?: string;
    isCaptain?: boolean;
    type?: (typeof SignupsCustomParams)[keyof typeof SignupsCustomParams];
  };
};

export const RegistrantTypes = Object.freeze<RegistrantTypes>({
  [RegistrantTypeEnum.GROUP_CAPTAIN]: {
    value: RegistrantTypeEnum.GROUP_CAPTAIN,
    label: 'Group captain',
    abbrev: 'GC',
    isCaptain: true,
    type: SignupsCustomParams.GROUP,
  },
  [RegistrantTypeEnum.PRIVATE_CAPTAIN]: {
    value: RegistrantTypeEnum.PRIVATE_CAPTAIN,
    label: 'Prepaid team captain',
    abbrev: 'PTC',
    isCaptain: true,
    type: SignupsCustomParams.PREPAID,
  },
  [RegistrantTypeEnum.GROUP_MEMBER]: {
    value: RegistrantTypeEnum.GROUP_MEMBER,
    label: 'Group member',
    abbrev: 'GM',
    type: SignupsCustomParams.GROUP,
  },
  [RegistrantTypeEnum.PRIVATE_MEMBER]: {
    value: RegistrantTypeEnum.PRIVATE_MEMBER,
    label: 'Prepaid team member',
    abbrev: 'PTM',
    type: SignupsCustomParams.PREPAID,
  },
  [RegistrantTypeEnum.INDIVIDUAL]: {
    value: RegistrantTypeEnum.INDIVIDUAL,
    label: 'Free agent',
    abbrev: 'FA',
    type: SignupsCustomParams.FREE_AGENT,
  },
  [RegistrantTypeEnum.WAITLISTED_INDIVIDUAL]: {
    value: RegistrantTypeEnum.WAITLISTED_INDIVIDUAL,
    label: 'Waitlist',
    abbrev: 'W',
  },
  [RegistrantTypeEnum.CANCELLED]: {
    value: RegistrantTypeEnum.CANCELLED,
    label: '',
  },
  [RegistrantTypeEnum.STAFF]: {
    value: RegistrantTypeEnum.STAFF,
    label: 'Hosts',
    abbrev: 'H',
  },
});

export const isCaptainType = (kind: RegistrantTypeEnum) =>
  kind === RegistrantTypeEnum.GROUP_CAPTAIN || kind === RegistrantTypeEnum.PRIVATE_CAPTAIN;
export default RegistrantTypeEnum;

export type RegistrantTypeCategory =
  | 'captains'
  | 'groups'
  | 'prepaid_teams'
  | 'free_agents'
  | 'waitlist';

export const RegistrantTypeEnumMap: Record<RegistrantTypeCategory, ExtractedRegistrantTypeEnum[]> =
  {
    captains: ['group_captain', 'private_captain'],
    groups: ['group_captain', 'group_member'],
    prepaid_teams: ['private_captain', 'private_member'],
    free_agents: ['individual', 'waitlist_individual'],
    waitlist: ['waitlist_individual'],
  } as const;

export const getRegistrantTypesFromCategories = (categories: RegistrantTypeCategory[]) => {
  const registrantTypes = new Set<ExtractedRegistrantTypeEnum>();

  categories.forEach(category => {
    RegistrantTypeEnumMap[category].forEach(type => registrantTypes.add(type));
  });

  return Array.from(registrantTypes);
};
