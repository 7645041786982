import { gql, type TypedDocumentNode } from '@apollo/client';
import { graphql, type ResultOf } from 'graphql-schema';
import { type DiscoverFilterVariables } from './types';

// --- Programs ---

export const HASURA_DISCOVER_NEIGHBORHOODS = graphql(`
  query GetActiveNeighborhoods($cityName: String!) {
    neighborhoods(
      where: {
        leagues: {
          organizationByOrganization: { name: { _eq: $cityName } }
          status: { _eq: registration_open }
          archived: { _eq: false }
          private: { _eq: false }
        }
      }
    ) {
      _id
      name
    }
  }
`);

export type HasuraDiscoverNeighborhoodsData = ResultOf<typeof HASURA_DISCOVER_NEIGHBORHOODS>;

// --- Rentals ---

type DiscoverRentalNeighborhoodsData = {
  discoverRentalNeighborhoods: {
    _id: string;
    name: string;
  }[];
};

export const DISCOVER_RENTAL_NEIGHBORHOODS: TypedDocumentNode<
  DiscoverRentalNeighborhoodsData,
  DiscoverFilterVariables
> = gql`
  query DiscoverRentalNeighborhoods($input: DiscoverRentalsInput!) {
    discoverRentalNeighborhoods(input: $input) {
      _id
      name
    }
  }
`;
