import { useCallback, useMemo, useState } from 'react';
import { skipToken, useLazyQuery, useMutation, useSuspenseQuery } from '@apollo/client';
import moment from 'moment-timezone';

import { formatCents } from '../../utils';
import { useAlertMessage } from '../index';
import { type PlanDetails } from '../../apps/Anon/HomepageV2/VoloPassV2/VoloPassSignUpModal/components/types';
import {
  GET_STRIPE_PROMO,
  ACTIVATE_MEMBERSHIP,
  CANCEL_MEMBERSHIP,
  UPGRADE_MEMBERSHIP,
  REINSTATE_MEMBERSHIP,
  GET_SUBSCRIPTION_AND_ORGS,
} from './graphql';
import useCurrentUserV2 from '../useCurrentUserV2';
import hasuraClient from '../../apollo/hasuraClient';

const useVoloPassDetailsV2 = (
  { annualPlanOverride, city } = { annualPlanOverride: '', city: '' }
) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [planStatus, setPlanStatus] = useState('');

  const { currentUser, refetch } = useCurrentUserV2({ fetchPolicy: 'cache-and-network' });
  const { _id, membership_expires, home_organization } = currentUser || {};
  const {
    data,
    refetch: activeRefetch,
    error: activeError,
  } = useSuspenseQuery(
    GET_SUBSCRIPTION_AND_ORGS,
    _id
      ? {
          variables: { userId: _id },
          client: hasuraClient,
        }
      : skipToken
  );

  const { subscriptions = [], organizations = [], getUserPaymentSources = [] } = data ?? {};

  let activeSubscription:
    | {
        interval: 'month' | 'year';
        status:
          | 'active'
          | 'trialing'
          | 'canceled'
          | 'incomplete'
          | 'incomplete_expired'
          | 'past_due'
          | 'paused'
          | 'unpaid';
        stripe_subscription_id: string;
        stripe_product_id: string;
        organization: string | null;
      }
    | undefined;
  if (subscriptions.length && subscriptions[0]) {
    [activeSubscription] = subscriptions;
  }
  const [planDetails, setPlanDetails] = useState<PlanDetails>(() => {
    const selectedCity = organizations.find(org =>
      activeSubscription
        ? org.external_id === activeSubscription.organization
        : org._id === home_organization
    );
    return {
      // if there's an active subscription, use that city as the default
      // otherwise use the user's home org
      // TODO @Noah figure out what happens for users with no home org or anonymous users :'(
      selectedOrganizationId: selectedCity?._id ?? '',
      planId: annualPlanOverride || (selectedCity?.volo_pass_annual_plan_id ?? ''),
      promo: '',
      enteringPromo: false,
      promoData: null,
      variant: activeSubscription ? 'annual' : 'monthly',
    };
  });

  const { promoData, planId, selectedOrganizationId } = planDetails;

  const { showError, showSuccess } = useAlertMessage();

  const updatePlanDetails = useCallback(
    // TODO add the correct type here
    (update: any) => setPlanDetails({ ...planDetails, ...update }),
    [planDetails]
  );

  const [membershipGetPromoQuery] = useLazyQuery(GET_STRIPE_PROMO, { client: hasuraClient });
  const [startMembershipMutation, { loading: startMembershipUpdating }] = useMutation(
    ACTIVATE_MEMBERSHIP,
    { client: hasuraClient, refetchQueries: ['getSubscriptionAndOrgs'] }
  );
  const [cancelMembershipMutation] = useMutation(CANCEL_MEMBERSHIP, {
    client: hasuraClient,
    refetchQueries: ['getSubscriptionAndOrgs'],
  });
  const [updateUserPlanMutation] = useMutation(UPGRADE_MEMBERSHIP, {
    client: hasuraClient,
    refetchQueries: ['getSubscriptionAndOrgs'],
  });
  const [reinstateMembershipMutation] = useMutation(REINSTATE_MEMBERSHIP, {
    client: hasuraClient,
    refetchQueries: ['getSubscriptionAndOrgs'],
  });

  const selectedCity = useMemo(() => {
    if (city) return organizations.find(o => o.name === city)!;
    return organizations.find(o => o._id === selectedOrganizationId)!;
  }, [organizations, selectedOrganizationId, city]);

  const cityOptions = useMemo(() => {
    return organizations?.map(({ _id, name }: { _id: string; name: string }) => ({
      value: _id,
      label: name,
    }));
  }, [organizations]);

  const activeVoloPassPlanDetails = activeSubscription
    ? {
        planId: activeSubscription.stripe_product_id,
        planInterval: activeSubscription.interval,
        planOrganization: activeSubscription.organization,
      }
    : {};

  const expiredDate = moment(membership_expires ?? '').format('MM/DD/YYYY');
  const afterExpireDate = moment().isAfter(moment(expiredDate).endOf('day'));

  const refetchAll = async () => {
    await Promise.all([refetch(), activeRefetch()]);
  };

  const checkPayment = useCallback(async () => {
    // in case someone just added their card info, have to refetch
    if (!getUserPaymentSources.length) await refetch();
    const paymentSources = getUserPaymentSources ?? [];
    const defaultSource = paymentSources.find((p: any) => p.isDefault && !p.isExpired);
    if (!defaultSource) {
      throw new Error('Please make sure you have at least one payment on file.');
    }
  }, [getUserPaymentSources, refetch]);

  const validatePromo = async (promoEntry?: string) => {
    try {
      if (!promoEntry) return;
      updatePlanDetails({ promoData: null });
      const { data: resData } = await membershipGetPromoQuery({
        variables: { promoId: promoEntry, orgId: selectedCity?.external_id },
      });
      updatePlanDetails({ promoData: resData?.getStripePromo.promo });
    } catch (e) {
      showError(e);
    }
  };

  const getPromoText = () => {
    if (!promoData) return '';
    const { amount_off, percent_off, duration, duration_in_months } = promoData;
    const isAnnual = planDetails.variant === 'annual';
    const intervalText = isAnnual ? 'year' : 'month';
    switch (duration) {
      case 'repeating':
        return `${amount_off ? formatCents(amount_off) : ''} ${percent_off ? `${percent_off}%` : ''} off Volo Pass${isAnnual ? '' : ` for ${duration_in_months} ${intervalText}s`}!`;
      case 'forever':
        return `${amount_off ? formatCents(amount_off) : ''} ${percent_off ? `${percent_off}%` : ''} off Volo Pass every ${intervalText}!`;
      case 'once':
      default:
        return `${amount_off ? formatCents(amount_off) : ''} ${percent_off ? `${percent_off}%` : ''} off Volo Pass for 1 ${intervalText}!`;
    }
  };

  const startPlan = useCallback(async () => {
    try {
      if (!currentUser || !currentUser?.stripe_id) {
        throw new Error('You must be signed in to start Volo Pass.');
      }
      await checkPayment();
      if (!planId) throw new Error('Please choose a plan before saving.');
      const { data: activateData } = await startMembershipMutation({
        variables: {
          planId,
          // TODO clean this up
          ...(promoData?.valid ? { promoCode: promoData.id } : { promoCode: '' }),
          orgId: selectedCity?.external_id,
        },
      });
      await refetch();
      setPlanStatus(activateData?.startVoloPass?.status ?? '');
      showSuccess('Successfully started Volo Pass');
    } catch (e) {
      showError(e);
    }
  }, [
    checkPayment,
    currentUser,
    planId,
    promoData,
    refetch,
    selectedCity?.external_id,
    showError,
    showSuccess,
    startMembershipMutation,
  ]);

  const reinstatePlan = useCallback(async () => {
    try {
      await checkPayment();
      const { data: reinstateData } = await reinstateMembershipMutation();
      setPlanStatus(reinstateData?.reinstateVoloPass.status ?? '');
      showSuccess('Successfully reinstated Volo Pass.');
    } catch (e) {
      showError(e);
    }
  }, [checkPayment, reinstateMembershipMutation, showError, showSuccess]);

  const cancelPlan = useCallback(async () => {
    try {
      await cancelMembershipMutation();
      setPlanStatus('canceled');
      showSuccess('Successfully cancelled Volo Pass.');
    } catch (e) {
      showError(e);
    }
  }, [cancelMembershipMutation, showError, showSuccess]);

  const upgradeVoloPassPlan = useCallback(async () => {
    try {
      await checkPayment();
      if (!planId) throw new Error('Please choose a plan before saving.');
      await updateUserPlanMutation({
        variables: {
          planId,
          // TODO clean this up
          ...(promoData?.valid ? { promoCode: promoData.id } : { promoCode: '' }),
        },
      });
      await refetch();
      showSuccess('Successfully upgraded to annual Volo Pass!');
    } catch (e) {
      showError(e);
    }
  }, [checkPayment, planId, promoData, refetch, showError, showSuccess, updateUserPlanMutation]);

  return {
    acceptedTerms,
    afterExpireDate,
    cancelPlan,
    cityOptions,
    error: activeError,
    getPromoText,
    membershipExpires: membership_expires,
    planDetails,
    planStatus,
    activeVoloPassPlanDetails,
    reinstatePlan,
    selectedCity,
    setAcceptedTerms,
    startMembershipMutation,
    startMembershipUpdating,
    startPlan,
    updatePlanDetails,
    updateUserPlanMutation,
    upgradeVoloPassPlan,
    validatePromo,
    refetchAll,
  };
};

export default useVoloPassDetailsV2;
