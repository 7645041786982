import { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { useActionStates } from '../../../hooks';
import openNativeSettingsApp from '../openNativeSettingsApp';
import {
  GET_USER_NOTIFICATION_PREFERENCES,
  UPDATE_NOTIFICATIONS_PREFERENCES,
  UPDATE_TEAM_EMAIL_PREFERENCES,
} from '../graphql/ACCOUNT_FORMS_MUTATIONS_AND_QUERIES';
import { Alert, Heading, Skeleton, Stack, Switch, Text, View } from '../../../base-components';
import { ErrorMessageEnum } from '../../../constants/enums';
import styles from '../styles';

// ** Note to future devs: we're using state to prevent deprecated fields from overwriting new preferences
// eg: if notifications.recommendations.email is set to true, emailPreference can never be false
// const emailPreference = currentUser?.notificationPreferences?.email || currentUser?.notifications?.recommendations?.email || false;

const isWeb = Platform.OS === 'web';

const Notifications = ({ pushEnabled }: { pushEnabled?: boolean }) => {
  const [marketingPush, setMarketingPush] = useState(pushEnabled || false);
  const [marketingEmail, setMarketingEmail] = useState(false);
  const [marketingSms, setMarketingSms] = useState(false);
  const [teamPush, setTeamPush] = useState(pushEnabled || false);
  const [teamEmail, setTeamEmail] = useState(false);

  const [showEmailAlert, setShowEmailAlert] = useState(true);

  // sync with device settings
  // These are not updated here but in player-app's usePushHandler
  useEffect(() => {
    setMarketingPush(!!pushEnabled);
    setTeamPush(!!pushEnabled);
  }, [pushEnabled]);

  const { setSuccess, setError, showAlert, setShowAlert, alertMessage, alertType } =
    useActionStates({ withAlerts: true });

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER_NOTIFICATION_PREFERENCES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ currentUser }) => {
      const { notification_preferences } = currentUser || {};

      setMarketingPush(notification_preferences?.marketing_push || false);
      setMarketingEmail(notification_preferences?.marketing_email || false);
      setMarketingSms(notification_preferences?.marketing_sms || false);
      setTeamPush(notification_preferences?.team_push || false);
      setTeamEmail(notification_preferences?.team_email || false);
    },
  });

  const { currentUser } = userData || {};

  const [updateNotificationPreferences, { loading }] = useMutation(
    UPDATE_NOTIFICATIONS_PREFERENCES
  );

  const [updateTeamNotificationPreferences, { loading: teamLoading }] = useMutation(
    UPDATE_TEAM_EMAIL_PREFERENCES
  );

  const updateUserNotificationPreferences = async ({
    marketing_email,
    marketing_sms,
  }: {
    marketing_email?: boolean;
    marketing_sms?: boolean;
  }) => {
    try {
      await updateNotificationPreferences({
        variables: {
          input: {
            userId: currentUser!._id,
            marketing_email,
            marketing_sms,
          },
        },
      });
      setSuccess('Notification preferences updated!');
    } catch (error) {
      setError(ErrorMessageEnum.UPDATE_FAILED);
      console.error(error);
    }
  };

  const updateUserTeamNotificationPreferences = async (team_email: boolean) => {
    try {
      await updateTeamNotificationPreferences({
        variables: {
          userId: currentUser!._id,
          team_email,
        },
      });
    } catch (error) {
      setError(ErrorMessageEnum.UPDATE_FAILED);
      console.error(error);
    }
  };

  // only update push notifications on mobile devices
  const handlePushToggle = () => openNativeSettingsApp();

  const handleMarketingEmailToggle = (val: boolean) => {
    setMarketingEmail(val);
    updateUserNotificationPreferences({ marketing_email: val });
  };

  const handleMarketingSmsToggle = (val: boolean) => {
    setMarketingSms(val);
    updateUserNotificationPreferences({ marketing_sms: val });
  };

  const handleTeamEmailToggle = (val: boolean) => {
    setTeamEmail(val);
    updateUserTeamNotificationPreferences(val);
  };

  if (userError) {
    setError(ErrorMessageEnum.USER_INFO_LOAD_FAILED);
    console.error(userError);
  }

  if (userLoading)
    return (
      <View style={styles.skeletonContainer}>
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
        <Skeleton style={styles.skeleton} />
      </View>
    );

  return (
    <View>
      <Alert
        message="NOTE: You cannot unsubscribe from program emails from Volo as they may contain important details such as weather updates, league information, etc."
        showAlert={showEmailAlert}
        status="warning"
        setShowAlert={setShowEmailAlert}
        autoDismiss={false}
      />
      {showAlert ? (
        <Alert
          status={alertType}
          message={alertMessage}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      ) : null}

      <Heading size="xs" marginTop={6}>
        Marketing Notifications
      </Heading>
      {/* Push Notification settings sync with mobile device preferences and currently have no purpose on web */}
      {!isWeb ? (
        <View style={styles.containerRow}>
          <View flex={1}>
            <Text style={styles.label}>
              Push Notifications (important league updates, announcements, etc.)
            </Text>
          </View>
          <Switch isDisabled={loading} value={marketingPush} onToggle={handlePushToggle} />
        </View>
      ) : null}
      <View style={styles.containerRow}>
        <Text style={styles.label}>Marketing Emails</Text>
        <Switch isDisabled={loading} value={marketingEmail} onToggle={handleMarketingEmailToggle} />
      </View>

      <Stack space={2}>
        <View style={styles.containerRow}>
          <Text style={styles.label}>Marketing Text Messages</Text>
          <Switch isDisabled={loading} value={marketingSms} onToggle={handleMarketingSmsToggle} />
        </View>
        <Text type="caption" fontSize={8}>
          By activating ‘Marketing Text Messages’, I agree to receive informational and marketing
          text messages from Volo Sports at the phone number used when signing up for a Volo Sports
          account. I also agree to Volo Sports’ SMS Terms of Service and Privacy Policy. Message
          frequency will vary. Message and data rates may apply. Consent is not a condition of any
          purchase with Volo Sports. Deactivate the ‘Text Messages’ button on the Settings page to
          opt-out of receiving messages. Contact info@volosports.com for help.
        </Text>
      </Stack>

      <Heading size="xs" marginTop={6}>
        Team Notifications
      </Heading>
      {/* Push Notification settings sync with mobile device preferences and currently have no purpose on web */}
      {!isWeb ? (
        <View style={styles.containerRow}>
          <View flex={1}>
            <Text style={styles.label}>Team Push Notifications</Text>
          </View>
          <Switch isDisabled={teamLoading} value={teamPush} onToggle={handlePushToggle} />
        </View>
      ) : null}
      <View style={styles.containerRow}>
        <Text style={styles.label}>Team Emails</Text>
        <Switch isDisabled={teamLoading} value={teamEmail} onToggle={handleTeamEmailToggle} />
      </View>
    </View>
  );
};

export default Notifications;
