export const RatingValues = [1, 2, 3, 4, 5];

/** @deprecated - Avoid hardcoded enums and use extracted */
export enum RatingTypeEnum {
  GAME_EXPERIENCE = 'GAME_EXPERIENCE',
  REFEREE = 'REFEREE',
  SPONSOR_BAR = 'SPONSOR_BAR',
  VENUE = 'VENUE',
  OWN_CAPTAIN = 'OWN_CAPTAIN',
  OWN_TEAM = 'OWN_TEAM',
  OPPOSITION_CAPTAIN = 'OPPOSITION_CAPTAIN',
  OPPOSITION_TEAM = 'OPPOSITION_TEAM',
  CUSTOM = 'CUSTOM',
}

export const RatingType: Record<
  RatingTypeEnum,
  {
    value: RatingTypeEnum;
    label: string;
    question: string;
  }
> = Object.freeze({
  [RatingTypeEnum.GAME_EXPERIENCE]: {
    value: RatingTypeEnum.GAME_EXPERIENCE,
    label: 'Game Experience',
    question: 'How was the experience overall? Did you have a good time?',
  },
  [RatingTypeEnum.REFEREE]: {
    value: RatingTypeEnum.REFEREE,
    label: 'Referee',
    question: 'How was the referee?',
  },
  [RatingTypeEnum.SPONSOR_BAR]: {
    value: RatingTypeEnum.SPONSOR_BAR,
    label: 'Sponsor Bar',
    question: 'How was the sponsor bar?',
  },
  [RatingTypeEnum.VENUE]: {
    value: RatingTypeEnum.VENUE,
    label: 'Venue',
    question: 'How was the venue?',
  },
  [RatingTypeEnum.OWN_CAPTAIN]: {
    value: RatingTypeEnum.OWN_CAPTAIN,
    label: 'Your Captain',
    question: 'How did your captain do?',
  },
  [RatingTypeEnum.OWN_TEAM]: {
    value: RatingTypeEnum.OWN_TEAM,
    label: 'Your Team',
    question: 'How did you like playing with your team?',
  },
  [RatingTypeEnum.OPPOSITION_CAPTAIN]: {
    value: RatingTypeEnum.OPPOSITION_CAPTAIN,
    label: 'Their Captain',
    question: 'How did their captain do?',
  },
  [RatingTypeEnum.OPPOSITION_TEAM]: {
    value: RatingTypeEnum.OPPOSITION_TEAM,
    label: 'Their Team',
    question: 'How would you rate the opposition team? Were they good sports?',
  },
  [RatingTypeEnum.CUSTOM]: {
    value: RatingTypeEnum.CUSTOM,
    label: 'Custom',
    question: 'Enter a custom question for your survey',
  },
});
