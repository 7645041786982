import { gql, type TypedDocumentNode } from '@apollo/client';
import { graphql } from 'graphql-schema';

import type { DiscoverFilterVariables } from './types';

export const HASURA_DISCOVER_ACTIVITIES = graphql(`
  query GetActiveSports($cityName: String!) {
    sports(
      where: {
        leagues: {
          organizationByOrganization: { name: { _eq: $cityName } }
          status: { _eq: registration_open }
          archived: { _eq: false }
          private: { _eq: false }
        }
      }
    ) {
      _id
      name
    }
  }
`);

type DiscoverRentalSportsData = {
  discoverRentalSports: {
    sportNames: string[];
  };
};

export const DISCOVER_RENTAL_ACTIVITIES: TypedDocumentNode<
  DiscoverRentalSportsData,
  DiscoverFilterVariables
> = gql`
  query DiscoverRentalSports($input: DiscoverRentalsInput!) {
    discoverRentalSports(input: $input) {
      sportNames
    }
  }
`;
