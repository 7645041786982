import { useCallback } from 'react';
import { useWindowDimensions, Platform, type StyleProp, type ViewStyle } from 'react-native';

import { View } from '../../../base-components';
import { CustomButton } from '../../../custom-components';
import { DaysOfWeek } from '../../../constants/enums/days-of-week';
import styles from '../styles';
import type { ButtonVariant } from '../../../custom-components/CustomButton/styles';

export type DayFilterType = {
  onPress: (param?: string) => void | undefined;
  variant?: ButtonVariant;
  squared?: boolean;
  fullWidth?: boolean;
  optStyles?: StyleProp<ViewStyle>;
  selectedDays: string[];
};

const days = Object.values(DaysOfWeek).map(({ shortName, abbrevName }) => ({
  label: abbrevName,
  value: shortName,
}));

const DayFilter = ({
  onPress,
  squared = false,
  fullWidth = false,
  variant = 'outline',
  optStyles = {},
  selectedDays = [],
}: DayFilterType) => {
  const { width: layoutWidth } = useWindowDimensions();
  const breakpointMet = layoutWidth > 500;

  const isWeb = Platform.OS === 'web';

  const onPressDay = useCallback(
    (newValue: string) => {
      if (onPress) onPress(newValue);
    },
    [onPress]
  );

  const daysOptions = days.map(({ label, value }) => (
    <CustomButton
      key={value}
      value={value}
      label={!breakpointMet && isWeb ? value : label}
      variant={variant}
      style={styles.button}
      pressed={selectedDays.includes(value!)}
      textStyle={{ fontSize: 12 }}
      onPress={onPressDay}
      circular={!squared}
      thin={squared}
      fullWidth={fullWidth || squared}
    />
  ));

  return (
    <View style={styles.container}>
      <View style={[styles.row, optStyles]}>{daysOptions}</View>
    </View>
  );
};

export default DayFilter;
