import { useUserMembershipStatus } from '../../../../hooks';
import { ProgramTypeEnum, ProgramModuleTypes } from '../../../../constants';
import {
  type NeighborhoodModule,
  type VenueModule,
  type DateModule,
  type TimeModule,
  type PricingModule,
  type CapacityModule,
} from '../../ProgramCard';
import { type DiscoverProgramCardProps } from '../DiscoverProgramCard';
import useProgramDetails from './useProgramDetails';
import useProgramPricing from './useProgramPricing';
import { militaryToStandardTime, openMap, pluralize } from '../../../../utilities';

const useDiscoverProgramModules = ({
  programId = '',
  gameId = '',
  shouldSeePricesWithFees = false,
}: Omit<DiscoverProgramCardProps, 'onCardPress'>) => {
  const {
    programError,
    programLoading,
    gameLoading,
    gameError,
    programType,
    displayName = '',
    photoUrl = '',
    banner_text = '',
    start_date = '',
    startTime,
    end_time_estimate,
    neighborhoodName,
    venueName,
    venueAddress,
    numWeeks,
    registrantCount,
    registrationMin,
    registrationMax,
    isDailyProgram,
    availableDropInSpots,
    isPremier,
    isVoloPassExclusive,
  } = useProgramDetails({ programId, gameId });

  const {
    pricingLoading,
    pricingError,
    originalPrice,
    originalPriceWithFees,
    memberPrice,
    voloPassPriceWithFees,
  } = useProgramPricing({ programId });

  const { isVoloPassMember, membershipLoading, membershipError } = useUserMembershipStatus();

  const formattedStartTime = militaryToStandardTime(
    startTime,
    isDailyProgram ||
      programType === ProgramTypeEnum.SERIES ||
      programType === ProgramTypeEnum.DROPIN
  );
  const formattedEndTime = militaryToStandardTime(end_time_estimate, true);

  const loading = programLoading || pricingLoading || gameLoading || membershipLoading;
  const error = programError || pricingError || gameError || membershipError;

  const capacityThresholdMet = registrantCount >= registrationMin;

  const getFormattedPrice = (
    price: number | null | undefined,
    priceWithFees: number | null | undefined
  ) => {
    if (typeof price !== 'number') {
      return undefined;
    }

    if (shouldSeePricesWithFees && typeof priceWithFees === 'number') {
      return (priceWithFees / 100).toFixed(2);
    }

    return price / 100;
  };

  const formattedIndividualPricing = getFormattedPrice(originalPrice, originalPriceWithFees);
  const formattedVoloPassPricing = getFormattedPrice(memberPrice, voloPassPriceWithFees);

  // #region Modules with data

  // NEIGHBORHOOD
  const neighborhoodModule = {
    type: ProgramModuleTypes.NEIGHBORHOOD,
    neighborhood: neighborhoodName,
  } as NeighborhoodModule;
  // VENUE
  const venueModule = {
    type: ProgramModuleTypes.VENUE,
    venue: {
      venueName,
      onPressVenue: async () => openMap({ venueAddress, placeId: venueName }),
    },
  } as VenueModule;

  // DATE
  const dateModule = {
    type: ProgramModuleTypes.DATE,
    start_date: new Date(start_date ?? ''),
    num_weeks: programType === ProgramTypeEnum.LEAGUE ? numWeeks : null,
  } as DateModule;

  // TIME
  const timeModule = {
    type: ProgramModuleTypes.TIME,
    startTimeDate: formattedStartTime,
    endTimeDate:
      programType !== ProgramTypeEnum.SERIES &&
      programType !== ProgramTypeEnum.DROPIN &&
      !isDailyProgram
        ? formattedEndTime
        : null,
  } as TimeModule;

  // PRICING
  const pricingModule = {
    type: ProgramModuleTypes.PRICING,
    isVoloPassMember,
    price: formattedIndividualPricing,
    memberPrice: formattedVoloPassPricing,
  } as PricingModule;

  // CAPACITY (Not drop-in)
  const dailyCapacityModule = {
    type: ProgramModuleTypes.CAPACITY,
    capacity: `${registrantCount}/${registrationMax}`,
  } as CapacityModule;

  // DROP-IN CAPACITY
  const dropInCapacityModule = {
    type: ProgramModuleTypes.CAPACITY,
    capacity: `${availableDropInSpots} ${pluralize('spot', availableDropInSpots)}`,
  } as CapacityModule;
  // #endregion

  // #region Program-specific modules
  const generateTopLeft = () => {
    if (
      programType === ProgramTypeEnum.LEAGUE ||
      programType === ProgramTypeEnum.VOLUNTEER_EVENT ||
      programType === ProgramTypeEnum.VOLUNTEER_LEAGUE
    ) {
      return venueModule;
    } // SERIES, TOURNAMENT, DAILY
    return neighborhoodModule;
  };

  const generateTopRight = () => {
    switch (programType) {
      case ProgramTypeEnum.TOURNAMENT:
        return dateModule;
      case ProgramTypeEnum.VOLUNTEER_EVENT:
      case ProgramTypeEnum.VOLUNTEER_LEAGUE:
        return null;
      default: // LEAGUE, SERIES, DAILY
        return timeModule;
    }
  };

  const generateBottomLeft = () => {
    switch (programType) {
      case ProgramTypeEnum.LEAGUE:
        return dateModule;
      case ProgramTypeEnum.VOLUNTEER_EVENT:
      case ProgramTypeEnum.VOLUNTEER_LEAGUE:
        return timeModule;
      default: // SERIES, TOURNAMENT, DAILY
        return venueModule;
    }
  };

  const generateBottomRight = () => {
    switch (programType) {
      case ProgramTypeEnum.TOURNAMENT:
        return timeModule;
      case ProgramTypeEnum.VOLUNTEER_EVENT:
      case ProgramTypeEnum.VOLUNTEER_LEAGUE:
        return dateModule;
      case ProgramTypeEnum.DROPIN:
        return dropInCapacityModule;
      default: // LEAGUE, SERIES, DAILY
        if (isDailyProgram && capacityThresholdMet) {
          return dailyCapacityModule;
        }
        return pricingModule;
    }
  };

  // #endregion

  const topLeftModule = generateTopLeft();
  const topRightModule = generateTopRight();
  const bottomLeftModule = generateBottomLeft();
  const bottomRightModule = generateBottomRight();

  return {
    loading,
    error,
    topLeftModule,
    topRightModule,
    bottomLeftModule,
    bottomRightModule,
    programType,
    displayName,
    photoUrl,
    banner_text,
    isDailyProgram,
    isPremier,
    isVoloPassExclusive,
  };
};

export default useDiscoverProgramModules;
