import React, { type ComponentProps } from 'react';
import { useMutation } from '@apollo/client';

import type { ExtractEnum } from 'graphql-schema';
import { ErrorMessageEnum, TeamRoleEnum } from '../../../constants/enums';
import { Text, Modal } from '../../../base-components';
import { ConfirmCancelModal } from '../../../custom-components';
import UPDATE_TEAM_ROLE from '../graphql/UPDATE_TEAM_ROLE';

export interface TeamRoleModalProps extends ComponentProps<typeof Modal> {
  teamId: string;
  teamRole: { label: string; value: string } | null;
  player: { playerId: string; playerName: string } | null;
  onErrorMessage: (message: string) => void;
  onSuccessMessage: (message: string) => void;
}

export const TeamRoleModal: React.FC<TeamRoleModalProps> = ({
  player,
  teamId,
  teamRole,
  onClose,
  onErrorMessage,
  onSuccessMessage,
  ...props
}) => {
  const { playerId, playerName } = player || {};
  const { label: teamRoleLabel, value: teamRoleValue } = teamRole || {};

  const [updateTeamRole, { loading: roleUpdateLoading }] = useMutation(UPDATE_TEAM_ROLE);

  const handleUpdateTeamRole = async () => {
    try {
      if (!playerId || !teamRoleValue) throw new Error(ErrorMessageEnum.UPDATE_TEAM_ROLE);

      await updateTeamRole({
        variables: {
          input: {
            playerId,
            teamId,
            // Can update props for this after related components are migrated
            teamRole: teamRoleValue as ExtractEnum<'team_role_enum'>,
          },
        },
        update: cache => {
          cache.evict({ fieldName: 'currentUserLeagueRoster' });
        },
      });
      onSuccessMessage('Player Role Updated');
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        onErrorMessage(error.message);
      } else if (typeof error === 'string') {
        onErrorMessage(error);
      } else {
        onErrorMessage('Unknown error: failed to update player role.');
      }
    }
  };

  return (
    <ConfirmCancelModal
      headerText="Update Player Role"
      onConfirm={handleUpdateTeamRole}
      onClose={onClose}
      isLoading={roleUpdateLoading}
      {...props}
    >
      <Text mb={3}>
        Are you sure you would like to update <Text bold>{playerName}</Text>&apos;s role to{' '}
        <Text bold>{teamRoleLabel}</Text>?{' '}
        {teamRoleValue === TeamRoleEnum.CO_CAPTAIN && (
          <Text type="caption" fontSize="md">
            Note: this will give the player the same abilities as a captain.
          </Text>
        )}
      </Text>
    </ConfirmCancelModal>
  );
};

export default TeamRoleModal;
