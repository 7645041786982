// translates a league status to its stage counterpart
export const LeagueStatusToStageKey = Object.freeze({
    pending: 'upcoming',
    registration_open: 'upcoming',
    needs_approval: 'upcoming',
    ready: 'upcoming',
    active: 'active',
    completed: 'archived',
    cancelled: 'archived',
    error: 'archived',
    staged: 'staged',
    draft: 'staged',
});
export const getLeagueStatusesFromStage = (stage) => {
    return Object.entries(LeagueStatusToStageKey)
        .filter(([, _stage]) => _stage === stage)
        .map(([status]) => status);
};
