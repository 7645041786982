import { startOfDay, endOfDay, minutesTo_HH_MM, createBufferInMinutes, } from '../utils/date-helpers.js';
/** default time range used for the time range selector components */
const defaultTimeRange = {
    timeLow: 0,
    timeHigh: 1439,
};
const isFilteringByTimeRange = ({ low, high }) => low !== defaultTimeRange.timeLow || high !== defaultTimeRange.timeHigh;
const arrayFilterActive = (array) => array.length > 0;
const totalParticipantsWanted = (spots) => Object.values(spots).reduce((acc, curr) => acc + (curr || 0), 0);
const buildDateClause = ({ dateLow, dateHigh, }) => {
    return {
        _gte: dateLow ? startOfDay(dateLow) : startOfDay(new Date()),
        ...(dateHigh && { _lte: endOfDay(dateHigh) }),
    };
};
const buildTimeRangeClause = ({ timeLow, timeHigh, }) => {
    return {
        ...(timeLow && { _gte: minutesTo_HH_MM(timeLow) }),
        ...(timeHigh && { _lte: minutesTo_HH_MM(timeHigh) }),
    };
};
/** builds the gender filter used against `gender` field on the program */
const buildGenderFilter = ({ selectedGenders, spots, }) => {
    const gendersWanted = [
        'coed',
        'open',
        // make sure to include any selected genders
        ...(selectedGenders || []),
    ];
    const addMenProgramGender = (spots.minimumMen || spots.minimumNonBinary) &&
        !spots.minimumWomen &&
        !gendersWanted.includes('men');
    const addWomenProgramGender = (spots.minimumWomen || spots.minimumNonBinary) &&
        !spots.minimumMen &&
        !gendersWanted.includes('women');
    // logic ported from `discover-programs.js`
    if (addMenProgramGender)
        gendersWanted.push('men');
    if (addWomenProgramGender)
        gendersWanted.push('women');
    return { gender: { _in: gendersWanted } };
};
const buildSportsFilter = (selectedSportNames) => arrayFilterActive(selectedSportNames)
    ? { sportBySport: { name: { _in: selectedSportNames } } }
    : undefined;
const buildLocationFilters = ({ venueIds, neighborhoodIds, }) => {
    const searchingForLocation = arrayFilterActive(venueIds) || arrayFilterActive(neighborhoodIds);
    if (!searchingForLocation)
        return undefined;
    return {
        venueByVenue: {
            _or: [
                ...(arrayFilterActive(venueIds) ? [{ _id: { _in: venueIds } }] : []),
                ...(arrayFilterActive(neighborhoodIds)
                    ? [{ neighborhood_id: { _in: neighborhoodIds } }]
                    : []),
            ],
        },
    };
};
const buildDaysOfWeeksFilter = (selectedDaysOfWeek) => arrayFilterActive(selectedDaysOfWeek)
    ? { days_of_weeks: { day_of_week: { _in: selectedDaysOfWeek } } }
    : undefined;
const buildCompetitionLevelsFilter = (selectedSkillLevels) => arrayFilterActive(selectedSkillLevels)
    ? { competition_levels: { competition_level: { _in: selectedSkillLevels } } }
    : undefined;
const buildProgramTagFilter = (programTagIds) => arrayFilterActive(programTagIds) ? { program_tag: { _in: programTagIds } } : undefined;
/** builds the volo pass where clauses used against the current `is_premier` | `is_volo_pass_exclusive` */
const buildVoloPassFilters = ({ premierProgramsOnly, voloPassExclusiveOnly, }) => {
    return {
        ...(premierProgramsOnly && { is_premier: { _eq: true } }),
        ...(voloPassExclusiveOnly && { is_volo_pass_exclusive: { _eq: true } }),
    };
};
/**
 * builds the drop in capacity filter using the computed fields from game_drop_in_capacity view
 *
 * total_men_spots = sum of 'm' and 'a' spots
 * total_flexible_spots = sum of all spots ('m', 'f', 'a')
 */
const buildDropInCapacityFilter = (spots) => {
    const participantsWanted = totalParticipantsWanted(spots);
    const { minimumMen, minimumWomen, minimumNonBinary } = spots;
    // fallback to make sure there is at least 1 spot available
    if (!participantsWanted)
        return { total_available_spots: { _gte: 1 } };
    return {
        _and: [
            // men can only match m or a spots
            ...(minimumMen
                ? [
                    {
                        total_male_eligible_spots: { _gte: minimumMen },
                    },
                ]
                : []),
            // women/non-binary can match all spots
            ...(minimumWomen || minimumNonBinary
                ? [
                    {
                        total_available_spots: { _gte: participantsWanted },
                    },
                ]
                : []),
        ],
    };
};
// =================================================
// Non Daily Programs
// =================================================
/** builds the where clause for non daily programs */
export const buildDiscoverNonDailyInput = (filters, currentCityId, defaultProgramTypes = []) => {
    return {
        where: {
            organization: { _eq: currentCityId },
            status: { _eq: 'registration_open' },
            private: { _eq: false },
            archived: { _eq: false },
            registrationByRegistration: {
                available_spots: { _gte: totalParticipantsWanted(filters.spots) || 1 },
                registration_open_date: { _lte: startOfDay(new Date()) },
                registration_close_date: { _gte: endOfDay(new Date()) },
            },
            program_type: {
                _in: arrayFilterActive(filters.selectedProgramTypes)
                    ? filters.selectedProgramTypes
                    : defaultProgramTypes,
            },
            start_date: buildDateClause({ dateHigh: filters.dateHigh, dateLow: filters.dateLow }),
            ...(isFilteringByTimeRange({ low: filters.timeLow, high: filters.timeHigh }) && {
                start_time_estimate: buildTimeRangeClause({
                    timeHigh: filters.timeHigh,
                    timeLow: filters.timeLow,
                }),
            }),
            ...buildGenderFilter({ selectedGenders: filters.selectedGenders, spots: filters.spots }),
            ...buildVoloPassFilters({
                premierProgramsOnly: filters.premierProgramsOnly,
                voloPassExclusiveOnly: filters.voloPassExclusiveOnly,
            }),
            ...buildSportsFilter(filters.selectedSportNames),
            ...buildLocationFilters({
                venueIds: filters.selectedVenueIds,
                neighborhoodIds: filters.selectedNeighborhoodIds,
            }),
            ...buildDaysOfWeeksFilter(filters.selectedDaysOfWeek),
            ...buildCompetitionLevelsFilter(filters.selectedSkillLevels),
            ...buildProgramTagFilter(filters.programTagIds),
        },
    };
};
// =================================================
// Daily Programs
// =================================================
export const buildDiscoverDailyInput = (filters, currentCityId, defaultProgramTypes = []) => {
    const programTypes = arrayFilterActive(filters.selectedProgramTypes)
        ? filters.selectedProgramTypes
        : defaultProgramTypes;
    const baseLeagueFilters = {
        organization: { _eq: currentCityId },
        private: { _eq: false },
        archived: { _eq: false },
        ...buildGenderFilter({ selectedGenders: filters.selectedGenders, spots: filters.spots }),
        ...buildVoloPassFilters({
            premierProgramsOnly: filters.premierProgramsOnly,
            voloPassExclusiveOnly: filters.voloPassExclusiveOnly,
        }),
        ...buildSportsFilter(filters.selectedSportNames),
        ...buildDaysOfWeeksFilter(filters.selectedDaysOfWeek),
        ...buildCompetitionLevelsFilter(filters.selectedSkillLevels),
        ...buildProgramTagFilter(filters.programTagIds),
    };
    const leagueFilters = {
        ...baseLeagueFilters,
        ...buildLocationFilters({
            venueIds: filters.selectedVenueIds,
            neighborhoodIds: filters.selectedNeighborhoodIds,
        }),
        start_date: buildDateClause({ dateHigh: filters.dateHigh, dateLow: filters.dateLow }),
        // - remove the 'DROPIN' as that is not a valid program type to query against db leagues table
        program_type: { _in: programTypes.filter(type => type !== 'DROPIN') },
        status: { _eq: 'registration_open' },
        registrationByRegistration: {
            available_spots: { _gte: totalParticipantsWanted(filters.spots) || 1 },
            registration_open_date: { _lte: startOfDay(new Date()) },
            registration_close_date: { _gte: endOfDay(new Date()) },
        },
    };
    const gameFilters = {
        start_time: {
            _gte: filters.dateLow
                ? startOfDay(filters.dateLow)
                : // #396 - display drop ins in discover for up to 15 minutes after the game has started
                    (() => {
                        // Round to nearest minute to prevent constant updates
                        const now = new Date();
                        now.setSeconds(0, 0);
                        return createBufferInMinutes(now, 15).toISOString();
                    })(),
            ...(filters.dateHigh && { _lte: endOfDay(filters.dateHigh) }),
        },
        ...buildLocationFilters({
            venueIds: filters.selectedVenueIds,
            neighborhoodIds: filters.selectedNeighborhoodIds,
        }),
        drop_in_capacity: buildDropInCapacityFilter(filters.spots),
        leagueByLeague: baseLeagueFilters,
    };
    const queryIncludesDropIns = programTypes.includes('DROPIN');
    const queryOnlyIncludesDropIns = queryIncludesDropIns && programTypes.length === 1;
    return {
        where: {
            ...(isFilteringByTimeRange({ low: filters.timeLow, high: filters.timeHigh }) && {
                event_start_time_str: buildTimeRangeClause({
                    timeHigh: filters.timeHigh,
                    timeLow: filters.timeLow,
                }),
            }),
            // note - adding the `_is_null` filters allows the query planner to better optimize & reduce the number of rows scanned
            // - only searching for drop in games only
            ...(queryOnlyIncludesDropIns
                ? {
                    league_id: { _is_null: true },
                    game_id: { _is_null: false },
                    game: gameFilters,
                }
                : // - searching for both drop ins (games) and regular daily programs
                    queryIncludesDropIns
                        ? {
                            _or: [
                                { league_id: { _is_null: false }, league: leagueFilters },
                                { game_id: { _is_null: false }, game: gameFilters },
                            ],
                        }
                        : // - only searching for regular daily programs - no drop in games
                            {
                                game_id: { _is_null: true },
                                league_id: { _is_null: false },
                                league: leagueFilters,
                            }),
        },
    };
};
