import { graphql, type ResultOf, type VariablesOf } from 'graphql-schema';
import { skipToken, useSuspenseQuery, type SuspenseQueryHookOptions } from '@apollo/client';
import type { TPaymentListItem, UserPayments } from './types';
import getPaymentsFromRegistrants from './utils/getPaymentsFromRegistrants';
import getPaymentsFromRentalRegistrants from './utils/getPaymentsFromRentalRegistrants';

export type UserPaymentsData = NonNullable<ResultOf<typeof GET_USER_PAYMENTS>['users_by_pk']>;
export type RegistrantPaymentData = UserPaymentsData['registrants'];
export type RentalRegistrantData = UserPaymentsData['rental_registrants'];

type UserPaymentsInput = VariablesOf<typeof GET_USER_PAYMENTS>;

/**
 * ! Post-migration TODO
 * Pagination was never properly implemented. We'd just cap the list at 50.
 * Limits added in the query so we don't pull more than we ever need.
 * Maybe a view to consolidate these tables in the future?
 */

const GET_USER_PAYMENTS = graphql(`
  query getUserPayments($userId: uuid!, $limit: Int = 50, $offset: Int = 0) {
    users_by_pk(_id: $userId) {
      _id
      totalReferralRewards: total_referral_rewards
      totalCredits: total_credits

      credits(order_by: { created_at: desc }, limit: $limit, offset: $offset) {
        _id
        amount
        note
        type
        organization
        created_at
        userByCreatedBy {
          _id
          full_name
        }
      }

      registrants(order_by: { created_at: desc }, limit: $limit, offset: $offset) {
        _id
        leagueByLeague {
          _id
          name
        }
        is_drop_in
        manually_added_by
        organization
        created_at
        charge {
          _id
          donation_cents
          credit_cents
          after_discount_cents
          total_charge_cents
          promo_code_str
          promo_discount_cents
          refund_cents
          refund_issued_at
        }
      }

      rental_registrants(
        where: { rental_reservation: { reserving_user: { _eq: $userId } } }
        order_by: { created_at: desc }
        limit: $limit
        offset: $offset
      ) {
        _id
        rentalByRental {
          _id
          name
        }
        rental_reservation {
          rental_charge_reservations {
            rentalChargeByRentalCharge {
              _id
              rental_charge_refunds_aggregate {
                aggregate {
                  sum {
                    refund_cents
                  }
                }
              }
              donation_cents
              credits_refunded_cents
              total_charge_cents
              fee_cents
              promo_code_str
              promo_discount_cents
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
`);

export const usePlayerPayments = (
  options?: SuspenseQueryHookOptions<unknown, UserPaymentsInput> | typeof skipToken
) => {
  const { data, ...rest } = useSuspenseQuery(GET_USER_PAYMENTS, options);

  const user = data?.users_by_pk!;

  const { registrants, rental_registrants, credits } = user;

  const finalCredits = credits.map(credit => {
    const { _id, amount, note, type, organization, created_at, userByCreatedBy } = credit;

    return {
      paymentId: _id,
      date: created_at ?? '',
      status: 'Credit',
      net: amount,
      credits: amount,
      reason: note,
      type: type === 'gift_card' ? 'GC' : 'LC',
      organizationId: organization,
      manuallyAddedBy: userByCreatedBy?.full_name,
      maxRefundableCents: 0,
      promoDiscountCents: 0,
    } satisfies TPaymentListItem;
  });

  const { programPaginatedPayments, programTotals } = getPaymentsFromRegistrants(registrants);

  const { rentalPaginatedPayments, rentalTotals } =
    getPaymentsFromRentalRegistrants(rental_registrants);

  const {
    programCount,
    programTotalPayments,
    programTotalNet,
    programTotalRefunds,
    programTotalDonations,
  } = programTotals;

  const {
    rentalCount,
    rentalTotalPayments,
    rentalTotalNet,
    rentalTotalRefunds,
    rentalTotalDonations,
  } = rentalTotals;

  const payments = programPaginatedPayments
    .concat(rentalPaginatedPayments)
    .concat(finalCredits)
    .sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
    .slice(0, 50); // See TODO above

  const totalPayments = programTotalPayments + rentalTotalPayments;
  const totalNet = programTotalNet + rentalTotalNet;
  const totalRefunds = programTotalRefunds + rentalTotalRefunds;
  const totalDonations = programTotalDonations + rentalTotalDonations;
  const count = programCount + rentalCount;

  return {
    data: {
      user: {
        _id: user._id,
        totalReferralRewards: user.totalReferralRewards ?? 0,
      },
      userPayments: {
        payments,
        totalPayments,
        totalNet,
        totalRefunds,
        totalDonations,
        totalCredits: user.totalCredits ?? 0,
        count,
      },
    } satisfies UserPayments,
    ...rest,
  };
};
