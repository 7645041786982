import { useQuery, type QueryHookOptions } from '@apollo/client';
import type { VariablesOf, ResultOf } from 'graphql-schema';
import { useDebounce } from '../../hooks';
import { LEAGUE_SEARCH } from './graphql';
import hasuraClient from '../../apollo/hasuraClient';

export type LeagueListVars = {
  input: {
    bounds?: {
      ne: { lat: number; lng: number };
      sw: { lat: number; lng: number };
    };
    cityName?: string;
    dateHigh?: string;
    dateLow?: string;
    daysOfWeek?: string[];
    discoverable?: boolean;
    nameSearch?: string;
    neighborhoodIds?: string[];
    omitArchived?: boolean;
    omitReoccuringChildren?: boolean;
    omitReoccuringParents?: boolean;
    organizationId?: string;
    pagination?: {
      numPerPage?: number;
      pageNum?: number;
    };
    programStatus?: string;
    programType?: string;
    programTypes?: string[];
    season?: string;
    sortBy?: string;
    seasonNames?: string[];
    sport?: string;
    sportNames?: string[];
    stage?: string;
    stages?: string[];
    venueIds?: string[];
  };
};

/** @deprecated */
export type LeagueSearchFilters = Omit<LeagueListVars['input'], 'nameSearch'>;

export type LeagueSearchVariables = VariablesOf<typeof LEAGUE_SEARCH>;
export type LeagueSearchData = ResultOf<typeof LEAGUE_SEARCH>;

export type UseLeagueSearchArgs = {
  input: string;
  limit?: number;
  offset?: number;
  minimumSearchLength?: number;
  variables?: LeagueSearchVariables;
  queryOptions?: QueryHookOptions<LeagueSearchData, LeagueSearchVariables>;

  /** @deprecated */
  filters?: LeagueSearchFilters | null;
};

/**
 * Hook for running the league list query with a search input
 */
export const useLeagueSearch = ({
  input,
  variables,
  queryOptions,
  minimumSearchLength = 4,
}: UseLeagueSearchArgs) => {
  const search = input.trim();

  const nameSearch = useDebounce(search, 400);

  const query = useQuery(LEAGUE_SEARCH, {
    client: hasuraClient,
    skip: search.length < minimumSearchLength || nameSearch.length < minimumSearchLength,
    variables: {
      ...variables,
      where: {
        ...variables?.where,
        name: { _ilike: `%${nameSearch}%` },
      },
    },
    ...queryOptions,
  });

  return {
    ...query,
    leagues: query.data?.leagues ?? [],
    count: query.data?.leagues_aggregate?.aggregate?.count ?? 0,
  };
};

export default useLeagueSearch;
