import classNames from 'classnames/bind';
import type { ResultOf } from 'graphql-schema';
import type { FC, ReactNode } from 'react';

import styles from './styles.module.scss';
import { Tooltip } from '../../../../components';
import tag from '../../../../images/icons/tag-solid.svg';
import type {
  LeagueData,
  SCHEDULE_TEAM_FRAGMENT,
  SCHEDULE_WEEK_FRAGMENT,
} from '../../../../apps/RFO/Manage/Schedule/graphql';

const cx = classNames.bind(styles);

type WeekItem = {
  week: ResultOf<typeof SCHEDULE_WEEK_FRAGMENT>;
  teams: ResultOf<typeof SCHEDULE_TEAM_FRAGMENT>[];
  weekTags: LeagueData['week_tags'];
  weekDescriptions: LeagueData['week_descriptions'];
  children?: ReactNode;
};

const WeekItem: FC<WeekItem> = ({ week, teams, weekTags, weekDescriptions, children }) => {
  const { week_num, notes, byes = [], week_tag } = week;

  if (!week) return <span />;

  const weekTagIdx = weekTags.findIndex(t => t._id === week_tag);
  const weekDescription = weekDescriptions[weekTagIdx];
  const foundWeekTag = weekTags[weekTagIdx];

  return (
    <div className={cx('week-item')}>
      <div className={cx('week-label')}>
        <h2 className={cx('week-num')}>{`Week ${week_num}`}</h2>
        {week_tag && (
          <span
            className={cx('week-tag')}
            {...(weekDescription
              ? {
                  'data-tip': weekDescription.description,
                  'data-for': week_tag,
                }
              : {})}
          >
            <Tooltip id={week_tag} effect="float" />
            <img src={tag} alt="tag" />
            {foundWeekTag?.week_tag ?? week_tag}
          </span>
        )}
        {notes && <span className={cx('notes')}>{notes}</span>}
      </div>
      <div className={cx('week-events')}>
        {children}
        {!!byes?.length && (
          <div className={cx('bye-wrap')}>
            <span>
              <h3 className={cx('byes')}>Byes</h3>
              {byes.map(team => (
                <p key={team._id}>{teams.find(({ _id }) => _id === team._id)?.name}</p>
              ))}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeekItem;
