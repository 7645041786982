import { graphql } from 'graphql-schema';

const ORG_EMAIL_TEMPLATES = graphql(`
  query TemplateEmails($organization: uuid!) {
    emails(where: { type: { _eq: Template }, organization: { _eq: $organization } }) {
      _id
      html
      subject
    }
  }
`);

export default ORG_EMAIL_TEMPLATES;
