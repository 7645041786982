import type { FC } from 'react';
import { Box, Heading, Text, TextArea } from '../../../../base-components';

const AlternateMethod: FC<{ emails: string }> = ({ emails }) => {
  return (
    <Box bg="white.600" rounded="sm" p="4">
      <Heading fontSize="md" color="gray.700">
        Alternate Method
      </Heading>
      <Text fontSize="sm" my="2">
        To email players using another method, you can copy the addresses here.
      </Text>
      <TextArea isReadOnly autoCompleteType="off" value={emails} bg="gray.50" />
    </Box>
  );
};

export default AlternateMethod;
