import { graphql } from 'graphql-schema';

const INSERT_EMAIL_TEMPLATE = graphql(`
  mutation InsertEmailTemplate($organization: uuid!, $html: String!, $subject: String!) {
    insert_emails_one(
      object: { organization: $organization, html: $html, subject: $subject, type: Template }
    ) {
      _id
      html
      subject
    }
  }
`);

export default INSERT_EMAIL_TEMPLATE;
