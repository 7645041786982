import { ActivityIndicator } from 'react-native';
import { useQuery } from '@apollo/client';

import { View, Text } from '../../../base-components';
import { CustomCheckbox } from '../../../custom-components';
import HASURA_DISCOVER_PROGRAM_TAGS from '../graphql/DISCOVER_TAGS';
import styles from '../styles';

type ProgramTagFilterType = {
  onPress: (newTag: string) => void;
  cityName: string | null;
  selectedTags: string[];
};

const ProgramTagFilter = ({ onPress, cityName, selectedTags = [] }: ProgramTagFilterType) => {
  const { data, loading, error } = useQuery(HASURA_DISCOVER_PROGRAM_TAGS, {
    skip: !cityName,
    variables: { cityName: cityName! },
  });

  if (!data?.program_tags && loading) return <ActivityIndicator />;

  if (error)
    return (
      <View>
        <Text>{String(error)}</Text>
      </View>
    );

  return (
    <View style={styles.container}>
      {data?.program_tags?.map(tag => (
        <CustomCheckbox
          key={tag._id}
          value={tag._id}
          label={tag.name}
          onPress={value => onPress(value)}
          checked={selectedTags.includes(tag._id)}
        />
      ))}
    </View>
  );
};

export default ProgramTagFilter;
