import { gql } from '@apollo/client';
import { graphql } from 'graphql-schema';

export const UPDATE_USER_PROFILE = graphql(`
  mutation updateUserProfile($userId: uuid!, $_set: users_set_input!) {
    update_users_by_pk(pk_columns: { _id: $userId }, _set: $_set) {
      _id
      first_name
      last_name
      email
      phone_number
      phone_country_code
      birthday
      gender
    }
  }
`);

export const UPDATE_USER_HOME_ORG = gql`
  mutation updateUserHomeOrganization($input: UpdateUserHomeOrganizationInput!) {
    updateUserHomeOrganization(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const TOGGLE_USER_ACCOUNT_STATUS = graphql(`
  mutation toggleUserAccountStatus($userId: uuid!, $deactivated: Boolean!, $note: String!) {
    update_users_by_pk(pk_columns: { _id: $userId }, _set: { deactivated: $deactivated }) {
      _id
      deactivated
    }

    insert_notes_one(object: { user: $userId, type: "note", note: $note }) {
      _id
    }
  }
`);

export const INSERT_REGISTRANT_WAIVER_REMINDER = graphql(`
  mutation InsertRegistrantWaiverReminder($registrant: uuid!) {
    insert_registrant_waiver_reminders_one(object: { registrant: $registrant }) {
      _id
      registrantByRegistrant {
        _id
        waiver_reminders {
          _id
          date_sent
          userByCreatedBy {
            _id
            username
          }
        }
      }
    }
  }
`);

export const INSERT_RENTAL_REGISTRANT_WAIVER_REMINDER = graphql(`
  mutation InsertRentalRegistrantWaiverReminder($registrant: uuid!) {
    insert_rental_registrant_waiver_reminders_one(object: { rental_registrant: $registrant }) {
      _id
      rentalRegistrantByRentalRegistrant {
        _id
        waiver_reminders {
          _id
          date_sent
          userByCreatedBy {
            _id
            username
          }
        }
      }
    }
  }
`);
