import { ProgramTypeEnum, ProgramType } from '../../../constants/enums';
import { ConfirmCancelModal } from '../../../custom-components';
import { Text } from '../../../base-components';
import type { ConfirmCancelModalProps } from '../../../custom-components/ConfirmCancelModal/ConfirmCancelModal';

export interface CancellationConfirmationModalProps
  extends Omit<ConfirmCancelModalProps, 'headerText' | 'children'> {
  programType: ProgramTypeEnum;
  /** @default false */
  isVP?: boolean;
  /** @default false */
  isLessThanSixHours?: boolean;
  /** @default 0 */
  playerStrikes: number;
  onConfirm: () => void;
  onClose: () => void;
}

/** $10 cancellation fee - const is easier to update */
const CANCELLATION_FEE = '$10';

const CancellationMessageDetails = ({
  isVP = false,
  isLessThanSixHours = false,
  playerStrikes = 0,
}: Pick<CancellationConfirmationModalProps, 'isVP' | 'isLessThanSixHours' | 'playerStrikes'>) => {
  /** VP cancellation responses */
  if (isVP) {
    /** playerStrikes are only counted if player cancels within 6 hours of start time */
    if (isLessThanSixHours) {
      switch (playerStrikes) {
        /** VP player's first cancellation - no fee  */
        case 0:
          return (
            <Text>
              This will be your <Text fontWeight={700}>first</Text> cancellation within 6 hours of
              start time. You will have <Text fontWeight={700}>1</Text> remaining late cancellation
              before a {CANCELLATION_FEE} fee is applied.
            </Text>
          );
        /** VP player's second cancellation - last cancellation without a fee */
        case 1:
          return (
            <Text>
              This will be your <Text fontWeight={700}>second</Text> cancellation within 6 hours of
              start time. You will have <Text fontWeight={700}>0</Text> remaining late cancellations
              before a {CANCELLATION_FEE} fee is applied.
            </Text>
          );
        /** VP player's third (and beyond) cancellation - $10 fee is charged */
        default:
          return (
            <Text>
              This cancellation is within 6 hours of start time so you will be charged a{' '}
              <Text fontWeight={700}>{CANCELLATION_FEE}</Text> late cancellation fee.
            </Text>
          );
      }
    }
    /** VP & cancellation is more than 6 hours before start time - no fee charged, no strikes incurred */
    return (
      <Text>
        You will <Text fontWeight={700}>not</Text> be charged a cancellation fee.
      </Text>
    );
  }

  /** Non-VP cancellation responses */
  if (isLessThanSixHours) {
    /** Non-VP & cancellation is less than 6 hours before start time - no account credit */
    return (
      <Text>
        This cancellation is within 6 hours of start time so you will{' '}
        <Text fontWeight={700}>not</Text> receive a credit for registration fees
      </Text>
    );
  }
  /** Non-VP & cancellation is more than 6 hours before start time - account credit is issued */
  return (
    <Text>Credits for your registration fee will be reflected in your account in 24-48 hours.</Text>
  );
};

const CancellationConfirmationModal = ({
  programType,
  isVP = false,
  isLessThanSixHours = false,
  playerStrikes = 0,
  onConfirm,
  onClose,
  ...props
}: CancellationConfirmationModalProps) => {
  const formattedProgramType = ProgramType[programType as ProgramTypeEnum].name;

  return (
    <ConfirmCancelModal
      {...props}
      headerText={`Cancel ${formattedProgramType}`}
      onConfirm={onConfirm}
      onClose={onClose}
    >
      <Text>
        Are you sure you would like to cancel this {formattedProgramType.toLowerCase()} spot?{' '}
        <CancellationMessageDetails
          isVP={isVP}
          isLessThanSixHours={isLessThanSixHours}
          playerStrikes={playerStrikes}
        />
      </Text>
    </ConfirmCancelModal>
  );
};

export default CancellationConfirmationModal;
