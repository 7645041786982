import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import type { ResultOf } from 'graphql-schema';
import type { FC } from 'react';

import styles from './styles.module.scss';
import { ColorIndicator } from '../../../../components';
import blackCup from '../../../../images/icons/cup.svg';
import cup from '../../../../images/icons/main/cup.svg';
import Picture from '../../../../components/Picture/Picture';
import type {
  SCHEDULE_GAME_FRAGMENT,
  SCHEDULE_TEAM_FRAGMENT,
} from '../../../../apps/RFO/Manage/Schedule/graphql';

const cx = classNames.bind(styles);

type GameItem = {
  game: ResultOf<typeof SCHEDULE_GAME_FRAGMENT>;
  teams: ResultOf<typeof SCHEDULE_TEAM_FRAGMENT>[];
  editScore: (game: ResultOf<typeof SCHEDULE_GAME_FRAGMENT>) => void;
  timezone: string;
};

const GameItem: FC<GameItem> = ({ game, teams, editScore, timezone }) => {
  const { start_time, scores = [], venue, field_name, teams: gameTeams } = game;

  if (!teams) {
    return <p>Unknown teams</p>;
  }

  const hasScore = !!scores.length;

  return (
    <div className={cx('game-item', hasScore ? 'score-error' : '')}>
      <p className={cx('start-time')}>{moment.tz(start_time, timezone).format('h:mma z')}</p>
      <span>
        {gameTeams.map(({ team }) => {
          const { name: teamName, color_hex, color_name, is_volo_pass_team } = team;
          const { score: s = 0, forfeit = false } =
            scores.find(score => score.team._id === team._id) ?? {};
          const score = String(+s);
          return (
            <p
              className={cx('team-placeholder', { forfeit, unscored: hasScore && !score })}
              key={`${team._id}tph`}
            >
              <ColorIndicator
                color={color_hex}
                style={{ height: '8px', width: '8px', marginRight: '2px' }}
              />
              <span className={cx('team-color')}>({color_name || 'N/A'})</span>
              <span className={cx('team-name-score')}>
                {forfeit ? <s>{teamName}</s> : <span>{teamName}</span>}
                {is_volo_pass_team && (
                  <Picture
                    src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/VoloPass.png"
                    alt="volo pass"
                  />
                )}
                {hasScore && <b> ({score || '?'})</b>}
              </span>
            </p>
          );
        })}
      </span>
      {venue?.shorthand_name && <p>{venue.shorthand_name}</p>}
      {field_name && <p className={cx('sub-location')}>{field_name}</p>}
      <div className={cx('game-tools')}>
        <img
          alt="Edit score"
          className={cx('edit-score-trigger')}
          src={hasScore ? cup : blackCup}
          onClick={() => editScore(game)}
        />
      </div>
    </div>
  );
};

export default GameItem;
