import { graphql, type ResultOf } from 'graphql-schema';

// Old API never pulled from rentals but we probably should eventually

type RegistrantsData = ResultOf<typeof PLAYER_WAIVERS_QUERY>['registrants'];
const PLAYER_WAIVERS_QUERY = graphql(`
  query playerWaiversQuery($where: registrants_bool_exp) {
    registrants(where: $where) {
      _id
      organization
      leagueByLeague {
        _id
        name
        seasonBySeason {
          _id
          name
        }
      }
      waiver_date_signed
      waiver_signed
      waiver_reminders {
        _id
        date_sent
        userByCreatedBy {
          _id
          username
        }
      }
      waiver {
        _id
        name
        is_general
        status
        type
      }
    }
  }
`);

/**
 * Restructures the data into what's expected in PlayerWaiversTab
 */
export const formatRegistrants = (registrants: RegistrantsData) => {
  return registrants.map(registrant => {
    const {
      _id: registrantId,
      organization,
      leagueByLeague,
      waiver_date_signed,
      waiver_signed,
      waiver_reminders,
      waiver,
    } = registrant;

    const season = leagueByLeague.seasonBySeason?.name;

    return {
      registrantId,
      season,
      waiverType: waiver.type!, // ? How to make this non-nullable?
      programId: leagueByLeague._id,
      programName: leagueByLeague.name,
      organizationId: organization,
      waiverId: waiver._id,
      waiverSigned: waiver_signed,
      waiverDateSigned: waiver_date_signed,
      waiverReminders: waiver_reminders,
    };
  });
};

export default PLAYER_WAIVERS_QUERY;
