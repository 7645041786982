/* eslint-disable react/no-danger */
import classNames from 'classnames/bind';
import { useMemo, useState } from 'react';
import { Popup } from '../../components';
import Image from '../../components/Image';
import { prettyUrl } from '../../utils';
import weatherIcoBlk from '../../images/icons/umbrella.svg';
import jerseysIcoBlk from '../../images/icons/shirt.svg';
import refundsIcoBlk from '../../images/icons/piggy.svg';
import forfeitsIcoBlk from '../../images/icons/less.svg';
import faqIcoBlk from '../../images/icons/help2.svg';
import charityIcoBlk from '../../images/icons/heart.svg';
import aboutIcoBlk from '../../images/icons/about.svg';
import styles from './styles.module.scss';
import type { PlayerProgramOrganization } from '../../apps/APP/PlayerProgram/PLAYER_PROGRAM_QUERIES';

const cx = classNames.bind(styles);

type PolicyType = 'weather' | 'jerseys' | 'refunds' | 'forfeits' | 'faq' | 'about' | 'charity';

const iconsBlk = {
  weather: weatherIcoBlk,
  jerseys: jerseysIcoBlk,
  refunds: refundsIcoBlk,
  forfeits: forfeitsIcoBlk,
  faq: faqIcoBlk,
  about: aboutIcoBlk,
  charity: charityIcoBlk,
};

const titles = {
  weather: 'Weather Policy',
  jerseys: 'Jersey Policy',
  refunds: 'Refund Policy',
  forfeits: 'Forfeits Policy',
  faq: 'FAQ/Support',
  about: 'About',
  charity: 'Giving Back',
};

type PolicyItemProps = {
  policy: {
    label: PolicyType;
    item: {
      prefer: string;
      website_url: string;
      html: string;
    };
  };
  setPopup: React.Dispatch<React.SetStateAction<PolicyType | undefined>>;
};

const PolicyItem = ({ policy, setPopup }: PolicyItemProps) => {
  const { item, label } = policy;
  if (!item || (!item.html && !item.website_url)) return <span />;
  const action =
    item.prefer === 'html'
      ? {
          onClick: () => setPopup(label),
        }
      : {
          href: item.website_url,
          target: '_blank',
        };
  return (
    <a className={cx('policy-item')} {...action}>
      <Image src={iconsBlk[label]} />
      <h4>{titles[label]}</h4>
    </a>
  );
};

type Props = {
  organization: PlayerProgramOrganization;
};

const OrgPoliciesResources = (props: Props) => {
  const {
    organization: { name, website_url, icon, logo, city, more_information },
  } = props;

  const [popup, setPopup] = useState<PolicyType | undefined>();

  const pUrl = prettyUrl(website_url);
  const orgImg = logo || icon;

  const policies = useMemo(() => {
    const policyKeys = Object.keys(iconsBlk);
    return policyKeys
      .map(key => {
        const label = key as PolicyType;
        const item = {
          // @ts-expect-error - Too fragile to type
          website_url: more_information?.[`${key}_website_url`],
          // @ts-expect-error
          html: more_information?.[`${key}_html`],
          // @ts-expect-error
          prefer: more_information?.[`${key}_prefer`],
        };
        return { label, item };
      })
      .filter(policy => policy.item.html || policy.item.website_url);
  }, [more_information]);

  const hasAtLeastOnePolicy = policies.length > 0;

  return (
    <div className={cx('org-policies-resources')}>
      <div className={cx('text-center', 'mb-4')}>
        {orgImg && (
          <div className={cx('mb-4', styles.orgImg)}>
            <Image src={orgImg} />
          </div>
        )}
        <div className={cx('mb-4')}>
          <h3 className="page-title">{name}</h3>
          <div className={cx('org-basics')}>
            {city}
            {city && website_url ? ' · ' : ''}
            <a
              className={cx('external-site')}
              href={website_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {pUrl}
            </a>
          </div>
        </div>
        {more_information?.about_prefer === 'html' && (
          <div
            className={cx('html', 'about-text')}
            dangerouslySetInnerHTML={{ __html: more_information?.about_html ?? '' }}
          />
        )}
      </div>
      {hasAtLeastOnePolicy && (
        <div className={cx('org-policies')}>
          <div className={cx('policy-items')}>
            {policies.map(policy => (
              <PolicyItem key={policy.label} policy={policy} setPopup={setPopup} />
            ))}
          </div>
        </div>
      )}
      {popup ? (
        <Popup className="fixed" close={() => setPopup(undefined)} hidden={!popup}>
          <div className={cx('policy-html')}>
            <Image src={iconsBlk[popup]} />
            <h4>
              {name} {titles[popup]}
            </h4>
            {more_information?.[`${popup}_html`] && (
              <div
                className={cx('html')}
                dangerouslySetInnerHTML={{ __html: more_information?.[`${popup}_html`] ?? '' }}
              />
            )}
          </div>
        </Popup>
      ) : null}
    </div>
  );
};

export default OrgPoliciesResources;
