import { graphql } from 'graphql-schema';

export const VENUE_SCHEDULE_FRAGMENT = graphql(`
  fragment VenueScheduleFragment on venues @_unmask {
    _id
    shorthand_name
    timezone
  }
`);

export const GET_LEAGUE_DETAILS = graphql(
  `
    query leagueDetails($leagueWhereClause: leagues_bool_exp!) {
      leagues(where: $leagueWhereClause, limit: 1) {
        _id
        end_time_estimate
        start_time_estimate
        days {
          _id
          date
          week
        }
        organization: organizationByOrganization {
          _id
          venues {
            ...VenueScheduleFragment
          }
        }
        schedules: subschedules {
          _id
        }
        teams {
          _id
          name
        }
        venue: venueByVenue {
          ...VenueScheduleFragment
        }
        weeks {
          _id
          week_num
        }
      }
    }
  `,
  [VENUE_SCHEDULE_FRAGMENT]
);

export const GET_GAME_DETAILS = graphql(
  `
    query gameDetails($gameWhereClause: games_bool_exp!) {
      games(where: $gameWhereClause, limit: 1) {
        _id
        created_by_name
        day
        end_time_str
        field_name
        is_tournament
        start_time_str
        updated_at
        week
        teams {
          _id
          team: teamByTeam {
            _id
            name
          }
        }
        venue: venueByVenue {
          ...VenueScheduleFragment
        }
      }
    }
  `,
  [VENUE_SCHEDULE_FRAGMENT]
);

export const UPDATE_GAME = graphql(`
  mutation updateGame($input: UpdateGameInput!) {
    updateGame(input: $input) {
      game
    }
  }
`);

export const CREATE_GAME = graphql(`
  mutation createGame($input: CreateGameInput!) {
    createGame(input: $input) {
      game
    }
  }
`);
