import { useQuery } from '@apollo/client';
import type { ExtractEnum } from 'graphql-schema';

import { ProgramTypeEnum } from '../../../../constants';
import { getDayOfWeekFromDate } from '../../../../utilities';

import GET_HASURA_PROGRAM_DETAILS from '../graphql/GET_PROGRAM_DETAILS';
import GET_HASURA_GAME_DETAILS from '../graphql/GET_GAME_DETAILS';

const useProgramDetails = ({
  programId = '',
  gameId = '',
}: {
  programId?: string;
  gameId?: string;
}) => {
  const {
    data: gameData,
    loading: gameLoading,
    error: gameError,
  } = useQuery(GET_HASURA_GAME_DETAILS, {
    skip: !gameId,
    fetchPolicy: 'cache-first',
    variables: {
      id: gameId,
    },
  });

  const { games_by_pk: game } = gameData || {};
  const { start_time = '', leagueByLeague: gameLeague } = game || {};
  const { _id: gameLeagueId = '', sportBySport: sport } = gameLeague || {};
  const sportName = sport?.name || '';

  // Format start_time the same as start_time_estimate ('HH:mm')
  const gameStartTime = new Date(start_time ?? '').toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

  const {
    data: programData,
    loading: programLoading,
    error: programError,
  } = useQuery(GET_HASURA_PROGRAM_DETAILS, {
    skip: !programId && !gameLeagueId,
    fetchPolicy: 'cache-first',
    variables: {
      id: programId || gameLeagueId,
    },
  });

  const { leagues_by_pk: league } = programData || {};

  const {
    display_name: displayName,
    program_type: programType,
    start_date,
    header: photoUrl,
    banner_text,
    num_weeks_estimate = 0,
    num_playoff_weeks_estimate = 0,
    start_time_estimate = '',
    end_time_estimate = '',
    registrants_aggregate,
    is_premier: isPremier = false,
    is_volo_pass_exclusive: isVoloPassExclusive = false,
  } = league || {};

  // drop-in labeling is inconsistent
  const displayProgramType: ExtractEnum<'program_type_enum'> = gameId ? 'DROPIN' : programType!;

  const { drop_in_capacity: dropInCapacity } = game || {};
  const availableDropInSpots = dropInCapacity?.total_available_spots || 0;

  // Drop-ins and volunteer events are formatted differently
  const isDailyProgram =
    programType === ProgramTypeEnum.CLASS ||
    programType === ProgramTypeEnum.CLINIC ||
    programType === ProgramTypeEnum.EVENT ||
    programType === ProgramTypeEnum.PICKUP ||
    programType === ProgramTypeEnum.PRACTICE;

  const neighborhoodName =
    game?.venueByVenue?.neighborhoodByNeighborhoodId?.name ||
    league?.neighborhoodByNeighborhood?.name ||
    '';

  const venueName =
    game?.venueByVenue?.shorthand_name || league?.venueByVenue?.shorthand_name || '';
  const venueAddress =
    game?.venueByVenue?.formatted_address || league?.venueByVenue?.formatted_address || '';

  const { min_registration_size: registrationMin = 0, max_registration_size: registrationMax = 0 } =
    league?.registrationByRegistration || {};

  const numWeeks = num_weeks_estimate - (num_playoff_weeks_estimate || 0);

  // Drop-in naming convention [7/18/2024]: '<DAY> - <SPORT> - <NEIGHBORHOOD> - Drop-In'
  const gameDayOfWeek = start_time ? getDayOfWeekFromDate(start_time) : '';
  const gameDisplayName = `${gameDayOfWeek} - ${sportName} - ${neighborhoodName} - Drop-In`;

  return {
    programError,
    programLoading,
    gameLoading,
    gameError,
    displayName: gameId ? gameDisplayName : displayName,
    programType: displayProgramType,
    start_date,
    photoUrl,
    banner_text,
    startTime: gameId ? gameStartTime : start_time_estimate,
    end_time_estimate,
    neighborhoodName,
    venueName,
    venueAddress,
    registrantCount: registrants_aggregate?.aggregate?.count || 0,
    registrationMin,
    registrationMax,
    numWeeks,
    isDailyProgram,
    availableDropInSpots,
    isPremier,
    isVoloPassExclusive,
  };
};

export default useProgramDetails;
