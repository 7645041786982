import { useSuspenseQuery } from '@apollo/client';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { useState, type ComponentProps, type FC, type ReactElement } from 'react';
import type { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { isEmail } from 'validator';
import {
  Box,
  FontAwesomeIcon,
  FormControl,
  Row,
  Select,
  Text,
  TextInput,
  TouchableOpacity,
  VStack,
} from '../../../../base-components';
import { ORG_EMAIL_TEMPLATES } from '../../gql';
import { useMessageCenterStore } from '../../store';

const CustomFormControlLabel: FC<ComponentProps<typeof FormControl.Label>> = ({
  children,
  ...rest
}) => (
  <FormControl.Label _text={{ color: 'gray.700', fontSize: 'md', fontWeight: 'bold' }} {...rest}>
    {children}
  </FormControl.Label>
);

export interface EmailDetailsProps {
  organizationId: string;
  /** These are the props required by our web Froala wrapper for the message center */
  TextEditor: (props: {
    organizationId: string;
    onChange: (value: string) => void;
    html: string;
  }) => ReactElement;
  rentalEmailCount: number;
}

const EmailDetails: FC<EmailDetailsProps> = ({ organizationId, TextEditor, rentalEmailCount }) => {
  const [tempManualEmail, setTempManualEmail] = useState('');
  const [manualEmailError, setManualEmailError] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const { from, manualEmails, messageContent, subject } = useMessageCenterStore(store => ({
    from: store.from,
    manualEmails: store.manualEmails,
    messageContent: store.messageContent,
    subject: store.subject,
  }));

  const {
    data: { emails },
  } = useSuspenseQuery(ORG_EMAIL_TEMPLATES, {
    variables: {
      organization: organizationId,
    },
  });

  const templateOptions = emails.map(email => (
    <Select.Item key={email._id} label={email.subject ?? ''} value={email._id}>
      {email.subject}
    </Select.Item>
  ));

  const onSelectTemplate = (templateId: string) => {
    const foundTemplate = emails.find(({ _id }) => _id === templateId);
    if (foundTemplate) {
      setSelectedTemplate(templateId);
      useMessageCenterStore.setState(() => ({ messageContent: foundTemplate.html ?? '' }));
    }
  };

  const onManualEmailChange = (value: string) => {
    setTempManualEmail(value);
    setManualEmailError('');
  };

  const onManualEmailKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (event.nativeEvent.key === 'Enter') {
      if (isEmail(tempManualEmail)) {
        useMessageCenterStore.setState(partial => ({
          manualEmails: [...partial.manualEmails, tempManualEmail],
        }));
        setTempManualEmail('');
      } else {
        setManualEmailError('Invalid email');
      }
    }
  };

  return (
    <VStack bg="white.600" rounded="sm" p="4">
      <Row space="4" alignItems="end">
        <Text bold fontSize="md" color="gray.700">
          To
        </Text>
        <Text fontSize="sm" color="gray.700">
          {rentalEmailCount} players will be sent this email.
        </Text>
        {/* in a future version that supports league data, this will include a "filters" section */}
      </Row>
      <Row>
        <FormControl w="1/3" paddingRight="2">
          <CustomFormControlLabel>From</CustomFormControlLabel>
          <TextInput
            value={from}
            onChangeText={text => useMessageCenterStore.setState(() => ({ from: text }))}
            placeholder="Add your name here."
          />
        </FormControl>
        <FormControl w="2/3">
          <CustomFormControlLabel>Subject</CustomFormControlLabel>
          <TextInput
            value={subject}
            onChangeText={text => useMessageCenterStore.setState(() => ({ subject: text }))}
            placeholder="Add subject here."
          />
        </FormControl>
      </Row>
      <FormControl isInvalid={!!manualEmailError} marginBottom="2">
        <CustomFormControlLabel>Manually Add Emails</CustomFormControlLabel>
        <TextInput
          placeholder="Please enter to add"
          onKeyPress={onManualEmailKeyPress}
          onChangeText={onManualEmailChange}
          value={tempManualEmail}
        />
        <FormControl.ErrorMessage>{manualEmailError}</FormControl.ErrorMessage>
      </FormControl>
      {!!manualEmails.length && (
        <Row space="1" flexWrap="wrap" maxW="full">
          {manualEmails.map(email => (
            <Box
              key={email}
              bg="gray.100"
              flexDir="row"
              p="2"
              alignItems="center"
              rounded="md"
              borderWidth="1"
              borderColor="gray.400"
            >
              <Text marginRight="2" fontSize="sm">
                {email}
              </Text>
              <TouchableOpacity
                onPressIn={() =>
                  useMessageCenterStore.setState(partial => ({
                    manualEmails: partial.manualEmails.filter(val => val !== email),
                  }))
                }
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </TouchableOpacity>
            </Box>
          ))}
        </Row>
      )}
      <FormControl marginBottom="2">
        <CustomFormControlLabel>Choose Template Email</CustomFormControlLabel>
        <Select
          placeholder="Select a template"
          selectedValue={selectedTemplate}
          onValueChange={onSelectTemplate}
        >
          {templateOptions}
        </Select>
      </FormControl>
      <TextEditor
        organizationId={organizationId}
        onChange={value => useMessageCenterStore.setState(() => ({ messageContent: value }))}
        html={messageContent}
      />
    </VStack>
  );
};

export default EmailDetails;
