const DailyProgramTypes = [
    'EVENT',
    'PRACTICE',
    'PICKUP',
    'VOLUNTEER_EVENT',
    'CLASS',
    'CLINIC',
    'DROPIN',
];
export const isProgramTypeDaily = (programType) => {
    return DailyProgramTypes.includes(programType);
};
