/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { type ComponentProps, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { Spinner, Menu, IconButton, FontAwesomeIcon, View } from '@rivallapp/volosports-components';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import { useMutation } from '@apollo/client';
import styles from '../payment.module.scss';
import { DELETE_PAYMENT_SOURCE, SET_PAYMENT_SOURCE_DEFAULT } from '../PLAYER_PAYMENT_MUTATIONS';
import { type PaymentSource } from './EditPaymentMethods';
import { useAlertMessage } from '../../../hooks';
import hasuraClient from '../../../apollo/hasuraClient';
import useCurrentUserV2 from '../../../hooks/useCurrentUserV2';

// Possible values: Visa, MasterCard, American Express, Discover, Diners Club, etc.
export const formatCardBrandClassName = (brand: string) => brand.split(' ').join('').toLowerCase();

const MenuTrigger: ComponentProps<typeof Menu>['trigger'] = props => (
  <IconButton icon={<FontAwesomeIcon icon={faEllipsisH} />} rounded="full" {...props} />
);

type Props = {
  source: PaymentSource;
  onClose: () => void;
};

const PaymentSourceItem: React.FC<Props> = ({ source, onClose }) => {
  const { showSuccess, showError } = useAlertMessage();

  const { currentUser, refetch } = useCurrentUserV2();

  const [deletePaymentMethod, deleteMethodResult] = useMutation(DELETE_PAYMENT_SOURCE, {
    client: hasuraClient,
    onCompleted: async () => refetch(),
  });
  const [setDefaultPaymentMethod, setDefaultMethodResult] = useMutation(
    SET_PAYMENT_SOURCE_DEFAULT,
    { client: hasuraClient, onCompleted: async () => refetch() }
  );

  const { loading: deletePaymentLoading } = deleteMethodResult;
  const { loading: setDefaultLoading } = setDefaultMethodResult;
  const loading = setDefaultLoading || deletePaymentLoading;

  const handleDeletePayment = useCallback(async () => {
    try {
      if (!currentUser || !currentUser.stripe_id) {
        throw new Error('Cannot update payment methods while signed out.');
      }
      await deletePaymentMethod({
        variables: { sourceId: source.id },
      });
      showSuccess('Deleted payment method.');
    } catch (error) {
      showError(error);
    }
  }, [currentUser, deletePaymentMethod, showError, showSuccess, source.id]);

  const handleSetDefault = useCallback(async () => {
    if (source.isDefault) return;
    try {
      if (!currentUser || !currentUser.stripe_id) {
        throw new Error('Cannot update payment methods while signed out.');
      }
      await setDefaultPaymentMethod({
        variables: { sourceId: source.id },
      });
      showSuccess('Updated payment method.');
      if (onClose) onClose();
    } catch (error) {
      showError(error);
    }
  }, [
    currentUser,
    onClose,
    setDefaultPaymentMethod,
    showError,
    showSuccess,
    source.id,
    source.isDefault,
  ]);

  const MenuOptions = useMemo(
    () => [
      ...(!source.isDefault
        ? [
            {
              label: 'Use this payment method',
              disabled: source.isExpired,
              onClick: handleSetDefault,
            },
          ]
        : []),
      {
        label: 'Delete payment method',
        onClick: handleDeletePayment,
      },
    ],
    [handleDeletePayment, handleSetDefault, source]
  );

  return (
    <div
      className={cx(
        styles.sourceItem,
        'd-flex',
        'justify-content-between',
        'align-items-center',
        'bg-light',
        'p-3',
        'mb-3',
        {
          ...('notDefault' in styles ? { [styles.notDefault]: !source.isDefault } : {}),
          ...('isExpired' in styles ? { [styles.isExpired]: source.isExpired } : {}),
        }
      )}
    >
      <div
        className={cx(styles.main, 'text-left')}
        onClick={handleSetDefault}
        role="button"
        tabIndex={0}
      >
        {source.isDefault && <p className={styles.current}>Current</p>}
        <div className="d-flex align-items-center">
          <div className={cx(styles.brand, styles[formatCardBrandClassName(source.brand)])} />
          <div>
            <p>
              <b>**** **** **** {source.last4}</b>
            </p>
            <p className={cx(styles.exp)}>
              <small>
                {source.exp_month}/{source.exp_year}
              </small>
            </p>
          </div>
        </div>
      </div>
      <View>
        {loading ? (
          <Spinner />
        ) : (
          <Menu _overlay={{ useRNModal: true }} placement="bottom right" trigger={MenuTrigger}>
            {MenuOptions.map(({ onClick, disabled, label }) => (
              <Menu.Item onPress={onClick} isDisabled={disabled} key={label}>
                {label}
              </Menu.Item>
            ))}
          </Menu>
        )}
      </View>
    </div>
  );
};

export default PaymentSourceItem;
