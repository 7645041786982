import { gql } from '@apollo/client';
import { graphql } from 'graphql-schema';

export const ISSUE_REFUND = graphql(`
  mutation issueProgramRefund($input: ActionsRegistrantsIssueProgramRefundInput!) {
    issueProgramRefund(input: $input) {
      _id
    }
  }
`);

export const REMOVE_WITHOUT_REFUND = graphql(`
  mutation removeWithoutRefund($input: ActionsRegistrantsRemoveWithoutRefundInput!) {
    removeWithoutRefund(input: $input) {
      _id
    }
  }
`);

export const CURRENT_USER_SELF_WITHDRAW = gql`
  mutation currentUserSelfWithdraw($input: CurrentUserSelfWithdrawInput!) {
    currentUserSelfWithdraw(input: $input) {
      registrant {
        _id
      }
    }
  }
`;

export const ADD_CREDITS = graphql(`
  mutation addCredits($input: ActionsPlayersAddCreditsInput!) {
    addCredits(input: $input) {
      _id
    }
  }
`);

// NOTE (7/12/24):
// Because of the way our availableCredits are setup, if a user has credits from multiple
// organizations, this individual credit amount may exceed the organization credit balance,
// which will give the user a negative credit balance for that organization. This is not ideal,
// but we don't currently handle credits on a per-organization basis, so it works..
// Something to revisit when we start the white labeling discussions because credits from a
// non-Volo org probably shouldn't be usable for a Volo program, and vice versa.
export const DELETE_CREDITS = graphql(`
  mutation deleteCredits($creditId: uuid!, $userId: uuid!, $organizationId: uuid!, $amount: Int!) {
    delete_user_credits_by_pk(_id: $creditId) {
      _id
    }

    update_user_available_credits(
      where: { user: { _eq: $userId }, organization: { _eq: $organizationId } }
      _inc: { amount: $amount }
    ) {
      returning {
        _id
        amount
      }
    }
  }
`);

export const USER_MEMBERSHIP_HISTORY_QUERY = graphql(`
  query userMembershipHistory($userId: uuid!, $limit: Int = 10, $offset: Int) {
    membershipInvoices: invoices(
      where: { user: { _eq: $userId }, subscriptionBySubscription: { type: { _eq: VOLO_PASS } } }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      _id
      stripeInvoiceId: invoice_id
      createdAt: created_at
      status
      total
    }
    invoices_aggregate(where: { user: { _eq: $userId } }) {
      aggregate {
        count
      }
    }
  }
`);
