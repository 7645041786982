import { graphql } from 'graphql-schema';

export const GET_STRIPE_PROMO = graphql(`
  query getStripePromo($orgId: String!, $promoId: String!) {
    getStripePromo(orgId: $orgId, promoId: $promoId) {
      valid
      promo {
        active
        amount_off
        code
        created
        currency
        customer
        duration
        duration_in_months
        expires_at
        id
        max_redemptions
        name
        object
        percent_off
        redeem_by
        restrictions {
          first_time_transaction
        }
        times_redeemed
        valid
      }
    }
  }
`);

export const ACTIVATE_MEMBERSHIP = graphql(`
  mutation startVoloPassMembership($planId: String!, $orgId: String!, $promoCode: String) {
    startVoloPass(planId: $planId, orgId: $orgId, promoCode: $promoCode) {
      _id
      status
    }
  }
`);

export const CANCEL_MEMBERSHIP = graphql(`
  mutation cancelVoloPassMembership {
    cancelVoloPass {
      success
    }
  }
`);

export const UPGRADE_MEMBERSHIP = graphql(`
  mutation upgradeVoloPassMembership($planId: String!, $promoCode: String) {
    upgradeVoloPass(planId: $planId, promoCode: $promoCode) {
      _id
      status
    }
  }
`);

export const REINSTATE_MEMBERSHIP = graphql(`
  mutation reinstateVoloPass {
    reinstateVoloPass {
      _id
      status
      end_date
      subscription {
        _id
        cancel_at_period_end
        status
      }
    }
  }
`);

export const GET_SUBSCRIPTION_AND_ORGS = graphql(`
  query getSubscriptionAndOrgs($userId: uuid!) {
    subscriptions(
      limit: 1
      order_by: { start_date: asc }
      where: {
        user: { _eq: $userId }
        type: { _eq: VOLO_PASS }
        status: { _in: [active, trialing] }
      }
    ) {
      interval
      # TODO Metdata
      status
      stripe_subscription_id
      stripe_product_id
      organization
    }
    organizations(where: { is_discoverable: { _eq: true } }) {
      _id
      external_id
      name
      volo_pass_annual_plan_id
      volo_pass_monthly_plan_id
      volo_pass_monthly_price_cents
      is_volo_pass_active
    }
    getUserPaymentSources {
      id
      isDefault
      isExpired
      brand
      exp_month
      exp_year
      last4
    }
  }
`);
