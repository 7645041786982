/* eslint-disable import/prefer-default-export */
import { DonationFrequencyEnum } from '@rivallapp/volosports-components';
import { GenderEnum } from '../../../../shared/gender-enum';
import { RegistrantTypes } from '../../../../shared/registrant-type-enum';
import { RegisterTypes, RegisterSections, REGISTER_PATH } from './constants';
import { commaSeparatedList } from '../../../../utils';
import { membershipPlanId } from '../../../../environment';
import { RegistrationActions } from '../../../../contexts/RegistrationContext/RegistrationReducer';
import { monthlyPriceEnum } from '../../../../shared/kids-recurring-donation';

const { UPDATE_REGISTRATION_STATE } = RegistrationActions;
const blockTypes = [];

export const getDisallowedTypesMessage = options => {
  const {
    metGenderReq,
    male_capacity,
    female_capacity,
    free_agent_capacity,
    free_agent_limits_enabled,
    limit_individual_registrations,
    block_individual_registrations,
    block_group_captain_registrations,
    block_group_member_registrations,
    block_prepaid_team_captain_registrations,
    block_prepaid_team_member_registrations,
    block_all_registrations,
    gender,
  } = options || {};

  if (
    block_all_registrations &&
    block_prepaid_team_captain_registrations &&
    block_group_member_registrations &&
    block_group_captain_registrations
  ) {
    return {
      blockedMessage: 'Sorry, this program is sold out!',
    };
  }

  let blockGender;

  const blocked = [];

  if (!metGenderReq) {
    if (male_capacity && gender === GenderEnum.FEMALE) {
      blockGender = false;
    } else if (female_capacity && gender !== GenderEnum.FEMALE) {
      blockGender = true;
      blocked.push('non-female players');
    }
  }

  if (free_agent_limits_enabled && !free_agent_capacity) {
    blockGender = true;
    blocked.push('free agents');
  } else if (!blockGender && limit_individual_registrations && !block_individual_registrations) {
    if (!male_capacity && !female_capacity) {
      blockGender = true;
      blocked.push('free agents');
    } else if (!male_capacity && gender === GenderEnum.MALE) {
      blockGender = true;
      blocked.push('non-female free agents');
    } else if (!female_capacity && gender === GenderEnum.FEMALE) {
      blockGender = false;
    }
  }

  if (block_all_registrations) {
    blocked.push('free agents');
    blockTypes.push(RegisterTypes.FREE_AGENT);
  }
  if (block_group_captain_registrations) {
    blocked.push('new groups');
    blockTypes.push(RegisterTypes.GROUP_CAPTAIN);
  }
  if (block_group_member_registrations) {
    blocked.push('group members');
    blockTypes.push(RegisterTypes.GROUP_MEMBER);
  }
  if (block_prepaid_team_captain_registrations) {
    blocked.push('new prepaid teams');
    blockTypes.push(RegisterTypes.PREPAID_TEAM_CAPTAIN);
  }
  if (block_prepaid_team_member_registrations) {
    blocked.push('prepaid team members');
    blockTypes.push(RegisterTypes.PREPAID_TEAM_MEMBER);
  }
  if (block_individual_registrations) {
    blocked.push('free agents');
  }

  const waitlistMessage =
    !block_all_registrations && block_individual_registrations
      ? ' You can still pay to join the waitlist and we will move you into the Free Agent pool if spots open up.'
      : '';

  return {
    blockedMessage: blocked.length
      ? `This program is not accepting ${commaSeparatedList(blocked, {
          joiner: 'or',
        })}.${waitlistMessage}`
      : null,
    blockGender,
    blockTypes,
  };
};

/**
 * @param {any} options - RegistrationContext value
 */
export const getRedirectLink = (options = {}) => {
  const {
    currentUser: { gender },
    validGenders,
    blockRegistrations,
    limit_individual_registrations,
    divisionSelection,
    donationAmount,
    league,
    registrantType,
    wypSelection,
    joiningGroupId,
    selectedGroupId,
    newPrepaidTeam,
    newGroup,
    currentStepIndex,
    passwordRequired,
    passwordCorrect,
    male_capacity,
    fullTeam,
    is_locked,
  } = options;

  const { has_divisions } = league || {};

  const rTypes = [];

  Object.values(RegistrantTypes).forEach(rType => {
    if (rType.abbrev) rTypes.push(rType.abbrev);
  });

  if (
    blockRegistrations ||
    !registrantType ||
    blockTypes.includes(registrantType) ||
    !rTypes.includes(registrantType.toUpperCase()) ||
    !validGenders.includes(gender) ||
    (registrantType === 'fa' &&
      limit_individual_registrations &&
      !male_capacity &&
      gender === GenderEnum.MALE) ||
    ((selectedGroupId || joiningGroupId) && is_locked) ||
    ((selectedGroupId || joiningGroupId) && fullTeam)
  ) {
    return `${REGISTER_PATH}/${league._id}/${RegisterSections.REGISTRATION_TYPE.path}`;
  }

  switch (currentStepIndex) {
    case 1: {
      if (registrantType === RegisterTypes.GROUP_MEMBER && !joiningGroupId && !selectedGroupId) {
        return `${REGISTER_PATH}/${league._id}/${RegisterSections.SELECT_GROUP.path}?type=${RegisterTypes.GROUP_MEMBER}`;
      }
      if (
        registrantType === RegisterTypes.PREPAID_TEAM_MEMBER &&
        passwordRequired &&
        !passwordCorrect
      ) {
        return `${REGISTER_PATH}/${league._id}/${RegisterSections.JOIN_PREPAID_TEAM.path}?type=${RegisterTypes.PREPAID_TEAM_MEMBER}&invite=${joiningGroupId}`;
      }
      if (
        registrantType === RegisterTypes.PREPAID_TEAM_CAPTAIN &&
        (!newPrepaidTeam.name || !newPrepaidTeam.password || !newPrepaidTeam.sizeEstimate)
      ) {
        return `${REGISTER_PATH}/${league._id}/${RegisterSections.NEW_TEAM.path}?type=${RegisterTypes.PREPAID_TEAM_CAPTAIN}`;
      }
      if (
        registrantType === RegisterTypes.GROUP_CAPTAIN &&
        (!newGroup.name || !newGroup.sizeEstimate)
      ) {
        return `${REGISTER_PATH}/${league._id}/${RegisterSections.NEW_GROUP.path}?type=${RegisterTypes.GROUP_CAPTAIN}`;
      }
      if (fullTeam) {
        return `${REGISTER_PATH}/${league._id}/${RegisterSections.SELECT_GROUP.path}?type=${RegisterTypes.GROUP_MEMBER}`;
      }
      if (is_locked) {
        return `${REGISTER_PATH}/${league._id}/${RegisterSections.SELECT_GROUP.path}?type=${RegisterTypes.GROUP_MEMBER}`;
      }
      break;
    }
    case 2: {
      if (passwordRequired && !passwordCorrect) {
        return `${REGISTER_PATH}/${league._id}/${RegisterSections.JOIN_PREPAID_TEAM.path}?type=${RegisterTypes.PREPAID_TEAM_MEMBER}&invite=${joiningGroupId}`;
      }
      if (league?.why_you_play_enabled && !wypSelection) {
        return `${REGISTER_PATH}/${league._id}/${RegisterSections.FORMS.path}?type=${registrantType}`;
      }
      if (has_divisions && !divisionSelection) {
        return `${REGISTER_PATH}/${league._id}/${RegisterSections.SKILL_LEVEL.path}?type=${registrantType}`;
      }
      break;
    }
    case 3: {
      if (!league?.donations_disabled && !donationAmount && donationAmount !== 0) {
        return `${REGISTER_PATH}/${league._id}/${RegisterSections.DONATION.path}?type=${registrantType}`;
      }
      break;
    }
    default:
      return undefined;
  }

  return undefined;
};

export const getBackLink = options => {
  const { step, leagueId, league, search } = options || {};
  switch (step) {
    // Start & End
    case RegisterSections.START.path:
    case RegisterSections.CONFIRMATION.path:
      return null;
    // 01 -> Start
    case RegisterSections.REGISTRATION_TYPE.path:
      return `${REGISTER_PATH}/${leagueId}/${RegisterSections.START.path}${search}`;
    // 02A -> 01
    case RegisterSections.FORMS.path: {
      if (search.includes(RegisterTypes.PREPAID_TEAM_CAPTAIN)) {
        // if team captain, return to edit team; 02A -> 01B
        return `${REGISTER_PATH}/${leagueId}/${RegisterSections.NEW_TEAM.path}${search}`;
      }
      if (search.includes(RegisterTypes.GROUP_CAPTAIN)) {
        // if group captain, return to edit group; 02A -> 01C
        return `${REGISTER_PATH}/${leagueId}/${RegisterSections.NEW_GROUP.path}${search}`;
      }
      if (search.includes(RegisterTypes.GROUP_MEMBER)) {
        // if group member/prepaidteam member, return to edit group; 02A -> 01A
        return `${REGISTER_PATH}/${leagueId}/${RegisterSections.SELECT_GROUP.path}${search}`;
      }
      if (search.includes(RegisterTypes.PREPAID_TEAM_MEMBER)) {
        // if group member/prepaidteam member, return to edit group; 02A -> 01A
        return `${REGISTER_PATH}/${leagueId}/${RegisterSections.JOIN_PREPAID_TEAM.path}${search}`;
      }
      return `${REGISTER_PATH}/${leagueId}/${RegisterSections.REGISTRATION_TYPE.path}${search}`;
    }
    // 02B -> 02A
    case RegisterSections.SKILL_LEVEL.path: {
      if (league?.why_you_play_enabled) {
        return `${REGISTER_PATH}/${leagueId}/${RegisterSections.FORMS.path}${search}`;
      }
      return `${REGISTER_PATH}/${leagueId}/${RegisterSections.REGISTRATION_TYPE.path}${search}`;
    }
    // 03 -> 02B
    case RegisterSections.DONATION.path:
      return `${REGISTER_PATH}/${leagueId}/${RegisterSections.SKILL_LEVEL.path}${search}`;
    // 04 -> 03
    case RegisterSections.PAYMENT.path: {
      // If donationsDisabled, check for divisions & why you play
      if (!league?.donations_disabled) {
        return `${REGISTER_PATH}/${leagueId}/${RegisterSections.DONATION.path}${search}`;
      }
      if (league?.has_divisions) {
        return `${REGISTER_PATH}/${leagueId}/${RegisterSections.SKILL_LEVEL.path}${search}`;
      }
      if (league?.whyYouPlayEnabled) {
        return `${REGISTER_PATH}/${leagueId}/${RegisterSections.FORMS.path}${search}`;
      }
      return `${REGISTER_PATH}/${leagueId}/${RegisterSections.REGISTRATION_TYPE.path}${search}`;
    }
    default:
      return `${REGISTER_PATH}/${leagueId}/${RegisterSections.REGISTRATION_TYPE.path}${search}`;
  }
};

export const getCreditsApplied = ({ availableCreditsAmount = 0, priceCents = 0 }) => {
  if (!(availableCreditsAmount > 0)) return 0;
  const difference = availableCreditsAmount - priceCents;
  if (difference >= 0) return priceCents;
  if (difference < 0) return availableCreditsAmount;
  return 0;
};

export const checkResponseCompletion = ({ customQuestionsList = [], responses }) => {
  if (customQuestionsList?.length === 0) return true;

  // make sure all mandatory questions have been answered
  const checkList = customQuestionsList?.filter(
    ({ is_mandatory_question }, i) => is_mandatory_question && !responses?.[i]?.response
  );

  return checkList.length === 0;
};

export const registerAndPay = async (mutation, options) => {
  if (!mutation) {
    throw new Error('No mutation provided for registerAndPay method');
  }

  const {
    addVpMembership,
    blockRegistrations,
    appliedPromo,
    creditAppliedAmount,
    deletePaymentInfo,
    divisionSelection,
    donationAmount,
    donationFrequency,
    joiningGroupId,
    newGroup,
    newPrepaidTeam,
    responses,
    wypSelection,
    registrantType,
    league,
    game,
    history,
    startMembershipMutation,
    startRecurringDonation,
    registerDispatch,
    setSuccess,
    setError,
    setUpdating,
    selectedCity,
    waiverAgreed,
    cancellationPolicyAgreed = false,
    usePaymentSummaryProps,
  } = options;

  const isStartingRecurringDonation = donationFrequency === DonationFrequencyEnum.MONTHLY;

  try {
    setUpdating(true);
    registerDispatch({
      update: {
        processingPayment: true,
        leagueId: league?._id || game?.league?._id,
      },
      type: UPDATE_REGISTRATION_STATE,
    });
    if (blockRegistrations) {
      throw new Error('Sorry, Program is sold out!');
    }
    // If addVpMembership is in 'cart', startMembership
    if (addVpMembership) {
      await startMembershipMutation({
        variables: {
          planId: selectedCity?.volo_pass_monthly_plan_id || membershipPlanId,
          orgId: selectedCity?.external_id,
        },
      });
    }

    const input = {
      programId: league?._id ?? game?.league?._id,
      divisionSelection,
      ...(isStartingRecurringDonation ? {} : { donationAmount }),
      promoCodeStr: appliedPromo || null,
      whyYouPlay: wypSelection,
      deletePaymentInfo,
      vpInCart: addVpMembership,
      platform: 'web',
      creditAmount: creditAppliedAmount,
      waiverSigned: waiverAgreed,
      cancellationPolicyAgreed,
      ...(registrantType === RegisterTypes.GROUP_MEMBER ? { joiningGroupId } : {}),
      ...(registrantType === RegisterTypes.WAITLIST ? { isWaitlist: true } : {}),
      customResponseList: responses.filter(resp => !!resp),
      ...(registrantType === RegisterTypes.PREPAID_TEAM_CAPTAIN ? { newPrepaidTeam } : {}),
      ...(registrantType === RegisterTypes.GROUP_CAPTAIN ? { newGroup } : {}),
      isDonatingPortionOfRegistration: usePaymentSummaryProps.userIsDonatingRegistrationCost,
    };

    const result = await mutation({
      variables: {
        input,
      },
    });
    // If the user is starting a recurring donation
    if (isStartingRecurringDonation) {
      await startRecurringDonation({
        variables: {
          planId: monthlyPriceEnum.find(item => item.price === donationAmount)?.planId,
        },
      });
    }
    const { registrantId } = result?.data?.registerForProgram ?? {};
    history.push(
      `${REGISTER_PATH}/${league?._id || game?.league?._id}/${
        RegisterSections.CONFIRMATION.path
      }?regid=${registrantId}`
    );
    setSuccess('Successfully registered for program!');
  } catch (e) {
    setError(e);
  } finally {
    registerDispatch({
      type: UPDATE_REGISTRATION_STATE,
      update: { processingPayment: false },
    });
    setUpdating(false);
  }
};

export const onSubmitPrepaidRegistration = async (mutation, options) => {
  const {
    registerDispatch,
    responses = [],
    history,
    passwordInput,
    joiningGroupId,
    league,
    blockRegistrations,
    setSuccess,
    setError,
    waiverAgreed,
    wypSelection,
  } = options;

  const { _id: leagueId } = league || {};

  try {
    if (blockRegistrations) {
      throw new Error('Sorry! This program is sold out');
    }
    registerDispatch({
      update: { processingPayment: true },
      type: UPDATE_REGISTRATION_STATE,
    });
    const { joinPrepaidTeam } = await mutation({
      variables: {
        input: {
          prepaidGroupId: joiningGroupId,
          password: passwordInput,
          whyYouPlay: wypSelection,
          platform: 'web',
          customResponseList: responses.filter(resp => !!resp),
          waiverSigned: waiverAgreed,
        },
      },
    });
    registerDispatch({
      update: { processingPayment: false },
      type: UPDATE_REGISTRATION_STATE,
    });
    const registrantId = joinPrepaidTeam?.registrantId ?? '';
    history.push(
      `${REGISTER_PATH}/${leagueId}/${RegisterSections.CONFIRMATION.path}?regid=${registrantId}`
    );
    setSuccess('Successfully registered for program');
  } catch (e) {
    setError(e);
  } finally {
    registerDispatch({
      type: UPDATE_REGISTRATION_STATE,
      update: { processingPayment: false },
    });
  }
};
