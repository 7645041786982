import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { isEmail } from 'validator';
import useValidators from '../../../apps/OnboardingV2/useValidators';
import { useAlertMessage } from '../../../hooks';
import { UPDATE_USER_PROFILE } from '../PLAYER_DETAILS_MUTATIONS';
import hasuraClient from '../../../apollo/hasuraClient';
import type { ExtractedGenderEnum } from '../../../shared/gender-enum';

type Args = {
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string | null;
  birthday?: string | null;
  countryCode?: string | null;
  gender?: ExtractedGenderEnum | null;
};

const useEditPlayerInfo = (
  { userId, firstName, lastName, email, phoneNumber, birthday, countryCode, gender }: Args,
  onSuccess?: VoidFunction
) => {
  const [updating, setUpdating] = useState(false);

  const { emailValidator, emailIsValid, phoneNumberIsValid } = useValidators();
  const { showSuccess, showError } = useAlertMessage();

  const [updateUserMutation] = useMutation(UPDATE_USER_PROFILE, {
    client: hasuraClient,
  });

  const updateUserInfo = async () => {
    // Post-migration idea: input validation using webhooks
    try {
      setUpdating(true);
      // check email
      if (email) {
        await emailIsValid(email);

        const { isValid, message } = emailValidator;
        if (!isValid && message) throw new Error(message);
      }
      // check phone
      if (phoneNumber) {
        const { isValid, message } = await phoneNumberIsValid(phoneNumber, countryCode ?? '');
        if (!isValid && message) throw new Error(message);
      }
      // update input
      await updateUserMutation({
        variables: {
          userId,
          _set: {
            first_name: firstName,
            last_name: lastName,
            email: email ?? undefined,
            phone_number: phoneNumber ?? undefined,
            phone_country_code: countryCode ?? undefined,
            gender: gender ?? undefined,
            birthday: birthday ?? undefined,
          },
        },
      });

      showSuccess('Updated user info.');

      if (onSuccess) onSuccess();
    } catch (e) {
      showError(e);
    } finally {
      setUpdating(false);
    }
  };

  return { updating, updateUserInfo };
};

export default useEditPlayerInfo;
