import loadable from '@loadable/component';
import { Switch, Route } from 'react-router-dom';

const Staff_SelectProgram = loadable(
  () => import('../apps/Staff/SelectProgram/StaffSelectProgram')
);
const Staff_Program = loadable(() => import('../apps/Staff/StaffProgram/StaffProgramPage'));
const ErrorPage = loadable(() => import('../pages/ErrorPage'));

const StaffController = () => (
  <Switch>
    <Route exact path="/staff" component={Staff_SelectProgram} />
    <Route path="/staff/program/:id/:which_page" name="staff_program" component={Staff_Program} />
    <Route path="/staff/program/:id" name="staff_program" component={Staff_Program} />
    <Route component={ErrorPage} />
  </Switch>
);

export default StaffController;
