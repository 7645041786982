import { graphql } from 'graphql-schema';

const EDIT_STRIKE_COUNT = graphql(`
  mutation EditStrikeCount($userId: uuid!, $desiredStrikeCount: Int!) {
    editStrikeCount(userId: $userId, desiredStrikeCount: $desiredStrikeCount) {
      userId
    }
  }
`);

export default EDIT_STRIKE_COUNT;
