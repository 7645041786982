import { useState } from 'react';
import moment from 'moment-timezone';
import { useMutation } from '@apollo/client';
import isEmail from 'validator/lib/isEmail';
import isPostalCode from 'validator/lib/isPostalCode';
import {
  EMAIL_IS_VALID,
  USERNAME_IS_VALID,
  PHONE_NUMBER_IS_VALID,
  ZIP_IS_VALID,
} from './ONBOARDING_MUTATIONS';
import hasuraClient from '../../apollo/hasuraClient';

export type ValidatorType = {
  value: string;
  message?: string | null;
  isValid: boolean | undefined;
};

const useValidators = () => {
  const initialValidator: ValidatorType = {
    value: '',
    message: '',
    isValid: undefined,
  };

  const [validating, setValidating] = useState<Record<string, any>>({});
  const [emailValidator, setEmailValidator] = useState(initialValidator);
  const [usernameValidator, setUsernameValidator] = useState(initialValidator);
  const [zipValidator, setZipValidator] = useState(initialValidator);
  const [birthdayValidator, setBirthdayValidator] = useState(initialValidator);

  const [emailIsValidMutation] = useMutation(EMAIL_IS_VALID, { client: hasuraClient });
  const [usernameIsValidMutation] = useMutation(USERNAME_IS_VALID, { client: hasuraClient });
  const [phoneNumberIsValidMutation] = useMutation(PHONE_NUMBER_IS_VALID, { client: hasuraClient });
  const [zipIsValidMutation] = useMutation(ZIP_IS_VALID, { client: hasuraClient });

  const emailIsValid = async (email: string) => {
    setValidating({ email });
    setEmailValidator(initialValidator);

    if (!email || !isEmail(email)) {
      setEmailValidator({
        value: email,
        message: 'Please enter a valid email.',
        isValid: false,
      });
    } else {
      try {
        const { data } = await emailIsValidMutation({
          variables: { input: { email } },
        });
        if (!data?.emailIsValid) {
          throw new Error('Error validating email.');
        }
        setEmailValidator(data.emailIsValid);
      } catch (e) {
        console.error(e);
      }
    }
    setValidating({});
  };

  const usernameIsValid = async (username: string) => {
    setValidating({ username });
    setUsernameValidator(initialValidator);

    if (!username || username.length < 3) {
      setUsernameValidator({
        value: username,
        message: 'Username must be 3+ characters.',
        isValid: false,
      });
    } else {
      try {
        const { data } = await usernameIsValidMutation({
          variables: { input: { username } },
        });
        if (!data?.usernameIsValid) {
          throw new Error('Error validating username.');
        }
        setUsernameValidator(data.usernameIsValid);
      } catch (e) {
        console.error(e);
      }
    }
    setValidating({});
  };

  const phoneNumberIsValid = async (phoneNumber: string, countryCode: string) => {
    try {
      const { data } = await phoneNumberIsValidMutation({
        variables: { input: { phoneNumber, countryCode } },
      });
      if (!data?.phoneNumberIsValid) {
        throw new Error('Error validating phone number.');
      }
      return data.phoneNumberIsValid;
    } catch (e) {
      console.error(e);
    }
    return { isValid: false, message: 'Error validating phone number.' };
  };

  const zipIsValid = async (zip: string) => {
    setValidating({ zip });
    setZipValidator(initialValidator);
    if (!zip || !isPostalCode(zip, 'US')) {
      setZipValidator({
        value: zip,
        message: 'Please enter a valid zip code.',
        isValid: false,
      });
    } else {
      try {
        const { data } = await zipIsValidMutation({
          variables: { input: { zip } },
        });
        if (!data?.zipIsValid) {
          throw new Error('Error validating zip code.');
        }
        setZipValidator(data.zipIsValid);
      } catch (e) {
        console.error(e);
      }
    }
    setValidating({});
  };

  const birthdayIsValid = (birthday: string) => {
    setValidating({ birthday });
    setBirthdayValidator(initialValidator);

    const birthdayMoment = moment(
      birthday,
      ['MM/DD/YYYY', 'MM/D/YYYY', 'M/DD/YYYY', 'M/D/YYYY'],
      true
    );
    const isValidBirthday = birthdayMoment.isValid();

    if (!birthday || !isValidBirthday) {
      setBirthdayValidator({
        value: birthday,
        message: 'Please enter a valid date.',
        isValid: false,
      });
      return;
    }

    const age = moment().diff(birthdayMoment, 'year');

    if (age < 18) {
      setBirthdayValidator({
        value: birthday,
        message: 'You must be 18 or older to sign up.',
        isValid: false,
      });
    } else if (age > 90) {
      setBirthdayValidator({
        value: birthday,
        message: '🧐 hmm...',
        isValid: false,
      });
    } else {
      setBirthdayValidator({
        value: birthday,
        isValid: true,
      });
    }
    setValidating({});
  };

  const validateStartStep = async (email: string, username: string) => {
    await emailIsValid(email);
    await usernameIsValid(username);
  };

  return {
    emailIsValid,
    usernameIsValid,
    phoneNumberIsValid,
    zipIsValid,
    birthdayIsValid,
    validateStartStep,
    validating,
    emailValidator,
    usernameValidator,
    zipValidator,
    birthdayValidator,
  };
};

export default useValidators;
