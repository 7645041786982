import type { ExtractEnum } from 'graphql-schema';
import { orderBy } from './enum-helpers';
import getPerkIcon from '../utils/get-perk-icons';

export type PerkEnum = ExtractEnum<'perk_enum'>;

export const PerkEnum: { [K in PerkEnum]: K } = {
  all_teams_make_playoffs: 'all_teams_make_playoffs',
  bar_on_site: 'bar_on_site',
  bogo_pitchers: 'bogo_pitchers',
  bring_your_own_case: 'bring_your_own_case',
  city_wide_tournament_feeder: 'city_wide_tournament_feeder',
  dedicated_social_host: 'dedicated_social_host',
  discounted_volo_events: 'discounted_volo_events',
  dj_on_site: 'dj_on_site',
  dogs_welcome: 'dogs_welcome',
  flip_cup_count_in_standings: 'flip_cup_count_in_standings',
  free_street_parking: 'free_street_parking',
  indoor_space: 'indoor_space',
  licensed_referee: 'licensed_referee',
  lights: 'lights',
  near_public_transportation: 'near_public_transportation',
  new_facility: 'new_facility',
  new_perk: 'new_perk',
  outdoor_space: 'outdoor_space',
  parking_on_site: 'parking_on_site',
  popular_venue: 'popular_venue',
  referees: 'referees',
  reserved_tables: 'reserved_tables',
  sponsor_bar: 'sponsor_bar',
  sponsor_perks: 'sponsor_perks',
  street_parking: 'street_parking',
  t_shirt: 't_shirt',
  turf: 'turf',
  volo_exclusive_bar_deals: 'volo_exclusive_bar_deals',
  walking_distance_to_bar: 'walking_distance_to_bar',
  waterfront_view: 'waterfront_view',
} as const;

type PerkDetail = {
  key: PerkEnum;
  featureName: string;
  icon: string;
};

export enum VenuePerksEnum {
  all_teams_make_playoffs = 'all_teams_make_playoffs',
  bar_on_site = 'bar_on_site',
  bogo_pitchers = 'bogo_pitchers',
  bring_your_own_case = 'bring_your_own_case',
  city_wide_tournament_feeder = 'city_wide_tournament_feeder',
  dedicated_social_host = 'dedicated_social_host',
  discounted_volo_events = 'discounted_volo_events',
  dj_on_site = 'dj_on_site',
  dogs_welcome = 'dogs_welcome',
  flip_cup_count_in_standings = 'flip_cup_count_in_standings',
  free_street_parking = 'free_street_parking',
  indoor_space = 'indoor_space',
  licensed_referee = 'licensed_referee',
  lights = 'lights',
  near_public_transportation = 'near_public_transportation',
  new_facility = 'new_facility',
  new_perk = 'new_perk',
  outdoor_space = 'outdoor_space',
  parking_on_site = 'parking_on_site',
  popular_venue = 'popular_venue',
  referees = 'referees',
  reserved_tables = 'reserved_tables',
  sponsor_bar = 'sponsor_bar',
  sponsor_perks = 'sponsor_perks',
  street_parking = 'street_parking',
  t_shirt = 't_shirt',
  turf = 'turf',
  volo_exclusive_bar_deals = 'volo_exclusive_bar_deals',
  walking_distance_to_bar = 'walking_distance_to_bar',
  waterfront_view = 'waterfront_view',
}

export const VenuePerks: Readonly<Record<PerkEnum, PerkDetail>> = {
  [PerkEnum.all_teams_make_playoffs]: {
    key: PerkEnum.all_teams_make_playoffs,
    featureName: 'All Teams Make Playoffs',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/All+teams+make+playoffs.svg',
  },
  bar_on_site: {
    key: 'bar_on_site',
    featureName: 'Bar On Site',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Bar+on+site.svg',
  },
  bogo_pitchers: {
    key: 'bogo_pitchers',
    featureName: 'BOGO Pitchers',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/BOGO+Pitchers.svg',
  },
  bring_your_own_case: {
    key: 'bring_your_own_case',
    featureName: 'Bring Your Own Case (no glass)',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Bring+your+own+case+(no+glass).svg',
  },
  city_wide_tournament_feeder: {
    key: 'city_wide_tournament_feeder',
    featureName: 'City Wide Tournament Feeder',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/City+wide+tournament+feeder.svg',
  },
  dedicated_social_host: {
    key: 'dedicated_social_host',
    featureName: 'Dedicated Social Host',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Dedicated+social+hosts.svg',
  },
  discounted_volo_events: {
    key: 'discounted_volo_events',
    featureName: 'Discounted Volo Events',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Discounted+volo+events.svg',
  },
  dj_on_site: {
    key: 'dj_on_site',
    featureName: 'DJ on Site',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/DJ+on+Site.svg',
  },
  dogs_welcome: {
    key: 'dogs_welcome',
    featureName: 'Dogs Welcome',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Pets+Welcome.svg',
  },
  flip_cup_count_in_standings: {
    key: 'flip_cup_count_in_standings',
    featureName: 'Flip Cup Count in Standings',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Flip+cup+count+in+standings.svg',
  },
  free_street_parking: {
    key: 'free_street_parking',
    featureName: 'Free Street Parking',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Street+Parking.svg',
  },
  indoor_space: {
    key: 'indoor_space',
    featureName: 'Indoor Space',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Indoor+Space.svg',
  },
  licensed_referee: {
    key: 'licensed_referee',
    featureName: 'Licensed Referee',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Licensed+Referee.svg',
  },
  lights: {
    key: 'lights',
    featureName: 'Lights',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Lights.svg',
  },
  near_public_transportation: {
    key: 'near_public_transportation',
    featureName: 'Near Public Transportation',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Near+public+transportation.svg',
  },
  new_facility: {
    key: 'new_facility',
    featureName: 'New Facility',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/New+facility.svg',
  },
  new_perk: {
    key: 'new_perk',
    featureName: 'New Perk',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/New+perk.svg',
  },
  outdoor_space: {
    key: 'outdoor_space',
    featureName: 'Outdoor Space',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Outdoor+Space.svg',
  },
  parking_on_site: {
    key: 'parking_on_site',
    featureName: 'Parking on Site',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Parking+on+Site.svg',
  },
  popular_venue: {
    key: 'popular_venue',
    featureName: 'Popular Venue',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Popular+Venue.svg',
  },
  referees: {
    key: 'referees',
    featureName: 'Referees',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Referee.svg',
  },
  reserved_tables: {
    key: 'reserved_tables',
    featureName: 'Reserved Tables',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Researved+tables.svg',
  },
  sponsor_bar: {
    key: 'sponsor_bar',
    featureName: 'Sponsor Bar',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Sponsor+Bar.svg',
  },
  sponsor_perks: {
    key: 'sponsor_perks',
    featureName: 'Sponsor Perks',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Sponsored+perks.svg',
  },
  street_parking: {
    key: 'street_parking',
    featureName: 'Street Parking',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Street+Parking.svg',
  },
  t_shirt: {
    key: 't_shirt',
    featureName: 'T-Shirt',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Tshirt.svg',
  },
  turf: {
    key: 'turf',
    featureName: 'Turf',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Turf.svg',
  },
  volo_exclusive_bar_deals: {
    key: 'volo_exclusive_bar_deals',
    featureName: 'Volo Exclusive Bar Deals',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Volo+exclusive+bar+deals.svg',
  },
  walking_distance_to_bar: {
    key: 'walking_distance_to_bar',
    featureName: 'Walking Distance to Bar',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Walking+Distance+to+Bar.svg',
  },
  waterfront_view: {
    key: 'waterfront_view',
    featureName: 'Waterfront View',
    icon: 'https://rivall-public.s3.us-west-1.amazonaws.com/icons/feature-icons-2021/Waterfront+View+to+venue.svg',
  },
} as const;

export const PerkPriorityEnum = Object.freeze({
  flip_cup_count_in_standings: 0,
  dj_on_site: 1,
  bar_on_site: 2,
  turf: 3,
  licensed_referee: 4,
  parking_on_site: 5,
  waterfront_view: 6,
});

export const getPerkDetails = (perk: PerkEnum) => ({
  ICON: getPerkIcon(perk),
  LABEL: VenuePerks?.[perk]?.featureName,
});

export default orderBy({
  collection: VenuePerks,
  key: 'featureName',
});

export enum VenueTypeEnum {
  BAR = 'bar',
  CLUB = 'club',
  GYM = 'gym',
  PARK = 'park',
  POOL = 'pool',
  RESTAURANT = 'restaurant',
  SCHOOL = 'school',
  WATERFRONT = 'waterfront',
  OTHER_PRIVATE = 'other (private)',
  OTHER_PUBLIC = 'other (public)',
}
