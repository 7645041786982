import { graphql, type ResultOf } from 'graphql-schema';

/**
 * This type isn't implemented in the backend yet.
 * Leaving this in for post-migration TODO.
 */
// type PlayerNoteType =
//   | 'Mobile Comment Archive'
//   | 'call'
//   | 'email'
//   | 'note'
//   | 'DEACTIVATE'
//   | 'ACTIVATE';

export type NoteData = ResultOf<typeof GET_USER_NOTES>['notes'][number];
export const GET_USER_NOTES = graphql(`
  query userNotes($userId: uuid!) {
    notes(where: { user: { _eq: $userId } }, order_by: { updated_at: desc }) {
      _id
      type
      note
      userByCreatedBy {
        full_name
      }
      updated_at
    }
  }
`);

export const ADD_USER_NOTE = graphql(`
  mutation userNoteAdd($userId: uuid!, $type: String, $note: String) {
    insert_notes_one(object: { user: $userId, type: $type, note: $note }) {
      _id
      type
      note
    }
  }
`);

export const EDIT_USER_NOTE = graphql(`
  mutation userNoteEdit($_id: uuid!, $type: String, $note: String) {
    update_notes_by_pk(pk_columns: { _id: $_id }, _set: { type: $type, note: $note }) {
      _id
      type
      note
    }
  }
`);

export const DELETE_USER_NOTE = graphql(`
  mutation userNoteDelete($_id: uuid!) {
    delete_notes_by_pk(_id: $_id) {
      _id
    }
  }
`);
