import { graphql } from 'graphql-schema';

const GET_HASURA_PROGRAM_DETAILS = graphql(`
  query GetProgramDetails($id: uuid!) {
    leagues_by_pk(_id: $id) {
      _id
      display_name
      program_type
      start_date
      is_premier
      is_volo_pass_exclusive
      start_time_estimate
      end_time_estimate
      banner_text
      header
      num_weeks_estimate
      num_playoff_weeks_estimate
      sportBySport {
        _id
        name
      }
      registrants_aggregate {
        aggregate {
          count
        }
      }
      registrationByRegistration {
        _id
        max_registration_size
        min_registration_size
      }
      neighborhoodByNeighborhood {
        _id
        name
      }
      venueByVenue {
        _id
        shorthand_name
        formatted_address
      }
      organizationByOrganization {
        _id
        is_volo_pass_active
      }
    }
  }
`);

export default GET_HASURA_PROGRAM_DETAILS;
