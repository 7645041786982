import { graphql, type ResultOf } from 'graphql-schema';

export type UserDetails = NonNullable<ResultOf<typeof GET_USER>['users_by_pk']>;

export const GET_USER = graphql(`
  query getUser($userId: uuid!) {
    users_by_pk(_id: $userId) {
      _id
      has_volo_pass
      membership_expires
      first_name
      last_name
      full_name
      gender
      email
      phone_number
      phone_country_code
      profile_picture
      created_at
      birthday
      league_count
      username
      deactivated
      roles {
        role
      }
      organizationByHomeOrganization {
        _id
        name
      }
      subscriptions(where: { type: { _eq: VOLO_PASS } }) {
        status
        start_date
        end_date
        interval
        membershipOrganization: organizationByOrganization {
          _id
          name
        }
      }
    }
  }
`);

export const WAIVER_QUERY = graphql(`
  query waiver($_id: uuid!) {
    waivers_by_pk(_id: $_id) {
      _id
      name
    }
  }
`);
