import { forwardRef } from 'react';
import type { ResultOf } from 'graphql-schema';
import { ColorIndicator } from '../../../components';
import type { LEAGUE_STANDINGS_QUERY } from './LEAGUE_STANDINGS_QUERY';

type Props = NonNullable<ResultOf<typeof LEAGUE_STANDINGS_QUERY>['leagues_by_pk']>;

const LeagueTeamPrintView = forwardRef<HTMLDivElement, Props>(({ standings }, ref) => {
  return (
    <div ref={ref} className="m-2">
      {standings.map(({ teamByTeam: team }) => {
        if (!team) return null;
        return (
          <div key={team._id}>
            <h2 style={{ margin: '30px 0 10px' }}>
              {team.name}
              {team.color_name ? (
                <ColorIndicator
                  color={team.color_hex}
                  style={{ height: '15px', width: '15px', margin: '0 -2px 0 5px' }}
                />
              ) : null}
              <span className="ml-2" style={{ fontSize: '14px', color: '#b4b6b6' }}>
                ({team.color_name}){' '}
              </span>
            </h2>
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Zip</th>
                  <th>Birthday</th>
                  <th>Shirt Size</th>
                  <th>Gender</th>
                </tr>
              </thead>
              <tbody>
                {team.registrants?.map(({ userByUser: user, ...r }) => (
                  <tr key={r._id}>
                    <td>{user.first_name || ''}</td>
                    <td>{user.last_name || ''}</td>
                    <td>{user.zip || ''}</td>
                    <td>{user.birthday || ''}</td>
                    <td>{user.shirt_size || ''}</td>
                    <td>{user.gender || ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
});

export default LeagueTeamPrintView;
