/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, type FC } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import styles from '../../player-details.module.scss';
import { Button } from '../../../../components';
import { MembershipInterval, MembershipStatus } from '../../../../shared/membership-enums';
import chevronDown from '../../../../images/home/chevron-down.png?as=webp';
import chevronLeft from '../../../../images/home/chevron-left.png?as=webp';
import type { UserDetails } from '../../PLAYER_DETAILS_QUERIES';

const cx = classNames.bind(styles);

type PlayerInfoDisplayProps = {
  userDetails: UserDetails;
  setUpdatingInfo: (isUpdating: boolean) => void;
};

const PlayerInfoDisplay: FC<PlayerInfoDisplayProps> = props => {
  const { userDetails, setUpdatingInfo } = props;

  const {
    first_name,
    last_name,
    email,
    username,
    phone_number,
    birthday,
    gender,
    organizationByHomeOrganization,
    created_at,
    subscriptions,
  } = userDetails;

  const [collapsed, setCollapsed] = useState(true);

  const subscription = subscriptions[0];

  return (
    <div className={cx('noEdit')}>
      <label>First Name</label>
      <div>{first_name}</div>
      <label>Last Name</label>
      <div>{last_name}</div>
      <label>Email</label>
      <div>{email}</div>
      <label>Username</label>
      <div>{username}</div>
      <label>Phone</label>
      <div>{phone_number}</div>
      <label>Birthday</label>
      <div>{birthday}</div>
      <label>Gender</label>
      <div>{gender?.toUpperCase()}</div>
      <label>Registration Date</label>
      <div>{moment(created_at).format('MM/DD/YYYY h:mm a')}</div>
      <label style={{ display: 'flex', alignItems: 'center' }}>
        Volo Pass Status{' '}
        <img
          onClick={() => setCollapsed(!collapsed)}
          onKeyDown={() => setCollapsed(!collapsed)}
          height="35px"
          src={!collapsed ? chevronDown : chevronLeft}
          alt="toggle collapse Volo Pass details"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
        />
      </label>
      <div>{subscription?.status ? MembershipStatus[subscription.status].label : 'None'}</div>
      {!collapsed && (
        <>
          <label>Start Date</label>
          <div>
            {subscription?.start_date
              ? moment(subscription.start_date).format('MM/DD/YYYY h:mm a')
              : '-'}
          </div>
          <label>End Date</label>
          <div>
            {subscription?.end_date
              ? moment(subscription.end_date).format('MM/DD/YYYY h:mm a')
              : '-'}
          </div>
          <label>Subscription Type</label>
          <div>
            {subscription?.interval ? MembershipInterval[subscription.interval]!.label : 'None'}
          </div>
          <label>Membership City</label>
          <div>
            {subscription?.membershipOrganization?.name ??
              organizationByHomeOrganization?.name ??
              'No metadata or home city found'}
          </div>
        </>
      )}
      <Button primary square className={cx('actionButton')} onClick={() => setUpdatingInfo(true)}>
        Edit Player Details
      </Button>
    </div>
  );
};

export default PlayerInfoDisplay;
