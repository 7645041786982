import { graphql } from 'graphql-schema';
// queries are `prefetching` what is needed for the discover cards
// - will help with pull to refresh on mobile as the data will be refetched & updated in the cache
// at the discover level queries as well
const LeagueDetails = graphql(`
  fragment LeagueDetails on leagues @_unmask {
    _id
    name
    display_name
    program_type
    start_date
    is_premier
    is_volo_pass_exclusive
    start_time_estimate
    end_time_estimate
    banner_text
    header
    num_weeks_estimate
    num_playoff_weeks_estimate
    sportBySport {
      _id
      name
    }
    registrants_aggregate {
      aggregate {
        count
      }
    }
    registrationByRegistration {
      _id
      max_registration_size
      min_registration_size
    }
    neighborhoodByNeighborhood {
      _id
      name
    }
    venueByVenue {
      _id
      shorthand_name
      formatted_address
    }
    organizationByOrganization {
      _id
      is_volo_pass_active
    }
  }
`);
export const GET_HASURA_DISCOVER_DAILY = graphql(`
    query DiscoverDaily($where: discover_daily_bool_exp!, $limit: Int = 15, $offset: Int = 0) {
      discover_daily(
        where: $where
        order_by: [
          { event_start_date: asc }
          { event_start_time_str: asc }
          { event_end_time_str: asc }
        ]
        limit: $limit
        offset: $offset
      ) {
        _id
        game_id
        game {
          _id
          start_time
          end_time
          venueByVenue {
            _id
            shorthand_name
            formatted_address
            neighborhoodByNeighborhoodId {
              _id
              name
            }
          }
          drop_in_capacity {
            _id
            total_available_spots
          }
          leagueByLeague {
            _id
            program_type
            sportBySport {
              _id
              name
            }
          }
        }
        league_id
        league {
          ...LeagueDetails
        }
        event_start_date
        event_start_time_str
        event_end_time_str
      }

      discover_daily_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `, [LeagueDetails]);
export const GET_HASURA_PROGRAMS = graphql(`
    query DiscoverPrograms($where: leagues_bool_exp!, $limit: Int = 15, $offset: Int = 0) {
      leagues(
        where: $where
        limit: $limit
        offset: $offset
        order_by: [
          { featured_order: asc }
          { start_date: asc }
          { start_time_estimate: asc }
          { end_time_estimate: asc }
        ]
      ) {
        ...LeagueDetails
      }

      leagues_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `, [LeagueDetails]);
