import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { ActivityIndicator } from 'react-native';

import { View, Select } from '../../../base-components';
import GET_ORGANIZATIONS_QUERY from '../graphql/ORGANIZATION_LIST';
import styles from '../styles';

export type CityFilterType = {
  cityName?: string | undefined;
  onPress: (arg: string) => void;
};

const CityFilter = ({ cityName = 'No City Selected', onPress }: CityFilterType) => {
  const { data, loading } = useQuery(GET_ORGANIZATIONS_QUERY);

  const orgOptions = useMemo(() => {
    const organizationList = data?.organizations ?? [];
    const organizationOptions = organizationList?.map(({ _id, name }) => ({
      value: _id,
      label: name,
    }));

    return organizationOptions?.map((option: { value: string; label: string }) => (
      // evenutally will switch to _id for values, but would need to update entire store
      <Select.Item key={option.value} value={option.label} label={option.label} />
    ));
  }, [data]);

  const onValueChange = (value: string) => onPress(value);

  if (loading) return <ActivityIndicator />;

  return (
    <View style={[styles.container, styles.cityContainer]}>
      <View style={styles.cityRow}>
        <Select selectedValue={cityName} onValueChange={onValueChange} flex={1}>
          {orgOptions}
        </Select>
      </View>
    </View>
  );
};

export default CityFilter;
