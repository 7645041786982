import { graphql, type ResultOf } from 'graphql-schema';

export const SCHEDULE_DAY_FRAGMENT = graphql(`
  fragment ScheduleDayFragment on days @_unmask {
    _id
    date
    is_tournament
    week
  }
`);

export const SCHEDULE_TEAM_FRAGMENT = graphql(`
  fragment ScheduleTeamFragment on teams @_unmask {
    _id
    color_hex
    color_name
    is_volo_pass_team
    name
  }
`);

export const SCHEDULE_GAME_FRAGMENT = graphql(
  `
    fragment ScheduleGameFragment on games @_unmask {
      _id
      day
      field_name
      start_time
      scores {
        _id
        forfeit
        score
        team: teamByTeam {
          ...ScheduleTeamFragment
        }
      }
      teams {
        _id
        team: teamByTeam {
          ...ScheduleTeamFragment
        }
      }
      venue: venueByVenue {
        _id
        shorthand_name
      }
    }
  `,
  [SCHEDULE_TEAM_FRAGMENT]
);

export const SCHEDULE_WEEK_FRAGMENT = graphql(
  `
    fragment ScheduleWeekFragment on weeks @_unmask {
      _id
      is_tournament
      league
      notes
      week_num
      week_tag
      byes: bye_teams {
        _id
        team: teamByTeam {
          ...ScheduleTeamFragment
        }
      }
    }
  `,
  [SCHEDULE_TEAM_FRAGMENT]
);

export const GET_SCHEDULE_DETAILS = graphql(
  `
    query getScheduleDetails($leagueWhereClause: leagues_bool_exp!) {
      leagues(where: $leagueWhereClause, limit: 1) {
        _id
        external_id
        end_time_estimate
        game_length_in_minutes_estimate
        num_games_per_team_per_week_estimate
        num_playoff_weeks_estimate
        num_weeks_estimate
        schedule_approved
        start_date
        start_time_estimate
        teams_approved
        timezone
        days_of_weeks {
          _id
          day_of_week
        }
        days(order_by: { date: asc }) {
          ...ScheduleDayFragment
        }
        games(order_by: { start_time: asc }) {
          ...ScheduleGameFragment
        }
        organization
        registration: registrationByRegistration {
          _id
          registration_close_date
          registration_open_date
        }
        subschedules(where: { schedule_type: { _eq: league } }) {
          _id
          start_date
        }
        teams {
          ...ScheduleTeamFragment
        }
        tournaments {
          _id
        }
        venue: venueByVenue {
          _id
          shorthand_name
          timezone
        }
        week_descriptions {
          _id
          description
        }
        week_tags {
          _id
          week_tag
        }
        weeks(order_by: { week_num: asc }) {
          ...ScheduleWeekFragment
        }
      }
    }
  `,
  [SCHEDULE_DAY_FRAGMENT, SCHEDULE_GAME_FRAGMENT, SCHEDULE_TEAM_FRAGMENT, SCHEDULE_WEEK_FRAGMENT]
);

export type LeagueData = ResultOf<typeof GET_SCHEDULE_DETAILS>['leagues'][number];

export const CREATE_DAY = graphql(`
  mutation createDay($object: days_insert_input!) {
    insert_days_one(object: $object) {
      _id
    }
  }
`);

export const UPDATE_DAY = graphql(`
  mutation updateDay($input: UpdateDayInput!) {
    updateDay(input: $input) {
      day
    }
  }
`);

export const DELETE_DAY = graphql(`
  mutation deleteDay($dayId: uuid!) {
    deleteDay(dayId: $dayId) {
      success
    }
  }
`);

export const CREATE_WEEK = graphql(
  `
    mutation createWeek($object: weeks_insert_input!) {
      insert_weeks_one(object: $object) {
        ...ScheduleWeekFragment
      }
    }
  `,
  [SCHEDULE_WEEK_FRAGMENT]
);

export const UPDATE_WEEK = graphql(`
  mutation updateWeek($weekId: uuid!, $update: weeks_set_input!) {
    update_weeks_by_pk(pk_columns: { _id: $weekId }, _set: $update) {
      _id
      # Fetch these fields for cache updates
      notes
      week_tag
    }
  }
`);

export const DELETE_WEEK = graphql(`
  mutation deleteWeek($weekId: uuid!) {
    deleteWeek(weekId: $weekId) {
      success
    }
  }
`);

export const DELETE_GAME = graphql(`
  mutation deleteGame($gameId: uuid!) {
    deleteGame(gameId: $gameId) {
      success
    }
  }
`);

export const GET_APPROVED_VENUES = graphql(`
  query getApprovedVenues($orgId: uuid!) {
    venues(where: { organization: { _eq: $orgId } }) {
      _id
      shorthand_name
    }
  }
`);

export const UPSERT_SCHEDULE = graphql(`
  mutation upsertSchedule(
    $object: subschedules_insert_input!
    $conflict: subschedules_on_conflict!
    $clearExisting: Boolean!
    $subscheduleId: uuid!
  ) {
    delete_subschedule_timeslots(where: { subschedule: { _eq: $subscheduleId } }) {
      affected_rows
    }
    delete_subschedule_teams(where: { subschedule: { _eq: $subscheduleId } })
      @include(if: $clearExisting) {
      affected_rows
    }
    delete_subschedule_venues(where: { subschedule: { _eq: $subscheduleId } })
      @include(if: $clearExisting) {
      affected_rows
    }
    insert_subschedules_one(object: $object, on_conflict: $conflict) {
      _id
    }
  }
`);

export const CREATE_SCHEDULE_GAMES = graphql(`
  mutation createScheduleGames(
    $subscheduleId: uuid!
    $datesAndTimes: ActionsCreateFlowLeagueUpdateDatesTimesInput!
  ) {
    createScheduleGames(subscheduleId: $subscheduleId) {
      subschedule
    }
    leagueUpdateDatesTimes(input: $datesAndTimes) {
      _id
    }
  }
`);

export const RELEASE_SCHEDULE = graphql(`
  mutation releaseSchedule($programId: uuid!) {
    update_subschedules(
      where: { league: { _eq: $programId } }
      _set: { subschedule_approved: true }
    ) {
      affected_rows
      returning {
        _id
        updated_by
        subschedule_approved
      }
    }
    update_leagues_by_pk(pk_columns: { _id: $programId }, _set: { schedule_approved: true }) {
      _id
      schedule_approved
      updated_by
    }
    update_teams(
      where: { league: { _eq: $programId }, is_volo_pass_team: { _eq: true } }
      _set: { schedule_enabled: true }
    ) {
      affected_rows
      returning {
        _id
        updated_by
        schedule_enabled
      }
    }
    # TODO create drop in slots for the vp teams
    # could that be a triggered function execution?
    # TODO Team chats
  }
`);

export const UNRELEASE_SCHEDULE = graphql(`
  mutation unreleaseSchedule($programId: uuid!) {
    update_subschedules(
      where: { league: { _eq: $programId } }
      _set: { subschedule_approved: false }
    ) {
      affected_rows
      returning {
        _id
        updated_by
        subschedule_approved
      }
    }
    update_leagues_by_pk(pk_columns: { _id: $programId }, _set: { schedule_approved: false }) {
      _id
      schedule_approved
      updated_by
    }
    update_teams(
      where: { league: { _eq: $programId }, is_volo_pass_team: { _eq: true } }
      _set: { schedule_enabled: false }
    ) {
      affected_rows
      returning {
        _id
        updated_by
        schedule_enabled
      }
    }
  }
`);

export const CHECK_SCHEDULE_COUNTS = graphql(`
  query checkScheduleCounts($subscheduleIds: [uuid!]!, $leagueId: uuid!) {
    checkScheduleCounts(subscheduleIds: $subscheduleIds, leagueId: $leagueId) {
      opponentCounts {
        teamId
        teamName
        opponents {
          opponentId
          opponentName
          count
        }
      }
      gameTimeCounts {
        teamId
        teamName
        startTimes {
          startTime
          count
        }
      }
      venueCounts {
        teamId
        teamName
        venues {
          venueId
          venueName
          subvenue
          count
        }
      }
    }
  }
`);

export const CREATE_CSV_SCHEDULE = graphql(`
  mutation createCSVSchedule($input: CreateCSVScheduleInput!) {
    createCSVSchedule(input: $input) {
      subschedule
    }
  }
`);
