/* eslint-disable react/destructuring-assignment */
import React, { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { PaymentForm } from '../../custom-components';
import {
  DonationFrequencyEnum,
  MembershipEnum,
  Memberships,
  ProgramTypeEnum,
} from '../../constants';
import getLineItemsFromBreakdown from './utils/getLineItemsFromBreakdown';
import useProgramPricing from './hooks/useProgramPricing';
import MembershipConfirmationMessage from './components/MembershipConfirmationMessage';
import getMembershipDescription from './utils/getMembershipDescription';
import type usePaymentSummary from './hooks/usePaymentSummary';
import PaymentSummaryLoading from './PaymentSummaryLoading';
import PaymentSummaryError from './PaymentSummaryError';
import { filterNullOrUndefined } from '../../utilities';

export type LeagueRegistrationVariant = 'PREPAID_TEAM_CAPTAIN' | 'PREPAID_TEAM_MEMBER';

export type PaymentSummaryProps = {
  /**
   * The league associated with the purchase
   */
  leagueId: string;

  /**
   * The props returned by `usePaymentSummary`
   */
  usePaymentSummaryProps: ReturnType<typeof usePaymentSummary>;

  /**
   * Override which theme is shown. This is needed specifically for Web daily registration, as the
   * dark mode appearance is shown despite web being in light mode.
   */
  themeOverride?: 'light' | 'dark';

  /**
   * The promo code the user wants to be applied. This will be reflected in the pricing total, though
   * the consumer of the component is responsible for displaying the actual savings.
   */
  promoCodeStr?: string;

  /**
   * The amount in cents that the user wants to apply in credits. The result in the line items may
   * be different if the total cost is less than the amount supplied.
   */
  creditsAppliedInCents?: number;

  /**
   * The amount in cents that the user wants to donate to the Volo Kids foundation.
   */
  donationsAppliedInCents?: number;

  /**
   * The frequency of the Volo Kids donation.
   */
  donationFrequency?: DonationFrequencyEnum;

  /**
   * A React element that displays the promo code form, along with the amount saved with their
   * applied promo
   */
  promoCodeComponent: React.ReactNode;

  /**
   * A React element that displays the terms and conditions for Volo Pass when clicked. This will
   * be displayed inline with text, so make sure the component looks good in that context.
   */
  voloPassTermsAndConditions: React.ReactNode;

  /**
   * Optional callback when the pricing selected changes.
   */
  onChangePricing?: (pricing: Pricing) => any;
} & (
  | {
      isDropIn: false;

      /**
       * Special cases re: registration. The following options are:
       *  * `PREPAID_TEAM_CAPTAIN`: User is paying for the registration cost of an entire team
       *  * `PREPAID_TEAM_MEMBER`: User is joining a prepaid team
       */
      registrationVariant?: LeagueRegistrationVariant;
    }
  | {
      isDropIn: true;
    }
);

export type Pricing = 'REGULAR' | MembershipEnum.VOLO_PASS;

const PaymentSummary = (props: PaymentSummaryProps) => {
  const {
    isDropIn,
    usePaymentSummaryProps: {
      pricingSelected,
      setPricingSelected,
      areMemberTermsAgreed,
      setAreMemberTermsAgreed,
      userIsDonatingRegistrationCost,
      setUserIsDonatingRegistrationCost,
    },
    themeOverride,
    promoCodeComponent,
    voloPassTermsAndConditions,
    donationFrequency = DonationFrequencyEnum.ONCE,
    onChangePricing,
  } = props;

  const {
    appliedPricingBreakdown,

    monthlyCostOfVoloPassInCents,
    voloPassFeesInCents,

    isVoloPassMember,
    voloPassInCart,
    programHasVoloPassPricing,

    programHasMemberPricing,
    program_type,
    is_volo_pass_exclusive,
    canDonateAtNoCost,
  } = useProgramPricing(props);

  // We're really just abusing `useState` here to give us the original value of this state before
  // it changes as you switch between each of the pricing options
  const [defaultBreakdown] = useState(
    appliedPricingBreakdown.membershipDiscountApplied ?? 'REGULAR'
  );

  const { lineItems, total } = getLineItemsFromBreakdown({
    ...appliedPricingBreakdown,
    donationFrequency,
    voloPassInCart,
    monthlyCostOfVoloPassInCents,
    voloPassFeesInCents,
    userIsDonatingRegistrationCost,
    registrationVariant: 'registrationVariant' in props ? props.registrationVariant : undefined,
  });

  const { originalPrice, memberPrice } = appliedPricingBreakdown;

  const voloPassDescription = getMembershipDescription({
    membership: MembershipEnum.VOLO_PASS,
    monthlyPriceOfVoloPassInCents: monthlyCostOfVoloPassInCents ?? 0,
    // @ts-expect-error program_type can technically be undefined if the league isn't found
    programType: isDropIn ? ProgramTypeEnum.DROPIN : program_type,
    savingsInCents:
      typeof originalPrice === 'number' && typeof memberPrice === 'number'
        ? originalPrice - memberPrice
        : 0,
  });

  const handlePricingChange = (value: string) => {
    const enumValue = MembershipEnum[value as MembershipEnum];

    // No change
    if (enumValue === pricingSelected) {
      return;
    }

    // A single variable controls both VF and VP, so reset every change to make the user re-agree
    setAreMemberTermsAgreed(false);
    if (enumValue) {
      setPricingSelected(enumValue as Pricing);
      onChangePricing?.(enumValue as Pricing);
    } else {
      setPricingSelected('REGULAR');
      onChangePricing?.('REGULAR');
    }
  };

  /**
   * If the program has NO membership pricing, or the membership pricing for at least one of the
   * pricing types happens to be the same as the original price, show a different copy to avoid
   * implying there would be a discount.
   *
   * If this option is FALSE, the radios should be:
   *   * Non-member Price
   *   * Volo Pass Member Price (if it has one)
   *   * Volo Fitness Member Price (if it has one)
   *
   * If this option is TRUE, the radios should be:
   *   * Registration
   *   * Registration With Volo Pass (if it has a VP price)
   *   * Registration With Volo Fitness (if it has a VF Price)
   */
  const shouldDisplayAlternativeCopy =
    !programHasMemberPricing || (programHasVoloPassPricing && originalPrice === memberPrice);

  const shouldShowVoloPassDescription =
    !isVoloPassMember &&
    pricingSelected !== MembershipEnum.VOLO_PASS &&
    !shouldDisplayAlternativeCopy;

  return (
    <PaymentForm themeOverride={themeOverride}>
      <PaymentForm.LineItemGroup
        value={pricingSelected}
        defaultValue={defaultBreakdown}
        onChange={handlePricingChange}
        items={
          'registrationVariant' in props && props.registrationVariant === 'PREPAID_TEAM_MEMBER'
            ? [
                {
                  value: 'REGULAR',
                  name: 'Registration',
                  priceInCents: 0,
                  _text: { fontWeight: 'semibold' },
                },
              ]
            : [
                typeof originalPrice === 'number' &&
                  !is_volo_pass_exclusive && {
                    value: 'REGULAR',
                    name: shouldDisplayAlternativeCopy ? 'Registration' : 'Non-Member Price', // On events with no member pricing, show generic "Registration" copy.
                    priceInCents: originalPrice,
                    ...(userIsDonatingRegistrationCost
                      ? {
                          originalPriceInCents: originalPrice + 100,
                        }
                      : {}),
                    isDisabled: !!isVoloPassMember && !!programHasVoloPassPricing,
                    _text: { fontWeight: 'semibold' },
                  },

                programHasVoloPassPricing && {
                  value: MembershipEnum.VOLO_PASS,
                  name: shouldDisplayAlternativeCopy
                    ? `Registration With ${Memberships[MembershipEnum.VOLO_PASS].name}`
                    : `${Memberships[MembershipEnum.VOLO_PASS].name} Member Price`,
                  description: shouldShowVoloPassDescription ? voloPassDescription : undefined,
                  priceInCents: memberPrice!, // Non-null is checked in `useProgramPricing`
                  originalPriceInCents: originalPrice ?? undefined,
                  isDisabled: false,
                  messageIfFormInvalid: 'Please agree to the terms and conditions.',
                  _text: { fontWeight: 'semibold' },

                  // Props related to the Terms and Conditions box. These are ignored if the user is
                  // already a Volo Pass member as they have already agreed to the terms and conditions.
                  ...(!isVoloPassMember
                    ? {
                        confirmationMessage: (
                          <MembershipConfirmationMessage
                            membership={MembershipEnum.VOLO_PASS}
                            monthlyPriceInCents={monthlyCostOfVoloPassInCents!}
                            termsAndConditionsComponent={voloPassTermsAndConditions}
                          />
                        ),
                        isConfirmationMessageChecked: areMemberTermsAgreed,
                        onConfirmationMessagePress: (confirmation: boolean) =>
                          setAreMemberTermsAgreed(Boolean(confirmation)),
                      }
                    : {}),
                },
              ].filter(filterNullOrUndefined)
        }
      />

      {canDonateAtNoCost && (
        <PaymentForm.Checkbox
          value="Donate a portion of your registration cost to the Volo Kids Foundation"
          isDisabled={!canDonateAtNoCost}
          isChecked={userIsDonatingRegistrationCost}
          onChange={isSelected => setUserIsDonatingRegistrationCost(isSelected)}
        >
          Donate $1.00 of my registration fee to the Volo Kids Foundation at no extra cost to me.
        </PaymentForm.Checkbox>
      )}

      {lineItems.length > 0 && <PaymentForm.Divider />}

      {lineItems.map(lineItem => (
        <PaymentForm.LineItem key={lineItem.name} {...lineItem} />
      ))}

      <PaymentForm.Divider />
      {promoCodeComponent}
      {promoCodeComponent && <PaymentForm.Divider />}

      <PaymentForm.LineItem {...total} _text={{ fontWeight: 'bold' }} />
    </PaymentForm>
  );
};

const PaymentSummaryWrapper = (props: PaymentSummaryProps) => (
  <ErrorBoundary FallbackComponent={PaymentSummaryError}>
    <Suspense fallback={<PaymentSummaryLoading {...props} />}>
      <PaymentSummary {...props} />
    </Suspense>
  </ErrorBoundary>
);

PaymentSummaryWrapper.Loading = PaymentSummaryLoading;

export default PaymentSummaryWrapper;
