import { useQuery } from '@apollo/client';

import HASURA_DISCOVER_COMPETITION_LEVELS, {
  type HasuraDiscoverCompetitionLevels,
} from '../graphql/DISCOVER_SKILL_LEVELS';
import { View, Text } from '../../../base-components';
import { CustomButton } from '../../../custom-components';

import styles from '../styles';

export type SkillLevelFilterType = {
  cityName?: string;
  onPress: (
    skillLevel: HasuraDiscoverCompetitionLevels['league_competition_levels'][number]['competition_level']
  ) => void;
  selectedSkillLevels: string[];
};

const SkillLevelFilter = ({
  onPress,
  cityName,
  selectedSkillLevels = [],
}: SkillLevelFilterType) => {
  const { data, error } = useQuery(HASURA_DISCOVER_COMPETITION_LEVELS, {
    skip: !cityName,
    fetchPolicy: 'cache-and-network',
    variables: { cityName: cityName! },
  });

  if (error) {
    return (
      <View>
        <Text>{error.message}</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        {data?.league_competition_levels?.map(level => (
          <CustomButton
            key={level._id}
            value={level.competition_level}
            variant="outline"
            onPress={onPress}
            pressed={selectedSkillLevels.includes(level.competition_level)}
            style={styles.button}
          />
        ))}
      </View>
    </View>
  );
};

export default SkillLevelFilter;
