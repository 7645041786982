import { graphql } from 'graphql-schema';

export const JOIN_PREPAID_TEAM = graphql(`
  mutation joinPrepaidTeam($input: JoinPrepaidTeamInput!) {
    joinPrepaidTeam(input: $input) {
      registrantId
    }
  }
`);

export const ADD_PAYMENT_SOURCE = graphql(`
  mutation addUserPaymentSource($tokenId: String!) {
    addUserPaymentSource(tokenId: $tokenId) {
      success
    }
  }
`);

export const CHECK_PASSWORD = graphql(`
  query checkGroupPassword($input: CheckGroupPasswordInput!) {
    checkGroupPassword(input: $input)
  }
`);
