import {} from 'graphql-schema';
import { getLeagueStatusesFromStage } from '../../leagues/program-status-helpers.js';
export const formatMessageCenterUsersBoolExp = (args) => {
    const { organizationId, genders, players, isMarketing, voloPassOnly, omitEmailAddresses, membershipStatus, userPreferenceDays, userPreferenceLocations, userPreferenceSports, league, leagueIds, teamIds, sports, stages, teamRoles, registrantTypes, neighborhoods, venues, seasons, programTypes, whyYouPlaySelections, leagueStartDateMaximum, leagueStartDateMinimum, rental, reservationDates, reservationMinDate, reservationMaxDate, rentalRegistrantKinds,
    // TODO
    // subscriptionType,
    // membershipDuration,
    // membershipDurationHigh,
    // membershipDurationInterval,
    // membershipDurationLow,
     } = args;
    const isFilteringAgainstLeagues = Boolean(sports?.length ||
        stages?.length ||
        neighborhoods?.length ||
        venues?.length ||
        seasons?.length ||
        programTypes?.length ||
        leagueStartDateMaximum ||
        leagueStartDateMinimum);
    return {
        ...(omitEmailAddresses?.length ? { email: { _nin: omitEmailAddresses } } : {}),
        ...(genders?.length ? { gender: { _in: genders } } : {}),
        ...(voloPassOnly ? { has_volo_pass: { _eq: true } } : {}),
        ...(isMarketing
            ? {
                notification_preferences: {
                    marketing_email: { _eq: true },
                },
            }
            : {}),
        ...(players?.length ? { _id: { _in: players } } : {}),
        ...(userPreferenceDays?.length ? { interest_days: { day: { _in: userPreferenceDays } } } : {}),
        ...(userPreferenceLocations?.length
            ? { interest_organizations: { organization: { _in: userPreferenceLocations } } }
            : {}),
        ...(userPreferenceSports?.length
            ? { interest_sports: { sport: { _in: userPreferenceSports } } }
            : {}),
        ...(membershipStatus?.length
            ? {
                volo_pass_subscription_status: membershipStatus.includes('none')
                    ? { _is_null: true }
                    : { _in: membershipStatus.filter(m => m !== 'none') },
            }
            : {}),
        ...(teamIds?.length
            ? {
                team_players: {
                    team: { _in: teamIds },
                },
            }
            : {}),
        ...(rental
            ? {
                rental_registrants: {
                    rental: { _eq: rental },
                    organization: { _eq: organizationId },
                    ...(rentalRegistrantKinds?.length ? { kind: { _in: rentalRegistrantKinds } } : {}),
                    ...(reservationMinDate || reservationMaxDate || reservationDates?.length
                        ? {
                            rental_reservation: {
                                date_string: {
                                    ...(reservationDates?.length ? { _in: reservationDates } : {}),
                                    ...(reservationMinDate ? { _gte: reservationMinDate } : {}),
                                    ...(reservationMaxDate ? { _lte: reservationMaxDate } : {}),
                                },
                            },
                        }
                        : {}),
                },
            }
            : {
                registrants: {
                    organization: { _eq: organizationId },
                    ...(league ? { league: { _eq: league } } : {}),
                    ...(leagueIds?.length ? { league: { _in: leagueIds } } : {}),
                    ...(registrantTypes?.length ? { kind: { _in: registrantTypes } } : {}),
                    ...(teamRoles?.length ? { team_role: { _in: teamRoles } } : {}),
                    ...(whyYouPlaySelections?.length
                        ? { why_you_play_selection: { _in: whyYouPlaySelections } }
                        : {}),
                    ...(isFilteringAgainstLeagues
                        ? {
                            leagueByLeague: {
                                ...(stages?.length
                                    ? { status: { _in: stages.flatMap(s => getLeagueStatusesFromStage(s)) } }
                                    : {}),
                                ...(sports?.length ? { sport: { _in: sports } } : {}),
                                ...(seasons?.length ? { season: { _in: seasons } } : {}),
                                ...(programTypes?.length ? { program_type: { _in: programTypes } } : {}),
                                ...(leagueStartDateMinimum || leagueStartDateMaximum
                                    ? {
                                        start_date: {
                                            ...(leagueStartDateMinimum ? { _gte: leagueStartDateMinimum } : {}),
                                            ...(leagueStartDateMaximum ? { _lte: leagueStartDateMaximum } : {}),
                                        },
                                    }
                                    : {}),
                                ...(venues?.length || neighborhoods?.length
                                    ? {
                                        venueByVenue: {
                                            ...(venues?.length ? { _id: { _in: venues } } : {}),
                                            ...(neighborhoods?.length
                                                ? { neighborhood_id: { _in: neighborhoods } }
                                                : {}),
                                        },
                                    }
                                    : {}),
                            },
                        }
                        : {}),
                },
            }),
    };
};
