import { useState } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames/bind';
import styles from '../player-details.module.scss';
import { capitalize } from '../../../utils';

import Picture from '../../../components/Picture/Picture';
import { Tooltip } from '../../../components';
import PlayerInfoEdit from './components/PlayerInfoEdit';
import PlayerInfoDisplay from './components/PlayerInfoDisplay';
import type { UserDetails } from '../PLAYER_DETAILS_QUERIES';

const cx = classNames.bind(styles);

type Props = {
  userDetails: UserDetails;
};

const PlayerInfo = (props: Props) => {
  const { userDetails } = props;

  const [updatingInfo, setUpdatingInfo] = useState(false);

  const {
    _id,
    first_name,
    last_name,
    profile_picture,
    league_count,
    deactivated,
    membership_expires,
  } = userDetails;

  const status = league_count ? 'Active' : 'Inactive';

  return (
    <div className="col-12 col-md-2 p-0" style={{ borderRight: '1px solid lightgray' }}>
      <div className="container text-center">
        {profile_picture ? (
          <Picture className={cx('profilePic')} src={profile_picture} alt="profile pic" />
        ) : (
          <span className={cx('defaultImg')}>
            {first_name?.charAt(0)} {last_name?.charAt(0)}
          </span>
        )}
        <div className="mb-3">
          <div
            className={cx(
              'editName',
              deactivated ? 'text-volo-danger font-italic' : 'text-volo-primary'
            )}
          >
            {first_name} {last_name}
          </div>
          {deactivated && <small className="d-block">Player deactivated</small>}
          <span className={cx(status, 'mr-3')}>{capitalize(status)}</span>
          <span className={cx('isMember')}>
            {membership_expires && (
              <span
                data-for={_id}
                data-tip={`Expires ${moment(membership_expires).format('MM/DD/YY')}`}
                className={cx('isMember')}
              >
                <Picture
                  src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/VoloPass.png"
                  alt="volo pass"
                />
                <Tooltip id={_id} />
              </span>
            )}
          </span>
        </div>
        <div className={cx('editInfo')}>
          {updatingInfo ? (
            <PlayerInfoEdit userDetails={userDetails} setUpdatingInfo={setUpdatingInfo} />
          ) : (
            <PlayerInfoDisplay userDetails={userDetails} setUpdatingInfo={setUpdatingInfo} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerInfo;
