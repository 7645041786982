import { useQuery } from '@apollo/client';

import GET_HASURA_PROGRAM_PRICING from '../graphql/GET_PROGRAM_PRICING';
import type { DiscoverProgramCardProps } from '../DiscoverProgramCard';

const safeMath = (a: number | null | undefined, b: number | null | undefined) => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a + b;
  }

  return null;
};

const useProgramPricing = ({ programId }: Pick<DiscoverProgramCardProps, 'programId'>) => {
  const {
    data: pricingData,
    loading: pricingLoading,
    error: pricingError,
  } = useQuery(GET_HASURA_PROGRAM_PRICING, {
    skip: !programId,
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        leagueId: programId!,
      },
    },
  });

  const { programPricingForRegistration } = pricingData || {};
  const pricingBreakdown = programPricingForRegistration?.pricingBreakdown;
  const { originalPrice = 0, memberPrice, totalFeeCents } = pricingBreakdown ?? {};

  return {
    pricingLoading,
    pricingError,
    originalPrice,
    originalPriceWithFees: safeMath(originalPrice, totalFeeCents),
    memberPrice,
    voloPassPriceWithFees: safeMath(memberPrice, totalFeeCents),
  };
};

export default useProgramPricing;
