import { type FC, type ComponentProps, Suspense, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { MembershipEnum } from '../../constants';
import { Center, View, Text, Heading, Spinner } from '../../base-components';
import { useUserVoloPassMembershipStatus, useCurrentUserV2 } from '../../hooks';
import GET_ORGANIZATION_MEMBERSHIPS from './gql/GET_ORGANIZATION_MEMBERSHIPS';
import { MembershipCard } from '../MembershipCard';

/** Formatted member cards that query organization offerings and current user status.
 * Container styling to happen in implementation (flex, spacing, etc)
 * Links to other sections are also platform-specific
 */

// These may be updated as sections are built out
interface PlayerMembershipProps extends ComponentProps<typeof View> {
  voloPassManageMembership: () => void;
  voloPassBillingHistory: () => void;
  voloPassLearnMore: () => void;
  voloPassUpgradeAnnual: () => void;
}

const PlayerMembership: FC<PlayerMembershipProps> = ({
  voloPassManageMembership,
  voloPassBillingHistory,
  voloPassLearnMore,
  voloPassUpgradeAnnual,
  ...props
}) => {
  const { currentUser } = useCurrentUserV2();
  const homeOrganization = currentUser?.home_organization ?? '';

  const { data, error } = useQuery(GET_ORGANIZATION_MEMBERSHIPS, {
    skip: !homeOrganization,
    variables: {
      id: homeOrganization,
    },
  });

  const vpIsActive = !!data?.organization?.is_volo_pass_active;

  const {
    voloPassMembershipError,
    hasVoloPassMemberHistory,
    voloPassMembershipStatus,
    voloPassMembershipStartDate,
    voloPassMembershipType,
    voloPassMembershipRefetch,
  } = useUserVoloPassMembershipStatus();

  const displayError = error || voloPassMembershipError;

  /**
   * We want the most up-to-date membership status. 'network-only' doesn't work
   * so we have to force a refetch on mount.
   */
  useEffect(() => {
    voloPassMembershipRefetch();
  }, [voloPassMembershipRefetch]);

  if (displayError)
    return (
      <View>
        <Text>An error occurred while loading your memberships.</Text>
        <Text type="caption">{displayError?.message}</Text>
      </View>
    );

  return vpIsActive ? (
    <View {...props}>
      <Heading fontSize={18} marginBottom={2}>
        Volo Pass Membership
      </Heading>
      <MembershipCard
        variant={MembershipEnum.VOLO_PASS}
        learnMore={voloPassLearnMore}
        billingHistory={voloPassBillingHistory}
        manageMembership={voloPassManageMembership}
        upgradeAnnual={voloPassUpgradeAnnual}
        {...(hasVoloPassMemberHistory && voloPassMembershipStatus && voloPassMembershipType
          ? {
              membershipDetails: {
                membershipStatus: voloPassMembershipStatus,
                membershipStartDate: voloPassMembershipStartDate,
                membershipType: voloPassMembershipType,
              },
            }
          : {})}
      />
    </View>
  ) : null;
};

const Loading = () => (
  <Center flex={1}>
    <Spinner size="lg" />
  </Center>
);

export default (props: PlayerMembershipProps) => (
  <Suspense fallback={<Loading />}>
    <PlayerMembership {...props} />
  </Suspense>
);
