/* eslint-disable react/no-danger */
import type { CSSProperties, FC } from 'react';
import classNames from 'classnames/bind';
import { sanitize } from 'isomorphic-dompurify';
import cup from '../../../images/icons/cup.svg';
import styles from './styles.module.scss';
import type { StaffLeagueData } from '../../../apps/Staff/graphql';

const cx = classNames.bind(styles);

type LeagueDetails = {
  league: StaffLeagueData;
  style?: CSSProperties;
  minify?: boolean;
};

const LeagueDetails: FC<LeagueDetails> = ({ league, style = {}, minify = false }) => {
  const { league_description } = league;
  let description = league_description ?? '';

  if (minify) {
    description = description.length > 150 ? `${description.slice(0, 150)}...` : description;
  }

  description = sanitize(description);

  return (
    <div className={cx('info-container')} style={style}>
      <img className={cx('icon')} src={cup} alt="cup" />
      <p className={cx('title')}>The Gist</p>
      <div className={cx('gist')} dangerouslySetInnerHTML={{ __html: description ?? '' }} />
      <p className={cx('safety')}>
        COVID safety protocols may be implemented at any point during the season as required by
        venue, city or state mandate. Credits will be issued for any COVID-19 related league
        cancellations.
      </p>
    </div>
  );
};

export default LeagueDetails;
