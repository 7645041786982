import { useSuspenseQuery } from '@apollo/client';

import { Link } from 'react-router-dom';
import { Suspense } from 'react';
import { GenericErrorBoundary, ListContainer, Loading } from '../../../components';
import GET_USER_PARTICIPATION, {
  type RegistrantData,
  type RentalRegistrantData,
} from './PLAYER_PARTICIPATION_QUERIES';
import { RentalRegistrantTypeOptions } from '../../../shared/rental-enums';
import { RegistrantTypes } from '../../../shared/registrant-type-enum';
import { upperFirst } from '../../../shared/string-manipulations';
import {
  ParticipationActivityIcon,
  ParticipationRating,
  ParticipationTeamLink,
  ParticipationWaiver,
} from './components/ListComponents';
import hasuraClient from '../../../apollo/hasuraClient';

export type PlayerParticipationProps = {
  userId: string;
};

// TODO: Pagination?
const PlayerParticipation = ({ userId }: PlayerParticipationProps) => {
  const { data } = useSuspenseQuery(GET_USER_PARTICIPATION, {
    client: hasuraClient,
    variables: { userId },
  });

  const fullName = data?.users_by_pk?.full_name || '';
  const registrants = data?.users_by_pk?.registrants || [];
  const rentalRegistrants = data?.users_by_pk?.rental_registrants || [];

  // Don't know how to sort this better besides an action
  const items = [...registrants, ...rentalRegistrants].sort((a, b) => {
    const createdA = new Date(a.created_at).getTime();
    const createdB = new Date(b.created_at).getTime();

    return createdB - createdA;
  });

  /** Returns column data when given Registrant data (everything except Rentals) */
  const getRegistrantItem = (item: RegistrantData) => {
    const {
      _id,
      league,
      is_drop_in,
      group,
      dropInSlot,
      kind,
      waiver_id,
      waiver_signed,
      waiver_date_signed,
      waiver_reminders,
    } = item;

    const registrantTypeLabel = is_drop_in
      ? 'Drop-In'
      : RegistrantTypes[kind]?.label || 'Free agent';

    // the team they're on per the assembler, but that hasn't necessarily been released to players
    const stagedTeam = league.teams[0];

    let teamName = is_drop_in ? dropInSlot?.team?.name : stagedTeam?.name;
    // Override for former user leagues query
    if (!teamName && group) {
      teamName = group.name;
    }

    const activityName = league.sportBySport.name;
    const seasonName = league.seasonBySeason.name;

    return [
      <ParticipationActivityIcon activityName={activityName} />,
      <Link
        to={{
          pathname: `/rfo/league/${league._id}/view`,
          search: `?organization=${league.organization}`,
        }}
      >
        {league.name}
      </Link>,
      <ParticipationTeamLink
        teamTo={`/rfo/league/${league._id}/assemble?organization=${league.organization}`}
        registrantType={registrantTypeLabel}
        teamName={teamName}
      />,
      upperFirst(seasonName),
      <ParticipationRating />,
      <ParticipationWaiver
        waiver_id={waiver_id}
        waiver_signed={waiver_signed}
        waiver_date_signed={waiver_date_signed}
        waiver_reminders={waiver_reminders}
        registrantId={_id}
        fullName={fullName}
      />,
    ];
  };

  /** Returns column data when given RentalRegistrant data */
  const getRentalRegistrantItem = (item: RentalRegistrantData) => {
    const { _id, rental, kind, waiver_id, waiver_signed, waiver_date_signed, waiver_reminders } =
      item;

    const { _id: rentalId, sport, name } = rental;

    return [
      <ParticipationActivityIcon activityName={sport.name} />,
      <Link to={{ pathname: `/rfo/rental/${rentalId}/view` }}>{name}</Link>,
      <ParticipationTeamLink
        teamTo={`/rfo/rental/${rentalId}/reservations`}
        registrantType={RentalRegistrantTypeOptions[kind].label}
        teamName={`${sport.name} Rental`}
      />,
      <p>---</p>, // No seasons for rentals
      <ParticipationRating />,
      <ParticipationWaiver
        waiver_id={waiver_id}
        waiver_signed={waiver_signed}
        waiver_date_signed={waiver_date_signed}
        waiver_reminders={waiver_reminders}
        registrantId={_id}
        fullName={fullName}
        isRental
      />,
    ];
  };

  return (
    <div className="mt-4">
      <ListContainer
        columnTitles={['', 'Program', 'Team', 'Season', 'ParticipationRating', 'Waiver']}
        columnData={items.map(item => {
          return item.__typename === 'registrants'
            ? getRegistrantItem(item)
            : getRentalRegistrantItem(item);
        })}
        numOfCol={6}
        emptyText="User has not participated in any leagues"
      />
    </div>
  );
};

export default (props: PlayerParticipationProps) => (
  <Suspense fallback={<Loading />}>
    <GenericErrorBoundary>
      <PlayerParticipation {...props} />
    </GenericErrorBoundary>
  </Suspense>
);
