import { graphql } from 'graphql-schema';
// Shared queries that are not handled in the SCL (for components per platform)
export const HASURA_DISCOVER_TOP_SPORTS = graphql(`
  query GetDiscoverTopSports($cityId: uuid!, $programTypes: [program_type_enum!]) {
    sports(
      where: {
        leagues: {
          organization: { _eq: $cityId }
          archived: { _eq: false }
          private: { _eq: false }
          status: { _eq: registration_open }
          program_type: { _in: $programTypes }
        }
      }
    ) {
      _id
      name
      leagues_aggregate(
        where: {
          organization: { _eq: $cityId }
          archived: { _eq: false }
          private: { _eq: false }
          status: { _eq: registration_open }
          program_type: { _in: $programTypes }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`);
