// NOTE: this file is pulled from `apps/volosports-api/src/graphql/types/league/resolvers/utils/calcPricesWithFees.ts`
import { TechFees } from '../../constants/registration-fees-enum.js';
const DailyProgramList = [
    'CLASS',
    'CLINIC',
    'PICKUP',
    'PRACTICE',
    'DROPIN',
];
function calcPriceWithFees({ originalPriceCents, programType, fees, prepaidMinTeamSize, }) {
    if (originalPriceCents === null)
        return null;
    if (originalPriceCents === 0)
        return 0;
    const { flat_rate, percentage } = fees;
    let flatFeeCents = flat_rate;
    // Flat fee & processing fee are only for leagues
    if (programType !== 'LEAGUE') {
        flatFeeCents = 0;
    }
    else if (typeof prepaidMinTeamSize === 'number') {
        const minTeamSize = Math.max(prepaidMinTeamSize, 5);
        flatFeeCents = Math.round(minTeamSize * flat_rate);
    }
    // add processing fee after flat fee is applied // Only for Leagues
    let processingFeeCents = 0;
    if (programType === 'LEAGUE')
        processingFeeCents = Math.round((originalPriceCents + flatFeeCents) * percentage);
    // Tech fees for non-League Programs
    let techFeeCents = 0;
    // Daily Programs (Pickup, Drop-In, Class, Clinic, Practice): flat $1 Tech fee
    if (DailyProgramList.includes(programType)) {
        techFeeCents = TechFees.flat;
    }
    // Events: Tech Fee equal to 10% of the event registration cost.
    // Tournaments: Tech Fee equal to 10% of the tournament registration cost.
    if (programType === 'EVENT' || programType === 'TOURNAMENT') {
        techFeeCents = Math.round(originalPriceCents * TechFees.percentage);
    }
    const totalFeeCents = Math.round(processingFeeCents + flatFeeCents + techFeeCents);
    return Math.round(originalPriceCents + totalFeeCents);
}
export { calcPriceWithFees };
