/**
 * Converts an ISO string to a unix timestamp.
 * @param iso - The ISO string to convert.
 * @param seconds - Whether to return the timestamp in seconds. Defaults to false.
 */
export const convertISOtoUnix = (iso, options = {}) => {
    const { seconds = false } = options;
    const unix = new Date(iso).getTime();
    if (isNaN(unix))
        return null;
    if (seconds) {
        return unix / 1000;
    }
    return unix;
};
/**
 * Converts a unix timestamp to an ISO string.
 * @param unix - The unix timestamp to convert.
 * @param seconds - Whether the timestamp is in seconds. Defaults to false.
 */
export const convertUnixToISO = (unix, options = {}) => {
    const { seconds = false } = options;
    const date = new Date(seconds ? unix * 1000 : unix);
    if (isNaN(date.getTime()))
        return null;
    return date.toISOString();
};
/**
 * Validates if the input is a valid ISO date string or Date object
 * Accepts: `YYYY-MM-DD`, `YYYY-MM-DDTHH:mm:ss.sssZ`
 */
export const isValidDate = (date) => {
    if (date instanceof Date)
        return !isNaN(date.getTime());
    // Check for ISO format YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss.sssZ
    return (/^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{3})?Z?)?$/.test(date) &&
        !isNaN(new Date(date).getTime()));
};
export function startOfDay(date) {
    if (!isValidDate(date))
        return null;
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate.toISOString();
}
export function endOfDay(date) {
    if (!isValidDate(date))
        return null;
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate.toISOString();
}
/**
 * Converts minutes since start of day to HH:mm format
 * @example minutesTo_HH_MM(1170) // returns "19:30" (7:30 PM)
 */
export const minutesTo_HH_MM = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};
/**
 * Creates a new date object with a buffer of minutes added to it
 * @example createBufferInMinutes(new Date(), 15) // returns a date 15 minutes from now
 */
export const createBufferInMinutes = (date, minutes) => {
    const buffer = new Date(date);
    buffer.setMinutes(buffer.getMinutes() + minutes);
    return buffer;
};
/**
 * Returns a date representing the end of the current quarter. Ex. 3/31, 6/30, etc.
 */
export const getQuarterEndDate = () => {
    const now = new Date();
    const month = now.getMonth();
    const year = now.getFullYear();
    const quarterEndMonth = Math.floor(month / 3) * 3 + 2;
    const quarterEnd = new Date(year, quarterEndMonth + 1, 0);
    return endOfDay(quarterEnd);
};
