import { useMutation } from '@apollo/client';
import classNames from 'classnames/bind';
import { useState, type FC } from 'react';
import type { ResultOf } from 'graphql-schema';

import styles from './styles.module.scss';
import { Button, ColorIndicator } from '../../../../components';
import { Checkbox, FancyField } from '../../../../components/fancyFieldsV2';
import type {
  SCHEDULE_GAME_FRAGMENT,
  SCHEDULE_TEAM_FRAGMENT,
} from '../../../../apps/RFO/Manage/Schedule/graphql';
import { useActionStates } from '../../../../hooks';
import { UPDATE_GAME_SCORES } from './graphql';
import hasuraClient from '../../../../apollo/hasuraClient';

const cx = classNames.bind(styles);

type EditGameScores = {
  game: ResultOf<typeof SCHEDULE_GAME_FRAGMENT>;
  teams?: string[];
  closePopup: VoidFunction;
  refetch: VoidFunction;
};

type ScoreStateObj = {
  score: number;
  forfeit: boolean;
  teamId?: string;
  team?: {
    _id: string;
    team: ResultOf<typeof SCHEDULE_TEAM_FRAGMENT>;
  };
};

const EditGameScores: FC<EditGameScores> = props => {
  const { closePopup, refetch, game, teams: teamIds = [] } = props;
  const { _id: gameId, teams = [], scores = [] } = game;

  const { updating, setError, setSuccess, setUpdating } = useActionStates({ withAlerts: true });

  const initialScores = teamIds.map(teamId => {
    const savedScores = scores.find(({ team }) => team._id === teamId);
    return {
      // if score exists, add teamId from team_id
      ...(savedScores
        ? {
            score: savedScores.score,
            forfeit: savedScores.forfeit,
            teamId: savedScores.team._id,
          }
        : { score: 0, forfeit: false, teamId }),
      team: teams.find(({ team }) => team._id === teamId),
    };
  });

  const [currentScores, updateEditScores] = useState<ScoreStateObj[]>(initialScores);
  const [gameEditScore] = useMutation(UPDATE_GAME_SCORES, { client: hasuraClient });

  const updateScores = (updatedScore: { forfeit: boolean; score: number }, idx: number) => {
    const duplicateScores = [...currentScores];
    duplicateScores[idx] = {
      ...duplicateScores[idx],
      ...updatedScore,
    };
    updateEditScores(duplicateScores);
  };

  const saveScores = async () => {
    setUpdating(true);
    try {
      const [teamScore1, teamScore2] = currentScores;
      if (!teamScore1 || !teamScore2) {
        throw new Error('Unexpectedly failed saving scores');
      }
      await gameEditScore({
        variables: {
          input: {
            gameId,
            teamScore1: {
              score: teamScore1.score,
              teamId: teamScore1.teamId!,
              forfeit: teamScore1.forfeit,
            },
            teamScore2: {
              score: teamScore2.score,
              teamId: teamScore2.teamId!,
              forfeit: teamScore2.forfeit,
            },
          },
        },
      });
      setSuccess('Updated score.');
      closePopup();
      if (refetch) refetch();
    } catch (err) {
      setError(err);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className={cx('edit-score-popup')}>
      <h2>Edit Score</h2>
      {currentScores.map(({ score = 0, forfeit = false, team }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={idx} className={cx('field-wrap')}>
          <ColorIndicator color={team?.team?.color_hex} style={{ marginRight: '3px' }} />
          <h3 className={cx('team-name')}>{team?.team?.name ?? 'TBD'}</h3>
          <div className="row no-gutters">
            <div className="col-6 my-auto">
              <Checkbox
                label="Forfeit?"
                className={cx('forfeit-button')}
                value={!!forfeit}
                onChange={() => updateScores({ forfeit: !forfeit, score: 0 }, idx)}
              />
            </div>
            <div className="col-6 my-auto">
              <FancyField
                blockStyle
                type="number"
                noLabel
                className={cx('score-input')}
                onChange={(value: string | number) =>
                  updateScores({ forfeit: false, score: +value }, idx)
                }
                value={score || 0}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>Score</label>
            </div>
          </div>
        </div>
      ))}
      <Button primary className={cx('save')} onClick={saveScores} disabled={updating}>
        {updating ? 'Saving...' : 'Save'}
      </Button>
      <small className="float-right pt-3">gameId: {gameId}</small>
    </div>
  );
};

export default EditGameScores;
