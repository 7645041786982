import {
  createContext,
  useReducer,
  useMemo,
  useEffect,
  useState,
  type ReactNode,
  type FC,
} from 'react';
import { skipToken, useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { type VariablesOf } from 'graphql-schema';

import { usePaymentSummary, useProgramPricing } from '@rivallapp/volosports-components';
import { RegisterTypes } from '../../apps/APP/Register/tools/constants';
import { useSearchQuery } from '../../hooks';
import { getDisallowedTypesMessage } from '../../apps/APP/Register/tools/helpers';
import RegistrationReducer, { initialState, RegistrationActions } from './RegistrationReducer';
import { ProgramGenders } from '../../shared/gender-enum';
import { RegistrantTypes } from '../../shared/registrant-type-enum';
import useCurrentUserV2 from '../../hooks/useCurrentUserV2';
import {
  DROP_IN_PRICING,
  GET_DROP_IN,
  GET_GAME,
  GET_GROUP,
  GET_LEAGUE,
  GET_MEMBERSHIPS,
  PROGRAM_PRICING,
} from './graphql';
import hasuraClient from '../../apollo/hasuraClient';
import { isValidUUID } from '../../utils';

const { UPDATE_REGISTRATION_STATE } = RegistrationActions;

// TODO migration this needs better initial state or a type or something
const RegistrationContext = createContext(null);

export const RegistrationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { dropInId, step, gameId, leagueId } = useParams<{
    dropInId?: string;
    step?: string;
    gameId?: string;
    leagueId?: string;
  }>();

  const [registerState, registerDispatch] = useReducer(RegistrationReducer, initialState);

  const query = useSearchQuery();
  const invite = query?.get('invite')?.toString() || '';
  const type = query?.get('type')?.toString() || '';

  const {
    donationAmount,
    appliedPromo,
    registrantType,
    creditAppliedAmount,
    selectedGroupId,
    addVpMembership,
  } = registerState;

  const { currentUser, refetch: currentUserRefetch, paymentSources } = useCurrentUserV2();

  type GroupWhereClauseType = VariablesOf<typeof GET_GROUP>['groupWhereClause'];
  const groupWhereClause: GroupWhereClauseType = {};

  if (invite) {
    if (isValidUUID(invite)) {
      groupWhereClause._id = { _eq: invite };
    } else {
      groupWhereClause.external_id = { _eq: invite };
    }
  } else if (selectedGroupId) {
    if (isValidUUID(selectedGroupId)) {
      groupWhereClause._id = { _eq: selectedGroupId };
    } else {
      groupWhereClause.external_id = { _eq: selectedGroupId };
    }
  }

  const { data: groupData, error: groupError } = useSuspenseQuery(
    GET_GROUP,
    !invite && !selectedGroupId
      ? skipToken
      : {
          fetchPolicy: 'network-only',
          variables: { groupWhereClause },
          client: hasuraClient,
        }
  );

  type DropInWhereClauseType = VariablesOf<typeof GET_DROP_IN>['dropInWhereClause'];
  const dropInWhereClause: DropInWhereClauseType = {};

  if (dropInId) {
    if (isValidUUID(dropInId)) {
      dropInWhereClause._id = { _eq: dropInId };
    } else {
      dropInWhereClause.external_id = { _eq: dropInId };
    }
  }

  const { data: dropInData, error: dropInError } = useSuspenseQuery(
    GET_DROP_IN,
    dropInId
      ? {
          variables: { dropInWhereClause },
          client: hasuraClient,
        }
      : skipToken
  );

  type GameWhereClauseType = VariablesOf<typeof GET_GAME>['gameWhereClause'];
  const gameWhereClause: GameWhereClauseType = {};

  if (gameId) {
    if (isValidUUID(gameId)) {
      gameWhereClause._id = { _eq: gameId };
    } else {
      gameWhereClause.external_id = { _eq: gameId };
    }
  } else if (dropInData?.drop_in_slots?.[0]?.game) {
    gameWhereClause._id = { _eq: dropInData.drop_in_slots[0].game };
  }

  const { data: gameData, error: gameError } = useSuspenseQuery(
    GET_GAME,
    gameId || dropInData?.drop_in_slots?.[0]?.game
      ? {
          variables: { gameWhereClause },
          client: hasuraClient,
        }
      : skipToken
  );

  const game = gameData?.games?.[0];

  type LeaguesWhereClauseType = VariablesOf<typeof GET_LEAGUE>['leaguesWhereClause'];
  const leaguesWhereClause: LeaguesWhereClauseType = {};
  if (leagueId) {
    if (isValidUUID(leagueId)) {
      leaguesWhereClause._id = { _eq: leagueId };
    } else {
      leaguesWhereClause.external_id = { _eq: leagueId };
    }
  } else if (game?.league) {
    leaguesWhereClause._id = { _eq: game.league };
  }

  const { data: leagueData, error: leagueError } = useSuspenseQuery(
    GET_LEAGUE,
    leagueId || game?.league
      ? {
          fetchPolicy: 'network-only',
          variables: {
            leaguesWhereClause,
          },
          client: hasuraClient,
        }
      : skipToken
  );

  const isPrepaidTeam = registrantType === RegisterTypes.PREPAID_TEAM_CAPTAIN;

  const usePaymentSummaryProps = usePaymentSummary();

  const league = useMemo(() => leagueData?.leagues?.[0], [leagueData]);

  const { data: pricingData, error: pricingError } = useSuspenseQuery(
    PROGRAM_PRICING,
    league?._id || game?.league
      ? {
          variables: {
            input: {
              ...(currentUser ? {} : { ignoreLoggedIn: true }),
              leagueId: league?._id ?? game?.league ?? '',
              isPrepaidTeam,
              promoCodeStr: appliedPromo,
              donationAmount: donationAmount ?? 0,
              creditAmount: creditAppliedAmount ?? 0,
              // show member pricing if membership is in 'cart'
              ...(addVpMembership ? { membershipName: 'VOLO_PASS' } : {}),
            },
          },
          client: hasuraClient,
        }
      : skipToken
  );

  const { data: dropInPriceData, error: dropInPriceError } = useSuspenseQuery(
    DROP_IN_PRICING,
    game?.league
      ? {
          variables: {
            input: {
              leagueId: game.league,
              donationAmount: donationAmount ?? 0,
              creditAmount: creditAppliedAmount ?? 0,
              ...(appliedPromo ? { promoCodeStr: appliedPromo } : {}),
            },
          },
          client: hasuraClient,
        }
      : skipToken
  );

  const { data: membershipData } = useSuspenseQuery(
    GET_MEMBERSHIPS,
    currentUser && currentUser._id
      ? {
          fetchPolicy: 'network-only',
          client: hasuraClient,
          variables: { userId: currentUser!._id },
        }
      : skipToken
  );

  // User is prevented from starting a recurring donation if they are already an active donor
  const { active_donation, active_volo_pass } = membershipData ?? {};

  const { groups } = groupData ?? {};
  const group = groups?.[0];
  const prepaid = !!group?.prepaid;
  const registrants = group?.registrants ?? [];
  const is_locked = !!group?.is_locked;
  const { registration, start_date, program_type } = league ?? {};

  const {
    block_group_member_registrations,
    block_group_captain_registrations,
    block_prepaid_team_captain_registrations,
    block_all_registrations,
    block_individual_registrations,
    limit_individual_registration,
    male_capacity,
    female_capacity,
    max_team_size,
  } = registration || {};

  const numMembers = registrants?.length || 0;

  const [registrationClosed, setRegistrationClosed] = useState(false);
  useEffect(() => {
    const registrationOpenDate = league?.registration?.registration_open_date;
    const registrationCloseDate = league?.registration?.registration_close_date;
    // close registration if league date is before reg_open & after reg_close
    if (
      registrationOpenDate &&
      registrationCloseDate &&
      (new Date().toISOString() < registrationOpenDate ||
        new Date().toISOString() > registrationCloseDate)
    ) {
      setRegistrationClosed(true);
    }
    // close registration if current date is after drop-in date
    // TODO migration - this was 100% broken before, verify new condition works
    if (game?._id && new Date() >= new Date(game?.start_time ?? '')) {
      setRegistrationClosed(true);
    }
    // allow for additional registrations with a group link before start date
    if (start_date && invite && new Date().toISOString() < start_date) setRegistrationClosed(false);
  }, [
    league?.registration?.registration_close_date,
    league?.registration?.registration_open_date,
    game?._id,
    game?.start_time,
    invite,
    start_date,
  ]);

  useEffect(() => {
    if (typeof max_team_size === 'number' && numMembers >= max_team_size) {
      // @ts-expect-error TODO migration
      registerDispatch({
        update: {
          fullTeam: true,
          joiningGroupId: null,
          selectedGroupId: null,
        },
        type: UPDATE_REGISTRATION_STATE,
      });
    }

    if (invite && typeof max_team_size === 'number') {
      // @ts-expect-error TODO migration
      registerDispatch({
        update: {
          joiningGroupId: invite,
          selectedGroupId: invite,
          fullTeam: numMembers >= max_team_size,
          is_locked,
        },
        type: UPDATE_REGISTRATION_STATE,
      });
    }

    if (type) {
      // @ts-expect-error TODO migration
      registerDispatch({
        update: { registrantType: prepaid ? RegisterTypes.PREPAID_TEAM_MEMBER : type },
        type: UPDATE_REGISTRATION_STATE,
      });
    }

    // reset joiningGroupId & selectedGroupId if group from invite is locked
    if (is_locked) {
      // @ts-expect-error TODO migration
      registerDispatch({
        update: {
          joiningGroupId: null,
          selectedGroupId: null,
        },
        type: UPDATE_REGISTRATION_STATE,
      });
    }

    if (prepaid) {
      // @ts-expect-error TODO migration
      registerDispatch({
        update: { passwordRequired: prepaid },
        type: UPDATE_REGISTRATION_STATE,
      });
    }

    if (block_individual_registrations && type === 'fa') {
      // @ts-expect-error TODO migration
      registerDispatch({
        update: { registrantType: RegisterTypes.WAITLIST },
        type: UPDATE_REGISTRATION_STATE,
      });
    }
  }, [
    registerDispatch,
    invite,
    is_locked,
    type,
    prepaid,
    block_individual_registrations,
    numMembers,
    max_team_size,
  ]);

  /**
   * Convert from the Web `registrantType` to the SCL `registrationVariant`
   */
  const paymentSummaryRegistrationVariant = (() => {
    if (registrantType === RegisterTypes.PREPAID_TEAM_CAPTAIN) {
      return 'PREPAID_TEAM_CAPTAIN';
    }

    if (registrantType === RegisterTypes.PREPAID_TEAM_MEMBER) {
      return 'PREPAID_TEAM_MEMBER';
    }

    return undefined;
  })();

  const value = useMemo(() => {
    const useProgramPricingWrapped = () =>
      useProgramPricing({
        usePaymentSummaryProps,
        promoCodeStr: appliedPromo,
        donationsAppliedInCents: donationAmount || 0,
        registrationVariant: paymentSummaryRegistrationVariant,
        creditsAppliedInCents: creditAppliedAmount || 0,
        leagueId: league?._id ?? game?.league ?? '',
        isDropIn: Boolean(game?.league),
      });

    // saves the valid RegistrantTypes abbreviations to block invalid strings in the url
    const rTypes: string[] = [];

    Object.values(RegistrantTypes).forEach(rType => {
      if ('abbrev' in rType) rTypes.push(rType.abbrev!);
    });

    const { available_credits = [], gender, membership_expires, stripe_id } = currentUser ?? {};
    const isVoloPassMember = (active_volo_pass?.aggregate?.count ?? 0) > 0;

    const availableCreditsAmount = available_credits.reduce(
      (acc: number, { amount }: { amount: number }) => acc + amount,
      0
    );

    // @ts-expect-error TS doesn't like that league gender is technically nullable
    const { validGenders } = ProgramGenders?.[league?.gender] ?? {};

    const { blockGender, blockedMessage, blockTypes } =
      getDisallowedTypesMessage({
        ...(league?.registration ? league.registration : {}),
        metGenderReq: validGenders?.includes(gender),
        gender,
        block_individual_registrations,
      }) || {};

    return [
      {
        ...registerState,
        league,
        programType: program_type,
        isDropIn: Boolean(game?._id),
        currentUser,
        currentUserRefetch,
        group,
        error:
          leagueError || pricingError || groupError || gameError || dropInPriceError || dropInError,
        availableCreditsAmount,
        pricingBreakdown: pricingData?.programPricingForRegistration?.pricingBreakdown || {},
        dropInPriceData,
        promoCodeMessage: pricingData?.programPricingForRegistration?.promoCodeMessage || '',
        blockRegistrations:
          block_group_member_registrations &&
          block_group_captain_registrations &&
          block_prepaid_team_captain_registrations &&
          block_all_registrations &&
          !selectedGroupId,
        block_individual_registrations,
        isVoloPassTrialActive: false,
        userIsActiveDonor: (active_donation?.aggregate?.count ?? 0) > 0,
        paymentSources,
        membershipExpires: membership_expires,
        gender,
        hasStripeId: !!stripe_id,
        step,
        blockGender,
        blockedMessage,
        blockTypes,
        rTypes,
        validGenders,
        limit_individual_registration,
        male_capacity,
        female_capacity,
        max_team_size,
        registrationClosed,
        isPrepaidTeam,
        usePaymentSummaryProps,
        // Overwrite the original field with the new one for backwards compatibility
        termsAgreed: usePaymentSummaryProps.areMemberTermsAgreed,
        addVpMembership:
          !isVoloPassMember && usePaymentSummaryProps.pricingSelected === 'VOLO_PASS',
        useProgramPricingWrapped,
        paymentSummaryRegistrationVariant,
      },
      registerDispatch,
    ];
  }, [
    league,
    block_individual_registrations,
    registerState,
    program_type,
    game?._id,
    game?.league,
    currentUser,
    paymentSources,
    currentUserRefetch,
    group,
    leagueError,
    pricingError,
    groupError,
    gameError,
    dropInPriceError,
    dropInError,
    pricingData?.programPricingForRegistration?.pricingBreakdown,
    pricingData?.programPricingForRegistration?.promoCodeMessage,
    dropInPriceData,
    block_group_member_registrations,
    block_group_captain_registrations,
    block_prepaid_team_captain_registrations,
    block_all_registrations,
    selectedGroupId,
    active_donation,
    step,
    limit_individual_registration,
    male_capacity,
    female_capacity,
    max_team_size,
    registrationClosed,
    isPrepaidTeam,
    usePaymentSummaryProps,
    paymentSummaryRegistrationVariant,
    appliedPromo,
    donationAmount,
    creditAppliedAmount,
    active_volo_pass,
  ]);

  // @ts-expect-error TODO migration
  return <RegistrationContext.Provider value={value}>{children}</RegistrationContext.Provider>;
};

export default RegistrationContext;
