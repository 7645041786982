import { readFragment } from 'graphql-schema';
import { MembershipEnum } from '../../../constants';
import {
  DROP_IN_PRICING_FRAGMENT,
  PRICE_BREAKDOWN_FRAGMENT,
  type DropInPricingData,
  type LeaguePricingData,
} from '../graphql';

/**
 * This function accepts the query response from either PRICING_QUERY or DROP_IN_PRICING_QUERY and
 * returns the data in a common format that can be consumed by the UI regardless of if the program
 * is a Drop-In or non-Drop-In. We should consider refactoring our pricing queries on the backend
 * to make the schema line up more consistently.
 */
const normalizePricingQueryData = (data: LeaguePricingData | DropInPricingData) => {
  // Non-drop-in data, e.g. Leagues, Pickups, Etc.
  if ('regular' in data && 'vp' in data) {
    const regularBreakdown = readFragment(PRICE_BREAKDOWN_FRAGMENT, data.regular.pricingBreakdown);
    const vpBreakdown = readFragment(PRICE_BREAKDOWN_FRAGMENT, data.vp.pricingBreakdown);

    const { currentPhase, memberPrice, originalPrice, nonMemberPrice, ...restRegularBreakdown } =
      regularBreakdown;
    const {
      currentPhase: vpCurrentPhase,
      memberPrice: vpMemberPrice,
      originalPrice: vpOriginalPrice,
      nonMemberPrice: vpNonMemberPrice,
      ...restVpBreakdown
    } = vpBreakdown;

    return {
      REGULAR: {
        ...restRegularBreakdown,
        // pulling these out to show that they are not a part of the drop in pricing breakdown for future reference
        currentPhase,
        memberPrice,
        originalPrice,
        nonMemberPrice,
      },
      [MembershipEnum.VOLO_PASS]: {
        ...restVpBreakdown,
        // pulling these out to show that they are not a part of the drop in pricing breakdown for future reference
        currentPhase: vpCurrentPhase,
        memberPrice: vpMemberPrice,
        originalPrice: vpOriginalPrice,
        nonMemberPrice: vpNonMemberPrice,
      },
    };
  }

  // Drop-in data
  if ('dropinPricingForRegistration' in data) {
    const nonmemberBreakdown = readFragment(
      DROP_IN_PRICING_FRAGMENT,
      data.dropinPricingForRegistration.nonmemberBreakdown
    );
    const memberBreakdown = readFragment(
      DROP_IN_PRICING_FRAGMENT,
      data.dropinPricingForRegistration.memberBreakdown
    );

    const originalPrice = nonmemberBreakdown?.programPriceCents ?? null;
    const memberPrice = memberBreakdown.programPriceCents;

    return {
      REGULAR: nonmemberBreakdown
        ? {
            ...nonmemberBreakdown,
            originalPrice: nonmemberBreakdown.programPriceCents,
            memberPrice,
            membershipDiscountApplied: null,
            // Drop-in pricing doesn't have a current phase related to registration phases
            currentPhase: null,
          }
        : null,
      [MembershipEnum.VOLO_PASS]: memberBreakdown
        ? {
            ...memberBreakdown,
            originalPrice,
            memberPrice,
            membershipDiscountApplied: MembershipEnum.VOLO_PASS,
            // Drop-in pricing doesn't have a current phase related to registration phases
            currentPhase: null,
          }
        : null,
    };
  }

  throw new Error('No pricing could be found for this program!');
};

export default normalizePricingQueryData;

/**
 * Because we try and combine the program & drop in pricing schemas, we need to normalize the data for client side consumption.
 * Looking back - this is a bit of a mess and post migration we should consider refactoring to a more streamlined approach
 * with separation of concerns between the multiple different registrations & pricing we support
 */

/** Currently supports the following:
 * - `REGULAR` - Non-member pricing & can be null for drop in pricing
 * - `VOLO_PASS` - Member pricing & can be null for drop in pricing
 */
export type NormalizedPricingQueryData = ReturnType<
  typeof normalizePricingQueryData
>[keyof ReturnType<typeof normalizePricingQueryData>];
