import { type FC, Suspense, useState } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import { graphql, type ExtractEnum } from 'graphql-schema';
import styles from './league-forms.module.scss';
import { GenericErrorBoundary, PageLoader } from '../../components';
import { Checkbox } from '../../components/fancyFieldsV2';

const cx = classNames.bind(styles);

const shirtAmounts: Record<ExtractEnum<'shirt_size_enum'> | 'total', number> = {
  xs: 0,
  s: 0,
  m: 0,
  l: 0,
  xl: 0,
  xxl: 0,
  total: 0,
};

const LEAGUE_SHIRT_QUERY = graphql(`
  query LeagueJerseys($leagueId: uuid!) {
    leagues_by_pk(_id: $leagueId) {
      _id
      name
      teams {
        _id
        name
        color_hex
        color_name
        registrants {
          _id
          userByUser {
            _id
            shirt_size
          }
        }
      }
    }
  }
`);

type Props = { leagueId: string };

const ViewJerseyCount: FC<Props> = ({ leagueId }) => {
  const [allowXxl, setAllowXxl] = useState(true);

  const {
    data: { leagues_by_pk: league },
  } = useSuspenseQuery(LEAGUE_SHIRT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { leagueId },
  });

  if (!league) throw new Error('League not found.');

  const teams = league.teams.map(team => ({
    ...team,
    shirtAmounts:
      team.registrants?.reduce(
        (acc, { userByUser: { shirt_size } }) => {
          if (!shirt_size) return acc;
          if (!allowXxl && shirt_size === 'xxl') {
            acc.xl += 1;
          } else if (shirt_size === 'xs') {
            acc.s += 1;
          } else {
            acc[shirt_size] += 1;
          }
          acc.total += 1;
          return acc;
        },
        { ...shirtAmounts }
      ) ?? shirtAmounts,
  }));

  return (
    <div className={cx('jersey-container', 'container')}>
      <div className="row no-gutters justify-content-center">
        <div className="col-2">
          <Checkbox
            value={allowXxl}
            property="allowXxl"
            onChange={() => setAllowXxl(!allowXxl)}
            label="Offer XXL"
          />
        </div>
      </div>
      <table className={cx('jersey-table')}>
        <thead className={cx('jersey-table-title')}>
          <tr>
            <th colSpan={9}>{league.name}</th>
          </tr>
        </thead>
        <tbody>
          <tr className={cx('jersey-table-title')}>
            <td>Team Name</td>
            <td>Color</td>
            <td>SM</td>
            <td>MD</td>
            <td>LG</td>
            <td>XL</td>
            <td>XXL</td>
            <td>Total</td>
          </tr>
          {teams.map(team => (
            <tr key={team._id}>
              <td>{team.name}</td>
              <td>
                {team.color_name || 'WARNING: No color'}
                {team.color_hex && (
                  <span
                    className={cx('color-indicator')}
                    style={{ backgroundColor: team.color_hex }}
                  />
                )}
              </td>
              <td>{team.shirtAmounts.s}</td>
              <td>{team.shirtAmounts.m}</td>
              <td>{team.shirtAmounts.l}</td>
              <td>{team.shirtAmounts.xl}</td>
              <td>{team.shirtAmounts.xxl}</td>
              <td>{team.shirtAmounts.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default (props: Props) => (
  <GenericErrorBoundary>
    <Suspense fallback={<PageLoader />}>
      <ViewJerseyCount {...props} />
    </Suspense>
  </GenericErrorBoundary>
);
