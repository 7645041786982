import { gql } from '@apollo/client';
import { graphql } from 'graphql-schema';

export const ORGANIZATION_QUERY = gql`
  query organization($organizationId: ID!) {
    organization(_id: $organizationId) {
      _id
      name
      logo
      icon
      city
      website_url
      contact_email
      contact_phone_number
      location {
        formatted_address
      }
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation ($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation ($input: CurrentUserInput) {
    updateCurrentUser(input: $input) {
      currentUser {
        _id
        username
        email
        firstName
        lastName
        gender
        zip
        shirt_size
        birthday
        emailHidden
        phoneHidden
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query {
    currentUser {
      _id
      email
      gender
      zip
      shirt_size
      birthday
      firstName
      lastName
      username
      picture
    }
  }
`;

export const UPLOAD_PROFILE_PICTURE = gql`
  mutation ($input: UploadProfilePictureInput!) {
    uploadProfilePicture(input: $input) {
      _id
      picture
    }
  }
`;

export const DEACTIVATE_CURRENT_USER = graphql(`
  mutation DeactivateCurrentUser($password: String!, $reason: String!) {
    deactivateCurrentUser(password: $password, reason: $reason) {
      _id
    }
  }
`);
