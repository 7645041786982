import { useMutation } from '@apollo/client';
import classNames from 'classnames/bind';
import uniqBy from 'lodash-es/uniqBy';
import type { TPaymentListItem } from '@rivallapp/volosports-components';
import { getLabelOptions } from '@rivallapp/shared/object-helpers';
import { Suspense, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import styles from '../player-details.module.scss';
import {
  ADD_CREDITS,
  ISSUE_REFUND,
  REMOVE_WITHOUT_REFUND,
} from './PLAYER_PAYMENT_MUTATION_AND_QUERIES';
import { ConfirmButton, FilterSelect, GenericErrorBoundary, PageLoader } from '../../../components';
import { Checkbox, FancyField } from '../../../components/fancyFieldsV2';
import { dollarsToCents } from '../../../utils';
import { CreditTypeEnum } from '../../../shared/credit-enum';
import useCurrentUserV2 from '../../../hooks/useCurrentUserV2';
import { useAlertMessage } from '../../../hooks';

const cx = classNames.bind(styles);

type CurPaymentDetailsState = {
  creditAmount: string;
  creditNote: string;
  sendEmail: boolean;
  refundAmount: string;
  moneyType: 'credit' | 'refund';
  actionType: 'keep' | 'remove';
  amountType: 'full' | 'partial' | 'no';
  isGiftCard: boolean;
};

const clearPaymentObject = Object.freeze({
  creditAmount: '0',
  creditNote: '',
  sendEmail: true,
  refundAmount: '0',
  moneyType: 'credit',
  actionType: 'keep',
  amountType: 'partial',
  isGiftCard: true,
});

type Props = {
  userId: string;
  payments: TPaymentListItem[];
  refetch: () => Promise<any>; // Can be loose since we're not using the returned data
  updating: boolean;
  setUpdating: (val: boolean) => void;
};

const PaymentAction = ({ userId, payments, refetch, updating, setUpdating }: Props) => {
  const [curPaymentDetails, setCurPaymentDetails] =
    useState<CurPaymentDetailsState>(clearPaymentObject);
  const [curPayment, setCurPayment] = useState<Partial<TPaymentListItem>>({});
  const updatePaymentDetails = (update: Partial<CurPaymentDetailsState>) =>
    setCurPaymentDetails({ ...curPaymentDetails, ...update });

  const [registrantIssueRefund] = useMutation(ISSUE_REFUND);
  const [removeWithoutRefund] = useMutation(REMOVE_WITHOUT_REFUND);
  const [addCreditsMutation] = useMutation(ADD_CREDITS);

  const { currentUser } = useCurrentUserV2();

  const { showError, showSuccess } = useAlertMessage();

  const {
    creditAmount,
    creditNote,
    sendEmail,
    refundAmount,
    moneyType,
    actionType,
    amountType,
    isGiftCard,
  } = curPaymentDetails;
  const { paymentId, organizationId } = curPayment;

  const refundCents = dollarsToCents(refundAmount);

  const amountOption = getLabelOptions(['full', 'partial', 'no']);
  const moneyOption = getLabelOptions(['refund', 'credit']);
  const actionOption = getLabelOptions(['keep', 'remove']);
  const leagueOption = uniqBy(
    payments
      .filter(({ status }) => status !== 'Credit')
      .map(({ reason: label, paymentId: value }) => ({ label, value })),
    'value'
  );
  leagueOption.splice(0, 0, { label: 'Gift Card', value: 'gift' });

  const clearPaymentState = async () => {
    setCurPaymentDetails(clearPaymentObject);
    setCurPayment({});
    await refetch();
  };

  const updateFields = async (
    type: 'full' | 'partial' | 'no',
    currentPayment: Partial<TPaymentListItem>
  ) => {
    if (!currentPayment) {
      // gift card was selected
      updatePaymentDetails({
        isGiftCard: true,
        moneyType: 'credit',
        actionType: 'keep',
        amountType: 'partial',
        creditAmount: '0',
      });
      setCurPayment({ paymentId: 'gift' });
    } else {
      const maxRefundableCents = currentPayment.maxRefundableCents ?? 0;
      // a program was selected
      const updateAmount = type === 'partial' ? '0' : String(maxRefundableCents / 100);
      updatePaymentDetails({
        amountType: type,
        actionType: type === 'no' ? 'remove' : actionType,
        isGiftCard: false,
        creditAmount: updateAmount,
        refundAmount: updateAmount,
      });
      setCurPayment(currentPayment);
    }
  };

  const isDisabled = () => {
    if (isGiftCard) {
      return !creditAmount || !creditNote;
    }
    if (amountType === 'no') {
      return false;
    }
    if (moneyType === 'credit') {
      return !creditAmount || !creditNote || (amountType === 'partial' && +creditAmount > 5000);
    }
    if (moneyType === 'refund') {
      return !refundAmount;
    }
    return !!paymentId;
  };

  const onRemove = async () => {
    if (!paymentId) {
      showError('Payment not found.');
      return;
    }

    setUpdating(true);
    try {
      await removeWithoutRefund({
        variables: {
          input: {
            registrantId: paymentId,
            sendEmail,
          },
        },
      });
      await clearPaymentState();
      showSuccess('Player removed from program.');
    } catch (e) {
      showError(e);
    } finally {
      setUpdating(false);
    }
  };

  const onRefund = async () => {
    if (!paymentId) {
      showError('Payment not found.');
      return;
    }

    setUpdating(true);
    try {
      await registrantIssueRefund({
        variables: {
          input: {
            registrantId: paymentId,
            isPartialRefund: amountType !== 'full',
            ...(amountType !== 'full' ? { refundCents } : {}),
            sendEmail,
            keepInProgram: actionType !== 'remove',
          },
        },
      });
      showSuccess(
        `Player refunded and ${actionType === 'remove' ? 'removed from' : 'kept in'} program.`
      );
      await clearPaymentState();
    } catch (e) {
      showError(e);
    } finally {
      setUpdating(false);
    }
  };

  const onCredits = async () => {
    if (!paymentId) {
      showError('Payment not found.');
      return;
    }

    setUpdating(true);
    try {
      let successMsg = 'Player credits added and kept in program.';

      await addCreditsMutation({
        variables: {
          input: {
            userId,
            amount: dollarsToCents(creditAmount),
            note: creditNote,
            sendEmail,
            reason: isGiftCard ? 'gift' : '',
            // pass currentUser.organization if gift card
            organizationId: organizationId || currentUser?.home_organization || '',
            type: isGiftCard ? CreditTypeEnum.GIFT_CARD : CreditTypeEnum.PROGRAM_CREDIT,
          },
        },
      });

      if (actionType === 'remove') {
        await removeWithoutRefund({
          variables: {
            input: {
              registrantId: paymentId,
              sendEmail: false, // Previously did not send emails here
            },
          },
        });
        successMsg = 'Player credits added and removed from program.';
      } else if (isGiftCard) successMsg = 'Gift card added.';

      showSuccess(successMsg);
      await clearPaymentState();
    } catch (e) {
      showError(e);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className={cx('selectContainer', 'mt-4', 'card')}>
      <div className="row mx-0">
        <div className={cx('selectRow', 'my-auto col-12 col-md-1')}>Issue</div>
        <div className={cx('selectRow', 'my-auto col-12 col-md-4')}>
          <FilterSelect
            skinny
            shadow
            onChange={
              opt => updateFields(amountType, payments.find(p => p.paymentId === opt?.value)!) // can't deselect so there's always a value
            }
            labelSingular="Select League"
            options={leagueOption}
            value={leagueOption.find(({ value }) => String(value) === paymentId) || null}
          />
        </div>
        {!isGiftCard && (
          <>
            <div className={cx('selectRow', 'my-auto col-12 col-md-2')}>
              <FilterSelect
                skinny
                shadow
                onChange={opt => updateFields(opt?.value!, curPayment)}
                labelSingular="Select Amount"
                options={amountOption}
                value={amountOption.find(({ value }) => value === amountType) || null}
              />
            </div>
            <div className={cx('selectRow', 'my-auto col-12 col-md-2')}>
              <FilterSelect
                skinny
                shadow
                onChange={opt => updatePaymentDetails({ moneyType: opt?.value })}
                labelSingular="Select Type"
                options={moneyOption}
                value={moneyOption.find(({ value }) => value === moneyType) || null}
                disabled={amountType === 'no'}
              />
            </div>
            <div className={cx('selectRow', 'my-auto col-12 col-md-1')}>and</div>
            <div className={cx('selectRow', 'my-auto col-12 col-md-2')}>
              <FilterSelect
                skinny
                shadow
                onChange={opt => updatePaymentDetails({ actionType: opt?.value })}
                labelSingular="Select Action"
                options={actionOption}
                value={actionOption.find(({ value }) => value === actionType) || null}
                disabled={amountType === 'no'}
              />
            </div>
          </>
        )}
      </div>
      {paymentId && (
        <div className="row mt-2 justify-content-center">
          <div className="col-6 col-md-2 my-auto">
            <Checkbox
              className="my-3 mb-2"
              value={sendEmail}
              onChange={(val: boolean) => updatePaymentDetails({ sendEmail: val })}
              label="Send Email"
              style={{ fontWeight: 400, lineHeight: 1.4, fontSize: '14px' }}
            />
          </div>
          {moneyType === 'refund' && amountType !== 'no' && (
            <div className="col-6 col-md-3 my-3">
              <CurrencyInput
                placeholder="Enter refund amount"
                prefix="$"
                decimalScale={2}
                allowNegativeValue={false}
                value={refundAmount}
                onValueChange={value => updatePaymentDetails({ refundAmount: value || '0' })}
                disabled={amountType !== 'partial'}
                className={cx('currencyInput')}
              />
              {/* Following component depends on `maxPartialRefund` which is no longer exists */}
              {/* {amountType === 'partial' && refundCents > maxPartialRefund && (
                <div className="error">
                  Partial refund cannot exceed {formatCents(maxPartialRefund)}.
                </div>
              )} */}
            </div>
          )}
          {moneyType === 'credit' && amountType !== 'no' && (
            <>
              <div className="col-6 col-md-3 my-3">
                <CurrencyInput
                  placeholder="Enter credit amount"
                  prefix="$"
                  decimalScale={2}
                  allowNegativeValue={false}
                  value={creditAmount}
                  onValueChange={value => updatePaymentDetails({ creditAmount: value || '0' })}
                  disabled={amountType !== 'partial'}
                  className={cx('currencyInput')}
                />
                {amountType === 'partial' && +creditAmount > 5000 && (
                  <div className="error">Cannot exceed $5,000 in credits.</div>
                )}
              </div>
              <div className="col-6 col-md-7">
                <FancyField
                  grey
                  noLabel
                  className="my-3"
                  value={creditNote}
                  onChange={(value: string) => updatePaymentDetails({ creditNote: value })}
                  label="Credit Note"
                  placeholder="Enter credit note"
                />
              </div>
            </>
          )}
          <div className="col-12">
            {/* @ts-expect-error - Old button */}
            <ConfirmButton
              onClick={() => {
                if (amountType === 'no') return onRemove();
                if (moneyType === 'credit') return onCredits();
                return onRefund();
              }}
              disabled={updating || isDisabled()}
              confirmingProps={{ danger: true }}
              confirmText={updating ? 'Hang tight...' : 'Confirm'}
              className="mt-2"
            >
              {(() => {
                if (updating) return 'Hang tight...';
                if (amountType === 'no') return 'Remove';
                if (isGiftCard) return `Issue $${creditAmount} gift card`;
                const amountText = `${amountType} $${
                  moneyType === 'credit' ? creditAmount : refundAmount
                }`;
                return `Issue ${amountText} ${moneyType} & ${actionType} ${
                  actionType === 'remove' ? 'from' : 'in'
                } program`;
              })()}
            </ConfirmButton>
            {actionType === 'remove' && (
              <small className="text-danger mt-2">
                This will delete transaction and registration history from Volo. Contact{' '}
                <a href="mailto:info@volosports.com">info@volosports.com</a> for an invoice if
                needed.
              </small>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default (props: Props) => (
  <Suspense fallback={<PageLoader />}>
    <GenericErrorBoundary>
      <PaymentAction {...props} />
    </GenericErrorBoundary>
  </Suspense>
);
