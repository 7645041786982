import { graphql } from 'graphql-schema';

const INVALIDATE_STRIKE = graphql(`
  mutation InvalidateStrike($strikeId: uuid!, $invalidated: Boolean!) {
    update_player_strikes_by_pk(
      pk_columns: { _id: $strikeId }
      _set: { invalidated: $invalidated, invalidation_type: MANUAL }
    ) {
      _id
      invalidated
      invalidation_type
    }
  }
`);

export default INVALIDATE_STRIKE;
