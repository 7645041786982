import type { ComponentProps } from 'react';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { Pressable, FontAwesomeIcon } from '@rivallapp/volosports-components';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from '../player-details.module.scss';
import { formatCents } from '../../../utils';
import type { ProgramPaymentsProps } from './ProgramPayments';

const cx = classNames.bind(styles);

type DeleteCreditItemProps = {
  onDeleteCredit: ComponentProps<typeof Pressable>['onPress'];
};

const DeleteCreditItem = ({ onDeleteCredit }: DeleteCreditItemProps) => (
  <Pressable alignItems="center" justifyContent="center" onPress={onDeleteCredit}>
    <FontAwesomeIcon icon={faTrashCan} size={5} />
  </Pressable>
);

const ProgramPaymentsList = ({
  payments,
  availableCredits,
  setDeleteCreditState,
}: ProgramPaymentsProps) => {
  return (
    <>
      {payments.map(payment => {
        const {
          status = '',
          net = 0,
          credits = 0,
          donation = 0,
          date,
          type,
          rentalId,
          leagueId,
          reason,
          organizationId,
          manuallyAddedBy,
          paymentId,
          promoCode,
          refund = 0,
          revenue = 0,
        } = payment;

        return (
          <div
            className={cx('invoiceRow', styles[status.toLowerCase()], 'row')}
            role="button"
            tabIndex={0}
            key={paymentId}
          >
            <div className="col-1 my-auto text-left p-0">
              {moment(date).format('MM/DD/YY')} <br />
              {moment(date).format('h:mm a')}
            </div>
            <div className="col-5 my-auto text-left">
              <span>({type})</span>
              {rentalId ? <Link to={`/rfo/rental/${rentalId}`}> {reason} </Link> : null}
              {leagueId ? (
                <Link to={`/rfo/league/${leagueId}/view?organization=${organizationId}`}>
                  {' '}
                  {reason}{' '}
                </Link>
              ) : null}
              {!leagueId && !rentalId ? ` ${reason} ` || ' --- ' : null}
              <span>{payment?.isDropin && ' - DROPIN'}</span>
            </div>
            {manuallyAddedBy ? (
              <>
                <div className="col-3 my-auto">
                  <b>Added by: </b> {manuallyAddedBy}
                </div>
                <div className="col-2 my-auto">
                  {status === 'Credit' && (
                    <span className={cx('greenColor')}>+{formatCents(credits)}</span>
                  )}
                </div>
                <div className="col-1 my-auto p-0">
                  {status === 'Credit' && availableCredits >= net && (
                    <DeleteCreditItem
                      onDeleteCredit={() =>
                        setDeleteCreditState({
                          showCreditPopup: true,
                          creditId: paymentId,
                          organizationId,
                        })
                      }
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col-2 my-auto">{status === 'Paid' && (promoCode || 'N/A')}</div>
                <div className="col-1 my-auto">
                  {status === 'Paid' && (credits ? formatCents(credits) : '---')}
                </div>
                <div className="col-1 my-auto">
                  {status === 'Paid' && (donation ? formatCents(donation) : '---')}
                </div>
                <div className="col-1 my-auto">
                  {refund > 0 && <span className={cx('redColor')}>-{formatCents(refund)}</span>}
                  {status === 'Paid' && !refund && formatCents(net)}
                </div>
                <div className="col-1 my-auto p-0">
                  {status === 'Paid' && !refund && formatCents(revenue)}
                  {status === 'Credit' && availableCredits >= net && (
                    <DeleteCreditItem
                      onDeleteCredit={() =>
                        setDeleteCreditState({
                          showCreditPopup: true,
                          creditId: paymentId,
                          organizationId,
                        })
                      }
                    />
                  )}
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ProgramPaymentsList;
