import { graphql } from 'graphql-schema';

const GET_RENTAL_EMAILS = graphql(`
  query GetRentalEmails($where: users_bool_exp!) {
    users(where: $where) {
      _id
      email
    }
  }
`);

export default GET_RENTAL_EMAILS;
