import { gql, type TypedDocumentNode } from '@apollo/client';
import { graphql } from 'graphql-schema';

import { type DiscoverFilterVariables } from './types';

export const HASURA_DISCOVER_VENUES = graphql(`
  query GetActiveVenues($cityName: String!) {
    venues(
      where: {
        organizationByOrganization: { name: { _eq: $cityName } }
        leagues: {
          status: { _eq: registration_open }
          archived: { _eq: false }
          private: { _eq: false }
        }
      }
    ) {
      _id
      shorthand_name
    }
  }
`);

type DiscoverRentalVenuesData = {
  discoverRentalVenues: {
    venues: {
      _id: string;
      shorthand_name: string;
    }[];
  };
};

export const DISCOVER_RENTAL_VENUES: TypedDocumentNode<
  DiscoverRentalVenuesData,
  DiscoverFilterVariables
> = gql`
  query DiscoverRentalVenues($input: DiscoverRentalsInput!) {
    discoverRentalVenues(input: $input) {
      venues {
        _id
        shorthand_name: shorthandName
      }
    }
  }
`;
