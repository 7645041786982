import { type FC } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import Select from 'react-select';

import AnonNav from './AnonNav';
import HeaderLinks from './HeaderLinks';
import styles from './styles.module.scss';
import { addSearchParams } from '../../utils/url-search-helpers';
import Picture from '../../components/Picture/Picture';
import useCurrentUserV2 from '../../hooks/useCurrentUserV2';
import { useAuthStore } from '../../zustand-stores';

const cx = classNames.bind(styles);

// All routes that display the city select dropdown
const ROUTES_THAT_SHOW_CITY_SELECT = [
  'programs',
  'messages',
  'dashboard',
  'settings',
  'staff',
  'promo-codes',
  'create',
  'leagues',
  'beta/leagues',
  'ratings',
  'approved-venues',
  'city-page-details',
  'city-hero-details',
  'neighborhoods',
  'stripe-setup',
  'daily-dropins',
  'rentals',
].map(route => `/rfo/${route}`);

const RFOHeader: FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { currentUser, organizationId, organizations: allOrgs } = useCurrentUserV2();
  const { roles } = useAuthStore(store => ({ roles: store.roles }));

  // Added to allow ADMIN role to view all organizations
  // Otherwise, only show organizations user has permissions for
  const organizations =
    (roles.includes('ADMIN')
      ? allOrgs
      : currentUser?.organization_admins.map(o => o.organizationByOrganization)) ?? [];
  const organization = organizations.find(({ _id }) => _id === organizationId);
  const organizationList = organizations.map(({ _id: value, name: label }) => ({ value, label }));

  const { logo, icon, name } = organization ?? {};
  const imgSrc = logo || icon;
  const orgLogo = imgSrc ? <Picture className={cx('org-logo')} src={imgSrc} alt={name} /> : null;

  return (
    <header className={cx('header')}>
      <div className="container align-items-center justify-content-between">
        <div className={cx('left')}>
          {ROUTES_THAT_SHOW_CITY_SELECT.some(path => pathname.includes(path)) ? (
            <div className={cx('home-link')}>
              <Link to="/" about="Volo Sports">
                <div className={cx('logo-thumb')} />
              </Link>
              <span className={cx('bar')} />
              <Select
                value={organizationList.find(org => org.value === organizationId)}
                options={organizationList}
                onChange={org => {
                  if (organizationId !== org?.value) {
                    addSearchParams({ history, params: { organization: org?.value } });
                  }
                }}
                className={cx('city-select')}
                styles={{
                  control: base => ({
                    ...base,
                    height: '35px',
                    minHeight: '35px',
                  }),
                }}
              />
            </div>
          ) : (
            <Link
              about="Volo"
              className={cx('home-link')}
              to={{
                pathname: '/rfo/leagues',
                search: `?organization=${organizationId}`,
              }}
            >
              <div className={cx('logo-thumb')} />
              {orgLogo && <span className={cx('bar')} />}
              {orgLogo}
            </Link>
          )}
        </div>
        {currentUser ? <HeaderLinks /> : <AnonNav />}
      </div>
    </header>
  );
};

export default RFOHeader;
