import React from 'react';
import { ActivityIndicator } from 'react-native-web';
import { Button } from '@rivallapp/volosports-components';
import { useQuery, NetworkStatus } from '@apollo/client';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';

import { USER_MEMBERSHIP_HISTORY_QUERY } from './PLAYER_PAYMENT_MUTATION_AND_QUERIES';
import { formatCents } from '../../../utils';
import styles from '../player-details.module.scss';
import { Error } from '../../../components';
import hasuraClient from '../../../apollo/hasuraClient';

const cx = classNames.bind(styles);

type MembershipPaymentsProps = {
  userId: string;
};

const MembershipPayments: React.FC<MembershipPaymentsProps> = ({ userId }) => {
  const { data, error, fetchMore, networkStatus } = useQuery(USER_MEMBERSHIP_HISTORY_QUERY, {
    client: hasuraClient,
    skip: !userId,
    notifyOnNetworkStatusChange: true,
    variables: { userId },
  });

  if (networkStatus === NetworkStatus.loading) return <ActivityIndicator />;
  if (error || !userId) {
    return <Error error={error || 'Missing userId'} />;
  }

  const membershipInvoices = data?.membershipInvoices ?? [];
  const count = data?.invoices_aggregate?.aggregate?.count ?? 0;
  const hasMore = membershipInvoices.length < count;

  if (!membershipInvoices.length) return null;

  const handleLoadMoreInvoices = async () => {
    await fetchMore({
      variables: {
        offset: membershipInvoices.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        return {
          ...fetchMoreResult,
          membershipInvoices: [
            ...previousResult.membershipInvoices,
            ...fetchMoreResult.membershipInvoices,
          ],
        };
      },
    });
  };

  return (
    <div>
      <h3>Volo Pass Payments</h3>
      <div className={cx('title', 'row text-left')}>
        <div className="col-4">Date</div>
        <div className="col-4">Paid</div>
        <div className="col-4">Status</div>
      </div>
      {membershipInvoices.map(({ createdAt, status, total, stripeInvoiceId }) => (
        <div className={cx('invoiceRow', 'row')} key={stripeInvoiceId}>
          <div className="col-4">{moment(createdAt).format('MMM Do YYYY')}</div>
          <div className="col-4">{formatCents(total)}</div>
          <div className="col-4">{status?.toUpperCase()}</div>
        </div>
      ))}
      {hasMore && (
        <Button
          variant="solid"
          colorScheme="brandPrimary"
          onPress={handleLoadMoreInvoices}
          isLoading={networkStatus === NetworkStatus.fetchMore}
        >
          Load More
        </Button>
      )}
    </div>
  );
};

export default MembershipPayments;
