import loadable from '@loadable/component';
import { Switch, Route } from 'react-router-dom';
// eslint-disable-next-line import/named
import { RegistrationProvider } from '../contexts';

const AppDashboard = loadable(() => import('../apps/APP/PlayerDashboard/PlayerDashboard'));
const PlayerProgram = loadable(() => import('../apps/APP/PlayerProgram'), { ssr: false });
const PlayerRental = loadable(() => import('../apps/APP/PlayerRental'), { ssr: false });
const PlayerDropIn = loadable(() => import('../apps/APP/PlayerDropIn'), { ssr: false });
const ErrorPage = loadable(() => import('../pages/ErrorPage'));

const AppController = () => (
  // `useRegistrationData` used throughout these components which requires this context
  <RegistrationProvider>
    <Switch>
      <Route path="/app/dashboard" component={AppDashboard} />
      <Route path="/app/member/:leagueId" component={PlayerProgram} />
      <Route path="/app/reservation/:reservationId" component={PlayerRental} />
      <Route path="/app/:gameId" component={PlayerDropIn} />
      <Route component={ErrorPage} />
    </Switch>
  </RegistrationProvider>
);

export default AppController;
