import classNames from 'classnames/bind';
import orderBy from 'lodash-es/orderBy';
import { useState, useRef, type FC } from 'react';
import { useQuery } from '@apollo/client';
import { useReactToPrint } from 'react-to-print';
import type { ResultOf, VariablesOf } from 'graphql-schema';

import EditScores from './EditGameScores';
import styles from './styles.module.scss';
import WeekItem from './WeekItem';
import DayItem from './DayItem';
import GameItem from './GameItem';
import { Button, Popup, PageLoader } from '../../../../components';
import {
  GET_SCHEDULE_DETAILS,
  SCHEDULE_GAME_FRAGMENT,
} from '../../../../apps/RFO/Manage/Schedule/graphql';
import hasuraClient from '../../../../apollo/hasuraClient';
import { isValidUUID } from '../../../../utils';

const cx = classNames.bind(styles);

type ManageSchedule = {
  leagueId: string;
};

type ScoreState = {
  game: ResultOf<typeof SCHEDULE_GAME_FRAGMENT>;
  teams: string[];
};

const ManageSchedule: FC<ManageSchedule> = ({ leagueId }) => {
  // Used for print view
  const ref = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const [editingScore, setEditingScore] = useState<ScoreState | null>(null);

  type LeagueWhereClauseType = VariablesOf<typeof GET_SCHEDULE_DETAILS>['leagueWhereClause'];
  const leagueWhereClause: LeagueWhereClauseType = {};

  if (leagueId) {
    if (isValidUUID(leagueId)) {
      leagueWhereClause._id = { _eq: leagueId };
    } else {
      leagueWhereClause.external_id = { _eq: leagueId };
    }
  }

  const { data, loading, error, refetch } = useQuery(GET_SCHEDULE_DETAILS, {
    skip: !leagueId,
    fetchPolicy: 'network-only',
    variables: { leagueWhereClause },
    client: hasuraClient,
  });

  if (loading) return <PageLoader />;
  if (error || !leagueId) return <div>Error! {JSON.stringify(error || 'No leagueId!')}</div>;

  const league = data?.leagues?.[0];

  if (!league?.schedule_approved) {
    return <p className="my-2">This program does not have a released schedule yet.</p>;
  }

  const {
    games = [],
    teams = [],
    tournaments = [],
    days = [],
    week_tags = [],
    week_descriptions = [],
    weeks = [],
  } = league ?? {};
  const { timezone } = league || {};

  if (!weeks?.length || !teams?.length || !days?.length || !games?.length) {
    return <div />;
  }

  const editScore = (game?: ResultOf<typeof SCHEDULE_GAME_FRAGMENT> | null) => {
    if (game?._id) {
      setEditingScore({
        game,
        teams: (teams || [])
          .filter(({ _id }) => (game.teams.map(({ team }) => team._id) || []).includes(_id))
          .map(team => team._id),
      });
    } else setEditingScore(null);
  };

  return (
    <div>
      <Button small onClick={handlePrint} className="m-2">
        Print View
      </Button>
      <div className={cx('schedule-wrap')} ref={ref}>
        <div>
          {orderBy(weeks, 'week_num').map(week => {
            if (!tournaments?.length || !week.is_tournament) {
              return (
                <WeekItem
                  key={week._id}
                  {...{
                    week,
                    teams,
                    timezone,
                  }}
                  weekTags={week_tags ?? []}
                  weekDescriptions={week_descriptions ?? []}
                >
                  {days
                    .filter(day => day.week === week._id)
                    .map(day => (
                      <DayItem
                        key={day._id}
                        games={games.filter(game => game.day === day._id)}
                        day={day}
                      >
                        {games
                          .filter(game => game.day === day._id)
                          .map(game => (
                            <GameItem
                              key={game._id}
                              {...{
                                teams,
                                editScore,
                                timezone,
                                game,
                              }}
                            />
                          ))}
                      </DayItem>
                    ))}
                </WeekItem>
              );
            }
            return '';
          })}
        </div>
        {editingScore?.game && (
          <Popup close={editScore} className="fixed" hidden={!editingScore}>
            <EditScores {...editingScore} closePopup={editScore} refetch={refetch} />
          </Popup>
        )}
      </div>
    </div>
  );
};

export default ManageSchedule;
