import { useEffect } from 'react';
import { useSuspenseQuery, skipToken } from '@apollo/client';
import { readFragment } from 'graphql-schema';
import { MembershipEnum } from '../../../constants';
import type usePaymentSummary from './usePaymentSummary';
import canDonateDollarFromReg from '../utils/canDonateDollarFromReg';
import normalizePricingQueryData from '../utils/normalizePricingQueryData';
import { PaymentSummaryVPExclusiveError } from '../PaymentSummaryError';
import type { LeagueRegistrationVariant, Pricing } from '../PaymentSummary';
import {
  DROP_IN_PRICING,
  GET_CURRENT_USER_HOME_ORG,
  LEAGUE_FRAGMENT,
  PROGRAM_PRICING,
} from '../graphql';
import { useCurrentUserV2 } from '../../../hooks';
import {
  VOLO_PASS_MONTHLY_FEE,
  voloPassFindMonthlyPrice,
} from '../../../constants/enums/volo-pass-tiers-enum';

type UseProgramPricingInput = (
  | { isDropIn: true }
  | { isDropIn: false; registrationVariant?: LeagueRegistrationVariant }
) & {
  leagueId: string;
  isDropIn: boolean;
  usePaymentSummaryProps: ReturnType<typeof usePaymentSummary>;
  promoCodeStr?: string;
  creditsAppliedInCents?: number;
  donationsAppliedInCents?: number;
};

/**
 * Custom hook for determining the price of a program, returning the price breakdown as well as some
 * helpful variables.
 */
const useProgramPricing = (props: UseProgramPricingInput) => {
  const {
    isDropIn,
    leagueId,
    creditsAppliedInCents,
    donationsAppliedInCents,
    promoCodeStr,
    usePaymentSummaryProps: { pricingSelected, setPricingSelected, userIsDonatingRegistrationCost },
  } = props;

  const isPrepaidTeam =
    'registrationVariant' in props
      ? props.registrationVariant === 'PREPAID_TEAM_CAPTAIN'
      : undefined;

  const { currentUser } = useCurrentUserV2();
  const currentUserHomeOrgId = currentUser?.home_organization;

  const basePricingInput = {
    leagueId,
    isPrepaidTeam,
    creditAmount: creditsAppliedInCents,
    donationAmount: donationsAppliedInCents,
    isDonatingPortionOfRegistration: userIsDonatingRegistrationCost,
    promoCodeStr,
    // This flag is set to allow anonymous users to view pricing
    ignoreLoggedIn: true,
  };

  const { data: leagueData } = useSuspenseQuery(
    PROGRAM_PRICING,
    !isDropIn
      ? {
          fetchPolicy: 'cache-and-network',
          variables: {
            programId: leagueId,
            regInput: {
              ...basePricingInput,
              membershipName: undefined,
            },
            vpInput: {
              ...basePricingInput,
              membershipName: 'VOLO_PASS',
            },
          },
        }
      : skipToken
  );

  const { data: dropInData } = useSuspenseQuery(
    DROP_IN_PRICING,
    isDropIn
      ? {
          fetchPolicy: 'cache-and-network',
          variables: {
            programId: leagueId,
            input: {
              leagueId,
              creditAmount: creditsAppliedInCents,
              donationAmount: donationsAppliedInCents,
              promoCodeStr,
              isDonatingPortionOfRegistration: userIsDonatingRegistrationCost,
            },
          },
        }
      : skipToken
  );

  const { data: homeOrgData } = useSuspenseQuery(
    GET_CURRENT_USER_HOME_ORG,
    currentUserHomeOrgId ? { variables: { currentUserHomeOrgId } } : skipToken
  );
  const homeOrganization = homeOrgData?.organizations_by_pk;

  const data = leagueData ?? dropInData;

  // Both queries failed, but didn't throw an exception for whatever reason
  if (!data) {
    throw new Error('[useProgramPricing]: Unable to fetch pricing for this program');
  }

  const { program } = data ?? {};
  const league = readFragment(LEAGUE_FRAGMENT, program);
  const {
    is_volo_pass_exclusive = false,
    organization: leagueOrganization,
    program_type,
  } = league ?? {};

  /** either the `current user home org` (if available) or the `league organization` */
  const organization = homeOrganization ?? leagueOrganization;

  if (is_volo_pass_exclusive && !organization?.is_volo_pass_active) {
    // PaymentSummaryError handles the appropriate copy for this error
    throw new PaymentSummaryVPExclusiveError();
  }
  // Reformat the data into a convenient type
  const breakdownMap = normalizePricingQueryData(data);

  const pricingBreakdown = breakdownMap[pricingSelected] ?? breakdownMap.REGULAR;

  if (!pricingBreakdown) {
    throw new Error(
      `No pricing found for ${pricingSelected} (pricing) - Unable to derive pricing breakdown`
    );
  }

  const canDonate = canDonateDollarFromReg(pricingBreakdown, userIsDonatingRegistrationCost);

  const canDonateAtNoCost =
    ('registrationVariant' in props &&
      props.registrationVariant !== 'PREPAID_TEAM_MEMBER' &&
      canDonate) ||
    (!('registrationVariant' in props) && canDonate);

  const { memberPrice, membershipDiscountApplied } = pricingBreakdown;

  // `useSuspenseQuery` doesn't have an `onCompleted` like `useQuery` does, so this is the next best
  // option. It's not really great practice to keep Apollo data in state, but in this case, the
  // pricingSelected variable needs to be exposed to the outside world via `usePaymentSummary`, so
  // there's really not much we can do for the moment.
  useEffect(() => {
    // Guard against invalid memberships for this component.
    if (membershipDiscountApplied === MembershipEnum.VOLO_KIDS_RECURRING) {
      throw new Error('Unsupported membership type!');
    }

    if (is_volo_pass_exclusive) {
      setPricingSelected(MembershipEnum.VOLO_PASS);
    } else {
      setPricingSelected((membershipDiscountApplied ?? 'REGULAR') as Pricing);
    }

    // We want this to only be done on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isVoloPassMember = !!currentUser?.has_volo_pass;

  const programHasVoloPassPricing =
    organization?.is_volo_pass_active && typeof memberPrice === 'number';

  const programHasMemberPricing = programHasVoloPassPricing;

  const voloPassInCart = pricingSelected === MembershipEnum.VOLO_PASS && !isVoloPassMember;

  const voloPassMonthlyPriceCents = organization?.volo_pass_monthly_price_cents;
  const voloPassMonthlyFeeCents = organization?.volo_pass_monthly_fee_cents;

  const monthlyCostOfVoloPassInCents =
    programHasVoloPassPricing && voloPassMonthlyPriceCents ? voloPassMonthlyPriceCents : undefined;

  return {
    programHasMemberPricing,

    isVoloPassMember,
    voloPassInCart,
    monthlyCostOfVoloPassInCents,
    voloPassFeesInCents: voloPassMonthlyFeeCents,
    programHasVoloPassPricing,

    program_type,
    is_volo_pass_exclusive,
    canDonateAtNoCost,

    appliedPricingBreakdown: pricingBreakdown,
  };
};

export default useProgramPricing;
