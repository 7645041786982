import { skipToken, useSuspenseQuery } from '@apollo/client';

import { DISCOVER_RENTAL_VENUES, HASURA_DISCOVER_VENUES } from '../graphql/DISCOVER_VENUES';
import { Row, Stack, Switch, Text, View } from '../../../base-components';
import { CustomCheckbox } from '../../../custom-components';

import styles from '../styles';
import DiscoverFilterBoundary from '../DiscoverFilterBoundary/DiscoverFilterBoundary';

export type VenueFilterProps = {
  onPress?: (arg: string) => void;
  cityName?: string;
  selectedVenueIds?: string[];
  isRental?: boolean;
  /** flag for when to show premier toggle - ie. when main tabs are not rental or volunteer @default true */
  showPremierToggle: boolean;
  isPremierToggled: boolean;
  onTogglePremier: (bool: boolean) => void;
};

/**
 * Simple toggle to show UI that Premier 'Venues' are only shown - this actually relates to just filtering out programs in discover that are `is_premier_only`
 * @see https://docs.google.com/presentation/d/1P31VrRPVHlAsNTan4oHD1PPKHWqKNZuqnKRHLQjSFuA/edit#slide=id.g2f2972ed650_0_9
 */
const PremierToggle = ({
  isPremierToggled,
  onTogglePremier,
}: Pick<VenueFilterProps, 'isPremierToggled' | 'onTogglePremier'>) => {
  return (
    <Row alignItems="center" space={2}>
      <Switch value={isPremierToggled} onToggle={() => onTogglePremier(!isPremierToggled)} />
      <Text>Show only Premier Venues</Text>
    </Row>
  );
};

const VenueFilter = ({
  onPress,
  isRental,
  cityName = '',
  selectedVenueIds = [],
  showPremierToggle = true,
  isPremierToggled = false,
  onTogglePremier,
}: VenueFilterProps) => {
  const { data: rentalVenuesData } = useSuspenseQuery(
    DISCOVER_RENTAL_VENUES,
    cityName && isRental
      ? {
          fetchPolicy: 'cache-and-network',
          variables: {
            input: {
              cityName,
              // Note: Pagination is set to 1000 because this query defaults to a page size of 20
              pagination: { numPerPage: 1000 },
            },
          },
        }
      : skipToken
  );
  const { data: programVenuesData } = useSuspenseQuery(
    HASURA_DISCOVER_VENUES,
    cityName && !isRental
      ? {
          fetchPolicy: 'cache-and-network',
          variables: {
            cityName,
          },
        }
      : skipToken
  );
  const venueNames =
    (isRental ? rentalVenuesData?.discoverRentalVenues.venues : programVenuesData?.venues) ?? [];

  return (
    <View style={styles.container}>
      {showPremierToggle && (
        <Stack my={3} paddingLeft={5}>
          <PremierToggle isPremierToggled={isPremierToggled} onTogglePremier={onTogglePremier} />
        </Stack>
      )}
      {venueNames.map(({ _id: venueId, shorthand_name: shorthandName }) => (
        <CustomCheckbox
          key={venueId}
          value={venueId}
          label={shorthandName}
          onPress={val => onPress?.(val)}
          checked={selectedVenueIds.includes(venueId)}
          isDisabled={isPremierToggled}
        />
      ))}
    </View>
  );
};

export default (props: VenueFilterProps) => (
  <DiscoverFilterBoundary>
    <VenueFilter {...props} />
  </DiscoverFilterBoundary>
);
