import classNames from 'classnames/bind';
import type { CSSProperties, FC } from 'react';
import smile from '../../../images/icons/smile.svg';
import styles from './styles.module.scss';
import { VenuePerks, type PerkEnum } from '../../../shared/venue-features-enum';
import getPerkIcon from '../../../utils/get-perk-icons';
import type { StaffLeagueData } from '../../../apps/Staff/graphql';

const cx = classNames.bind(styles);

type LeaguePerks = {
  league: StaffLeagueData;
  style?: CSSProperties;
  minify?: boolean;
};

const LeaguePerks: FC<LeaguePerks> = ({ league, style, minify }) => {
  const { league_perks } = league;
  const features = league.features.map(feat => feat.feature as PerkEnum).toSorted();

  if (!league_perks && !features.length) return <span />;

  return (
    <div className={cx('info-container')} style={style}>
      <img className={cx('icon')} src={smile} alt="smile" />
      <p className={cx('title')}>Perks</p>
      <div className="row mx-0">
        {[...features, ...(league_perks ? [league_perks] : [])].map(feature => (
          <div className={cx('features', `col-12 col-md-${minify ? '2' : '4'}`)} key={feature}>
            <img src={getPerkIcon(feature) as string} alt="feature" />
            <span>{!minify && (VenuePerks?.[feature as PerkEnum]?.featureName ?? feature)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeaguePerks;
