import { graphql } from 'graphql-schema';

const GET_ORGANIZATION_MEMBERSHIPS = graphql(`
  query Organization($id: uuid!) {
    organization: organizations_by_pk(_id: $id) {
      _id
      is_volo_pass_active
    }
  }
`);

export default GET_ORGANIZATION_MEMBERSHIPS;
