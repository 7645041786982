import { RoleEnum } from '@rivallapp/volosports-components';
import { setContext } from '@apollo/client/link/context/index.js';
export const getAuthHeaders = ({ getRoles, getAuthToken }) => {
    const roles = getRoles();
    const authToken = getAuthToken();
    let currentRole = '';
    if (roles.includes(RoleEnum.PLAYER))
        currentRole = RoleEnum.PLAYER;
    if (roles.includes(RoleEnum.STAFF))
        currentRole = RoleEnum.STAFF;
    if (roles.includes(RoleEnum.OWNER))
        currentRole = RoleEnum.OWNER;
    if (roles.includes(RoleEnum.ADMIN))
        currentRole = RoleEnum.ADMIN;
    const isLoggedIn = authToken && roles.length > 0;
    if (isLoggedIn) {
        return {
            Authorization: authToken,
            'x-hasura-role': currentRole,
        };
    }
    return {};
};
/**
 * Sets 'Authorization' header and 'x-hasura-role' based on "highest" role of user.
 */
const createAuthLink = (args) => setContext((_, { headers }) => {
    const authHeaders = getAuthHeaders(args);
    return {
        headers: {
            ...headers,
            ...authHeaders,
        },
    };
});
export default createAuthLink;
