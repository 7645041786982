import { type TypedDocumentNode, gql } from '@apollo/client';
import { graphql } from 'graphql-schema';
import { StripeMembershipIntervalEnum, StripeSubscriptionStatusEnum } from '../../../constants';

type MembershipDetails = {
  stripeSubscriptionId: string;
  stripePlanId: string;
  planStartsAt: string;
  planExpiresAt: string;
  status: StripeMembershipIntervalEnum;
  billingCycleAnchor: string;
  userHomeOrganization: string | undefined;
  plan: {
    id: string;
    interval: StripeSubscriptionStatusEnum;
  };
  metadata: {
    organization?: string;
    organizationId?: string;
  };
};

type UserMembershipStatusResponse = {
  userIsActiveVoloPassMember: boolean;
  userHasPastVoloPassMembership: boolean;
  userIsActiveVoloFitnessMember: boolean;
  userHasPastVoloFitnessMembership: boolean;
};

type VoloPassMembershipStatusData = {
  userHasPastVoloPassMembership: boolean;
  userIsActiveVoloPassMember: boolean;
  activeVoloPassMembership: MembershipDetails;
  pastVoloPassMembership: MembershipDetails;
};

export const GET_USER_MEMBERSHIP_STATUS: TypedDocumentNode<UserMembershipStatusResponse> = gql`
  query getUserMembershipStatus {
    userIsActiveVoloPassMember
    userHasPastVoloPassMembership
    userIsActiveVoloFitnessMember
    userHasPastVoloFitnessMembership
  }
`;

export const GET_VOLO_PASS_SUBSCRIPTION_DETAILS = graphql(`
  query getVoloPassSubscriptionDetails($userId: uuid!, $type: membership_type_enum!) {
    activeSubscription: subscriptions(
      limit: 1
      order_by: { start_date: asc }
      where: { user: { _eq: $userId }, type: { _eq: $type }, status: { _in: [active, trialing] } }
    ) {
      interval
      # TODO Metdata
      status
      stripe_subscription_id
      stripe_product_id
      organization
      end_date
      start_date
      cancel_at_period_end
    }
    pastSubscription: subscriptions(
      limit: 1
      order_by: { start_date: asc }
      where: {
        user: { _eq: $userId }
        type: { _eq: $type }
        status: { _in: [canceled, incomplete_expired] }
      }
    ) {
      interval
      # TODO Metdata
      status
      stripe_subscription_id
      stripe_product_id
      organization
      end_date
      start_date
    }
  }
`);

/**
 * @deprecated - remove at end of migration
 * @todo migrate stories using this query
 */
export const GET_USER_VOLO_PASS_MEMBERSHIP_DETAILS: TypedDocumentNode<VoloPassMembershipStatusData> = gql`
  query getVoloPassMembershipStatus {
    userHasPastVoloPassMembership
    userIsActiveVoloPassMember
    activeVoloPassMembership {
      stripeSubscriptionId
      stripePlanId
      planStartsAt
      planExpiresAt
      status
      billingCycleAnchor
      userHomeOrganization
      plan {
        id
        interval
      }
      metadata {
        organization
        organizationId
      }
    }
    pastVoloPassMembership {
      stripeSubscriptionId
      stripePlanId
      planStartsAt
      planExpiresAt
      status
      billingCycleAnchor
      plan {
        id
        interval
      }
      metadata {
        organization
        organizationId
      }
    }
  }
`;
