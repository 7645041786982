import classNames from 'classnames/bind';
import { Link, type LinkProps } from 'react-router-dom';

import { getMediumBlueSportIcon } from '../../../../utils/get-sport-icons';
import star from '../../../../images/icons/gold-star-2.svg';
import styles from '../../player-details.module.scss';
import type { RegistrantData } from '../PLAYER_PARTICIPATION_QUERIES';
import { WaiverAction, WaiverStatus } from '../../components';
import { PrintWaiverIconButton } from '../../../../components';

const cx = classNames.bind(styles);

export const ParticipationActivityIcon = ({ activityName }: { activityName?: string }) => {
  if (!activityName) return null;

  return <img src={getMediumBlueSportIcon(activityName)} alt="sport icon" height="35" />;
};

export const ParticipationTeamLink = ({
  teamTo,
  registrantType,
  teamName,
}: {
  teamTo: LinkProps['to'];
  registrantType: string;
  teamName?: string;
}) => {
  return (
    <>
      <b>{registrantType}</b> with <br />
      {teamName ? <Link to={teamTo}>{teamName}</Link> : <p>---</p>}
    </>
  );
};

export const ParticipationRating = () => (
  // 0 for player rating is temporary until we have solution for this on the backend
  <>
    {0}
    <img src={star} className={cx('star')} alt="star" />
  </>
);

export const ParticipationWaiver = ({
  waiver_id,
  waiver_signed,
  waiver_date_signed,
  fullName,
  waiver_reminders,
  registrantId,
  isRental = false,
}: {
  waiver_id: RegistrantData['waiver_id'];
  waiver_signed: RegistrantData['waiver_signed'];
  waiver_date_signed: RegistrantData['waiver_date_signed'];
  fullName: string;
  waiver_reminders: RegistrantData['waiver_reminders'];
  registrantId: string;
  isRental?: boolean;
}) => {
  return (
    <div className={cx('waiver-controls', 'd-flex justify-content-center align-items-center')}>
      <WaiverStatus signed={waiver_signed} />
      {waiver_signed ? (
        <PrintWaiverIconButton
          size="sm"
          backgroundColor="transparent"
          waiverId={waiver_id}
          dateSigned={waiver_date_signed}
          fullName={fullName}
        />
      ) : (
        <WaiverAction
          dateSigned={waiver_date_signed}
          waiverReminders={waiver_reminders}
          userFullName={fullName}
          waiverId={waiver_id}
          registrantId={registrantId}
          isRental={isRental}
        />
      )}
    </div>
  );
};
