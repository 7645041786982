import { Suspense, type FC, type PropsWithChildren } from 'react';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';
import styles from '../styles';
import { Spinner, Text, View } from '../../../base-components';
import { getErrorMessage } from '../../../utilities';

const FallbackComponent: FC<FallbackProps> = ({ error }) => (
  <View style={styles.container}>
    <Text>{getErrorMessage(error)}</Text>
  </View>
);

/** Suspense/Error boundary wrapper for any Discover filters that call their own queries */
const DiscoverFilterBoundary: FC<PropsWithChildren> = ({ children }) => (
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <Suspense fallback={<Spinner />}>{children}</Suspense>
  </ErrorBoundary>
);

export default DiscoverFilterBoundary;
