import classNames from 'classnames/bind';

import type { TPaymentListItem } from '@rivallapp/volosports-components';
import styles from '../player-details.module.scss';

import type { CreditState } from './PlayerPaymentContainer';
import ProgramPaymentsList from './ProgramPaymentsList';

const cx = classNames.bind(styles);

export type ProgramPaymentsProps = {
  payments: TPaymentListItem[];
  availableCredits: number;
  setDeleteCreditState: React.Dispatch<React.SetStateAction<CreditState>>;
};

const ProgramPayments = ({
  payments,
  availableCredits,
  setDeleteCreditState,
}: ProgramPaymentsProps) => {
  /**
   * Payments from the backend are based on registrants and charge data associated with a program.
   * If a user signs up for a program and later gets a partial refund, the charge data is updated.
   * So even though there were 2 separate "payments", only one payment is represented and returned.
   *
   * Changing the resolver to return properly formatted data would be ideal, but that
   * would require updating player-app which uses that same resolver and correctly renders payments/refunds.
   *
   * So instead, we're left with this hacky solution to duplicate payments that were refunded.
   *
   * Long term solution:
   *  - Refactor backend to support tracking separate instances of payments for the same user/program combo
   *  - Update web and mobile accordingly
   */
  const paymentsWithPartials: TPaymentListItem[] = [];

  for (const payment of payments) {
    if (payment.status === 'Refund') {
      paymentsWithPartials.push(
        /**
         * The payment as returned from the backend has "refund" values
         * We can keep most of them as is except the date which should be the date of the refund
         */
        {
          ...payment,
          date: payment.refundIssuedAt || payment.date,
        },
        /**
         * The copy just needs some adjustments to fit into the UI correctly
         */
        {
          ...payment,
          paymentId: `${payment.paymentId}-original`,
          status: 'Paid',
          type: 'P',
          refund: 0,
          net: payment.net + (payment.refund ?? 0),
        }
      );
    } else {
      paymentsWithPartials.push(payment);
    }
  }

  // Date values are unix timestamp strings
  paymentsWithPartials.sort((a, b) => Number(b.date) - Number(a.date));

  return (
    <div>
      <div className={cx('title', 'row')}>
        <div className="col-1">Date</div>
        <div className="col-5">Program Name</div>
        <div className="col-2">Promo</div>
        <div className="col-1">Credit</div>
        <div className="col-1">Donation</div>
        <div className="col-1">Paid</div>
        <div className="col-1">Revenue</div>
      </div>
      <ProgramPaymentsList
        payments={paymentsWithPartials}
        availableCredits={availableCredits}
        setDeleteCreditState={setDeleteCreditState}
      />
      <div className={cx('legend')}>
        <div className="row">
          <div className="col">
            <b>Type Legend</b>
          </div>
          <div className="col">
            <b>P</b> = Paid Registration
          </div>
          <div className="col">
            <b>R</b> = Refund
          </div>
          <div className="col">
            <b>LC</b> = League Credit
          </div>
          <div className="col">
            <b>GC</b> = Gift Card
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramPayments;
