import { skipToken, useSuspenseQuery, type SuspenseQueryHookOptions } from '@apollo/client';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { RoleEnum } from '@rivallapp/volosports-components';
import { graphql, type ResultOf } from 'graphql-schema';
import hasuraClient from '../apollo/hasuraClient';
import { useAuthStore } from '../zustand-stores';
import useOrganizations from './useOrganizations';

/**
 * This hook returns the current user signed in as well as the current organizationId
 * that is being used (from the admin drop down or url params)
 * It also does organization validation to make sure the user has authorization to view the
 * selected organization
 */

const CURRENT_USER_QUERY = graphql(`
  query CurrentUser {
    currentUser {
      _id
      gender
      username
      gender
      stripe_id
      home_organization
      membership_expires
      has_volo_pass
      roles {
        role
      }
      organization_admins(order_by: { organizationByOrganization: { name: asc } }) {
        _id
        organizationByOrganization {
          _id
          name
          logo
          icon
        }
      }
      volo_pass_subscription_status
      available_credits {
        _id
        amount
      }
    }
    paymentSources: getUserPaymentSources {
      id
      last4
      exp_month
      exp_year
      brand
      isDefault
      isExpired
    }
  }
`);

export type CurrentUserData = ResultOf<typeof CURRENT_USER_QUERY>;

const useCurrentUserV2 = (options?: SuspenseQueryHookOptions<CurrentUserData>) => {
  const { search } = useLocation();

  const { isAuthenticatedWithHasura, roles } = useAuthStore(store => ({
    roles: store.roles,
    isAuthenticatedWithHasura: store.isAuthenticatedWithHasura,
  }));

  const { data, ...rest } = useSuspenseQuery(
    CURRENT_USER_QUERY,
    isAuthenticatedWithHasura
      ? {
          client: hasuraClient,
          ...options,
        }
      : skipToken
  );

  const orgData = useOrganizations();

  if (!data || !data?.currentUser?._id) {
    return {
      ...rest,
      organizationId: undefined,
      organizations: [],
      paymentSources: [],
      currentUser: undefined,
    };
  }
  const query = qs.parse(search, { ignoreQueryPrefix: true });

  const organizations = data.currentUser.organization_admins.map(
    o => o.organizationByOrganization._id
  );

  let organizationId = organizations[0];

  const queryOrgId = (query?.organization || organizationId) as string | undefined;

  // No permission check needed if no ord
  if (!queryOrgId || queryOrgId === organizationId) {
    return { ...rest, ...data, organizations: orgData.organizations, organizationId };
  }

  // city admin
  if (roles.includes(RoleEnum.OWNER) && organizations.includes(queryOrgId)) {
    organizationId = queryOrgId;
  }
  // super admin
  if (roles.includes(RoleEnum.ADMIN)) {
    organizationId = queryOrgId;
  }

  return { ...rest, ...data, organizations: orgData.organizations, organizationId };
};

export default useCurrentUserV2;
