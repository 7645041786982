import { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { graphql } from 'graphql-schema';
import { Button, Loading, Popup } from '../../../components';
import styles from './styles.module.scss';
import hasuraClient from '../../../apollo/hasuraClient';

const cx = classNames.bind(styles);

const LEAGUE_CONTACT_QUERY = graphql(`
  query leagueContact($leagueId: uuid!) {
    league: leagues_by_pk(_id: $leagueId) {
      _id
      email: league_contact_email
      name: league_contact_name
      phoneNumber: league_contact_phone_number
      textable: league_contact_textable
    }
  }
`);

const LeagueContact = ({ leagueId, isButton }) => {
  const [showDetails, setShowDetails] = useState(false);

  const {
    data: leagueContactData,
    loading: leagueContactLoading,
    error: leagueContactError,
  } = useQuery(LEAGUE_CONTACT_QUERY, {
    skip: !leagueId,
    client: hasuraClient,
    fetchPolicy: 'cache-and-network',
    variables: { leagueId },
  });

  const { league } = leagueContactData || {};
  const { name, email, phoneNumber, textable } = league || {};

  if (leagueContactLoading) return <Loading kind="inline" />;
  if (leagueContactError) throw new Error(leagueContactError);

  return (
    <>
      {isButton ? (
        <Button default small onClick={() => setShowDetails(true)} role="button" tabIndex={0}>
          Contact host
        </Button>
      ) : (
        <a onClick={() => setShowDetails(true)} role="button" tabIndex={0}>
          Contact host
        </a>
      )}
      <Popup hidden={!showDetails} close={() => setShowDetails(false)}>
        <div className={cx(styles.contactInfo, 'mb-4')}>
          <h3>{name}</h3>
          <p>
            <a href={`mailto:${email}`}>{email}</a>
          </p>
          {phoneNumber && (
            <p>
              {textable ? 'Text or call' : 'Call'} {phoneNumber}
            </p>
          )}
        </div>
        <a onClick={() => setShowDetails(false)} role="button" tabIndex={0}>
          Close
        </a>
      </Popup>
    </>
  );
};

LeagueContact.propTypes = {
  leagueId: PropTypes.string.isRequired,
  isButton: PropTypes.bool,
};

LeagueContact.defaultProps = {
  isButton: false,
};

export default LeagueContact;
