import { graphql, type ResultOf } from 'graphql-schema';

const HASURA_DISCOVER_COMPETITION_LEVELS = graphql(`
  query GetActiveCompetitionLevels($cityName: String!) {
    league_competition_levels(
      where: {
        leagueByLeague: {
          organizationByOrganization: { name: { _eq: $cityName } }
          status: { _eq: registration_open }
          archived: { _eq: false }
          private: { _eq: false }
        }
      }
      distinct_on: [competition_level]
    ) {
      _id
      competition_level
    }
  }
`);

export type HasuraDiscoverCompetitionLevels = ResultOf<typeof HASURA_DISCOVER_COMPETITION_LEVELS>;

export default HASURA_DISCOVER_COMPETITION_LEVELS;
