import { useMutation } from '@apollo/client';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import { useState, type FC } from 'react';

import styles from '../edit-schedule.module.scss';
import { Button, CalendarPicker } from '../../../../../../components';
import { useActionStates } from '../../../../../../hooks';
import hasuraClient from '../../../../../../apollo/hasuraClient';
import { CREATE_DAY } from '../../graphql';

const cx = classNames.bind(styles);

type AddWeekDay = {
  weekId: string;
  leagueId: string;
  is_tournament?: boolean;
  afterSave: (dayId: string) => Promise<void>;
  hideText?: boolean;
};

const AddWeekDay: FC<AddWeekDay> = ({
  weekId,
  leagueId,
  is_tournament = false,
  afterSave,
  hideText = false,
}) => {
  const { setError, setSuccess } = useActionStates({ withAlerts: true });

  const [selectedDate, setSelectedDate] = useState(moment());
  const [createDayMutation] = useMutation(CREATE_DAY, { client: hasuraClient });

  const onSave = async () => {
    try {
      // v1 back end resolver defaulted to noon
      const date = selectedDate.hour(12).toISOString();
      const { data } = await createDayMutation({
        variables: {
          object: { week: weekId, league: leagueId, date, is_tournament: !!is_tournament },
        },
      });
      setSuccess('Created new day');
      if (data?.insert_days_one?._id) {
        afterSave(data.insert_days_one._id);
      }
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div className={cx('select-new-date')}>
      {!hideText && (
        <>
          <h2>Add Day</h2>
          <p>
            Select a new day in this week.
            <br />
            To add a day on a future date, click Add Week to extend the schedule.
          </p>
        </>
      )}
      <CalendarPicker
        moment={selectedDate}
        onChange={setSelectedDate}
        calendarOnly
        hideSave
        className={cx('game-date-picker')}
        style={{ margin: '0 auto' }}
      />
      <Button onClick={onSave}>Add Day</Button>
    </div>
  );
};

export default AddWeekDay;
