import type { ExtractEnum } from 'graphql-schema';

export const DowNameEnum = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};

export const DowAbbrevEnum = {
  MONDAY: 'Mon',
  TUESDAY: 'Tue',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thu',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
  SUNDAY: 'Sun',
};

// currently these are the values saved to days_of_week in the db.
export const DowShortEnum: Record<string, ExtractEnum<'day_of_week_enum'>> = {
  MONDAY: 'M',
  TUESDAY: 'T',
  WEDNESDAY: 'W',
  THURSDAY: 'Th',
  FRIDAY: 'F',
  SATURDAY: 'S',
  SUNDAY: 'Su',
};

export const DowValueEnum = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

export const DaysOfWeek = {
  MONDAY: {
    name: DowNameEnum.MONDAY,
    abbrevName: DowAbbrevEnum.MONDAY,
    shortName: DowShortEnum.MONDAY,
    value: DowValueEnum.MONDAY,
  },
  TUESDAY: {
    name: DowNameEnum.TUESDAY,
    abbrevName: DowAbbrevEnum.TUESDAY,
    shortName: DowShortEnum.TUESDAY,
    value: DowValueEnum.TUESDAY,
  },
  WEDNESDAY: {
    name: DowNameEnum.WEDNESDAY,
    abbrevName: DowAbbrevEnum.WEDNESDAY,
    shortName: DowShortEnum.WEDNESDAY,
    value: DowValueEnum.WEDNESDAY,
  },
  THURSDAY: {
    name: DowNameEnum.THURSDAY,
    abbrevName: DowAbbrevEnum.THURSDAY,
    shortName: DowShortEnum.THURSDAY,
    value: DowValueEnum.THURSDAY,
  },
  FRIDAY: {
    name: DowNameEnum.FRIDAY,
    abbrevName: DowAbbrevEnum.FRIDAY,
    shortName: DowShortEnum.FRIDAY,
    value: DowValueEnum.FRIDAY,
  },
  SATURDAY: {
    name: DowNameEnum.SATURDAY,
    abbrevName: DowAbbrevEnum.SATURDAY,
    shortName: DowShortEnum.SATURDAY,
    value: DowValueEnum.SATURDAY,
  },
  SUNDAY: {
    name: DowNameEnum.SUNDAY,
    abbrevName: DowAbbrevEnum.SUNDAY,
    shortName: DowShortEnum.SUNDAY,
    value: DowValueEnum.SUNDAY,
  },
};
