import { useCallback, useContext } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { OverflowMenu } from '../../../../../components';
import styles from '../../../../../styles/main.module.scss';
import {
  DELETE_PAYMENT_SOURCE,
  SET_PAYMENT_SOURCE_DEFAULT,
} from '../../../../../features/PaymentForm/PLAYER_PAYMENT_MUTATIONS';
import hasuraClient from '../../../../../apollo/hasuraClient';
import { useActionStates } from '../../../../../hooks';
import { RegistrationContext } from '../../../../../contexts';

const cx = classNames.bind(styles);

const PaymentSources = ({ source }) => {
  const { setSuccess, setError } = useActionStates({ withAlerts: true });
  const [deletePayment] = useMutation(DELETE_PAYMENT_SOURCE, { client: hasuraClient });
  const [setDefaultPayment] = useMutation(SET_PAYMENT_SOURCE_DEFAULT, { client: hasuraClient });

  const [registerState] = useContext(RegistrationContext);
  const { currentUserRefetch } = registerState;

  const { isDefault, isExpired, last4, id, exp_month, exp_year, brand } = source || {};

  const getSourceActions = useCallback(
    paymentSource => [
      ...(!paymentSource.isDefault
        ? [
            {
              label: 'Use this payment method',
              disabled: paymentSource.isExpired,
              onClick: async () => {
                try {
                  await setDefaultPayment({
                    variables: { sourceId: paymentSource?.id },
                  });
                  await currentUserRefetch();
                  setSuccess('Successfuly added payment method');
                } catch (e) {
                  setError(e);
                }
              },
            },
          ]
        : []),
      {
        label: 'Delete payment method',
        onClick: async () => {
          try {
            await deletePayment({
              variables: { sourceId: paymentSource?.id },
            });
            await currentUserRefetch();
            setSuccess('Successfuly removed payment method');
          } catch (e) {
            setError(e);
          }
        },
      },
    ],
    [currentUserRefetch, deletePayment, setDefaultPayment, setSuccess, setError]
  );

  return (
    <div
      className={cx(
        'sourceItem',
        'd-flex',
        'justify-content-between',
        'align-items-center',
        'bg-light',
        'p-3',
        'mb-3',
        {
          notDefault: !isDefault,
          isExpired,
        }
      )}
    >
      <div
        className={cx('main', 'text-left')}
        onClick={() => (isDefault ? {} : setDefaultPayment({ paymentSourceId: id }))}
        role="button"
        tabIndex={0}
      >
        {isDefault && <p className={cx('current')}>Current</p>}
        <div className="d-flex align-items-center">
          <div className={cx('brand', brand?.toLowerCase())} />
          <div>
            <p>
              <b>**** **** **** {last4}</b>
            </p>
            <p className={cx('exp')}>
              <small>
                {exp_month}/{exp_year}
              </small>
            </p>
          </div>
        </div>
      </div>

      <OverflowMenu options={getSourceActions(source)} />
    </div>
  );
};

PaymentSources.propTypes = {
  source: PropTypes.shape({
    isDefault: PropTypes.bool,
    isExpired: PropTypes.bool,
    last4: PropTypes.string,
    id: PropTypes.string,
    exp_month: PropTypes.number,
    exp_year: PropTypes.number,
    brand: PropTypes.string,
  }),
};

PaymentSources.defaultProps = {
  source: {},
};

export default PaymentSources;
