import { graphql } from 'graphql-schema';

export const EMAIL_IS_VALID = graphql(`
  mutation EmailIsValid($input: ActionsSignupEmailIsValidInput!) {
    emailIsValid(input: $input) {
      isValid
      value
      message
    }
  }
`);

export const USERNAME_IS_VALID = graphql(`
  mutation UsernameIsValid($input: ActionsSignupUsernameIsValidInput!) {
    usernameIsValid(input: $input) {
      isValid
      value
      message
    }
  }
`);

export const PHONE_NUMBER_IS_VALID = graphql(`
  mutation PhoneNumberIsValid($input: ActionsSignupPhoneNumberIsValidInput!) {
    phoneNumberIsValid(input: $input) {
      isValid
      value
      message
    }
  }
`);

export const ZIP_IS_VALID = graphql(`
  mutation ZipIsValid($input: ActionsSignupZipIsValidInput!) {
    zipIsValid(input: $input) {
      isValid
      value
      message
    }
  }
`);

export const NEW_USER_SIGN_UP = graphql(`
  mutation CreateNewUser($input: ActionsSignupCreateNewUserInput!) {
    createNewUser(input: $input) {
      userId
    }
  }
`);

export const SEND_VERIFICATION_EMAIL = graphql(`
  mutation SendVerificationEmail($input: ActionsSignupSendVerificationEmailInput!) {
    sendVerificationEmail(input: $input) {
      status
    }
  }
`);
