import { graphql } from 'graphql-schema';

const GET_HASURA_GAME_DETAILS = graphql(`
  query GetGameDetails($id: uuid!) {
    games_by_pk(_id: $id) {
      _id
      start_time
      end_time
      venueByVenue {
        _id
        shorthand_name
        formatted_address
        neighborhoodByNeighborhoodId {
          _id
          name
        }
      }
      drop_in_capacity {
        _id
        total_available_spots
      }
      leagueByLeague {
        _id
        program_type
        sportBySport {
          _id
          name
        }
      }
    }
  }
`);

export default GET_HASURA_GAME_DETAILS;
