import { useQuery } from '@apollo/client';
import { GET_VOLO_PASS_SUBSCRIPTION_DETAILS } from './gql/GET_USER_MEMBERSHIP_DETAILS';
import useCurrentUserV2 from '../useCurrentUserV2';

/** simplified hook to return boolean membership status for all membership types */
const useUserMembershipStatus = () => {
  const { currentUser } = useCurrentUserV2();

  const {
    data,
    loading: membershipLoading,
    error: membershipError,
    ...rest
  } = useQuery(GET_VOLO_PASS_SUBSCRIPTION_DETAILS, {
    skip: !currentUser,
    fetchPolicy: 'cache-and-network',
    variables: {
      // assert as we are skipping the query if !currentUser
      userId: currentUser?._id!,
      type: 'VOLO_PASS',
    },
  });

  const isVoloPassMember = Boolean(data?.activeSubscription?.length);
  const isPastVoloPassMember = Boolean(data?.pastSubscription?.length);

  return {
    membershipLoading,
    membershipError,
    isVoloPassMember,
    isPastVoloPassMember,
    currentUserId: currentUser?._id,
    ...rest,
  };
};

export default useUserMembershipStatus;
