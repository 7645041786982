import { useState, useMemo, useCallback } from 'react';
import { TouchableOpacity, FlatList, type ListRenderItem } from 'react-native';
import { useQuery, type ApolloQueryResult } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faCommentDots } from '@fortawesome/pro-light-svg-icons/faCommentDots';
import type { ExtractEnum } from 'graphql-schema';
import { Colors, useTheme } from '../../../theme';
import RsvpSelector from '../RsvpSelector';
import { type PopupType } from '../TeamRoster/TeamRoster';
import { Button, Text, View } from '../../../base-components';
import { TeamRoles, TeamRoleEnum, RSVPReminderType } from '../../../constants/enums';
import styles from './styles';
import RSVPReminder from '../RSVPReminder';
import CHECK_CAN_SEND_RSVP_REMINDER from '../graphql/CHECK_CAN_SEND_RSVP_REMINDER';

export interface RosterItemProps {
  playerId: string;
  displayName: string;
  /** If current user is captain/co-captain/admin */
  hasCaptainPermissions?: boolean;
  /** If current user is co-captain (necessary for checking if user can edit role) */
  isCoCaptain: boolean;
  response?: string;
  teamId?: string;
  gameId?: string;
  teamRole: string;
  rsvpRefetch?: () => Promise<ApolloQueryResult<any>>;
  setSuccess?: (arg: string) => void;
  setError?: (arg: string) => void;
  setSelectedPlayer?: (arg: { playerId: string; playerName: string }) => unknown;
  setSelectedRole?: (arg: { label: string; value: string }) => void;
  setPopup?: (arg: PopupType) => unknown;
  /**
   * Overrides the default chat bubble press action which opens a contact info popup
   * Used in player-app which has a custom contact info bottom sheet modal
   */
  onContactBubblePress?: (playerId: string, isDropin?: boolean) => void;
  /**
   * If true, don't render the RSVP UI.
   * @default false
   * */
  hideRSVPSelector?: boolean;
}

type roleType = { label: string; value: ExtractEnum<'team_role_enum'> };

const RosterItem = ({
  playerId,
  displayName,
  hasCaptainPermissions = false,
  isCoCaptain = false,
  response = '',
  teamId = '',
  gameId = '',
  teamRole,
  hideRSVPSelector,
  rsvpRefetch,
  setSuccess,
  setError,
  setSelectedPlayer = () => {},
  setSelectedRole = () => {},
  setPopup = () => {},
  onContactBubblePress,
}: RosterItemProps) => {
  const { colors } = useTheme();
  const [showRoles, setShowRoles] = useState(false);

  const playerRoles = useMemo(() => {
    return Object.values(TeamRoles)
      .filter(
        role => role.label !== TeamRoles[TeamRoleEnum.TEAM_CAPTAIN].label && role.label !== teamRole
      )
      .map(({ label, value }) => ({ label, value }));
  }, [teamRole]);

  const handleSelectPlayer = useCallback(
    () => setSelectedPlayer({ playerId, playerName: displayName }),
    [displayName, playerId, setSelectedPlayer]
  );

  const handleContactBubblePress = async () => {
    if (onContactBubblePress) {
      onContactBubblePress(playerId);
    } else {
      await handleSelectPlayer();
      await setPopup('selectedPlayer');
    }
  };

  const handleRolePress = useCallback(
    async (role: roleType) => {
      await handleSelectPlayer();
      setSelectedRole(role);
      await setPopup('selectTeamRole');
    },
    [handleSelectPlayer, setPopup, setSelectedRole]
  );

  const renderRoles: ListRenderItem<roleType> = useCallback(
    ({ item }) => {
      return (
        <Button
          key={item.value}
          onPress={() => handleRolePress(item)}
          variant="white"
          style={styles.button}
          _hover={{ backgroundColor: Colors.hover }}
          _pressed={{ backgroundColor: Colors.primary }}
        >
          {item.label}
        </Button>
      );
    },
    [handleRolePress]
  );

  const { data } = useQuery(CHECK_CAN_SEND_RSVP_REMINDER, {
    skip: hideRSVPSelector,
    variables: {
      input: {
        gameId,
        teamId,
        reminder_type: RSVPReminderType.INDIVIDUAL,
        recipient_id: playerId,
      },
    },
  });

  /**
   * A captain or an admin can change a co-captain to team member and vice versa.
   * A co-captain can only change a co-captain to team member.
   * The captain's role cannot be changed.
   */
  const canEditRole =
    (teamRole === TeamRoles[TeamRoleEnum.TEAM_MEMBER].label &&
      hasCaptainPermissions &&
      !isCoCaptain) ||
    (teamRole === TeamRoles[TeamRoleEnum.CO_CAPTAIN].label && hasCaptainPermissions);

  const hasResponse = !!response;

  return (
    <View style={styles.rosterContainer} flex={1} borderBottomColor={colors.border}>
      <View>
        <Text>{displayName}</Text>
        {canEditRole ? (
          <>
            <TouchableOpacity
              style={styles.roleLabelContainer}
              onPress={() => setShowRoles(!showRoles)}
            >
              <Text isLink style={styles.roleLabel}>
                {teamRole}
              </Text>
              <FontAwesomeIcon
                icon={showRoles ? faChevronUp : faChevronDown}
                size={12}
                color={Colors.link.toString()}
                style={styles.icon}
              />
            </TouchableOpacity>

            {showRoles ? (
              <FlatList
                data={playerRoles}
                renderItem={renderRoles}
                horizontal
                showsHorizontalScrollIndicator={false}
              />
            ) : null}
          </>
        ) : (
          <Text style={styles.roleLabel}>{teamRole}</Text>
        )}
      </View>
      <View style={styles.rightContainer}>
        {data?.checkCanSendRSVPReminder?.reminder_eligible &&
          !hideRSVPSelector &&
          !hasResponse &&
          hasCaptainPermissions && (
            <RSVPReminder playerId={playerId} teamId={teamId} gameId={gameId} />
          )}
        {!hideRSVPSelector && (
          <RsvpSelector
            hasCaptainPermissions={hasCaptainPermissions}
            response={response}
            playerId={playerId}
            teamId={teamId}
            gameId={gameId}
            rsvpRefetch={rsvpRefetch}
            setSuccess={setSuccess}
            setError={setError}
          />
        )}
        <TouchableOpacity onPress={handleContactBubblePress}>
          <FontAwesomeIcon
            icon={faCommentDots}
            size={20}
            style={styles.icon}
            color={colors.text.toString()}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default RosterItem;
