import { useMemo } from 'react';
import { skipToken, useSuspenseQuery } from '@apollo/client';
import { getQuarterEndDate } from '@rivallapp/shared';
import type { PlayerStrikesProps } from '../PlayerStrikes';
import { ADMIN_PLAYER_STRIKE_COUNT, PLAYER_STRIKE_COUNT } from '../gql/PLAYER_STRIKE_COUNT';

/**
 * Conditionally calls different queries based on input and returns the output in a unified result.
 */
const usePlayerStrikeCount = (props: PlayerStrikesProps) => {
  const { variant } = props;

  const { data: playerStrikeCountData } = useSuspenseQuery(
    PLAYER_STRIKE_COUNT,
    variant === 'player' ? {} : skipToken
  );

  const { data: adminPlayerStrikeCountData } = useSuspenseQuery(
    ADMIN_PLAYER_STRIKE_COUNT,
    variant === 'admin'
      ? {
          variables: {
            id: props.userId,
          },
        }
      : skipToken
  );

  return useMemo(() => {
    if (variant === 'player') {
      return {
        strikeCount:
          playerStrikeCountData!.currentUser?.player_strikes_aggregate?.aggregate?.count ?? 0,
        nextStrikeReset: getQuarterEndDate(),
        nextStrikeWillTriggerFee:
          !!playerStrikeCountData!.currentUser?.next_strike_will_trigger_fee,
        isVoloPassMember: !!playerStrikeCountData!.currentUser?.has_volo_pass,
      };
    }

    return {
      strikeCount:
        adminPlayerStrikeCountData!.user?.player_strikes_aggregate?.aggregate?.count ?? 0,
      nextStrikeReset: getQuarterEndDate(),
      nextStrikeWillTriggerFee: !!adminPlayerStrikeCountData!.user?.next_strike_will_trigger_fee,
      isVoloPassMember: !!adminPlayerStrikeCountData!.user?.has_volo_pass,
    };
  }, [variant, playerStrikeCountData, adminPlayerStrikeCountData]);
};

export default usePlayerStrikeCount;
