import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import type { ResultOf } from 'graphql-schema';
import type { FC, ReactNode } from 'react';

import styles from './styles.module.scss';
import type {
  SCHEDULE_DAY_FRAGMENT,
  SCHEDULE_GAME_FRAGMENT,
} from '../../../../apps/RFO/Manage/Schedule/graphql';

const cx = classNames.bind(styles);

type DayItem = {
  day: ResultOf<typeof SCHEDULE_DAY_FRAGMENT>;
  games: ResultOf<typeof SCHEDULE_GAME_FRAGMENT>[];
  children?: ReactNode;
};

const DayItem: FC<DayItem> = ({ day, games = [], children }) => {
  const { date, is_tournament } = day;

  return (
    <div className={cx('day-item')}>
      <h3 className={cx('date')}>{moment(date).format('ddd M/D/YY')}</h3>
      {is_tournament && !games.length && (
        <p style={{ fontSize: '12px' }}>
          Click <i>Edit Schedule</i> to add playoff games.
        </p>
      )}
      {children}
    </div>
  );
};

export default DayItem;
