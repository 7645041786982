import type { TPaymentListItem } from '../types';
import type { RegistrantPaymentData } from '../usePlayerPayments';

type PaymentItem = {
  programPaginatedPayments: TPaymentListItem[];
  programTotals: {
    programCount: number;
    programTotalPayments: number;
    programTotalNet: number;
    programTotalRefunds: number;
    programTotalDonations: number;
  };
};

const getPaymentsFromRegistrants = (registrants: RegistrantPaymentData): PaymentItem => {
  const programTotals = {
    programCount: registrants.length,
    programTotalPayments: 0,
    programTotalNet: 0,
    programTotalRefunds: 0,
    programTotalDonations: 0,
  };

  const programPaginatedPayments = registrants.map(registrant => {
    const { _id, created_at, charge, leagueByLeague, is_drop_in, manually_added_by, organization } =
      registrant;

    const donation_cents = charge?.donation_cents ?? 0;
    const credit_cents = charge?.credit_cents ?? 0;
    const after_discount_cents = charge?.after_discount_cents ?? 0;
    const total_charge_cents = charge?.total_charge_cents ?? 0;
    const promo_code_str = charge?.promo_code_str;
    const promoDiscountCents = charge?.promo_discount_cents ?? 0;
    const refund_cents = charge?.refund_cents ?? 0;
    const refund_issued_at = charge?.refund_issued_at;

    /**
     * EXPECTED BEHAVIOR: Full credit/refund amount includes fees but not donations
     * https://volo-company.slack.com/archives/C04HBA50V34/p1725032522187989?thread_ts=1725025933.615749&cid=C04HBA50V34
     *
     * However, issuing a full refund currently refunds the entire charge INCLUDING donations.
     * This is a bug and will be addressed in a later update.
     */
    const maxRefundableCents = total_charge_cents;

    const revenue = after_discount_cents - refund_cents;
    const net = total_charge_cents - refund_cents;

    programTotals.programTotalPayments += total_charge_cents;
    programTotals.programTotalNet += revenue;
    programTotals.programTotalRefunds += refund_cents;
    programTotals.programTotalDonations += donation_cents;

    // In rare cases (in mongo at least), a league may be deleted.
    const reason = leagueByLeague?.name || 'Deleted';
    const leagueId = leagueByLeague?._id;

    return {
      paymentId: _id,
      date: created_at,
      refund: refund_cents,
      refundIssuedAt: refund_issued_at,
      donation: donation_cents,
      credits: credit_cents,
      revenue,
      maxRefundableCents,
      status: refund_cents > 0 ? 'Refund' : 'Paid',
      type: refund_cents > 0 ? 'R' : 'P',
      reason,
      ...(leagueId
        ? {
            leagueId,
          }
        : {
            type: 'Deleted',
          }),
      isDropin: is_drop_in,
      manuallyAddedBy: manually_added_by,
      organizationId: organization,
      promoCode: promo_code_str,
      promoDiscountCents,
      net,
    } satisfies TPaymentListItem;
  });

  return {
    programPaginatedPayments,
    programTotals,
  };
};

export default getPaymentsFromRegistrants;
